import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { Theme } from 'src/theme/types/createPalette';
import { resetPassword } from 'src/apis/auth';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';

import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';

export default function ResetPasswordScreen() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      enqueueSnackbar('Password and Confirm Password needs to be the same.', {
        variant: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      await resetPassword(password);
      submitMetrics(METRICS_TYPE.RESET_PASSWORD);
      history.push('/');
    } catch {
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={8} md={6}>
        <Box my={1}>
          <Typography variant='h6' className={classes.headerTitle}>
            Reset Password
          </Typography>
        </Box>
        <Paper className={classes.formWrapper}>
          <Typography variant='subtitle1'>
            Please enter and confirm your new password
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextInput
              label='Password*:'
              type='password'
              required={true}
              value={password}
              fullWidth={true}
              className={classes.formControl}
              onChange={setPassword}
            />
            <TextInput
              label='Confirm Password*:'
              type='password'
              required={true}
              value={confirmPassword}
              fullWidth={true}
              className={classes.formControl}
              onChange={setConfirmPassword}
            />
            <Button
              color='primary'
              type='submit'
              title='Reset password'
              width='100%'
              height='48px'
              className={classes.formControl}
              disabled={loading}
            />
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },

    headerTitle: {
      color: theme.palette.grayText.main,
    },

    formWrapper: {
      padding: theme.spacing(2),
    },

    formControl: {
      marginTop: theme.spacing(2),
    },
  })
);
