import { FC } from 'react';
import Grid from '@material-ui/core/Grid';

import Button from 'src/components/Button';
import { useViewport } from 'src/hooks/useViewport';
import { SubscriptionDto } from 'src/types';

import { subscriptionPlans } from './constants';
import PricingTier from './PricingTier';

interface PricingListProps {
  onClick: (plan: SubscriptionDto) => void;
}

const PricingList: FC<PricingListProps> = ({ onClick }) => {
  const { isMobile } = useViewport();

  return (
    <Grid container spacing={isMobile ? 0 : 2}>
      {subscriptionPlans.map((plan) => (
        <Grid item xs={12} lg={6} key={plan.name}>
          <PricingTier
            key={plan.name}
            plan={plan}
            cardActions={
              <Button
                title='Choose'
                color='primary'
                width='100%'
                onClick={() => onClick(plan)}
              />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PricingList;
