import {
  Box,
  styled,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  getRiskString,
  normalizeRiskValue,
} from 'src/hooks/useDashboardFilters';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useDashboardCategories from 'src/hooks/useDashboardCategories';
import { formatFilename } from 'src/utils';
import { FiveStarsRating } from 'src/components/FiveStarsRating';

import { RISK_TEXT_ASSOCIATION, useStyles } from '../common';
import { USElectionTableCell } from './USElectionTableCell';

export const RowContainer = styled(TableRow)(() => ({
  '&:hover': {
    opacity: 0.8,
  },
}));
export const CellContent = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));
export const Image = styled('img')(() => ({
  height: 30,
  borderRadius: 2,
  width: 30,
}));

interface RowProps {
  name: string;
  imageUrl?: string;
  columns: string[];
  garmScoreColumns: string[];
  data: { [x: string]: any };
  hostData: { [x: string]: any };
  isHost?: boolean;
  onClick: () => void;
}

const Row = ({
  name,
  data,
  columns,
  garmScoreColumns,
  hostData,
  isHost = false,
  onClick,
}: RowProps) => {
  const classes = useStyles();
  const rowClasses = rowUseStyles();
  const { riskComponents } = useDashboardCategories();
  const riskClasses: { [x: string]: string } = {
    nodssi: classes.nodssi,
    norisk: classes.noRisk,
    low: classes.lowRisk,
    medium: classes.medRisk,
    high: classes.highRisk,
  };

  const hostLabels: { [x: string]: string } = {
    noHost: 'No host',
    noPeriodHost: 'No data during this period',
    positiveHost: 'Trending Positive',
    negativeHost: 'Trending Negative',
    neutralHost: 'Trending Neutral',
  };

  const hostClasses: { [x: string]: string } = {
    noHost: classes.nodssi,
    noPeriodHost: classes.nodssi,
    positiveHost: classes.lowRisk,
    negativeHost: classes.highRisk,
    neutralHost: classes.noRisk,
  };

  const imagePath = formatFilename(data.showOnDB);

  // If image doesn't exist, replace it with a default
  const addDefaultSrc = (ev: any) => {
    ev.target.src = '/images/placeholder.png';
  };

  const showHostLength = data.hostList ? data.hostList.split(',').length : 0;

  const renderHostCell = (col: string, index: number) => {
    let hostLevel = 'noHost';
    if (showHostLength >= index) {
      if (hostData && hostData[col]) {
        if (hostData[col].score === null) {
          hostLevel = 'noPeriodHost';
        } else if (hostData[col].score > 1) {
          hostLevel = 'positiveHost';
        } else if (hostData[col].score < -1) {
          hostLevel = 'negativeHost';
        } else {
          hostLevel = 'neutralHost';
        }
      } else {
        hostLevel = 'noPeriodHost';
      }
    }
    return (
      <TableCell
        key={index}
        style={{
          minWidth: 150,
          color: '#000',
          textAlign: 'center',
          fontSize: '13px',
          position: 'relative',
        }}
        className={[hostClasses[hostLevel], classes.tableFont].join(' ')}
      >
        <Typography
          variant='caption'
          align='center'
          style={{
            fontSize: '0.85rem',
            fontWeight: 'normal',
          }}
          className={rowClasses.risk}
        >
          {hostLevel !== 'noHost' && (
            <>
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                {!!hostData && hostData[col]?.label}
              </span>
              <br />
            </>
          )}

          {hostLabels[hostLevel]}
        </Typography>
      </TableCell>
    );
  };

  const renderGarmScoreCell = (col: string, index: number) => {
    let riskVal = data[riskComponents[col]]?.score;
    const score = data[riskComponents[col]]?.score;
    const oldScore = data[riskComponents[col]]?.oldScore;
    const scoreChanged =
      typeof score === 'number' &&
      typeof oldScore === 'number' &&
      score !== oldScore;

    if (riskVal || riskVal === 0) {
      riskVal = normalizeRiskValue(getRiskString(riskVal));
    } else {
      riskVal = 'nodssi';
    }

    const higherRisks = data.totalEpisodeCount
      ? Math.floor(
          (100 * data[riskComponents[col]]?.episodeCountHigherThanAverage) /
            data.totalEpisodeCount
        )
      : 0;

    const lowerRisks = data.totalEpisodeCount
      ? Math.floor(
          (100 * data[riskComponents[col]]?.episodeCountLowerThanAverage) /
            data.totalEpisodeCount
        )
      : 0;
    return (
      <TableCell key={index} className={classes.scoreCell}>
        <div className={riskClasses[riskVal]}>
          <span>{RISK_TEXT_ASSOCIATION[riskVal]}</span>
          {data[riskComponents[col]] &&
            data[riskComponents[col]].episodeCountHigherThanAverage !==
              null && (
              <span>
                <span style={{ marginRight: 8 }}>
                  &#x2B07;
                  {lowerRisks}
                </span>
                <span>
                  &#x2B06;
                  {higherRisks}
                </span>
              </span>
            )}
          {scoreChanged && (
            <div
              className={rowClasses.riskChange}
              style={
                score > oldScore
                  ? {
                      background: '#FB923C',
                      top: 0,
                      clipPath: 'polygon(0 0, 100% 0, 0 100%)',
                    }
                  : {
                      background: '#10B981',
                      bottom: 0,
                      clipPath: 'polygon(0 0, 100% 100%, 0 100%)',
                    }
              }
            />
          )}
        </div>
      </TableCell>
    );
  };

  const renderCell = (content: string[], width: number) => (
    <TableCell
      style={{
        width: width || 165,
        minWidth: width || 165,
      }}
      align='left'
      className={classes.tableCell}
    >
      {content}
    </TableCell>
  );

  const renderNameCell = () => (
    <TableCell
      style={{
        borderRightWidth: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        height: '100%',
      }}
      className={classes.tableCell}
    >
      <Image
        src={data.logoUrl || imagePath}
        alt={`${name}`}
        onError={addDefaultSrc}
      />
      <div style={{ flex: 1 }}>
        <div>
          <Typography
            variant='caption'
            align='left'
            style={{
              fontSize: 14,
              lineHeight: '20px',
              color: '#2D2D38',
            }}
          >
            {name}
          </Typography>
        </div>
        <Typography
          variant='caption'
          align='left'
          style={{
            fontSize: 12,
            lineHeight: '16px',
            color: '#686874',
          }}
        >
          {data.listenerCount ? `${data.listenerCount} stars` : ''}
        </Typography>
      </div>
    </TableCell>
  );

  return (
    <RowContainer
      style={{
        minHeight: '48px',
        borderColor: '#E1E1E1',
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick();
      }}
    >
      {renderNameCell()}
      {columns.map((col: string, index: number) => {
        if (index === 0) return;
        if (isHost) {
          return renderHostCell(col, index);
        } else if (garmScoreColumns.includes(col)) {
          return renderGarmScoreCell(col, index);
        } else if (col === 'US Elections') {
          return (
            <USElectionTableCell
              key={index}
              content={data.electionsPercentage?.value || 0}
              up={data.electionsPercentage?.up || 0}
              down={data.electionsPercentage?.down || 0}
            />
          );
        } else if (col === 'Top IAB Categories') {
          return (
            <TableCell
              key={index}
              style={{
                width: 230,
                minWidth: 230,
              }}
              align='left'
              className={classes.tableCell}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: 10,
                  rowGap: 6,
                  flexWrap: 'wrap',
                  overflowY: 'auto',
                  maxHeight: 48,
                }}
              >
                {data.iabCategories &&
                  data.iabCategories.map((category: any) => (
                    <CellContent
                      key={category.name}
                      className={classes.cellChip}
                    >
                      {category.name}
                    </CellContent>
                  ))}
              </div>
            </TableCell>
          );
        } else if (col === 'Genres') {
          return renderCell(data.genreList, 165);
        } else if (col === 'No. of stars') {
          return (
            <TableCell
              key={index}
              style={{
                width: 230,
                minWidth: 230,
              }}
              align='left'
              className={classes.tableCell}
            >
              <FiveStarsRating value={data.listenerCount || 0} />{' '}
              {data.listenerCount && data.listenerCount !== '0'
                ? data.listenerCount
                : ''}
            </TableCell>
          );
        }
        return null;
      })}
    </RowContainer>
  );
};

const rowUseStyles = makeStyles(() =>
  createStyles({
    risk: {
      position: 'relative',
    },
    riskChange: {
      position: 'absolute',
      width: 15,
      height: 15,
      left: 0,
      zIndex: 1,
    },
  })
);

export default Row;
