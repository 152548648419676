import { Grid, Slider, styled } from '@material-ui/core';
import React from 'react';
import { Title } from '../Common';
import { PaperContainer } from '../styles';

type SelectionType = 3 | 4 | 5 | 6;

interface SelectionLabels {
  [x: number]: string;
}

interface SelectionMark {
  label?: string;
  value: number;
}

interface SelectionComponentProps {
  axisLabels?: SelectionLabels;
  icon?: boolean;
  onChange: (event: React.ChangeEvent<any>, n: number | number[]) => void;
  type?: SelectionType;
  value?: number | number[];
  step?: number;
  min?: number;
}

interface RangeSelectionComponentProps {
  axisLabels?: SelectionLabels;
  icon?: boolean;
  onChange: (event: React.ChangeEvent<any>, n: number | number[]) => void;
  title?: string;
  type?: SelectionType;
  value?: number | number[];
  filterType?: string;
  step?: number;
  min?: number;
}

const SELECTION_MARKS = {
  3: [{ value: 1 }, { value: 2 }, { value: 3 }],
  4: [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }],
  5: [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }],
  6: [
    { value: 0 },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
  ],
};

export const SliderContainer = styled(Slider)(({ theme }) => ({
  padding: `${theme.spacing(2.5)}px 0`,
  width: 212,
  '& .MuiSlider-track, & .MuiSlider-rail': {
    height: 4,
  },
  '& .MuiSlider-thumb': {
    height: 10,
    marginTop: -3,
    width: 10,
  },
  '& .MuiSlider-markLabel': {
    fontSize: 10,
    fontWeight: 500,
    marginTop: theme.spacing(0.7),
    '&[data-index="0"]': {
      left: '5% !important',
    },
    '&[data-index="4"]': {
      left: '93% !important',
    },
  },
}));

const SecondarySliderContainer = styled(Slider)(({ theme }) => ({
  padding: `${theme.spacing(2.5)}px 0`,
  width: 236,
  '& .MuiSlider-track, & .MuiSlider-rail': {
    height: 3,
  },
  '& .MuiSlider-thumb': {
    height: 10,
    marginTop: -4,
    width: 10,
  },
}));

const GridContainer = styled(Grid)(() => ({
  height: '100%',
}));

export const RangeSelectionContainer = styled(PaperContainer)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const SelectionComponent = ({
  axisLabels,
  onChange,
  type = 4,
  value,
  min = 0,
  step = 1,
}: SelectionComponentProps) => {
  const addLabelsToMarks = (): SelectionMark[] =>
    SELECTION_MARKS[type].map((mark: SelectionMark) => ({
      value: mark?.value,
      ...(axisLabels &&
        axisLabels[mark?.value] && { label: axisLabels[mark?.value] }),
    }));
  const marks = axisLabels ? addLabelsToMarks() : SELECTION_MARKS[type];

  return (
    <GridContainer
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      {type > 4 ? (
        <SecondarySliderContainer
          marks={marks}
          onChange={onChange}
          valueLabelDisplay='off'
          step={step}
          max={type - 1}
          min={min}
          value={value}
        />
      ) : (
        <SliderContainer
          marks={marks}
          onChange={onChange}
          valueLabelDisplay='off'
          step={step}
          max={type - 1}
          min={min}
          value={value}
        />
      )}
    </GridContainer>
  );
};

const RangeSelectionComponent = ({
  axisLabels,
  onChange = () => {},
  title,
  type = 4,
  value = [0, 3],
  min = 0,
  step = 1,
}: RangeSelectionComponentProps): React.ReactElement => (
  <Grid item xs={12}>
    {title && <Title>{title}</Title>}
    <SelectionComponent
      axisLabels={
        axisLabels || { 0: 'No Risk', 1: 'Low', 2: 'Medium', 3: 'High' }
      }
      icon
      onChange={onChange}
      type={type}
      value={value}
      step={step}
      min={min}
    />
  </Grid>
);

export default RangeSelectionComponent;
