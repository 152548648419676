import { baseAPICreator } from './apiCreator';
import { DashboardView } from 'src/types';
import { convertCategoryName } from 'src/utils/category';

const garmApi = baseAPICreator('/view');

export const getViewsAPI = async (): Promise<DashboardView[]> => {
  const { data } = await garmApi.get('');
  return data.map((view: any) => ({
    ...view,
    data: JSON.parse(view.data).map((item: any) => {
      if (item.type === 'score') {
        return {
          ...item,
          data: item.data.map((score: any) => ({
            ...score,
            title: convertCategoryName(score.title),
          })),
        };
      } else {
        return item;
      }
    }),
  }));
};

export const saveViewAPI = async (
  title: string,
  view: string
): Promise<DashboardView> => {
  const { data } = await garmApi.post('', {
    title,
    data: view,
  });
  return data;
};
