import { baseAPICreator } from './apiCreator';
import {
  IGarmScoreShowDetails,
  GarmScoresPerShowBody,
  SpotlightEpisode,
  GarmShowsRequestBody,
  ShowEpisodesType,
  IGarmScoreDto,
} from 'src/types';

const garmApi = baseAPICreator('/garm');

export const getGarmShows = async (): Promise<IGarmScoreShowDetails[]> => {
  const { data } = await garmApi.get('shows');
  return data;
};

export const getFilteredGarmShows = async (
  body: GarmShowsRequestBody
): Promise<{ data: IGarmScoreShowDetails[]; totalCount: number }> => {
  const { data } = await garmApi.post('shows/v2', body);
  return { data: data.data, totalCount: data.totalCount };
};

export const getFilteredGarmShowNames = async (
  body: GarmShowsRequestBody
): Promise<{ showNames: string[] }> => {
  const { data } = await garmApi.post('shows/names', body);
  return { showNames: data.showNames };
};

export const getGarmShowReports = async (
  body: GarmShowsRequestBody
): Promise<any> => {
  const { data } = await garmApi.post('shows/reports', body);
  return data;
};

export const getAllGarmScoresPerShow = async (
  show: string
): Promise<IGarmScoreDto[]> => {
  const { data } = await garmApi.get(`shows/${encodeURIComponent(show)}`);
  return data;
};

export const getGarmScoresPerShow = async (
  show: string,
  body: GarmScoresPerShowBody
): Promise<ShowEpisodesType> => {
  const { data } = await garmApi.post(
    `shows/${encodeURIComponent(show)}/v2`,
    body
  );
  return data;
};

export const getSpotlights = async (): Promise<
  { month: string; episodes: SpotlightEpisode[] }[]
> => {
  const { data } = await garmApi.get('episodes/spotlight/all');
  return data;
};

export const getPublishers = async (): Promise<string[]> => {
  const { data } = await garmApi.get('publisher-details');
  return data;
};

export const getIABCategories = async (): Promise<string[]> => {
  const { data } = await garmApi.get('iab-names-tree');
  return data;
};

export const getGarmSources = async (): Promise<string[]> => {
  const { data } = await garmApi.get('shows/sources');
  return data;
};

export const getGarmAllEpisodes = async (body: {
  episodeIds: string[];
}): Promise<{ data: IGarmScoreDto[] }> => {
  const { data } = await garmApi.post('episodes/all', body);
  return { data };
};
