import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { format } from 'date-fns';
import { MouseEvent, useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { TTimeRange } from 'src/types/general';
import Button from '../Button';

interface TimeRangeSelectorProps {
  defaultRange: TTimeRange;
  onChange?: (timeRange: TTimeRange) => void;
  parentClickable?: boolean;
}

export default function TimeRangeSelector({
  defaultRange,
  onChange,
}: TimeRangeSelectorProps) {
  const classes = useStyles();
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [timeRange, setTimeRange] = useState<TTimeRange>(defaultRange);

  useEffect(() => {
    setTimeRange(defaultRange);
  }, [defaultRange]);

  const formatter = (date: Date) => {
    return format(date, 'MMM dd, yyyy');
  };

  const handleClickDropDownIcon = (event: MouseEvent<HTMLButtonElement>) => {
    setShowRangePicker(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseRangePicker = () => {
    setShowRangePicker(false);
    setTimeRange({
      from: defaultRange.from,
      to: defaultRange.to,
    });
  };

  const handleChangeDateRange = ({ selection }: any) => {
    setTimeRange({
      from: new Date(selection.startDate),
      to: new Date(selection.endDate),
    });
  };

  const handleSetRange = () => {
    onChange?.(timeRange);
    setShowRangePicker(false);
  };

  const popoverId = showRangePicker ? 'time-range-popover' : undefined;

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      className='TimeRangeSelector'
    >
      {[formatter(defaultRange.from), formatter(defaultRange.to)].join(' - ')}
      <IconButton
        className={classes.iconButton}
        onClick={handleClickDropDownIcon}
        aria-describedby={popoverId}
      >
        <ArrowDropDownIcon style={{ fontSize: 24, marginLeft: 6 }} />
      </IconButton>

      <Popover
        id={popoverId}
        open={showRangePicker}
        anchorEl={anchorEl}
        onClose={handleCloseRangePicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display='flex' flexDirection='column' justifyContent='center'>
          <DateRangePicker
            ranges={[
              {
                startDate: timeRange.from,
                endDate: timeRange.to,
                key: 'selection',
              },
            ]}
            onChange={handleChangeDateRange}
          />
          <Button
            title='Set Range'
            color='primary'
            className={classes.setRangeButton}
            onClick={handleSetRange}
          />
        </Box>
      </Popover>
    </Box>
  );
}

const useStyles = makeStyles({
  iconButton: {
    padding: 0,
  },
  setRangeButton: {
    fontSize: '1rem',
  },
});
