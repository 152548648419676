import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { Theme } from 'src/theme/types/createPalette';
import Copy from 'src/icons/Copy';

interface APIKeyProps {
  value: string;
}

export default function APIKey({ value }: APIKeyProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipboard = () => {
    const textArea = document.createElement('textarea');
    textArea.value = value;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        enqueueSnackbar('Copied.', { variant: 'success' });
      } else {
        enqueueSnackbar('Copy was not successful.', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Copy was not successful.', { variant: 'error' });
    }
  };

  return (
    <Box className={classes.apiKey}>
      <Typography>{value}</Typography>
      <IconButton
        className={classes.iconButton}
        onClick={handleCopyToClipboard}
      >
        <Copy />
      </IconButton>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    apiKey: {
      borderRadius: 5,
      padding: theme.spacing(2),
      background: 'rgba(213, 213, 213, 0.5)',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    iconButton: {
      padding: 0,
    },
  })
);
