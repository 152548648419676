import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { Box, Button } from '@material-ui/core';

const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleError = useCallback(
    (error: any, message?: string) => {
      enqueueSnackbar(
        error?.response?.data?.message || message || error?.message,
        {
          variant: 'error',
        }
      );
    },
    [enqueueSnackbar]
  );

  const handleSuccess = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'success' });
    },
    [enqueueSnackbar]
  );

  const handleRefresh = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: 'default',
        autoHideDuration: null,
        action: (snackbarId) => (
          <Box display='flex' flexDirection='row' style={{ gap: 4 }}>
            <Button
              variant='text'
              color='primary'
              size='small'
              onClick={(e) => {
                e.preventDefault();
                window.location.replace('/dashboard');
              }}
              style={{
                color: 'rgb(0, 187, 255)',
                textDecoration: 'underline',
              }}
            >
              Refresh
            </Button>
            <Button
              variant='text'
              color='secondary'
              size='small'
              onClick={(e) => {
                e.preventDefault();
                closeSnackbar(snackbarId);
              }}
              style={{
                color: 'rgb(255, 23, 68)',
                textDecoration: 'underline',
              }}
            >
              Dismiss
            </Button>
          </Box>
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  return { handleError, handleSuccess, handleRefresh };
};

export default useNotification;
