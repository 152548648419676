import { useState, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import Highlighter from 'react-highlight-words';

import { useTextStyles } from 'src/hooks/useTextStyles';

import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/chevronLeft.svg';

interface MatchContentProps {
  searchWords: string[];
  matches: string[];
}

export const MatchContent = ({ searchWords, matches }: MatchContentProps) => {
  const textClasses = useTextStyles();
  const [index, setIndex] = useState<number>(0);

  return (
    <Box display='flex' gridGap={8} flexDirection='column'>
      <Typography
        className={textClasses.xsRegular}
        style={{ color: '#344054' }}
      >
        <Highlighter
          searchWords={searchWords}
          autoEscape={true}
          highlightStyle={{
            background: 'transparent',
            color: '#A855F7',
            fontWeight: 700,
          }}
          style={{
            whiteSpace: 'pre-line',
          }}
          textToHighlight={matches[index] || ''}
        />
      </Typography>
      <Box display='flex' flexDirection='row'>
        <Typography
          className={textClasses.xsRegular}
          style={{ color: '#667085', marginRight: 8 }}
        >{`${index + 1} of ${matches.length}`}</Typography>
        <ArrowLeftIcon
          width={16}
          height={16}
          stroke={index === 0 ? '#D0D5DD' : '#344054'}
          cursor='pointer'
          style={{ marginRight: 4 }}
          onClick={() => index !== 0 && setIndex(index - 1)}
        />
        <ArrowLeftIcon
          width={16}
          height={16}
          stroke={index === matches.length - 1 ? '#D0D5DD' : '#344054'}
          cursor='pointer'
          style={{ transform: 'rotate(180deg)' }}
          onClick={() => index !== matches.length - 1 && setIndex(index + 1)}
        />
      </Box>
    </Box>
  );
};
