import { Typography } from '@material-ui/core';
import React from 'react';
import { SubTitleContainer, TitleContainer } from './styles';

interface props {
  children: React.ReactNode;
}

export const SubTitleComponent: React.FC<props> = ({ children }) => (
  <SubTitleContainer item xs={12}>
    <Typography variant='caption'>{children}</Typography>
  </SubTitleContainer>
);
export const Title: React.FC<props> = ({ children }) => (
  <TitleContainer item xs={12}>
    <Typography variant='caption'>{children}</Typography>
  </TitleContainer>
);
