import { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import Grid from '@material-ui/core/Grid';

import { useViewport } from 'src/hooks/useViewport';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const TOCDocumentPages = {
  'terms-service': 1,
  'privacy-policy': 3,
};

export default function BarometerTOC() {
  const location = useLocation();
  const { width } = useViewport();
  const [numPages, setNumPages] = useState<number[]>([]);

  const scrollInToCurrentLink = () => {
    const link = qs.parse(location.search, { ignoreQueryPrefix: true }).link;
    if (link !== 'terms-service' && link !== 'privacy-policy') return;

    const page = TOCDocumentPages[link] || 1;
    setTimeout(() => {
      document.getElementById(`toc-page-${page}`)?.scrollIntoView();
    }, 2000);
  };

  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(new Array(numPages).fill(0).map((_, index) => index + 1));
    scrollInToCurrentLink();
  };

  return (
    <Grid container>
      <Document file='./TOC.pdf' onLoadSuccess={handleDocumentLoadSuccess}>
        {numPages.map((page) => (
          <div key={page} id={`toc-page-${page}`}>
            <Page width={width} pageNumber={page} />
          </div>
        ))}
      </Document>
    </Grid>
  );
}
