import React from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { makeStyles, createStyles, Typography } from '@material-ui/core';

import Button from './Button';

const useStyles: any = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '36px 16px 24px',
      gap: 12,
      width: '100%',
      minWidth: 320,
    },
    progressBar: {
      width: '100%',
    },
    cancelContainer: {
      width: 34,
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);

interface DownloadingProps {
  value: number;
  title?: string;
  handleCancel: () => void;
}

export const Downloading = ({
  value,
  title = 'Downloading...',
  handleCancel,
}: DownloadingProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography align='left'>{title}</Typography>
      <ProgressBar
        completed={value}
        className={classes.progressBar}
        bgColor='#3F91DE'
      />
      <div className={classes.cancelContainer}>
        <Button
          title='Cancel'
          color='primary'
          height={32}
          fullWidth
          onClick={() => handleCancel()}
        />
      </div>
    </div>
  );
};
