import React, { useEffect } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import makeStyles from '@material-ui/styles/makeStyles';
import createStyles from '@material-ui/styles/createStyles';

import { Theme } from 'src/theme/types/createPalette';

interface StyleProps {
  width?: number | string;
}

export interface ActionDialogProps extends StyleProps {
  title: string;
  isOpen: boolean;
  children?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClose: () => void;
}

export default function ActionDialog({
  title,
  width,
  isOpen,
  maxWidth,
  children,
  onClose,
}: ActionDialogProps) {
  const classes = useStyles({ width });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
  }, [isOpen]);

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      title={title}
      maxWidth={maxWidth}
      aria-label={title}
      classes={{ paper: classes.dialogPaper }}
    >
      <CancelIcon className={classes.closeIcon} onClick={handleClose} />
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      background: '#F3F6F9',
      maxWidth: '100%',
    },
    dialogContent: {
      width: (props: StyleProps) => props.width,
    },
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  }),
);
