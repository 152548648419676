import { useState, useMemo, useEffect, useCallback } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import { format } from 'date-fns';

import Spinner from 'src/components/Spinner';
import {
  TableWrapper,
  HeaderCell,
} from 'src/containers/dashboard/garm/components';
import { ReactComponent as DashboardIcon } from 'src/assets/dashboard/logo.svg';
import {
  ScoringRequestHistoryProps,
  ScoringRequestProps,
} from 'src/types/selfScoring';
import useAuth from 'src/hooks/useAuth';
import { getSelfScoringHistory } from 'src/apis/selfScoring';
import { getGarmDashboards } from 'src/utils';
import { useTextStyles } from 'src/hooks/useTextStyles';

import { ScoringRequestDetails } from './ScoringRequestDetails';

import { ReactComponent as HelpIcon } from 'src/assets/icons/helpCircle.svg';
import { ReactComponent as RefreshIcon } from 'src/assets/icons/refresh.svg';
import { ReactComponent as AlertIcon } from 'src/assets/icons/alert.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100vh',
      padding: 24,
      gap: 16,
      background: '#fff',
      flexWrap: 'nowrap',
    },
    banner: {
      padding: 16,
      borderRadius: 8,
      border: '1px solid #EAECF0',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      minHeight: 102,
    },
    title: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      color: '#344054',
    },
    searchBar: {
      width: 326,
      boxSizing: 'border-box',
      border: '1px solid #D0D5DD',
      padding: '2px 12px',
      borderRadius: 8,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
    viewSelection: {
      width: 135,
      height: 36,
      border: '1px solid #C3C3C7',
      borderRadius: 8,
      padding: '0 16px',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      marginLeft: 8,

      '& .MuiSvgIcon-root': {
        marginTop: -2,
      },

      '&:before, &:after': {
        border: 'none !important',
      },

      '& .MuiSelect-selectMenu': {
        padding: 0,
        paddingRight: 0,
      },
    },
    usedDashboard: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    },
    tableWrapper: {
      '& .MuiTableCell-body': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',

        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    status: {
      borderRadius: 16,
      borderWidth: 1,
      borderStyle: 'solid',
      padding: '2px 8px',
    },
    failedComplete: {
      width: 22,
      height: 22,
      padding: 4,
      borderRadius: '100%',
      border: '1px solid #FECDCA',
      background: '#FEF3F2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

const SortMapping: Record<string, string> = {
  'Request ID': '',
  'Request Date': 'date',
  Dashboard: '',
  'Total No. of Shows': 'shows',
  'Net New RSS Feeds Processing': 'newFeeds',
  'No. of Failed Uploads': '',
  Visibility: '',
};

export const SelfScoringHistory = () => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const [sortBy, setSortBy] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Nullable<ScoringRequestHistoryProps>>(null);
  const [selectedScoring, setSelectedScoring] =
    useState<Nullable<ScoringRequestProps>>(null);

  const { organization } = useAuth();
  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  const handleBack = () => {
    setSelectedScoring(null);
  };

  const fetchData = useCallback(
    async (
      page: number,
      pageSize: number,
      sortBy: string,
      sortOrder: string
    ) => {
      setLoading(true);
      try {
        const res = await getSelfScoringHistory({
          sortBy,
          sortOrder: sortOrder.toUpperCase(),
          page,
          pageSize,
        });
        setData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchData(page, pageSize, sortBy, sortOrder);
  }, [page, pageSize, sortBy, sortOrder]);

  if (selectedScoring) {
    return (
      <ScoringRequestDetails
        request={selectedScoring}
        handleBack={handleBack}
      />
    );
  }

  return (
    <Grid container direction='column' className={classes.container}>
      <Typography className={classes.title}>Scoring Request History</Typography>
      <Grid
        container
        direction='row'
        alignItems='center'
        style={{ marginTop: 24, gap: 16 }}
      >
        <Grid className={classes.banner}>
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ gap: 8 }}
          >
            <Typography className={textClasses.smMedium}>
              Net New RSS Feeds this Month
            </Typography>
            <HelpIcon />
          </Grid>
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ gap: 16 }}
          >
            <Typography className={classes.title}>
              {data?.netNewFeeds}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.banner}>
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ gap: 8 }}
          >
            <Typography className={textClasses.smMedium}>
              RSS Feeds Currently Processing
            </Typography>
            <HelpIcon />
          </Grid>
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ gap: 8 }}
          >
            <Typography className={classes.title}>
              {data?.processingFeeds}
            </Typography>
            <RefreshIcon />
          </Grid>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        style={{
          overflowX: 'scroll',
          boxShadow: '0px 0px 0px 1px #E0E0E0',
          borderRadius: 4,
          flex: 1,
          flexGrow: 1,
          marginTop: 30,
        }}
      >
        <TableWrapper
          stickyHeader
          style={{
            borderRadius: 4,
            height: '100%',
            borderCollapse: 'collapse',
          }}
          className={classes.tableWrapper}
        >
          <TableHead
            style={{
              position: 'sticky',
              top: -1,
              zIndex: 2,
            }}
          >
            <TableRow>
              {[
                'Request ID',
                'Request Date',
                'Dashboard',
                'Total No. of Shows',
                'Net New RSS Feeds Processing',
                'No. of Failed Uploads',
                'Visibility',
              ].map((column: string) => (
                <HeaderCell
                  key={column}
                  sortDirection={
                    sortBy === SortMapping[column] ? sortOrder : false
                  }
                  style={{
                    padding: '12px 20px',
                    borderRadius: 0,
                    background: '#F9FAFB',
                  }}
                >
                  {SortMapping[column] ? (
                    <TableSortLabel
                      disabled={['Top IAB Categories', 'Genres'].includes(
                        column
                      )}
                      active={sortBy === SortMapping[column]}
                      direction={
                        sortBy === SortMapping[column] ? sortOrder : 'asc'
                      }
                      onClick={() => {
                        setSortOrder(
                          sortBy === SortMapping[column] && sortOrder === 'asc'
                            ? 'desc'
                            : 'asc'
                        );
                        setSortBy(SortMapping[column]);
                      }}
                    >
                      <span className={textClasses.xsBold}>{column}</span>
                    </TableSortLabel>
                  ) : (
                    <span className={textClasses.xsBold}>{column}</span>
                  )}
                </HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableRow style={{ height: '100%', border: 'none' }}>
              <TableCell colSpan={7}>
                <Spinner />
              </TableCell>
            </TableRow>
          ) : (
            <TableBody>
              {data?.requests.map((item) => (
                <TableRow
                  key={item.id}
                  style={{
                    minHeight: '48px',
                    borderColor: '#E1E1E1',
                  }}
                >
                  <TableCell>
                    <Typography
                      variant='caption'
                      align='left'
                      className={textClasses.xsRegular}
                      style={{
                        color: '#155EEF',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSelectedScoring(item)}
                    >
                      {item.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='caption'
                      align='left'
                      className={textClasses.xsRegular}
                      style={{
                        color: '#475467',
                      }}
                    >
                      {format(new Date(item.date || new Date()), 'MM/dd/yyyy')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div className={classes.usedDashboard}>
                      <DashboardIcon />
                      <Typography
                        variant='caption'
                        align='left'
                        className={textClasses.xsRegular}
                        style={{
                          color: '#155EEF',
                        }}
                      >
                        {
                          garmDashboards.filter(
                            (dashboard) => dashboard.id === item.dashboardId
                          )[0]?.name
                        }
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='caption'
                      align='left'
                      className={textClasses.smRegular}
                      style={{
                        color: '#475467',
                      }}
                    >
                      {item.shows}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='caption'
                      align='left'
                      className={textClasses.smRegular}
                      style={{
                        color: '#475467',
                      }}
                    >
                      {item.processingNewFeeds}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant='caption'
                      align='left'
                      className={textClasses.smRegular}
                      style={{
                        color: '#475467',
                      }}
                    >
                      {item.failedShows}
                    </Typography>
                  </TableCell>
                  <TableCell
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 4,
                      height: '100%',
                    }}
                  >
                    <div
                      className={classes.status}
                      style={
                        item.status.toLowerCase() === 'processing'
                          ? { background: '#EFF8FF', borderColor: '#B2DDFF' }
                          : { background: '#ECFDF3', borderColor: '#ABEFC6' }
                      }
                    >
                      <Typography
                        variant='caption'
                        align='left'
                        className={textClasses.smRegular}
                        style={{
                          color:
                            item.status.toLowerCase() === 'processing'
                              ? '#175CD3'
                              : '#067647',
                        }}
                      >
                        {item.status.toLowerCase() === 'processing'
                          ? 'Processing'
                          : 'Complete'}
                      </Typography>
                    </div>
                    {!!item.failedShows &&
                      item.status.toLowerCase() === 'complete' && (
                        <div className={classes.failedComplete}>
                          <AlertIcon />
                        </div>
                      )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow style={{ height: '100%', border: 'none' }}>
                <TableCell style={{ border: 'none' }} />
              </TableRow>
            </TableBody>
          )}
          <TableFooter
            style={{
              position: 'sticky',
              bottom: -1,
              zIndex: 1,
              background: 'white',
            }}
          >
            <TableRow>
              <TableCell
                colSpan={1000}
                style={{
                  padding: 0,
                  boxShadow: 'inset 0 7px 2px -7px rgba(0,0,0,0.4)',
                }}
              >
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  style={{
                    position: 'sticky',
                    top: '50%',
                    left: 20,
                    maxWidth: 500,
                  }}
                >
                  {/* @ts-ignore */}
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={(_event: any, newPage: number) => {
                      setPage(newPage);
                    }}
                    onRowsPerPageChange={(event: any) => {
                      setPageSize(parseInt(event.target.value, 10));
                      setPage(0);
                    }}
                    count={data?.totalRequests || 0}
                    page={0}
                    rowsPerPage={10}
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      fontSize: 12,
                    }}
                    nextIconButtonProps={{
                      size: 'small',
                    }}
                    backIconButtonProps={{
                      size: 'small',
                    }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
          </TableFooter>
        </TableWrapper>
      </TableContainer>
    </Grid>
  );
};
