import clsx from 'clsx';
import { useHistory } from 'react-router';
import Box from '@material-ui/core/Box';

import { useLayoutStyles } from './useCommonStyles';

interface BarometerLogoProps {
  height?: number;
}

const BarometerLogo: React.FC<BarometerLogoProps> = ({ height = 42 }) => {
  const history = useHistory();
  const layoutClasses = useLayoutStyles();

  const handleGotoDashboard = () => {
    history.push('/dashboard');
  };

  return (
    <Box
      display='flex'
      flexWrap='no-wrap'
      justifyContent='center'
      alignItems='center'
      onClick={handleGotoDashboard}
    >
      <img
        src={'/images/icons/barometer.png'}
        height={height}
        alt='barometer'
        className={clsx('BarameterLogo', layoutClasses.ml2)}
      />
    </Box>
  );
};

export default BarometerLogo;
