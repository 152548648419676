import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 'calc(100% - 8px)',
      margin: '0 4px',
      background: '#E8E5E2',
      borderRadius: 2,
      padding: '85px 0',
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        width: 'calc(100% - 32px)',
        margin: '0 16px',
      },
    },
    bottomBorder: {
      position: 'absolute',
      top: '100%',
      left: 0,
      width: '100%',
      height: 8,
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
    },
    mainContainer: {
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 32,

      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
        padding: '0 100px',
      },

      '& > div': {
        flex: 1,
      },
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '40px',
      textTransform: 'uppercase',
      color: '#202226',
    },
    description: {
      fontWeight: 300,
      fontSize: 48,
      lineHeight: '56px',
      color: '#545454',
    },
    email: {
      width: '100%',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',

        '@media screen and (min-width: 768px)': {
          fontSize: 25,
          lineHeight: '40px',
          color: '#545454',
        },
      },
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 56,
      height: 56,
      borderRadius: '100%',
      cursor: 'pointer',
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',

      '@media screen and (min-width: 768px)': {
        width: 82,
        height: 82,
      },

      '& .MuiTypography-root': {
        fontWeight: 700,
        fontSize: 30,
        lineHeight: '40px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#EFEDEB',

        '@media screen and (min-width: 768px)': {
          fontWeight: 500,
          fontSize: 64,
          lineHeight: '96px',
        },
      },
    },
  })
);

const Contact: FC = () => {
  const classes = useStyles();

  const handleEnterEmail = () => {
    const registerFormLaunchButton = document.getElementById(
      'newsletter-email-capture'
    );

    if (!registerFormLaunchButton) return;

    registerFormLaunchButton.click();
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div>
          <Typography className={classes.title}>Contact</Typography>
          <Typography className={classes.description}>
            Get in touch with our team and request a demo
          </Typography>
        </div>
        <Box width={1} display='flex' alignItems='center' gridGap='24px'>
          <Box flex={1}>
            <TextField
              id='enter email'
              label='Enter your email'
              className={classes.email}
            />
          </Box>
          <Box className={classes.button} onClick={() => handleEnterEmail()}>
            <Typography>⟩</Typography>
          </Box>
        </Box>
      </div>
      <div className={classes.bottomBorder} />
    </div>
  );
};

export default Contact;
