import { SubscriptionTier } from './subscription';

// User Types Enum
export enum UserRole {
  VIEW = 'view',
  STAFF = 'staff', // company staff
  ADMIN = 'admin', // company admin
  SUPER = 'super', // our superusers
}

export interface UserBase {
  email: string;
  firstName: string;
  lastName: string;
  organizationId: string;
  password: string;
  role?: UserRole;
  enabled?: boolean;
}

export interface InvitationBase {
  email: string;
  firstName: string;
  lastName: string;
  role?: UserRole;
}

export interface UserPayload {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  role?: UserRole;
  organizationId: string;
  enabled?: boolean;

  joinedAt: Date;
  lastLoggedAt: Date;
}

export interface User extends UserPayload {
  apiKey: string;
  fullName: string;
  organizationName: string;
  subscriptionTier: SubscriptionTier;
}

export interface Member extends User {
  invited?: boolean;
  inviterId?: string;
  invitationCode?: string;
  invitationLink?: string;
}

export interface SignedUser extends User {
  token: string;
}

export type TUserInfoForm = {
  name: string;
  email: string;
  password: string;
  workspaceName: string;
  teamSize: string;
  industry: string;
  joinReason: string;
};

export interface TAcceptInvitationBody {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  invitationCode: string;
  currentJob?: string;
  joinReason?: string;
}

export type TVerifyResetPasswordCode = {
  code: string;
  email: string;
};

export type TVerifyResetCodeAndUpdatePassword = TVerifyResetPasswordCode & {
  password: string;
};
