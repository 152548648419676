import { MouseEventHandler } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import BarometerLogo from 'src/components/BarometerLogo';
import Button from 'src/components/Button';
import {
  useBackgroundColorStyles,
  useLayoutStyles,
} from 'src/components/useCommonStyles';
import { useScheduleDemoForm } from 'src/hooks/useScheduleDemoForm';
import { useViewport } from 'src/hooks/useViewport';
import { Theme } from 'src/theme/types/createPalette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: theme.palette.grayText.main,
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    brand: {
      '& > img': {
        transition: 'transform .25s linear',
      },
      '&:hover': {
        '& > img': {
          transform: 'scale(1.1);',
        },
      },
    },
  })
);

const Footer = () => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const backgroundColorClasses = useBackgroundColorStyles();

  const { isMobile } = useViewport();

  const { launchScheduleDemo } = useScheduleDemoForm({
    automaticLaunch: false,
  });

  const handleClickScheduleDemo: MouseEventHandler = (event) => {
    event.preventDefault();
    launchScheduleDemo();
  };

  return (
    <Grid
      container
      justifyContent='center'
      className={backgroundColorClasses.white}
    >
      <Grid item xs={12} md={10} xl={8}>
        <Box sx={{ width: '100%' }} py={9}>
          <Typography
            variant='body1'
            align='center'
            style={{
              fontWeight: '500',
              fontSize: 20,
              marginBottom: isMobile ? 40 : 0,
            }}
          >
            Barometer is an accredited partner building trust between brands and
            publishers.
          </Typography>
          <Box
            py={2}
            px={2}
            display='flex'
            justifyContent='space-around'
            alignItems='center'
          >
            <a
              href='https://iabtechlab.com/'
              rel='noreferrer'
              target='_blank'
              className={classes.brand}
            >
              <img
                src={'/images/brands/iab_member_tech_lab.png'}
                height='80px'
                alt='iab_member_tech_lab'
              />
            </a>
            <a
              href='https://wfanet.org/leadership/garm/about-garm'
              rel='noreferrer'
              target='_blank'
              className={classes.brand}
            >
              <img src={'/images/brands/garm.png'} height='60px' alt='garm' />
            </a>
            <a
              href='https://www.iab.com/'
              rel='noreferrer'
              target='_blank'
              className={classes.brand}
            >
              <img
                src={'/images/brands/iab_memberseal.gif'}
                height='80px'
                alt='iab_memberseal'
              />
            </a>
            <a
              href='https://www.edisonresearch.com/top-50-most-listened-to-podcasts-in-the-u-s-q2-2022/'
              rel='noreferrer'
              target='_blank'
              className={classes.brand}
            >
              <img
                src={'/images/spotlight/edison.png'}
                height='65px'
                alt='edison'
              />
            </a>
          </Box>
          <Box
            px={2}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <BarometerLogo height={38} />

            <Typography
              variant='body1'
              style={{
                fontWeight: 'normal',
                fontSize: 16,
                color: '#787878',
                marginTop: isMobile ? 40 : 0,
                marginBottom: isMobile ? 40 : 0,
              }}
            >
              © 2022 Vericrypt, Inc. All rights reserved.
            </Typography>

            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <a href='mailto:tamara@thebarometer.co' className={classes.link}>
                <Typography
                  variant='body1'
                  style={{
                    fontWeight: '500',
                    fontSize: 16,
                    color: 'black',
                  }}
                >
                  Contact us
                </Typography>
              </a>

              <Button
                className={layoutClasses.borderRadius3}
                color='primary'
                title='Schedule Demo'
                onClick={handleClickScheduleDemo}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Footer;
