import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles: any = makeStyles(() =>
  createStyles({
    container: {
      display: 'inline-block',
      position: 'relative',
      fontSize: 16,
    },
    progress: {
      position: 'absolute',
      top: 0,
      left: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 0,
      color: '#EAB308',
    },
  })
);

export const FiveStarsRating = ({ value }: { value: number }) => {
  const classes = useStyles();
  const progress = (value > 5 ? 5 : value) * 20;

  return (
    <div className={classes.container}>
      {String.fromCodePoint(0x2606)}
      {String.fromCodePoint(0x2606)}
      {String.fromCodePoint(0x2606)}
      {String.fromCodePoint(0x2606)}
      {String.fromCodePoint(0x2606)}
      <div style={{ width: `${progress}%` }} className={classes.progress}>
        {String.fromCodePoint(0x2605)}
        {String.fromCodePoint(0x2605)}
        {String.fromCodePoint(0x2605)}
        {String.fromCodePoint(0x2605)}
        {String.fromCodePoint(0x2605)}
      </div>
    </div>
  );
};
