import { baseAPICreator } from './apiCreator';
import { FileUploadRequest } from 'src/types/file';
import { toBase64 } from 'src/utils/file';

const fileApi = baseAPICreator('/file');

export const uploadFile = async (file: File) => {
  const fileContent = await toBase64(file);
  const body: FileUploadRequest = {
    base64Content: fileContent as string,
    fileName: file.name,
    fileMime: file.type,
  };
  const { data } = await fileApi.post('/upload', body);
  return data;
};
