import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ActionDialog from 'src/components/ActionDialog';
import { useSubscription } from 'src/hooks/useSubscription';
import { SubscriptionDto } from 'src/types';

import PaymentMethodForm from './PaymentMethodForm';

interface ChangePricingTierDialogProps {
  plan: SubscriptionDto;
  onClose: VoidFunction;
  onOK: VoidFunction;
}

const ChangePricingTierDialog = ({
  plan,
  onClose,
  onOK,
}: ChangePricingTierDialogProps) => {
  const { handleRegisterCustomerSubscription } = useSubscription(plan);

  const onRegisterCustomerSubscription = async (
    token: string,
    promoCode?: string,
  ) => {
    await handleRegisterCustomerSubscription({ token, promoCode, onClose });
    onOK();
  };

  return (
    <ActionDialog
      isOpen
      onClose={onClose}
      title='Billing Upgrade Dialog'
      width={800}
      maxWidth='md'
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h5'>
            Please enter your payment details:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <PaymentMethodForm
            planCost={plan.amount}
            onCancel={onClose}
            onPayNow={onRegisterCustomerSubscription}
          />
        </Grid>
      </Grid>
    </ActionDialog>
  );
};

export default ChangePricingTierDialog;
