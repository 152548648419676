import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from './Button';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
  }),
);

interface LinkButtonProps extends LinkProps {
  title: string;
  className?: string;
  width?: string | number;
  color?: 'primary' | 'secondary';
  variant?: 'text' | 'contained';
  style?: any;
}

const LinkButton: FC<LinkButtonProps> = ({
  target,
  title,
  to,
  width,
  className,
  variant = 'contained',
  color = 'primary',
  style = {},
}) => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link} target={target}>
      <Button
        color={color}
        width={width}
        variant={variant}
        className={className}
        style={style}
      >
        {title}
      </Button>
    </Link>
  );
};

export default LinkButton;
