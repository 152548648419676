import React, { FC, useState, useEffect } from 'react';
import { Grid, IconButton, makeStyles, createStyles } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import { IGarmCategory } from 'src/types';
import { Theme } from 'src/theme/types/createPalette';
import { useLayoutStyles } from 'src/components/useCommonStyles';
import ArticleScoredView from 'src/components/content-view/ArticleScoredView';
import GarmAccordion from 'src/components/content-view/GarmAccordion';
import useDashboardCategories from 'src/hooks/useDashboardCategories';

const addHighlightValues = (
  values: any,
  highlightStrings: any,
  isUtterance: boolean
) => {
  if (Object.prototype.toString.call(values) === '[object Array]') {
    highlightStrings = highlightStrings.concat(values);
  } else if (typeof values === 'object') {
    if (isUtterance && values.result) {
      highlightStrings = highlightStrings.concat(
        values.result?.map((item: any) => item.utterance)
      );
    } else {
      highlightStrings = highlightStrings.concat(Object.keys(values));
    }
  }

  return highlightStrings;
};
const getHighlightStrings = (episode: any, categories: IGarmCategory[]) => {
  let highlightStrings: any[] = [];

  categories.forEach((cat) => {
    if (!cat.enabled) {
      return;
    }
    return (cat.entries as any[]).forEach((entry: any) => {
      const riskVal = episode[entry.jsonKeyRisk];

      if (!riskVal) {
        return;
      }

      if (!!episode[entry.jsonKeyData]) {
        highlightStrings = addHighlightValues(
          episode[entry.jsonKeyData],
          highlightStrings,
          false
        );
      }

      if (!!episode[entry.jsonKeyDataHighlight]) {
        highlightStrings = addHighlightValues(
          episode[entry.jsonKeyDataHighlight],
          highlightStrings,
          false
        );
      }
    });
  });

  return Array.from(new Set(highlightStrings));
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      width: '100vw',
      height: '100vh',
    },
    spotPanel: {
      width: '100%',
      height: 720,
    },
    button: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(2),
    },
    rightAlign: {
      float: 'right',
    },
    rightPanel: {
      overflow: 'auto',
      maxHeight: '100%',
      paddingTop: 60,
    },
  })
);

interface GarmArticleContentProps {
  episode: any;
  show: string;
  showGenres: string[];
  isSpotlight?: boolean;
  onClose: VoidFunction;
}

const GarmArticleContent: FC<GarmArticleContentProps> = ({
  episode,
  show,
  showGenres,
  isSpotlight = false,
  onClose,
}) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const { dashboardCategories } = useDashboardCategories();

  const [search, setSearch] = useState('');

  useEffect(() => {
    function handleKey(event: any) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    document.addEventListener('keyup', handleKey);

    return function () {
      document.removeEventListener('keyup', handleKey);
    };
  }, []);

  const scoredAt = new Date(Date.now() - 24 * 60 * 60 * 1000);

  if (!episode) {
    return null;
  }

  const articleScore = {
    titleColloquialism: 0.8594477167382907,
    contentColloquialism: 0.6186128243566115,
    titleEditorialBias: 0.04324100132184935,
    contentEditorialBias: 0.00464841873053982,
    titleSentiment: 5,
    contentSentiment: 1,
    summarization:
      episode['Transcription'] && episode['Transcription'].length > 500
        ? episode['Transcription'].slice(0, 500) + '...'
        : episode['Transcription'],
    keywordsList: showGenres,
    readTime: '1.43 minutes',
    posScore: {
      VERB: 2,
    },
    sesScore: {
      title: 0,
      content: 0,
      titleHighlightings: {
        predictionProbability: 1,
        prediction: 1,
        distances: {
          test: 0,
        },
      },
      contentHighlightings: {
        predictionProbability: 1,
        prediction: 1,
        distances: {
          test: 0,
        },
      },
    },
  };

  const article = {
    guid: episode['Guid'],
    title: episode['Episode Title'],
    content: episode['Transcription'],
    articleURL: episode['Episode Link'],
    audioURL: episode['Audio File (AUTO)'],
    readTime: '1.43 minutes',
    publishedAt: episode['Episode Date'],
    scoredAt: scoredAt,
    score: articleScore,
    highlightStrings: getHighlightStrings(episode, dashboardCategories),
  };

  const iabCategories = episode['IAB New Data']
    ? Object.keys(episode['IAB New Data'])
    : episode['IAB Data']
    ? Object.keys(episode['IAB Data'][0][0])
    : [];

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Grid container className={isSpotlight ? classes.spotPanel : classes.panel}>
      <Grid
        item
        xs={7}
        style={{
          paddingTop: 5,
          paddingRight: 25,
          backgroundColor: '#FAFAFA',
          overflow: 'auto',
          maxHeight: '100%',
        }}
      >
        <Grid
          container
          justifyContent='flex-start'
          style={{
            position: 'sticky',
            top: 0,
            marginBottom: 20,
          }}
        >
          <Grid item className={layoutClasses.pl2}>
            <IconButton className={classes.rightAlign} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <ArticleScoredView
          article={article}
          search={search}
          show={show}
          showGenres={showGenres}
          iabCategories={iabCategories}
        />
      </Grid>

      <Grid item xs={5} className={classes.rightPanel}>
        {episode && (
          <GarmAccordion
            episode={episode}
            search={search}
            onChangeSearch={handleChangeSearch}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GarmArticleContent;
