import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { DropDownContainer } from '../styles';

interface DropDownOption {
  label: string;
  value?: string | number;
}

interface DropDownComponentProps {
  label?: string;
  onChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => void;
  options?: DropDownOption[];
  value?: string | number;
}

const DropDownComponent = ({ label, onChange, options, value = '' }: DropDownComponentProps): React.ReactElement => (
  <Grid item xs={12}>
    <DropDownContainer>
      <FormControl fullWidth>
        {label && <InputLabel>{label}</InputLabel>}
        <Select onChange={onChange} value={value} fullWidth>
          {options?.map((o: DropDownOption, index: number ) => (
            <MenuItem key={`${o.label}-${index}`} value={o?.value || o.label}>{o.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </DropDownContainer>
  </Grid>
);

export default DropDownComponent;
