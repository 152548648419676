export type ScoreVariant = 'Title' | 'Content';

export enum ScoreTypes {
  EDITORIAL_BIAS = 'editorialBias',
  COLLOQUIALISM = 'colloquialism',
  SENSATIONALISM = 'sensationalism',
}

export type TUserScore = {
  current: number;
  target?: number;
};

export type TVisualScore = {
  label: string;
  value: number;
};

export type TScoreMapping = {
  from: [number, number];
  range: [number, number];
  label: string;
};

export type TGroupScore = {
  [ScoreTypes.EDITORIAL_BIAS]: TUserScore;
  [ScoreTypes.COLLOQUIALISM]: TUserScore;
  [ScoreTypes.SENSATIONALISM]: TUserScore;
};

export type TTotalScore = {
  titleScore: TGroupScore;
  contentScore: TGroupScore;
  sesScore?: TBiasScore;
  racialScore?: TBiasScore;
};

export interface ScoreBody {
  title: string;
  content: string;
}

export type TPosScore = {
  ADJ?: number;
  ADP?: number;
  ADV?: number;
  AUX?: number;
  CCONJ?: number;
  DET?: number;
  NOUN?: number;
  PART?: number;
  PROPN?: number;
  PUNCT?: number;
  VERB?: number;
  SCONJ?: number;
};

export type TSESHighlighting = {
  predictionProbability: number;
  prediction: number;
  distances: Record<string, number>;
};

export type TBiasScore = {
  title: number;
  content: number;
  titleHighlightings: TSESHighlighting;
  contentHighlightings: TSESHighlighting;
};

export interface ScoreResponse {
  contentEditorialBias: number;
  titleEditorialBias: number;
  contentColloquialism: number;
  titleColloquialism: number;
  contentSentiment: number;
  titleSentiment: number;
  readTime: string;
  posScore: TPosScore;
  sesScore: TBiasScore;
  racialScore?: TBiasScore;
}

export interface KeywordsScoreResponse {
  keywordsList: string[];
}

export interface SummaryScoreResponse {
  summarization: string;
}

export interface TotalScoreResponse
  extends ScoreResponse,
    KeywordsScoreResponse,
    SummaryScoreResponse {}

export interface ArticleScoreDto {
  titleColloquialism: number;
  contentColloquialism: number;
  titleEditorialBias: number;
  contentEditorialBias: number;
  titleSentiment: number;
  contentSentiment: number;
  summarization: string;
  keywordsList: string[];
  readTime: string;
  posScore: TPosScore;
  sesScore: {
    title: number;
    content: number;
    titleHighlightings: TSESHighlighting;
    contentHighlightings: TSESHighlighting;
  };
  [key: string]: any;
}

export interface ScoreDetailDto {
  colloquialism: number;
  editorialBias: number;
  sentiment: number;
  sesScore: number;
}
