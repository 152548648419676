import { BillingInterval, SubscriptionTier, SubscriptionDto } from 'src/types';

export const subscriptionPlans: SubscriptionDto[] = [
  {
    name: 'Base Monthly',
    amount: 3495,
    type: SubscriptionTier.STANDARD,
    interval: BillingInterval.MONTH,
    intervalCount: 1,
    active: true,
    metadata: {
      benefits: [
        '10 unique logins for your team',
        'Brand suitability analysis for thousands of episodes',
        'Access to brand suitability scores for GARM 1, 2, 5, 7, 8, 9 and 11 as well as the Media Round Table value of Tolerance',
        'Unlimited dashboards ',
        'Unlimited downloads',
        '$500/1000 hours of transcription',
      ],
    },
    description: 'For standard commercial projects',
  },
  {
    name: 'Base Annual',
    amount: 33552,
    type: SubscriptionTier.STANDARD,
    interval: BillingInterval.YEAR,
    intervalCount: 1,
    active: true,
    metadata: {
      benefits: [
        '10 unique logins for your team',
        'Brand suitability analysis for thousands of episodes',
        'Access to brand suitability scores for GARM 1, 2, 5, 7, 8, 9 and 11 as well as the Media Round Table value of Tolerance',
        'Unlimited dashboards ',
        'Unlimited downloads',
        '$500/1000 hours of transcription',
      ],
    },
    description: 'For standard commercial projects',
  },
];
