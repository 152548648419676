import {
  ListItem,
  ListItemIcon,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/DescriptionOutlined';
import { Draggable } from 'react-beautiful-dnd';

import { Dashboard } from 'src/types';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 0,
      alignItems: 'flex-start',
    },
  })
);

interface DashboardItemProps {
  dashboard: Dashboard;
  index: number;
  noDraggable?: boolean;
  onSelectDashboard: (dashboard: Dashboard) => void;
}

export const DashboardItem = ({
  dashboard,
  index,
  noDraggable = false,
  onSelectDashboard,
}: DashboardItemProps) => {
  const classes = useStyles();

  if (noDraggable) {
    return (
      <ListItem
        button
        className={classes.container}
        onClick={() => {
          onSelectDashboard(dashboard);
        }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <DashboardIcon htmlColor='#757575' style={{ fontSize: 18 }} />
        </ListItemIcon>
        <Typography
          style={{ fontSize: 14, fontWeight: 400, lineHeight: '18px' }}
        >
          {dashboard.name}
        </Typography>
      </ListItem>
    );
  }

  return (
    <Draggable draggableId={dashboard.id.toString()} index={index}>
      {(provided) => (
        <ListItem
          button
          className={classes.container}
          onClick={() => {
            onSelectDashboard(dashboard);
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <DashboardIcon htmlColor='#757575' style={{ fontSize: 18 }} />
          </ListItemIcon>
          <Typography
            style={{ fontSize: 14, fontWeight: 400, lineHeight: '18px' }}
          >
            {dashboard.name}
          </Typography>
        </ListItem>
      )}
    </Draggable>
  );
};
