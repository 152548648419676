import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  })
);

interface ContentLoadingProps {
  noContainer?: boolean;
}

export default function ContentLoading({ noContainer }: ContentLoadingProps) {
  const classes = useStyles();

  const MainContent = (
    <Grid
      container={true}
      direction='column'
      justifyContent='center'
      alignItems='center'
      className={classes.container}
    >
      <CircularProgress color='primary' />
    </Grid>
  );

  if (noContainer) {
    return (
      <Box
        width={1}
        height='100px'
        display='flex'
        justifyContent='center'
        alignItems='center'
        position='relative'
      >
        {MainContent}
      </Box>
    );
  }

  return MainContent;
}
