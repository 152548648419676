import { baseAPICreator } from './apiCreator';

const verificationApi = baseAPICreator('/verification');

export const sendVerificationCode = async (email: string) => {
  const { data } = await verificationApi.post('/send', {
    email,
    type: 'code_verification',
  });
  return data.success;
};

export const checkVerificationCode = async (email: string, code: string) => {
  const { data } = await verificationApi.post('/check', { email, code });
  return data.success;
};
