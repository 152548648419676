import { useState } from 'react';
import { useSnackbar } from 'notistack';

import {
  cancelCustomerSubscription,
  registerCustomerSubscription,
  updateCustomerSubscription,
} from 'src/apis/payment';
import { SubscriptionDto } from 'src/types';
import useAuth from './useAuth';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';

export const useSubscription = (plan: SubscriptionDto) => {
  const { fetchUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [updating, setUpdating] = useState(false);

  const handleRegisterCustomerSubscription = async ({
    token,
    promoCode,
    onClose,
  }: {
    token: string;
    promoCode?: string;
    onClose: VoidFunction;
  }) => {
    try {
      await registerCustomerSubscription(plan.name, token, promoCode);
      submitMetrics(METRICS_TYPE.REGISTER_SUBSCRIPTION);
      await fetchUser();
      enqueueSnackbar('Successfully registered a subscription.', {
        variant: 'success',
      });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
      throw error;
    }
  };

  const handleUpgradeCustomerSubscription = async ({
    onClose,
  }: {
    onClose: VoidFunction;
  }) => {
    try {
      setUpdating(true);
      await updateCustomerSubscription(plan.name);
      submitMetrics(METRICS_TYPE.UPDATE_SUBSCRIPTION);
      await fetchUser();
      enqueueSnackbar('Successfully updated a subscription.', {
        variant: 'success',
      });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  const handleCancelCustomerSubscription = async ({
    onClose,
  }: {
    onClose: VoidFunction;
  }) => {
    try {
      setUpdating(true);
      await cancelCustomerSubscription();
      submitMetrics(METRICS_TYPE.CANCEL_SUBSCRIPTION);
      await fetchUser();
      enqueueSnackbar('Successfully cancelled a subscription.', {
        variant: 'success',
      });
      onClose();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setUpdating(false);
    }
  };

  return {
    updating,
    handleCancelCustomerSubscription,
    handleUpgradeCustomerSubscription,
    handleRegisterCustomerSubscription,
  };
};
