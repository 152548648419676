import clsx from 'clsx';
import { styled, Button, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 10,
      maxWidth: 220,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 12,
      marginBottom: 4,
      '&.disabled': {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
    },
    step: {
      background: '#3E91DE',
      borderRadius: '100%',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&.completed': {
        background: '#4CAF50',
      },
    },
  })
);

export const GenerateButton = styled(Button)(() => ({
  borderRadius: 24,
  boxShadow: '0px 0px 0px 1px #E0E0E0',
  fontSize: 14,
  fontWeight: 500,
  marginTop: 8,
}));

interface StepViewProps {
  step: number;
  title: string;
  description: string;
  completed: boolean;
  disabled: boolean;
  selectingDashboard?: boolean;
  selectDashboard?: () => void;
  removeDashboard?: () => void;
  generateReport?: () => void;
}

export default function StepView({
  step,
  title,
  description,
  completed,
  disabled,
  selectingDashboard = false,
  selectDashboard,
  removeDashboard,
  generateReport,
}: StepViewProps) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, { disabled })}>
      <div className={clsx(classes.step, { completed })}>
        {completed ? (
          <CheckIcon fontSize='small' style={{ color: '#fff' }} />
        ) : (
          <Typography variant='body2' style={{ color: '#fff' }}>
            {step}
          </Typography>
        )}
      </div>
      <Typography variant='body1'>{title}</Typography>
      <Typography variant='body2' align='center' style={{ opacity: 0.6 }}>
        {description}
      </Typography>
      {step === 1 && (
        <GenerateButton
          color={completed ? 'secondary' : 'primary'}
          disableElevation
          startIcon={
            selectingDashboard ? (
              <CircularProgress
                size={16}
                style={{ marginRight: 8 }}
                color='secondary'
              />
            ) : completed ? (
              <RemoveIcon />
            ) : (
              <AddIcon />
            )
          }
          variant='contained'
          size='small'
          onClick={() => {
            if (!selectingDashboard) {
              if (completed) {
                removeDashboard && removeDashboard();
              } else {
                selectDashboard && selectDashboard();
              }
            }
          }}
        >
          {completed ? 'Remove Dashboard' : 'Select Dashboard'}
        </GenerateButton>
      )}
      {step === 2 && (
        <GenerateButton
          color='primary'
          disabled={disabled}
          disableElevation
          startIcon={<AddIcon />}
          variant='contained'
          size='small'
          onClick={() => {
            generateReport && generateReport();
          }}
        >
          Generate Report
        </GenerateButton>
      )}
    </div>
  );
}
