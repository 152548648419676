import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import useAuth from 'src/hooks/useAuth';
import useQuery from 'src/hooks/useQuery';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';
import useNotification from 'src/hooks/useNotification';
import { Organization, Member, TAcceptInvitationBody } from 'src/types';
import {
  acceptInvitation,
  fetchInvitationDetails,
  fetchOrganization,
} from 'src/apis';
import ContentLoading from 'src/components/ContentLoading';
import { useLayoutStyles } from 'src/components/useCommonStyles';

import InvitationAcceptForm from './InvitationAcceptForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      minHeight: '100vh',
      alignItems: 'center',
      padding: theme.spacing(8),
    },
  })
);

const AcceptInvitation = () => {
  const query = useQuery();
  const classes = useStyles();
  const history = useHistory();
  const { fetchUser } = useAuth();
  const layoutClasses = useLayoutStyles();
  const { handleSuccess, handleError } = useNotification();

  const [current, setCurrent] = useState<Member>();
  const [organization, setOrganization] = useState<Organization>();
  const invitationCode = query.get('invitationCode');

  const getInvitationDetails = useCallback(async () => {
    if (!invitationCode) return;

    try {
      const invitation = await fetchInvitationDetails(invitationCode);
      setCurrent(invitation);
      const organization = await fetchOrganization(invitation.organizationId);
      setOrganization(organization);
    } catch (error) {
      handleError(error, 'Invitation not found.');
    }
  }, [invitationCode]);

  useEffect(() => {
    getInvitationDetails();
  }, [getInvitationDetails]);

  const handleSubmitInvitationAcceptForm = async (
    body: TAcceptInvitationBody
  ) => {
    try {
      await acceptInvitation(body);
      handleSuccess('You have accepted an invitation successfully.');
      submitMetrics(METRICS_TYPE.ACCEPT_INVITATION);
      await fetchUser();
      history.push('/dashboard');
    } catch (error) {
      handleError(error, 'Invitation accept was not successful.');
    }
  };

  if (!current) {
    return (
      <Grid container item xs={12}>
        <ContentLoading />
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={8}
      justifyContent='center'
      className={classes.container}
    >
      <Grid item xs={7}>
        <Grid item xs={12}>
          <Typography variant='h5'>
            You have been invited to join the {organization?.name}. Please fill
            out this brief form to get started!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            width='100%'
            src='/images/screens/barometer-select.png'
            alt='dashboard'
          />
        </Grid>
      </Grid>

      <Grid item xs={5} className={layoutClasses.padding3}>
        <InvitationAcceptForm
          current={current}
          onSubmit={handleSubmitInvitationAcceptForm}
        />
      </Grid>
    </Grid>
  );
};

export default AcceptInvitation;
