export enum FilterTypes {
  GARM = 'GARM Score',
  Host = 'Host',
  Podcasts = 'Podcasts',
}

export enum ScoreExceptionTypes {
  Genre = 'Genre',
  // Podcast = 'Podcast',
  GarmReasoning = 'GARM Reasoning',
}

export enum RiskLevel {
  No = 'No',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

interface ExceptionFilter {
  name: string;
  risk: RiskLevel;
}

export interface ScoreFilterType {
  type: ScoreExceptionTypes;
  exceptions: ExceptionFilter[];
}

export enum PodcastExceptionTypes {
  Include = 'Include',
  Exclude = 'Exclude',
}

export interface PodcastsFilterType {
  type: PodcastExceptionTypes;
  exceptions: string[];
}

export interface BrandCustomFilter {
  name: string;
  description: string;
  data: any;
  id: string;
  dashboardIds: string[];
  createdAt?: string;
  updatedAt?: string;
}

export interface BrandCustomFilterCreateBody {
  name: string;
  description: string;
  data: any;
}
