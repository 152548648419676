import { useCallback } from 'react';

import { createDashboard } from 'src/apis';
import useAuth from 'src/hooks/useAuth';
import useNotification from 'src/hooks/useNotification';
import { Dashboard, DashboardCreate } from 'src/types';
import { METRICS_TYPE, submitMetrics } from 'src/utils';

export const useCreateDashboard = (refetch = true) => {
  const { organization, fetchOrganization } = useAuth();

  const { handleSuccess, handleError } = useNotification();

  const handleCreateDashboard = useCallback(
    async (data: DashboardCreate): Promise<Dashboard | null> => {
      if (!organization?.apiKey) return null;

      try {
        const dashboard = await createDashboard(organization.apiKey, data);
        handleSuccess('Successfully created a new dashboard');

        if (refetch) {
          await fetchOrganization();
        }

        submitMetrics(METRICS_TYPE.CREATE_DASHBOARD);

        return dashboard;
      } catch (error) {
        handleError(error, 'There was an error while creating the dashboard');
        return null;
      }
    },
    [organization, refetch, fetchOrganization, handleError, handleSuccess]
  );

  return {
    onCreateDashboard: handleCreateDashboard,
  };
};
