import { jStat } from 'jstat';

import { ScoreTypes, TVisualScore, TScoreMapping } from 'src/types';
import {
  editorialMappings,
  colloquialMappings,
  sentimentMappings,
} from './constants';

export const scoreTypes = [
  ScoreTypes.EDITORIAL_BIAS,
  ScoreTypes.COLLOQUIALISM,
  ScoreTypes.SENSATIONALISM,
];

export const scoreRanges = {
  [ScoreTypes.EDITORIAL_BIAS]: [1, 5],
  [ScoreTypes.COLLOQUIALISM]: [1, 5],
  [ScoreTypes.SENSATIONALISM]: [-1, 1],
};

export const scoreLabels = {
  [ScoreTypes.EDITORIAL_BIAS]: { min: 'Biased', max: 'Unbiased' },
  [ScoreTypes.COLLOQUIALISM]: { min: 'Colloquial', max: 'Literary' },
  [ScoreTypes.SENSATIONALISM]: { min: 'Negative', max: 'Positive' },
};

export const getMappedScore = (
  targetRange: number[],
  sourceRange: number[],
  score: number
) => {
  // prettier-ignore
  const scale = (targetRange[1] - targetRange[0]) / (sourceRange[1] - sourceRange[0]);
  return Math.round(scale * (score - sourceRange[0]) + targetRange[0]);
};

export const getMappedVisualScore = (
  mappings: TScoreMapping[],
  score: number
): TVisualScore => {
  // prettier-ignore
  const originMapping = mappings.find((each) => each.range[0] < score) || editorialMappings[0];

  return {
    label: originMapping.label,
    value: Math.round(score),
  };
};

export const getVisualScore = (
  type: ScoreTypes,
  score: number
): TVisualScore => {
  switch (type) {
    case ScoreTypes.EDITORIAL_BIAS: {
      return getMappedVisualScore(editorialMappings, score);
    }
    case ScoreTypes.COLLOQUIALISM: {
      return getMappedVisualScore(colloquialMappings, score);
    }
    case ScoreTypes.SENSATIONALISM: {
      return getMappedVisualScore(sentimentMappings, score);
    }
  }
};

export const getTwoFloatingPointsScore = (score: number) => {
  return Number(score.toFixed(2));
};

export const calcRegression = (xPositions: number[], yPositions: number[]) => {
  const r = jStat.corrcoeff(yPositions, xPositions);
  const sy = jStat.stdev(xPositions);
  const sx = jStat.stdev(yPositions);
  const meanY = (jStat(xPositions) as any).mean();
  const meanX = (jStat(yPositions) as any).mean();
  const b = r * (sy / sx);
  const a = meanY - meanX * b;
  //Set up a line
  const x1 = jStat.min(yPositions);
  const x2 = jStat.max(yPositions);
  const y1 = a + b * x1;
  const y2 = a + b * x2;

  return {
    line: [
      [x1, y1],
      [x2, y2],
    ],
    r,
  };
};

export const convertRatings = (values: number[]): number[] | undefined => {
  if (values[0] === 0 && values[1] >= 5) {
    return undefined;
  }

  const min = values[0];
  const max = values[1] >= 5 ? 5 : values[1];
  return [min, max];
};

export const formatPodcastRating = (val: string) => {
  if (Number.isNaN(Number(val))) {
    return 0;
  }
  if (Number(val) > 5) {
    return 5;
  } else if (Number(val) < 0) {
    return 0;
  } else {
    return Number(val);
  }
};

export const formatPodcastRatingNumber = (val: number) => {
  if (Number.isNaN(Number(val))) {
    return 0;
  }
  if (Number(val) > 5) {
    return 5;
  } else if (Number(val) < 0) {
    return 0;
  } else {
    return Number(val);
  }
};

export const getValidGarmScores = (values: number[]): string[] | undefined => {
  if (values[0] === 0 && values[1] === 3) {
    return ['No', 'Low', 'Medium', 'High'];
  }

  const scores = ['No', 'Low', 'Medium', 'High']
    .map((score, index) => {
      if (index >= values[0] && index <= values[1]) {
        return score;
      }
      return '';
    })
    .filter((item) => item !== '');

  return scores;
};
