import { useState, useMemo, useEffect, useCallback } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import Spinner from 'src/components/Spinner';
import {
  TableWrapper,
  HeaderCell,
} from 'src/containers/dashboard/garm/components';
import {
  ScoringRequestProps,
  ScoringRequestRequestProps,
} from 'src/types/selfScoring';
import useAuth from 'src/hooks/useAuth';
import { getGarmDashboards } from 'src/utils';
import { useTextStyles } from 'src/hooks/useTextStyles';
import Button from 'src/components/NewButton';
import { getSelfScoringRequestById } from 'src/apis/selfScoring';

import { ReactComponent as HelpIcon } from 'src/assets/icons/helpCircle.svg';
import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrowUp.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/dashboard/logo.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      background: '#fff',
      flexWrap: 'nowrap',
    },
    header: {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      borderBottom: '1px solid #EAECF0',
    },
    content: {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
    },
    banner: {
      padding: 16,
      borderRadius: 8,
      border: '1px solid #EAECF0',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      minWidth: 220,
    },
    title: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      color: '#344054',
    },
    searchBar: {
      width: 326,
      boxSizing: 'border-box',
      border: '1px solid #D0D5DD',
      padding: '2px 12px',
      borderRadius: 8,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
    tableWrapper: {
      '& .MuiTableCell-body': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',

        '&:last-child': {
          borderRight: 'none',
        },
      },
    },
    status: {
      borderRadius: 16,
      borderWidth: 1,
      borderStyle: 'solid',
      padding: '2px 8px',
    },
    showList: {
      borderRadius: 8,
      border: '1px solid #EAECF0',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      '& .header': {
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        position: 'relative',
      },
      '& .content': {
        overflow: 'auto',
        maxHeight: 242,
        borderTop: '1px solid #EAECF0',
      },
    },
    detailsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 16,
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      },
    },
    usedDashboard: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    },
  })
);

interface ScoringRequestDetailsProps {
  request: ScoringRequestProps;
  handleBack: () => void;
}

export const ScoringRequestDetails = ({
  request,
  handleBack,
}: ScoringRequestDetailsProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const [showDetails, setShowDetails] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [openedIndx, setOpenedIndex] = useState<number>(-1);
  const [data, setData] = useState<Nullable<ScoringRequestRequestProps>>(null);

  const { organization } = useAuth();
  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  const handleOpenContent = (index: number) => {
    if (openedIndx === index) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(index);
    }
  };

  const fetchData = useCallback(async (id: string) => {
    setLoading(true);
    try {
      const res = await getSelfScoringRequestById(id);
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData(request.id);
  }, [request, fetchData]);

  if (loading) {
    return (
      <Grid container direction='column' className={classes.container}>
        <Spinner />
      </Grid>
    );
  }

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid className={classes.header}>
        <Grid container direction='row' alignItems='center' style={{ gap: 12 }}>
          <ArrowLeftIcon stroke='#667085' onClick={handleBack} />
          <Typography className={classes.title}>
            {request.date} Scoring Request
          </Typography>
          <Button
            variant='text'
            fontSize='sm'
            height={18}
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            <Typography
              className={textClasses.xsMedium}
              style={{ color: '#5EA1F2' }}
            >
              Hide Details
            </Typography>
          </Button>
        </Grid>
        {showDetails && (
          <Grid className={classes.detailsContainer}>
            <Grid>
              <Typography
                className={textClasses.xsBold}
                style={{ color: '#344054' }}
              >
                Used In
              </Typography>
              <Grid className={classes.usedDashboard}>
                <DashboardIcon />
                <Typography
                  variant='caption'
                  align='left'
                  className={textClasses.xsRegular}
                  style={{
                    color: '#155EEF',
                  }}
                >
                  {
                    garmDashboards.filter(
                      (dashboard) => dashboard.id === request.dashboardId
                    )[0]?.name
                  }
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <Typography
                className={textClasses.xsBold}
                style={{ color: '#344054' }}
              >
                ID
              </Typography>
              <Typography
                className={textClasses.xsRegular}
                style={{ color: '#667085' }}
              >
                {request.id}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                className={textClasses.xsBold}
                style={{ color: '#344054' }}
              >
                Created On
              </Typography>
              <Typography
                className={textClasses.xsRegular}
                style={{ color: '#667085' }}
              >
                {request.date}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid className={classes.content}>
        <Grid
          container
          direction='row'
          alignItems='center'
          style={{ gap: 16, flexWrap: 'wrap' }}
        >
          <Grid className={classes.banner}>
            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ gap: 8 }}
            >
              <Typography className={textClasses.smMedium}>
                Total No. of Shows
              </Typography>
              <HelpIcon />
            </Grid>
            <Grid
              container
              direction='row'
              alignItems='center'
              style={{ gap: 16 }}
            >
              <Typography className={classes.title}>
                {data?.numberOfShows}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.banner}>
            <Box>
              <Box
                className={classes.status}
                style={{
                  background: '#ECFDF3',
                  borderColor: '#ABEFC6',
                  display: 'inline-block',
                }}
              >
                <Typography
                  className={textClasses.xsMedium}
                  style={{
                    color: '#067647',
                  }}
                >
                  Complete
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.title}>{data?.completed}</Typography>
          </Grid>
          <Grid className={classes.banner}>
            <Box>
              <Box
                className={classes.status}
                style={{
                  background: '#EFF8FF',
                  borderColor: '#B2DDFF',
                  display: 'inline-block',
                }}
              >
                <Typography
                  className={textClasses.xsMedium}
                  style={{
                    color: '#175CD3',
                  }}
                >
                  Processing
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.title}>
              {data?.processing}
            </Typography>
          </Grid>
          <Grid className={classes.banner}>
            <Box>
              <Box
                className={classes.status}
                style={{
                  background: '#FAF5FF',
                  borderColor: '#E9D5FF',
                  display: 'inline-block',
                }}
              >
                <Typography
                  className={textClasses.xsMedium}
                  style={{
                    color: '#7E22CE',
                  }}
                >
                  Net New
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.title}>{data?.netNew}</Typography>
          </Grid>
          <Grid className={classes.banner}>
            <Box>
              <Box
                className={classes.status}
                style={{
                  background: '#FEF3F2',
                  borderColor: '#FECDCA',
                  display: 'inline-block',
                }}
              >
                <Typography
                  className={textClasses.xsMedium}
                  style={{
                    color: '#B42318',
                  }}
                >
                  Failed
                </Typography>
              </Box>
            </Box>
            <Typography className={classes.title}>{data?.failed}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          style={{ marginTop: 24 }}
        >
          <TextField
            InputLabelProps={{
              shrink: true,
              style: { fontWeight: 'normal' },
            }}
            InputProps={{
              placeholder: 'Search shows...',
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon htmlColor='#858591' style={{ fontSize: 20 }} />
                </InputAdornment>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className={classes.searchBar}
          />
        </Grid>
        <Grid
          container
          style={{
            marginTop: 16,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          <Typography className={textClasses.xsMedium}>
            Viewing {data?.numberOfShows} Shows
          </Typography>
          {['Complete', 'Processing & Net New'].map((title, index) => {
            const content = (data?.shows || []).filter((item) =>
              title === 'Complete'
                ? item.status === 'Complete'
                : item.status === 'Processing'
            );
            return (
              <Box key={index} className={classes.showList}>
                <Box
                  className='header'
                  onClick={() => handleOpenContent(index)}
                >
                  <ArrowUpIcon
                    cursor='pointer'
                    style={
                      index === openedIndx
                        ? {}
                        : { transform: 'rotate(180deg)' }
                    }
                    onClick={() => handleOpenContent(index)}
                  />
                  <Typography className={textClasses.smBold}>
                    {title}
                  </Typography>
                  <Box
                    className={classes.status}
                    style={
                      title === 'Complete'
                        ? { background: '#ECFDF3', borderColor: '#ABEFC6' }
                        : { background: '#EFF8FF', borderColor: '#B2DDFF' }
                    }
                  >
                    <Typography
                      className={textClasses.xsMedium}
                      style={{
                        color: title === 'Complete' ? '#067647' : '#175CD3',
                      }}
                    >
                      {content.length} Shows
                    </Typography>
                  </Box>
                </Box>
                {index === openedIndx && (
                  <Box className='content'>
                    <TableContainer
                      component={Paper}
                      style={{
                        overflowX: 'scroll',
                        boxShadow: '0px 0px 0px 1px #E0E0E0',
                        borderRadius: 4,
                        flex: 1,
                        flexGrow: 1,
                      }}
                    >
                      <TableWrapper
                        stickyHeader
                        style={{
                          height: '100%',
                          borderCollapse: 'collapse',
                        }}
                        className={classes.tableWrapper}
                      >
                        <TableHead
                          style={{
                            position: 'sticky',
                            top: -1,
                            zIndex: 2,
                          }}
                        >
                          <TableRow>
                            {['Show', 'RSS Feed', 'Status'].map(
                              (column: string) => (
                                <HeaderCell
                                  key={column}
                                  style={{
                                    padding: '12px 20px',
                                    borderRadius: 0,
                                    background: '#F9FAFB',
                                  }}
                                >
                                  <Typography
                                    className={textClasses.smBold}
                                    style={{
                                      color: '#475467',
                                    }}
                                  >
                                    {column}
                                  </Typography>
                                </HeaderCell>
                              )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {content.map((show, index) => (
                            <TableRow
                              key={index}
                              style={{
                                height: '48px',
                              }}
                            >
                              <TableCell
                                style={{
                                  borderRight:
                                    '1px solid var(--gray-200, #EAECF0)',
                                  width: '30%',
                                }}
                              >
                                <Typography
                                  className={textClasses.xsRegular}
                                  style={{
                                    color: '#475467',
                                  }}
                                >
                                  {show.show}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  className={textClasses.xsRegular}
                                  style={{
                                    color: '#475467',
                                  }}
                                >
                                  {show.rssFeed}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 4,
                                  height: '100%',
                                }}
                              >
                                {(show.status === 'Complete' ||
                                  show.status === 'Processing') && (
                                  <div
                                    className={classes.status}
                                    style={
                                      show.status === 'Processing'
                                        ? {
                                            background: '#EFF8FF',
                                            borderColor: '#B2DDFF',
                                          }
                                        : {
                                            background: '#ECFDF3',
                                            borderColor: '#ABEFC6',
                                          }
                                    }
                                  >
                                    <Typography
                                      variant='caption'
                                      align='left'
                                      className={textClasses.smRegular}
                                      style={{
                                        color:
                                          show.status === 'Processing'
                                            ? '#175CD3'
                                            : '#067647',
                                      }}
                                    >
                                      {show.status}
                                    </Typography>
                                  </div>
                                )}
                                {show.isNetNew && show.status === 'Processing' && (
                                  <div
                                    className={classes.status}
                                    style={{
                                      background: '#FAF5FF',
                                      borderColor: '#E9D5FF',
                                    }}
                                  >
                                    <Typography
                                      variant='caption'
                                      align='left'
                                      className={textClasses.smRegular}
                                      style={{
                                        color: '#7E22CE',
                                      }}
                                    >
                                      Net New
                                    </Typography>
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </TableWrapper>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
