export default function LogOut({ color = '#D2D2D2' }: { color?: string }) {
  return (
    <svg
      width='24'
      height='19'
      viewBox='0 0 24 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 16.6047H5C4.46957 16.6047 3.96086 16.4415 3.58579 16.1508C3.21071 15.8602 3 15.466 3 15.055V4.20652C3 3.79549 3.21071 3.4013 3.58579 3.11066C3.96086 2.82002 4.46957 2.65674 5 2.65674H9'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 13.5052L21 9.63079L16 5.75635'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21 9.63086H9'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
