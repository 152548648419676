import { Typography, makeStyles, createStyles } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useDashboardStyles } from '../styles';
import { ReactComponent as DotsIcon } from 'src/assets/dashboard/dots.svg';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      gap: 4,

      '& .dot-icon': {
        display: 'none',
      },

      '&:hover': {
        background: '#F0F0F1',
        '& .dot-icon': {
          display: 'block',
        },
      },
    },
    showContainer: {
      position: 'relative',
      width: 24,
      height: 16,
      padding: 2,
      borderRadius: 100,

      '& > .show-flag': {
        position: 'absolute',
        top: '50%',
        width: 12,
        height: 12,
        borderRadius: '100%',
        transform: 'translate(0, -50%)',
        background: '#ffffff',
      },
    },
  })
);

interface ColumnItemProps {
  title: string;
  index: number;
  show: boolean;
  isCollapsable?: boolean;
  isSubItem?: boolean;
  isDragDisabled?: boolean;
  onChangeShow: () => void;
}

export const ColumnItem = ({
  title,
  show,
  index,
  isCollapsable = false,
  isSubItem = false,
  isDragDisabled = false,
  onChangeShow,
}: ColumnItemProps) => {
  const classes = useStyles();
  const commonClasses = useDashboardStyles();

  return (
    <Draggable
      key={title + 'column'}
      draggableId={title}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div
          className={classes.container}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div style={{ width: 20, height: 20 }}>
            <DotsIcon className='dot-icon' />
          </div>

          <Typography
            className={
              isSubItem
                ? commonClasses.textXSRegular
                : commonClasses.textSMRegular
            }
            style={{
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 144,
            }}
          >
            {title}
          </Typography>
          {isCollapsable ? (
            <div
              onClick={() => onChangeShow()}
              style={{ marginLeft: 4, display: 'flex', alignItems: 'center' }}
            >
              {show ? (
                <KeyboardArrowUpIcon style={{ fontSize: 18 }} />
              ) : (
                <KeyboardArrowDownIcon style={{ fontSize: 18 }} />
              )}
            </div>
          ) : (
            <div
              className={classes.showContainer}
              style={
                show ? { background: '#5EA1F2' } : { background: '#C3C3C7' }
              }
              onClick={() => onChangeShow()}
            >
              <div
                className='show-flag'
                style={
                  show
                    ? { right: 2 }
                    : {
                        left: 2,
                      }
                }
              />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
