import { FC, useMemo, useState } from 'react';
import Highlighter, { Chunk } from 'react-highlight-words';
import {
  Grid,
  Typography,
  TypographyProps,
  withStyles,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import AntiList from 'src/assets/anti-list.json';
import Button from '../Button';
import { GridLayoutSize, ArticleBase } from 'src/types';

const useStyles = makeStyles(() =>
  createStyles({
    downloadButton: {
      display: 'block',
      marginRight: 58,
      marginLeft: 'auto',
      fontWeight: 'normal',
      textDecoration: 'underline',
    },
  })
);

const Label = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
})((props: TypographyProps) => <Typography {...props} />);

interface ContentSummaryProps {
  article: ArticleBase;
  layouts: [GridLayoutSize, GridLayoutSize];
  search?: string;
}

function escapeRegExpFn(string: string): string {
  // eslint-disable-next-line no-useless-escape
  return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

type ChunkProps = {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  sanitize?: any;
  searchWords: (string | RegExp)[];
  textToHighlight: string;
};

const keywordsChunks = ({
  autoEscape,
  caseSensitive,
  sanitize,
  searchWords,
  textToHighlight,
}: ChunkProps): Array<Chunk> => {
  // Override default function from https://github.com/bvaughn/highlight-words-core/blob/eb170f8a78c7926b613e72733267f3243696113c/src/utils.js

  if (sanitize) {
    textToHighlight = sanitize(textToHighlight);
  }

  // @ts-ignore
  return (
    searchWords
      .filter((searchWord) => searchWord) // Remove empty words
      // @ts-ignore
      .reduce((chunks: any[], searchWord: string) => {
        let startwordregex = '';
        let endwordregex = '';

        if (searchWord[0].match(/[a-z]/i) || searchWord[0].match(/[1-9]/i)) {
          startwordregex = '\\b';

          if (searchWord.split(' ').length === 1) {
            endwordregex = '\\b';
          }
        }

        if (sanitize) {
          searchWord = sanitize(searchWord);
        }

        if (autoEscape) {
          searchWord = escapeRegExpFn(searchWord);
        }

        const regex = new RegExp(
          startwordregex + searchWord + endwordregex,
          caseSensitive ? 'g' : 'gi'
        );

        let match;
        while ((match = regex.exec(textToHighlight))) {
          const start = match.index;
          const end = regex.lastIndex;
          // We do not return zero-length matches
          if (end > start) {
            chunks.push({ highlight: false, start, end });
          }

          // Prevent browsers like Firefox from getting stuck in an infinite loop
          // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
          if (match.index === regex.lastIndex) {
            regex.lastIndex++;
          }
        }
        return chunks;
      }, [])
  );
};

const findChunks = ({
  autoEscape,
  caseSensitive,
  sanitize,
  searchWords,
  textToHighlight,
}: ChunkProps): Array<Chunk> => {
  const searchWordsChunks = keywordsChunks({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords,
    textToHighlight,
  });

  const antiListChunks = keywordsChunks({
    autoEscape,
    caseSensitive,
    sanitize,
    searchWords: AntiList,
    textToHighlight,
  });

  const highlightChunks = searchWordsChunks.filter((searchWordChunk: Chunk) => {
    const flag = antiListChunks.every((antiChunk: Chunk) => {
      if (
        searchWordChunk.start >= antiChunk.start &&
        searchWordChunk.end <= antiChunk.end
      ) {
        return false;
      }
      return true;
    });
    return flag;
  });

  return highlightChunks;
};

const ContentSummary: FC<ContentSummaryProps> = ({
  article,
  layouts,
  search,
}) => {
  const classes = useStyles();
  const [copied, setCopied] = useState<boolean>(false);
  let copyTimer: any = null;
  // const sesScore = article.score.sesScore;

  // const getSearchKeywords = (type: 'TITLE' | 'CONTENT'): string[] => {
  //   const titleDistances =
  //     article.score.sesScore?.titleHighlightings?.distances || {};
  //   const contentDistances =
  //     article.score.sesScore?.contentHighlightings?.distances || {};

  //   switch (type) {
  //     case 'TITLE':
  //       if (!sesScore?.title) return [];
  //       return Object.keys(titleDistances).filter(
  //         (key) => titleDistances[key] >= 1e-5,
  //       );
  //     case 'CONTENT':
  //       if (!sesScore?.content) return [];
  //       return Object.keys(contentDistances).filter(
  //         (key) => contentDistances[key] >= 1e-5,
  //       );
  //     default:
  //       return [];
  //   }
  // };
  const highlightings = useMemo(() => {
    return [...(article.highlightStrings || []), search].filter(
      (each) => each
    ) as string[];
  }, [article, search]);

  const downloadTranscript = () => {
    const download = document.createElement('a');
    const file = new Blob([article.content], { type: 'text/plain' });
    download.href = URL.createObjectURL(file);
    download.download = article.title + '.txt';
    download.click();
    download.remove();
    setTimeout(() => URL.revokeObjectURL(download.href), 7000);
  };

  const copyGUID = async () => {
    if (copyTimer) {
      clearTimeout(copyTimer);
    }
    setCopied(true);
    copyTimer = setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={layouts[0]}>
          <Label
            variant='body1'
            style={{
              fontWeight: 'normal',
              textAlign: 'right',
              marginRight: 60,
            }}
          >
            Transcript
          </Label>
          <Button
            title='Download'
            color='primary'
            variant='text'
            fontSize='sm'
            height={30}
            className={classes.downloadButton}
            onClick={downloadTranscript}
          />
          <CopyToClipboard text={article.guid} onCopy={copyGUID}>
            <Button
              title={copied ? 'Copied' : 'Copy GUID'}
              disabled={copied}
              color='primary'
              variant='text'
              fontSize='sm'
              height={30}
              className={classes.downloadButton}
            />
          </CopyToClipboard>
        </Grid>

        <Grid item xs={layouts[1]}>
          <Typography variant='body1' style={{ fontWeight: 'normal' }}>
            <Highlighter
              // searchWords={getSearchKeywords('CONTENT')}
              searchWords={highlightings}
              autoEscape={true}
              highlightStyle={{
                color: 'white',
                background: '#FF6D40',
              }}
              style={{
                whiteSpace: 'pre-line',
              }}
              textToHighlight={article.content}
              findChunks={findChunks}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentSummary;
