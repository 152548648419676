import { useRef, useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfiniteScroll from 'react-infinite-scroller';

import { useClickAway } from 'src/hooks/useClickAway';
import { useStyles } from './AppBar.styles';
import { BrandCustomFilter } from 'src/types/filter';

interface BrandCustomFiltersProps {
  filters: BrandCustomFilter[];
  isOpen: boolean;
  isSmallSidebar?: boolean;
  handleClose: () => void;
  handleSelect: (filter: BrandCustomFilter) => void;
}

export const BrandCustomFilters = ({
  filters,
  isOpen,
  isSmallSidebar,
  handleClose,
  handleSelect,
}: BrandCustomFiltersProps) => {
  const classes = useStyles();
  const [searchKey, setSearchKey] = useState<string>('');
  const ref = useRef<any>();
  const itemsPerPage = 100;
  const [hasMore, setHasMore] = useState(true);
  const [records, setRecords] = useState(itemsPerPage);

  const loadMore = () => {
    const data = filters.filter(
      (item: BrandCustomFilter) =>
        !searchKey ||
        (item && item.name.toLowerCase().includes(searchKey.toLowerCase()))
    );
    if (records >= data.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setRecords(records + itemsPerPage);
      }, 500);
    }
  };

  useClickAway({ ref, handleClickAway: handleClose });

  useEffect(() => {
    if (isOpen) {
      setSearchKey('');
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classes.selectionContainer}
      style={{ left: isSmallSidebar ? 372 : 552 }}
      ref={ref}
    >
      <div className={classes.selectionMain}>
        <div className={classes.selectionHeader}>
          <Typography
            style={{
              fontSize: 16,
              color: 'gray',
              fontWeight: 'normal',
            }}
          >
            Select Brand Standard Filter
          </Typography>
          <div className={classes.searchContainer}>
            <SearchIcon />
            <input
              placeholder='Search filter...'
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'normal',
            }}
          >
            {!searchKey
              ? `Showing ${filters.length} results`
              : `Showing ${
                  filters.filter(
                    (item: BrandCustomFilter) =>
                      item &&
                      item.name.toLowerCase().includes(searchKey.toLowerCase())
                  ).length
                } results for ${searchKey}`}
          </Typography>
        </div>
        <div className={classes.selectionOptions} style={{ marginTop: 12 }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<h4 className='loader'>Loading...</h4>}
            useWindow={false}
          >
            {filters
              .filter(
                (item: BrandCustomFilter) =>
                  !searchKey ||
                  (item &&
                    item.name.toLowerCase().includes(searchKey.toLowerCase()))
              )
              .slice(0, records)
              .map((item: BrandCustomFilter) => (
                <div key={item.id} className={classes.selectOption}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      cursor: 'pointer',
                      padding: '4px 0',
                    }}
                    onClick={() => handleSelect(item)}
                  >
                    {item.name}
                  </Typography>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};
