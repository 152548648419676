import clsx from 'clsx';

import MaterialButton from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from 'src/theme/types/createPalette';

type ButtonVariantType = 'text' | 'outlined' | 'contained';

interface StyleProps {
  height?: string | number;
  width?: string | number;
}

interface ButtonProps extends StyleProps {
  title?: string | JSX.Element;
  padding?: number;
  variant?: ButtonVariantType;
  fontSize?: 'sm' | 'md' | 'lg';
  className?: string;
  [key: string]: any;
}

export default function Button({
  title,
  height,
  width,
  variant = 'contained',
  fontSize = 'md',
  className,
  children,
  ...props
}: ButtonProps) {
  const classes = useStyles({ height, width });

  return (
    <MaterialButton
      variant={variant}
      disableElevation
      disableRipple
      className={clsx(
        className,
        classes.button,
        fontSize === 'sm' && classes.fontSizeSmall,
        fontSize === 'md' && classes.fontSizeNormal,
        fontSize === 'lg' && classes.fontSizeLarge
      )}
      classes={{
        textPrimary: classes.textPrimary,
        textSecondary: classes.textSecondary,
        outlinedPrimary: classes.outlinedPrimary,
        outlinedSecondary: classes.outlinedSecondary,
        containedPrimary: classes.containedPrimary,
        containedSecondary: classes.containedSecondary,
      }}
      {...props}
    >
      {title}
      {children}
    </MaterialButton>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      height: (style: StyleProps) => style.height || 48,
      width: (style: StyleProps) => style.width,
    },
    fontSizeSmall: {
      fontSize: 14,
    },
    fontSizeNormal: {
      fontSize: 16,
    },
    fontSizeLarge: {
      fontSize: 20,
    },
    textPrimary: {
      color: theme.palette.primary.main,
    },
    textSecondary: {
      color: theme.palette.secondary.main,
    },
    outlinedPrimary: {
      color: theme.palette.secondary.main,
    },
    outlinedSecondary: {
      color: theme.palette.primary.main,
    },
    containedPrimary: {
      color: theme.palette.secondary.main,
    },
    containedSecondary: {
      color: theme.palette.primary.main,
    },
  })
);
