import { baseAPICreator } from './apiCreator';
import { SearchResultProps, SearchHistoryProps } from 'src/types/search';

const searchApi = baseAPICreator('/search');

export const getSearchResult = async (body: {
  pageSize: number;
  page: number;
  text: string;
  mode: string;
  genres: string[];
  publishers: string[];
  shows?: string[];
}): Promise<{ data: SearchResultProps }> => {
  const { data } = await searchApi.post('', body);
  return { data: data };
};

export const getSearchHistory = async (): Promise<{
  data: SearchHistoryProps[];
}> => {
  const { data } = await searchApi.get('recent');
  return {
    data: data.map((item: any) => ({
      query: item.query,
      date: item.createdAt,
    })),
  };
};
