import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import BarometerLogo from 'src/components/BarometerLogo';
import Button from 'src/components/Button';
import LinkButton from 'src/components/LinkButton';
import { useScheduleDemoForm } from 'src/hooks/useScheduleDemoForm';
import { ROUTES } from 'src/utils';

import Footer from '../Footer';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2),
    },

    mainContainer: {
      marginTop: 70,
    },

    linkButton: {
      color: '#212121',
      fontWeight: 'normal',
      marginRight: 10,
    },

    scheduleDemoButton: {
      borderRadius: theme.spacing(3),
    },
  })
);

interface props {
  children: React.ReactNode;
}

const UnauthorizedContainWrapper: React.FC<props> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();

  const { launchScheduleDemo } = useScheduleDemoForm({
    automaticLaunch: false,
  });

  const handleClickScheduleDemo: MouseEventHandler = (event) => {
    event.preventDefault();
    launchScheduleDemo();
  };

  const handleSpotlightRedirectToDemo = () => {
    if (window.location.host === 'www.thebarometer.co') {
      window.location.assign('https://app.thebarometer.co/spotlight');
    } else {
      history.push(ROUTES.SPOTLIGHT);
    }
  };

  const handleRedirectToDemo = () => {
    if (window.location.host === 'www.thebarometer.co') {
      window.location.assign('https://app.thebarometer.co/login');
    } else {
      history.push(ROUTES.LOGIN);
    }
  };

  return (
    <Grid container justifyContent='center' style={{ backgroundColor: '#fff' }}>
      <Grid item xs={12} md={10} xl={8}>
        <Grid container justifyContent='space-between'>
          <BarometerLogo />
          <Box className={clsx(classes.buttonContainer)}>
            <Button
              color='secondary'
              variant='text'
              className={classes.linkButton}
              onClick={handleSpotlightRedirectToDemo}
            >
              Spotlight
            </Button>
            <LinkButton
              color='secondary'
              title='Our Mission'
              to={ROUTES.TEAM}
              variant='text'
              className={classes.linkButton}
            />
            <Button
              color='secondary'
              variant='text'
              className={classes.linkButton}
              onClick={handleRedirectToDemo}
            >
              Login
            </Button>
            <Button
              className={classes.scheduleDemoButton}
              color='primary'
              title='Schedule Demo'
              onClick={handleClickScheduleDemo}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          justifyContent='center'
          className={classes.mainContainer}
        >
          {children}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default UnauthorizedContainWrapper;
