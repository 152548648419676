import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

import advertisersImg from 'src/assets/landing/advertisers.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      background: '#EFEDEB',
      padding: '100px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: 36,
    },
    mainContainer: {
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
        alignItems: 'flex-end',
        padding: '0 100px',
      },

      '& > div': {
        flex: 1,
      },
    },
    title: {
      fontWeight: 300,
      fontSize: 30,
      lineHeight: '40px',
      color: '#202226',
      padding: 0,
      margin: 0,
      maxWidth: 520,

      '@media screen and (min-width: 768px)': {
        fontSize: 48,
        lineHeight: '56px',
      },
    },
    description: {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px',
      color: '#545454',
      padding: 0,
      margin: 0,

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '40px',
      },
    },
    logo: {
      position: 'absolute',
      width: '100%',
      bottom: -150,
      left: 0,

      '@media screen and (min-width: 768px)': {
        width: 'calc(100% + 100px)',
        marginLeft: -100,
        position: 'initial',
      },
    },
    advertisers: {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      paddingLeft: 30,

      '@media screen and (min-width: 768px)': {
        paddingLeft: 0,
      },

      '& > div': {
        display: 'flex',
        flexDirection: 'column',

        '& > h6': {
          fontWeight: 700,
          fontSize: 16,
          lineHeight: '24px',
          color: '#202226',
          letterSpacing: '0.04em',
          textTransform: 'uppercase',
          margin: 0,
          position: 'relative',

          '@media screen and (min-width: 768px)': {
            fontSize: 20,
            lineHeight: '40px',
          },

          '&::before': {
            content: '"→"',
            color: '#A172D7',
            fontWeight: 700,
            fontSize: 20,
            paddingRight: 16,
            position: 'absolute',
            right: '100%',
          },
        },

        '& > span': {
          fontWeight: 300,
          fontSize: 16,
          lineHeight: '24px',
          color: '#545454',

          '@media screen and (min-width: 768px)': {
            fontSize: 25,
            lineHeight: '40px',
          },
        },
      },
    },
  })
);

const BarometerAdvertisers: FC = () => {
  const classes = useStyles();

  const advertisers = [
    {
      title: 'Advertisers',
      description:
        'Stop guessing what’s in your media plan and find the perfect context for your campaigns.',
    },
    {
      title: 'Publishers',
      description:
        'Empower your team and clients with nuanced data about your inventory.',
    },
    {
      title: 'Platforms',
      description:
        'Join us in powering the future of programmatic omnichannel advertising at scale.',
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div>
          <h2 className={classes.title}>
            Aligning advertisers with the perfect digital inventory.
          </h2>
        </div>
        <div>
          <p className={classes.description}>
            Drive return on investment on premium, open-internet content by
            harnessing hundreds of thousands of AI inferences updating in real
            time.
          </p>
        </div>
      </div>
      <div className={classes.mainContainer}>
        <div>
          <img
            src={advertisersImg}
            alt='advertisers'
            className={classes.logo}
          />
        </div>
        <div className={classes.advertisers}>
          {advertisers.map((item) => (
            <div key={item.title}>
              <h6>{item.title}</h6>
              <span>{item.description}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BarometerAdvertisers;
