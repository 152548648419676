import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useDashboardStyles = makeStyles(() =>
  createStyles({
    textSMRegular: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: '#2D2D38',
    },
    textSMBold: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
      color: '#2D2D38',
    },
    textXSRegular: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#2D2D38',
    },
    textXSBold: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '16px',
      color: '#2D2D38',
    },
    textLGBold: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '28px',
      color: '#2D2D38',
    },
  })
);
