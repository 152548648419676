import { FC } from 'react';
import { format } from 'date-fns';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { ArticleBase } from 'src/types/article';

import { ArticleInputLabel } from './Labels';
import KeywordsList from './KeywordsList';
import ContentSummary from './ContentSummary';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      fontWeight: 'normal',
      textAlign: 'right',
      marginRight: 60,
    },
  })
);

interface ArticleScoredViewProps {
  article: ArticleBase;
  search?: string;
  show: string;
  showGenres: string[];
  iabCategories?: string[];
}

const ArticleScoredView: FC<ArticleScoredViewProps> = ({
  article,
  search,
  show,
  showGenres,
  iabCategories,
}) => {
  const classes = useStyles();

  let publishedAt = null;

  if (article && article.publishedAt) {
    try {
      publishedAt = format(new Date(article.publishedAt), 'eeee, dd MMMM yyyy');
    } catch {
      publishedAt = '-';
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <ArticleInputLabel variant='body1' className={classes.inputLabel}>
              Air Date
            </ArticleInputLabel>
          </Grid>
          <Grid item xs={9}>
            <Typography variant='body1'>{publishedAt}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <ArticleInputLabel variant='body1' className={classes.inputLabel}>
              Show
            </ArticleInputLabel>
          </Grid>
          <Grid item xs={9}>
            <Typography variant='body1'>{show}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <ArticleInputLabel variant='body1' className={classes.inputLabel}>
              Genre(s)
            </ArticleInputLabel>
          </Grid>
          <Grid item xs={9}>
            <KeywordsList keywords={showGenres} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <ArticleInputLabel variant='body1' className={classes.inputLabel}>
              IAB Categories
            </ArticleInputLabel>
          </Grid>
          <Grid item xs={9}>
            <KeywordsList keywords={iabCategories || []} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <ArticleInputLabel variant='body1' className={classes.inputLabel}>
              Title
            </ArticleInputLabel>
          </Grid>
          <Grid item xs={9}>
            <Typography
              variant='h3'
              style={{ fontWeight: 'normal', fontSize: 34 }}
            >
              {article.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <ArticleInputLabel variant='body1' className={classes.inputLabel}>
              Audio
            </ArticleInputLabel>
          </Grid>
          <Grid item xs={9}>
            <audio controls>
              {article.audioURL ? (
                <source src={article.audioURL} />
              ) : (
                <Typography
                  variant='body1'
                  style={{
                    fontWeight: 'normal',
                  }}
                >
                  No audio available
                </Typography>
              )}
            </audio>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ContentSummary article={article} layouts={[3, 9]} search={search} />
      </Grid>
    </Grid>
  );
};

export default ArticleScoredView;
