import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { init, ErrorBoundary } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.css';

init({
  dsn: 'https://07aa4153ef7d421e8b855f0c3aa0db2e@o4504044458737664.ingest.us.sentry.io/4504055959453696',
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ErrorBoundary fallback={<p>Something went wrong.</p>}>
      <App />
    </ErrorBoundary>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
