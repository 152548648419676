import { ArticleScoreDto } from './score';

export interface ArticleBase {
  guid: string;
  title: string;
  content: string;
  articleURL?: Nullable<string>;
  imageURL?: Nullable<string>;
  audioURL?: Nullable<string>;
  author?: Nullable<string>;
  topic?: Nullable<string>;
  readTime?: Nullable<string>;
  publishedAt?: Nullable<string>;
  scoredAt: Date;
  score: ArticleScoreDto;
  correlated?: any;
  metadata?: any;
  highlightStrings?: string[];
}

export interface ArticleDto extends ArticleBase {
  id: string;
  organizationId: string;
  createdAt?: Date;
}

export type ArticleCreate = ArticleBase;

export type TArticleOverview = {
  editorialBias: number;
  colloquialism: number;
  sensationalism: number;
  count: number;
};

export interface ArticlePeriodOverview extends TArticleOverview {
  from: Date;
  to: Date;
}

export enum IntervalTypes {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export type TPaginatedArticleDtoList = {
  articles: ArticleDto[];
  lastId?: string;
  totalCount: number;
};

export type TSESBiasScore = {
  NA: number;
  true: number;
  false: number;
};

export type TArticlesMetaInformation = {
  contentColloquialism: Record<number, string>;
  contentEditorialBias: Record<number, string>;
  contentSentiment: Record<number, string>;
  titleEditorialBias: Record<number, string>;
  titleColloquialism: Record<number, string>;
  titleSentiment: Record<number, string>;
  titleSESBias: TSESBiasScore;
  contentSESBias: TSESBiasScore;
};

export type TArticleSearchParams = {
  limit?: number;
  page?: number;
  dashboard?: string;
  searchText?: string;
};

export enum SCORE_TYPE {
  TITLE = 'TITLE',
  CONTENT = 'CONTENT',
}
