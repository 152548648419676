import { FC } from 'react';
import { Slider, styled, makeStyles, createStyles } from '@material-ui/core';

export const ScoreSlider = styled(Slider)(({ theme }) => ({
  padding: `${theme.spacing(2.5)}px 0`,
  '& .MuiSlider-track, & .MuiSlider-rail': {
    height: 8,
    borderRadius: 4,
  },
  '& .MuiSlider-rail': {
    color: 'transparent',
  },
  '& .MuiSlider-mark': {
    height: 6,
    width: 6,
    borderRadius: 3,
    marginTop: 1,
    marginLeft: -7,
    color: '#C0C0C0',
  },
  '& .MuiSlider-markActive': {
    marginLeft: -7,
    color: 'white',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    height: 6,
    width: 6,
    marginTop: 1,
    marginLeft: -7,
    color: 'white',
  },
  '& .MuiSlider-markLabel': {
    fontSize: 10,
    fontWeight: 500,
    marginTop: 5,
  },
}));

interface StyleProps {
  color: string;
  removeFirstActive: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    slider: {
      '& .MuiSlider-track': {
        backgroundColor: ({ color }: StyleProps) => color,
      },

      '& .MuiSlider-markActive': {
        '&[data-index="0"]': {
          marginLeft: 1,
          backgroundColor: ({ removeFirstActive }: StyleProps) =>
            removeFirstActive ? '#C0C0C0' : 'white',
        },
      },
    },
  })
);

interface GarmScoreProgressProps {
  color: string;
  min?: number;
  max?: number;
  step?: number;
  marks?: boolean;
  valueLabelDisplay?: 'auto' | 'off' | 'on';
  value: number;
}

const GarmScoreProgress: FC<GarmScoreProgressProps> = ({
  color,
  min = 1,
  max = 5,
  step = 1,
  marks = true,
  value,
}) => {
  const classes = useStyles({ color, removeFirstActive: value === min });

  return (
    <ScoreSlider
      aria-label='Score'
      defaultValue={1}
      step={step}
      marks={marks}
      min={min}
      max={max}
      value={value}
      className={classes.slider}
    />
  );
};

export default GarmScoreProgress;
