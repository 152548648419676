import { FC } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'src/theme/types/createPalette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      borderRadius: theme.spacing(1),
    },
  }),
);

type THeader = {
  field: string;
  label: string;
  render?: (data: any) => any;
};

type TData = any;

interface CustomTableProps {
  headers: THeader[];
  data: TData[];
}

const CustomTable: FC<CustomTableProps> = ({ headers, data }) => {
  const classes = useStyles();

  return (
    <Table>
      <TableHead>
        <TableRow className={classes.row}>
          {headers.map((header) => (
            <TableCell key={header.field}>{header.label}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={row.id || index} className={classes.row}>
            {headers.map((header) => (
              <TableCell key={header.field}>
                {header.render ? header.render(row) : row[header.field]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
