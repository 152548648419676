import useAuth from 'src/hooks/useAuth';
import { useGetLastApiUsagePeriod } from 'src/apis/apiUsage';
import { numberWithCommas, formatDate } from 'src/utils';

import APIKey from 'src/components/APIKey';
import Table from 'src/components/Table';

const APIKeys = () => {
  const { user } = useAuth();
  const { apiUsage } = useGetLastApiUsagePeriod(user?.organizationId);

  if (!user || !apiUsage) return null;

  const headers = [
    {
      field: 'apiKey',
      label: 'API Key',
      render: () => <APIKey value={user.apiKey} />,
    },
    {
      field: 'expirationDate',
      label: 'Expiration Date',
      render: () => formatDate(apiUsage.to),
    },
    {
      field: 'numberOfCallsLeft',
      label: '# of calls left',
      render: () => numberWithCommas(apiUsage.remaining),
    },
  ];

  const data = { ...user, ...apiUsage };
  return <Table headers={headers} data={[data]} />;
};

export default APIKeys;
