import { Box, Grid, Paper, styled } from '@material-ui/core';

export const Border = styled('hr')(({ theme }) => ({
  height: 1,
  opacity: 0.3,
  marginBottom: theme.spacing(2),
  display: 'flex',
  flex: 1,
}));

export const ColorFilterElement = styled(Box)(() => ({
  backgroundColor: '#459B99',
  borderRadius: '50%',
  display: 'inline-flex',
  height: 8,
  width: 8,
  '&.Negative': {
    backgroundColor: '#FF6D40',
  },
}));

export const DropDownContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  borderRadius: 0,
  boxShadow: 'none',
  height: 42,
  marginTop: theme.spacing(2.5),
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
  '& .MuiInput-underline': {
    '&::before, &::after': {
      content: 'none',
    },
  },
  '& .MuiSelect-icon': {
    top: 'calc(50% - 21px)',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    fontSize: 14,
    fontWeight: 500,
    marginTop: theme.spacing(-0.9),
  },
  '& .MuiSvgIcon-root.MuiSelect-icon': {
    color: '#1F1F1F',
  },
  '& .MuiFormLabel-root': {
    top: theme.spacing(-0.9),
    '& .MuiInputLabel-shrink': {
      top: theme.spacing(-1.2),
    },
  },
}));

export const PaperContainer = styled(Paper)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: 'none', // Set box-shadow to none here instead of using paper `elevation` property to allow easier reusability
  marginBottom: theme.spacing(2.5),
  marginTop: theme.spacing(0.5),
  height: 'auto',
  padding: 5,
  width: '100%',
  '& .TagCloudInput--input': {
    // padding: 0,
  },
  '& .TagCloudInput--ChipsContainer': {
    '&::before, &::after': {
      content: 'none',
    },
    '& .MuiChip-root': {
      height: 26,
      fontSize: 13,
      fontWeight: 'normal',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '& .MuiChip-deleteIcon': {
        color: 'rgba(255, 255, 255, 0.4)',
      },
      maxWidth: '100%',
    },
  },
}));

export const TitleContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2.25),
  '& span': {
    fontWeight: 500,
  },
}));

export const SubTitleContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1.2),
  marginTop: theme.spacing(2.25),
  '& span': {
    fontWeight: 'normal',
    opacity: 0.5,
  },
}));
