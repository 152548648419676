/* eslint-disable quotes */
import { FC, useEffect, useState } from 'react';
import {
  Drawer,
  IconButton,
  Typography,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { decode } from 'html-entities';
import { format } from 'date-fns';
import { Chart, ReactGoogleChartEvent } from 'react-google-charts';

import useDialog from 'src/hooks/useDialog';
import { formatFilename } from 'src/utils';
import MenuSelection from 'src/components/dashboard/MenuRow/menu-selection/MenuSelection';
import { GarmScoresPerShowBody } from 'src/types';

interface GarmDashboardShowDetailsProps {
  genres: string[];
  hostList: string[];
  selectedShow: string;
  logoUrl: Nullable<string>;
  hostsData: any;
  onChangeShow: (show: Nullable<string>, body: GarmScoresPerShowBody) => void;
}

interface HistoryHostProps {
  index: string;
  publishedAt: number;
  count: number;
  score: number;
}

interface NewHostProps {
  id: string;
  title: string;
  publishedAt: number;
  url: string;
  finalScore: number;
}

const ShowHostDataDetails: FC<GarmDashboardShowDetailsProps> = ({
  genres,
  hostList,
  selectedShow,
  logoUrl,
  hostsData,
  onChangeShow,
}) => {
  const classes = useStyles();
  const [showHostData, setShowHostData] = useState<{
    [x: string]: any;
  }>({});
  const [sortType, setSortType] = useState<string>('date');
  const [isSortNewest, setIsSortNewest] = useState<boolean>(true);

  const imagePath = formatFilename(selectedShow);

  const { onClose } = useDialog();

  const handleClose = () => {
    onClose();
    onChangeShow(null, {});
  };

  const addDefaultSrc = (ev: any) => {
    ev.target.src = '/images/placeholder.png';
  };

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: 'select',
      callback({ chartWrapper }: any) {
        const chart = chartWrapper.getChart();
        const data = chartWrapper.getDataTable();
        const selection = chart.getSelection();
        if (
          (selection[0]?.row || selection[0]?.row === 0) &&
          data.getValue(selection[0].row, 0)
        ) {
          const searchMonth = new Date(
            data.getValue(selection[0].row, 0)
          ).getMonth();
          const elements = Array.from(
            document.getElementsByClassName('newhost-date')
          );

          const matches: any = elements.filter((el: any) => {
            const month = new Date(el?.textContent).getMonth();
            return (month === 0 || month) && month === searchMonth;
          });

          matches[0]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      },
    },
  ];

  useEffect(() => {
    if (hostList) {
      const hostData: { [x: string]: any } = {};
      hostList.forEach((host) => {
        const hostNewData = hostsData[
          host as keyof typeof hostsData
        ]?.articles.map((item: any) => ({
          index: item.id,
          title: item.title,
          publishedAt: new Date(item.publishedAt).getTime(),
          url: item.url,
          finalScore:
            (item.titleScore ?? 0) +
            (item.descScore ?? 0) +
            (item.contentScore ?? 0),
        })) as NewHostProps[];

        const hostHistory = hostsData[
          host as keyof typeof hostsData
        ]?.aggregations.map((item: any) => ({
          index: item.id,
          publishedAt: new Date(item.publishedAt).getTime(),
          count: item.count,
          score: item.totalScore,
        })) as HistoryHostProps[];

        const filteredHostHistory =
          hostHistory &&
          hostHistory.length &&
          hostHistory.sort((a: HistoryHostProps, b: HistoryHostProps) => {
            const timeA = new Date(a.publishedAt);
            const timeB = new Date(b.publishedAt);
            if (timeA && timeB) {
              return timeB.getTime() - timeA.getTime();
            } else if (timeA && !timeB) {
              return -1;
            } else if (!timeA && timeB) {
              return 1;
            }
            return 0;
          });
        const score =
          filteredHostHistory && filteredHostHistory.length
            ? filteredHostHistory.reduce(
                (
                  acc: number,
                  item: {
                    index: string;
                    publishedAt: number;
                    count: number;
                    score: number;
                  }
                ) => acc + item.score,
                0
              ) / hostHistory.length
            : null;
        let hostSentiment = '';
        if (score === null) {
          hostSentiment = 'No data during this period';
        } else if (score > 1) {
          hostSentiment = 'Trending Positive';
        } else if (score < -1) {
          hostSentiment = 'Trending Negative';
        } else {
          hostSentiment = 'Trending Neutral';
        }
        hostData[host] = {
          hostSentiment,
          historyData: filteredHostHistory || [],
          newData: hostNewData || [],
        };
      });
      setShowHostData(hostData);
    }
  }, [hostsData, hostList]);

  const handleSort = (a: NewHostProps, b: NewHostProps) => {
    if (sortType === 'date') {
      const timeA = new Date(a.publishedAt);
      const timeB = new Date(b.publishedAt);
      if (timeA && timeB) {
        return isSortNewest
          ? timeB.getTime() - timeA.getTime()
          : timeA.getTime() - timeB.getTime();
      } else if (timeA && !timeB) {
        return isSortNewest ? -1 : 1;
      } else if (!timeA && timeB) {
        return isSortNewest ? 1 : -1;
      }
    } else if (sortType === 'title') {
      const titleA = a.title;
      const titleB = b.title;
      if (titleB > titleA) {
        return isSortNewest ? 1 : -1;
      } else {
        return isSortNewest ? -1 : 1;
      }
    } else {
      const scoreA = a.finalScore;
      const scoreB = b.finalScore;
      if (scoreA && scoreB) {
        return isSortNewest ? scoreB - scoreA : scoreA - scoreB;
      } else if (scoreA && !scoreB) {
        return isSortNewest ? -1 : 1;
      } else if (!scoreA && scoreB) {
        return isSortNewest ? 1 : -1;
      }
    }
    return 0;
  };

  const handleClickColumn = (type: string) => {
    if (sortType === type) {
      setIsSortNewest(!isSortNewest);
    } else {
      setSortType(type);
      setIsSortNewest(true);
    }
  };

  return (
    <Drawer
      anchor='right'
      open
      onClose={handleClose}
      className={classes.drawer}
    >
      <div className={classes.menu}>
        <img
          src={'/images/icons/barometer.png'}
          height={36}
          alt='barometer'
          className={classes.barometerLogo}
        />
        <MenuSelection />
      </div>
      <div
        style={{
          padding: '12px',
          paddingTop: '6px',
          position: 'relative',
          display: 'flex',
          flex: 1,
          flexGrow: 1,
          height: 'calc(100% - 66px)',
          flexWrap: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <div className={classes.header}>
          <IconButton onClick={handleClose} size='small'>
            <CloseIcon color='primary' />
          </IconButton>
          <img
            src={logoUrl || imagePath}
            alt='episode'
            onError={addDefaultSrc}
          />
          <div className={classes.title}>
            <Typography variant='subtitle2'>{decode(selectedShow)}</Typography>
            <Typography variant='body1' className={classes.genresTitle}>
              Genres:
            </Typography>
            <Typography variant='body2' className={classes.genre}>
              {genres.map((genre, index) =>
                index === genres.length - 1 ? genre : genre + ', '
              )}
            </Typography>
          </div>
        </div>
        <div className={classes.hostList}>
          {hostList.map(
            (host) =>
              !!showHostData[host] && (
                <div key={host} className={classes.host}>
                  {!showHostData[host].historyData.length ? (
                    <div style={{ height: 260, marginLeft: 30 }}>
                      <p style={{ fontSize: 16, color: 'rgb(117, 117, 117)' }}>
                        {host}
                      </p>
                      <p>{showHostData[host].hostSentiment}</p>
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          fontSize: 16,
                          color: 'rgb(117, 117, 117)',
                          marginLeft: 30,
                        }}
                      >
                        {host}
                      </p>
                      <div className={classes.historyHostData}>
                        <div className={classes.graphFrame}>
                          <Chart
                            chartType='Line'
                            width='320px'
                            height='220px'
                            data={[
                              [{ type: 'date', label: '' }, ''],
                              ...showHostData[host].historyData.map(
                                (item: any) => [
                                  new Date(item.publishedAt),
                                  item.score,
                                ]
                              ),
                            ]}
                            options={{
                              colors: ['#3E91DE'],
                              legend: { position: 'none' },
                            }}
                            chartEvents={chartEvents}
                            loader={<CircularProgress />}
                            errorElement={
                              <span>Sorry, something went wrong.</span>
                            }
                          />
                        </div>
                        <div>
                          <p>
                            <b>Sentiment across current time window:</b>
                            <br />
                            {showHostData[host].hostSentiment}
                          </p>
                          <p>
                            <b># of articles in current time window:</b>
                            <br />
                            {showHostData[host].historyData.reduce(
                              (partialSum: number, a: HistoryHostProps) =>
                                partialSum + a.count,
                              0
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={classes.newHostData}>
                    {!!showHostData[host].newData.length && (
                      <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align='center'
                              onClick={() => handleClickColumn('date')}
                            >
                              Date
                            </TableCell>
                            <TableCell
                              align='center'
                              onClick={() => handleClickColumn('score')}
                            >
                              Sentiment
                            </TableCell>
                            <TableCell
                              align='center'
                              onClick={() => handleClickColumn('title')}
                            >
                              Article Title
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {showHostData[host].newData
                            .sort(handleSort)
                            .map((newHost: NewHostProps) => (
                              <TableRow key={newHost.id}>
                                <TableCell
                                  align='center'
                                  className='newhost-date'
                                >
                                  {newHost.publishedAt &&
                                    format(
                                      new Date(newHost.publishedAt),
                                      'MM/dd/yy'
                                    )}
                                </TableCell>
                                <TableCell align='center'>
                                  {newHost.finalScore}
                                </TableCell>
                                <TableCell align='left'>
                                  <a
                                    href={newHost.url}
                                    rel='noreferrer'
                                    target='_blank'
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {newHost.title}
                                  </a>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      '& > img': {
        width: 42,
        height: 42,
        borderRadius: 4,
        marginLeft: 10,
      },
      marginBottom: 6,
      paddingBottom: 8,
      borderBottom: '1px solid #dddddd',
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    genresTitle: {
      fontSize: 12,
      lineHeight: '100%',
      opacity: 0.7,
      marginBottom: 1,
    },
    genre: {
      fontSize: 10,
      lineHeight: '100%',
      opacity: 0.7,
    },
    drawer: {
      '& > .MuiDrawer-paper': {
        width: '100%',
        maxWidth: '100%',
        overflow: 'auto',
      },
    },
    menu: {
      padding: '12px 20px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #dddddd',
      background: '#F5F7FA',
    },
    barometerLogo: {
      marginRight: 20,
    },
    hostList: {
      overflowX: 'auto',
      flex: 1,
      display: 'flex',
      alignItems: 'flex-start',
      gap: 24,
      paddingTop: 2,
      paddingBottom: 8,
      height: 'calc(100% - 2px)',
      '&::-webkit-scrollbar': {
        height: 8,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    host: {
      width: 750,
      minWidth: 750,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 20,
      position: 'relative',
      height: '100%',
    },
    historyHostData: {
      height: 260,
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      marginLeft: 30,
    },
    newHostData: {
      height: '100%',
      flex: 1,
      overflowY: 'auto',
      width: '100%',
      '& .MuiTableCell-root': {
        border: '1px solid rgba(224, 224, 224, 1)',
      },
      '& .MuiTableCell-head': {
        lineHeight: '150%',
        padding: 12,
        cursor: 'pointer',
        background: '#F1F2F5',
        '&:hover': {
          background: '#FDFAFF',
        },
      },
      '& .MuiTableCell-body': {
        lineHeight: '105%',
        padding: 12,
        '& a': {
          color: 'rgba(0, 0, 0, 0.87)',
        },
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    hostcontent: {
      fontSize: 10,
      lineHeight: '100%',
      opacity: 0.7,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    hostArticles: {
      borderCollapse: 'collapse',
      '& th, & td': {
        border: '1px solid #dddddd',
        padding: 8,
        textAlign: 'center',
        fontWeight: 'bold',
      },
      '& td': {
        fontWeight: 'normal',
      },
      '& td a': {
        color: 'black',
        cursor: 'pointer',
      },
    },
    articlesModal: {
      backgroundColor: '#f1f1f5',
      position: 'absolute',
      bottom: 12,
      right: 12,
      maxWidth: 560,
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 100px)',
    },
    circleClose: {
      backgroundColor: '#ffffff',
      borderRadius: '100%',
      position: 'absolute',
      top: 8,
      left: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f9f9fe',
      },
    },
    graphFrame: {
      width: 340,
      height: 240,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px dashed #ececec',
      padding: 10,
    },
  })
);

export default ShowHostDataDetails;
