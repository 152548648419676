import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Button from 'src/components/Button';
import Checkbox from 'src/components/Checkbox';
import TextInput from 'src/components/TextInput';
import { Theme } from 'src/theme/types/createPalette';
import useDebounce from 'src/hooks/useDebounce';
import { getPromoCodeInfo } from 'src/apis';

import pdfTermsConditions from 'src/assets/Terms_Conditions.pdf';

interface PaymentForm {
  name: string;
  promoCode: string;
}

interface PaymentMethodFormProps {
  planCost: number;
  onCancel: VoidFunction;
  onPayNow: (token: string, promoCode?: string) => void;
}

export default function PaymentMethodForm({
  planCost,
  onCancel,
  onPayNow,
}: PaymentMethodFormProps) {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const [creating, setCreating] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [form, setForm] = useState<PaymentForm>({
    name: '',
    promoCode: '',
  });
  const [promoPercentOff, setPromoPercentOff] = useState<number | undefined>(0);

  const debouncedPromoCode = useDebounce(form.promoCode, 500);

  const getPromoPercentOff = useCallback(async (promocode: any) => {
    const data = await getPromoCodeInfo(promocode);
    setPromoPercentOff(data?.percentOff);
  }, []);

  useEffect(() => {
    getPromoPercentOff(debouncedPromoCode);
  }, [debouncedPromoCode, getPromoPercentOff]);

  const handleChangeForm = async (value: string, name: string | undefined) => {
    if (!name) return;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (event: React.FormEvent<any>) => {
    event.preventDefault();
    try {
      setCreating(true);

      if (!stripe || !elements) return;
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) return;

      const { error, token } = await stripe.createToken(cardElement);
      if (error || !token) {
        enqueueSnackbar(error?.message || 'Can not create a token.', {
          variant: 'error',
        });
        return;
      }

      await onPayNow(token.id, form.promoCode);
    } finally {
      setCreating(false);
    }
  };

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        width: '100%',
      }}
    >
      {creating && <LinearProgress />}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextInput
              name='name'
              value={form.name}
              label='Name on card'
              fullWidth={true}
              variant='outlined'
              onChange={handleChangeForm}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name='promoCode'
              value={form.promoCode}
              label='Promo code (optional)'
              fullWidth={true}
              variant='outlined'
              onChange={handleChangeForm}
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup className={classes.cardFormGroup}>
              <fieldset className={classes.fieldset}>
                <CardElement />
              </fieldset>
            </FormGroup>
          </Grid>
          <div className={classes.amountContainer}>
            {promoPercentOff && (
              <div>
                <span className={classes.promoApplied}>Pormo applied!</span>
                <span>-${((promoPercentOff * planCost) / 100).toFixed(2)}</span>
              </div>
            )}
            <div>
              <span>Total Cost:</span>
              <span>
                $
                {promoPercentOff
                  ? (((100 - promoPercentOff) * planCost) / 100).toFixed(2)
                  : planCost.toFixed(2)}
              </span>
            </div>
          </div>
          <Grid
            container
            item
            xs={12}
            justifyContent='flex-start'
            alignItems='center'
          >
            <Checkbox
              name={'Terms and Conditions'}
              label=''
              checked={checked}
              noPadding={true}
              onChange={setChecked}
              className={classes.checkbox}
            />
            <Typography variant='body1'>
              By clicking this checkbox I agree to the{' '}
              <Link
                href={pdfTermsConditions}
                color='inherit'
                underline='always'
                target='_blank'
                rel='noreferrer'
              >
                Terms and Conditions
              </Link>
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent='space-between'>
            <Button
              title='Cancel'
              color='secondary'
              disabled={creating}
              onClick={onCancel}
            />
            <Button
              type='submit'
              color='primary'
              title='Pay Now'
              disabled={creating || !checked}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardFormGroup: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    fieldset: {
      width: '100%',
      boxSizing: 'border-box',
      paddingTop: theme.spacing(1),
      marginLeft: 0,
    },
    label: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    checkbox: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    amountContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginBottom: 20,
      '& > div': {
        width: '50%',
        padding: '8px 12px 4px',
        borderBottom: '1px solid #000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#555555',
        fontSize: 18,
      },
    },
    promoApplied: {
      borderRadius: 12,
      border: '1px solid #000000',
      padding: '4px 8px',
      background: '#41e78b',
      fontSize: 16,
    },
  })
);
