import { FC, useState, useCallback, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Typography, TextField } from '@material-ui/core';
import omit from 'lodash/omit';
import { useHistory } from 'react-router-dom';

import Button from 'src/components/Button';
import { useCommonStyles } from '../onboarding-redesign/utils/useCommonStyles';
import {
  verifyResetPasswordCode,
  verifyResetCodeAndUpdatePassword,
} from 'src/apis';
import AuthLayout from './AuthLayout';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';
import useQuery from 'src/hooks/useQuery';
import { TVerifyResetCodeAndUpdatePassword } from 'src/types';
import useNotification from 'src/hooks/useNotification';
import { ROUTES } from 'src/utils';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '100%',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '32px',
        color: '#545454',

        '@media screen and (min-width: 768px)': {
          fontSize: 25,
          lineHeight: '40px',
        },
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: '#545454',
      },
    },
    backBtn: {
      position: 'absolute',
      width: 'auto',
      fontWeight: 400,
      top: 45,
      left: 16,
      padding: 0,
      justifyContent: 'flex-start',
      color: '#545454',

      '@media screen and (min-width: 768px)': {
        left: 'calc(50% - 287px)',
        top: 38,
      },

      '& .MuiInput-underline:before': {
        top: 0,
      },
    },
    link: {
      color: '#A172D7',
      width: 'auto',
      height: 'auto',
      padding: 0,
    },
    inputsContainer: {
      marginTop: 52,
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    signup: {
      marginTop: 24,
      display: 'flex',
      alignItems: 'center',
    },
    loginBtn: {
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      borderRadius: 2,
      padding: '6px 16px',
      height: 40,
      marginTop: 52,

      '&:hover': {
        background:
          'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      },

      '& > .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 45,
        fontSize: 16,
        lineHeight: '24px',
        color: '#EFEDEB',
        fontWeight: 500,
      },
    },
  })
);

const ConfirmResetCodeScreen: FC = () => {
  const query = useQuery();
  const classes = useStyles();
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const code = query.get('code') || '';
  const email = query.get('email') || '';
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const { handleError, handleSuccess } = useNotification();
  const [form, setForm] = useState<
    TVerifyResetCodeAndUpdatePassword & { confirmPassword: string }
  >({
    code: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleVerifyCode = useCallback(async () => {
    try {
      await verifyResetPasswordCode({ code, email });
      setVerified(true);
      setForm((form) => ({
        ...form,
        code,
        email,
      }));
      handleSuccess('Your Password Reset Link has been verified.');
    } catch (error) {
      handleError(error, 'Your Password Reset Link is not valid.');
    }
  }, [code, email, handleSuccess, handleError]);

  useEffect(() => {
    handleVerifyCode();
  }, [handleVerifyCode]);

  const handleSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (form.password === form.confirmPassword) {
      try {
        setLoading(true);
        await verifyResetCodeAndUpdatePassword(omit(form, 'confirmPassword'));
        submitMetrics(METRICS_TYPE.VERIFY_RESET_CODE);
        history.push(ROUTES.LOGIN);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (value: string, name?: string) => {
    if (!name) return;
    setForm({ ...form, [name]: value });
  };

  if (!verified) return null;

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <Typography className={commonStyles.headline1}>
          Set new password
        </Typography>
        <Typography className={commonStyles.body1}>
          Please enter a new secure password for Barometer, and please confirm
          your password below.
        </Typography>
        <div className={classes.inputsContainer}>
          <TextField
            id='email'
            placeholder='Email'
            className={classes.input}
            value={email}
          />
          <TextField
            id='password'
            type='password'
            placeholder='Password'
            className={classes.input}
            value={form.password}
            onChange={(e) => handleChange(e.target.value, 'password')}
          />
          <TextField
            id='confirmPassword'
            type='password'
            placeholder='Confirm Password'
            className={classes.input}
            value={form.confirmPassword}
            onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
          />
        </div>
        <div>
          <Button
            className={classes.loginBtn}
            color='primary'
            type='submit'
            disabled={loading}
          >
            <span>Send password reset link</span>
            <span>→</span>
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ConfirmResetCodeScreen;
