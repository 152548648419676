import DashboardIcon from '@material-ui/icons/Dashboard';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import GroupIcon from '@material-ui/icons/Group';

import { TSidebarLink } from 'src/types';
import { ReactComponent as KeyIcon } from 'src/assets/icons/key.svg';
import { ReactComponent as FilterLinesIcon } from 'src/assets/icons/filterLines.svg';
import { ReactComponent as ClockIcon } from 'src/assets/icons/clock.svg';

export const Links: TSidebarLink[] = [
  {
    text: 'Dashboards',
    href: '/dashboard',
    icon: <DashboardIcon htmlColor='#757575' style={{ fontSize: 25 }} />,
    expandable: true,
    secSidebar: true,
  },
  {
    text: 'Reports',
    href: '/reports',
    icon: <AssessmentIcon htmlColor='#757575' style={{ fontSize: 25 }} />,
  },
  {
    text: 'Team',
    href: '/team',
    icon: <GroupIcon htmlColor='#757575' style={{ fontSize: 25 }} />,
  },
  {
    text: 'Documentation',
    href: '/documentation',
    icon: <MenuBookIcon htmlColor='#757575' style={{ fontSize: 25 }} />,
  },
  {
    text: 'Settings',
    href: '/settings',
    icon: <SettingsIcon htmlColor='#757575' style={{ fontSize: 25 }} />,
    children: [
      {
        text: 'Brand Standard Filters',
        href: '/settings/standard-filters',
        icon: <FilterLinesIcon />,
      },
      {
        text: 'Scoring Request History',
        href: '/settings/self-scoring',
        icon: <ClockIcon />,
      },
      {
        text: 'API Key',
        href: '/settings/api-key',
        icon: <KeyIcon />,
      },
    ],
  },
];
