import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';

import { useCommonStyles } from '../utils/useCommonStyles';

import CEOImg from 'src/assets/teams/ceo_tamara.png';
import CTOImg from 'src/assets/teams/cto_grant.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      position: 'relative',
      background: '#EFEDEB',
      marginBottom: 85,
    },
    mainContainer: {
      height: '100%',
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 10,
      columnGap: 120,
      zIndex: 1,
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        padding: '0 100px',
        flexDirection: 'row',
      },
    },
    title: {
      fontWeight: 300,
      fontSize: 38,
      lineHeight: '48px',
      color: '#202226',
      textTransform: 'uppercase',
      marginBottom: 40,
      marginTop: 40,

      '@media screen and (min-width: 768px)': {
        fontSize: 76,
        lineHeight: '96px',
        marginBottom: 120,
      },
    },
    description: {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px',
      color: '#545454',
      marginBottom: 40,

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '40px',
        marginBottom: 55,
      },
    },
    founders: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 120,
      paddingBottom: 75,

      '@media screen and (min-width: 768px)': {
        rowGap: 220,
        paddingBottom: 200,
      },
    },
    mainImg: {
      maxWidth: 450,
      width: '100%',

      '& > img': {
        width: 164,

        '@media screen and (min-width: 768px)': {
          width: 338,
        },
      },
    },
    noteContainer: {
      maxWidth: 920,
      margin: 'auto',
      marginTop: 60,
      padding: '0 16px',

      '@media screen and (min-width: 768px)': {
        marginTop: 120,
      },
    },
    note: {
      position: 'relative',

      '&::before': {
        content: '"“"',
        color: '#DBD5C3',
        fontWeight: 300,
        fontSize: 200,
        lineHeight: '150px',
        paddingRight: 42,
        position: 'absolute',
        right: '100%',
      },

      '& > span': {
        '@media screen and (min-width: 768px)': {
          display: 'none',
        },
      },
    },
  })
);

const FoundersData = [
  {
    name: 'Tamara Zubatiy',
    position: 'Co-Founder & CEO',
    photo: CEOImg,
    description:
      'Tamara studied Cognitive Science with specialization in Machine Learning and Neural Computation at UC San Diego. In her time out of office Tamara enjoys reading, running, yoga and the ocean.',
    note: 'Each day I get to engage Barometer’s amazing customers and prospective users, listen and learn from their stories and experiences, and use their insights to guide our team roadmap. I also work with our team and a small army of amazing advisors, investors and professionals to strategize for Barometer’s continued success. It is an honor and a privilege to guide the Barometer team on our its mission to empower companies to become aware of the biases in their text content and harness that knowledge in order to optimize for what matters most, while simultaneously becoming more accountable to their stakeholders!"',
  },
  {
    name: 'Grant Nelson',
    position: 'Co-Founder & CTO',
    photo: CTOImg,
    description:
      'Grant Nelson is the CTO and co-founder of Barometer, a technology company based in Atlanta, Georgia. Barometer analyzes millions of text samples quickly and consistently to help companies fingerprint their text content and optimize what matters.',
    note: 'At Barometer I work with our amazing tech team to optimize our core technologies and product. I ensure that we have a continuous delivery systems of testing, staging, and pushing to production. Our algorithms can be used to raise awareness to complex social issues, which is a responsibility I do not take lightly. As such, we are diligent and rigorous in our continuous performance testing. We are always delivering new capabilities in our API and Dashboards, which our customers use hundreds of thousands of times each month. I am excited to continue learning from our users and build out this tool to empower them to better understand and manage their text content."',
  },
];

const Founders: FC = () => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <Typography className={classes.title}>Our Founders</Typography>
      </div>
      <div className={classes.founders}>
        {FoundersData.map((founder, index) => (
          <div key={index}>
            <div className={classes.mainContainer}>
              <Box className={classes.mainImg}>
                <img src={founder.photo} alt='Founder' />
              </Box>
              <Box flex={1}>
                <Typography className={commonStyles.headline1}>
                  {founder.name}
                </Typography>
                <Typography className={commonStyles.caption}>
                  {founder.position}
                </Typography>
                <Box marginTop='16px'>
                  <Typography className={commonStyles.title2}>
                    {founder.description}
                  </Typography>
                </Box>
              </Box>
            </div>
            <Box className={classes.noteContainer}>
              <Typography
                className={classNames(classes.note, commonStyles.body2)}
              >
                <span>“</span>
                {founder.note}
              </Typography>
            </Box>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Founders;
