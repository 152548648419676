import { styled, TableFooter } from '@material-ui/core';

const Footer = styled(TableFooter)(() => ({
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: 4,
  backgroundColor: '#fff',
  borderTop: 'none',
}));

export default Footer;
