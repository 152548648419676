import clsx from 'clsx';

import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { Theme } from 'src/theme/types/createPalette';
import { InputChangeEvent, KeyboardInputEvent } from 'src/types';

type TextVariantTypes = 'filled' | 'outlined' | 'standard';

interface ITextInputProps {
  name?: string;
  type?: string;
  label?: string;
  value: string;
  required?: boolean;
  fullWidth?: boolean;
  variant?: TextVariantTypes;
  className?: string;
  readOnly?: boolean;
  onEnter?: () => void;
  onChange?: (value: string, name?: string) => void;
  [key: string]: any;
}

export default function TextInput({
  name,
  type,
  label,
  value,
  variant,
  required,
  fullWidth,
  className,
  readOnly,
  onEnter,
  onChange,
  ...props
}: ITextInputProps) {
  const classes = useStyles();

  const onKeyDown = (e: KeyboardInputEvent) => {
    if (e.keyCode === 13 && onEnter) {
      onEnter?.();
      e.preventDefault();
    }
  };

  const handleChange = (e: InputChangeEvent) => {
    onChange?.(e.target.value, e.target.name);
  };

  return (
    <TextField
      name={name}
      type={type}
      value={value}
      variant={variant}
      label={label}
      required={required}
      fullWidth={fullWidth}
      className={clsx(className, classes.formControl)}
      onKeyDown={onKeyDown}
      onChange={handleChange}
      InputProps={{
        readOnly: readOnly || false,
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),

      '& .MuiInputLabel-filled': {
        transform: 'translate(0px, -20px) scale(1)',
      },

      '& .MuiFilledInput-multiline': {
        paddingTop: theme.spacing(1.5),
      },

      '& .MuiOutlinedInput-input': {
        padding: theme.spacing(1),
      },

      '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
        transform: 'translate(14px, 10px) scale(1)',
      },

      '& .MuiFilledInput-input:not(.MuiFilledInput-inputMultiline)': {
        padding: theme.spacing(1.5, 1.5, 1.25),
      },
    },
  })
);
