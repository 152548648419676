import { baseAPICreator } from './apiCreator';
import { ScoreBody, ScoreResponse, TotalScoreResponse } from 'src/types/score';

const scoreApi = baseAPICreator('/score');

export const getGeneralScore = async (
  apiKey: string,
  body: ScoreBody
): Promise<ScoreResponse> => {
  const { data } = await scoreApi.post(`/general?secretKey=${apiKey}`, body);
  return data;
};

export const getTotalScore = async (
  apiKey: string,
  body: ScoreBody
): Promise<TotalScoreResponse> => {
  const { data } = await scoreApi.post(`/total?secretKey=${apiKey}`, body);
  return data;
};

export const getPDFScore = async (apiKey: string, body: any) => {
  const { data } = await scoreApi.post(`/pdf?secretKey=${apiKey}`, body);
  return data;
};

export const getContentOnlyScore = async (apiKey: string, body: any) => {
  const { data } = await scoreApi.post(
    `/content-only?secretKey=${apiKey}`,
    body
  );
  return data;
};
