import _get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Button from 'src/components/Button';
import CookieIcon from 'src/icons/cookie-icon.svg';
import { useLayoutStyles } from 'src/components/useCommonStyles';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      margin: 0,
      bottom: 0,
      maxWidth: '100vw',
      position: 'absolute',
    },
    container: {
      width: '100vw',
      padding: theme.spacing(1, 6),
    },
    link: {
      color: 'black',
    },
  })
);

const cookies = [
  { code: 'account', display: 'Account' },
  { code: 'login', display: 'Login' },
  { code: 'newsletters', display: 'Email' },
  { code: 'orders', display: 'Orders' },
  { code: 'surveys', display: 'Surveys' },
  { code: 'forms', display: 'Forms' },
  { code: 'third_party', display: 'Third Party' },
];

type TCookiePolicy = {
  confirmed: boolean;
  account: boolean;
  login: boolean;
  newsletters: boolean;
  orders: boolean;
  surveys: boolean;
  forms: boolean;
  third_party: boolean;
};

const CookiePolicyBox = () => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();

  const [cookiePolicy, setCookiePolicy] = useState<TCookiePolicy>({
    confirmed: false,
    account: false,
    login: false,
    newsletters: false,
    orders: false,
    surveys: false,
    forms: false,
    third_party: false,
  });

  const handleReadCookiesPolicy = useCallback(() => {
    const cookiesEnabled = JSON.parse(
      localStorage.getItem('cookiePolicy') || '{}'
    );
    if (typeof cookiesEnabled !== 'object') return;
    setCookiePolicy({
      confirmed: cookiesEnabled.confirmed || false,
      account: cookiesEnabled.account || false,
      login: cookiesEnabled.login || false,
      newsletters: cookiesEnabled.newsletters || false,
      orders: cookiesEnabled.orders || false,
      surveys: cookiesEnabled.surveys || false,
      forms: cookiesEnabled.forms || false,
      third_party: cookiesEnabled.third_party || false,
    });
  }, []);

  const handleAcceptAllCookies = () => {
    setCookiePolicy({
      confirmed: true,
      account: true,
      login: true,
      newsletters: true,
      orders: true,
      surveys: true,
      forms: true,
      third_party: true,
    });
    submitMetrics(METRICS_TYPE.ACCEPT_ALL);
  };

  const handleAcceptSelectedCookies = () => {
    setCookiePolicy({
      ...cookiePolicy,
      confirmed: true,
    });
    submitMetrics(METRICS_TYPE.ACCEPT_SELECTED);
  };

  useEffect(() => {
    handleReadCookiesPolicy();
  }, [handleReadCookiesPolicy]);

  const handleChangeCookie = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setCookiePolicy({ ...cookiePolicy, [event.target.name]: checked });
  };

  useEffect(() => {
    if (!cookiePolicy.confirmed) return;
    localStorage.setItem('cookiePolicy', JSON.stringify(cookiePolicy));
  }, [cookiePolicy]);

  return (
    <Dialog
      open={!cookiePolicy.confirmed}
      maxWidth='xl'
      classes={{ paper: classes.dialog }}
    >
      <Grid container justifyContent='center' className={classes.container}>
        <Grid item xs={12} sm={10} md={8}>
          <Grid container alignItems='center' item xs={12}>
            <img src={CookieIcon} alt='cookie' />
            <Typography variant='body1' className={layoutClasses.ml1}>
              By using our website you agree to our Cookie Policy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link to='/cookie-policy' className={classes.link}>
              Click here to read the full details of our cookies policy.
            </Link>
          </Grid>
          <Grid item xs={12}>
            {cookies.map((cookie) => (
              <FormControlLabel
                key={cookie.code}
                control={
                  <Switch
                    name={cookie.code}
                    checked={_get(cookiePolicy, cookie.code)}
                    onChange={handleChangeCookie}
                  />
                }
                label={cookie.display}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} container spacing={2}>
          <Grid item xs={12}>
            <Button
              title='Accept all cookies'
              height={30}
              fullWidth
              color='primary'
              onClick={handleAcceptAllCookies}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              title='Accept selected'
              height={30}
              fullWidth
              color='primary'
              onClick={handleAcceptSelectedCookies}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CookiePolicyBox;
