export enum BillingInterval {
  YEAR = 'year',
  MONTH = 'month',
}

export enum SubscriptionTier {
  BASIC = 'basic',
  STANDARD = 'standard',
}

export interface SubscriptionBase {
  name: string;
  type: SubscriptionTier;
  amount: number;
  interval: BillingInterval;
  intervalCount: number;
  active?: boolean;
  description?: string;
  metadata?: any;
}

export interface SubscriptionDto extends SubscriptionBase {
  productId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}
