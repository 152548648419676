import { styled, Table } from '@material-ui/core';

const TableWrapper = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-stickyHeader': {
    backgroundColor: theme.palette.common.white,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    paddingTop: 14,
    paddingBottom: 14,
    fontWeight: 500,
    borderBottom: 'none',
  },
  '& .MuiTablePagination-root': {
    borderBottom: 'none',
  },
  '&': {
    borderCollapse: 'collapse',
    borderRadius: 4,
  },
  '& .MuiTablePagination-root .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 'normal',
  },
}));

export default TableWrapper;
