import { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { useTextStyles } from 'src/hooks/useTextStyles';
import { PodcastExceptionTypes, PodcastsFilterType } from 'src/types/filter';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import { MultiSelectList } from './SelectList';
import { ReactComponent as RemoveIcon } from 'src/assets/icons/trash.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,

      '& > div': {
        padding: 16,
        background: '#FFF',
        borderRadius: 8,
      },
    },
    mainContent: {
      margin: '8px 0 8px 4px',
      padding: '8px 0 8px 16px',
      borderLeft: '1px solid #E1E1E3',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,

      '& > div': {
        borderRadius: 8,
        background: '#F5F5F5',
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      },
    },
    exceptionHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      marginBottom: 4,
    },
    exceptionContainer: {
      display: 'flex',
      padding: '8px 16px',
      minHeight: 36,
      borderRadius: 6,
      border: '2px solid #EEE',
      background: '#FFF',
      position: 'relative',

      '& .remove': {
        position: 'absolute',
        width: 22,
        height: 22,
        borderRadius: '100%',
        border: '1px solid #C3C3C7',
        background: '#FFF',
        padding: 4,
        right: 16,
        top: '50%',
        transform: 'translate(0 , -50%)',
        display: 'flex',
        cursor: 'pointer',
      },
    },
  })
);

interface ExceptionTypeSelectionProps {
  filter: PodcastsFilterType;
  selections: string[];
  updateFilter: (filter: PodcastsFilterType) => void;
}

const ExceptionTypeSelection = ({
  filter,
  selections,
  updateFilter,
}: ExceptionTypeSelectionProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const handleRemove = (index: number) => {
    const tempFilter = { ...filter };
    tempFilter.exceptions.splice(index, 1);
    updateFilter(tempFilter);
  };

  const handleSelect = (selected: string[]) => {
    const tempFilter = { ...filter };
    tempFilter.exceptions = selected;
    updateFilter(tempFilter);
  };

  return (
    <>
      <div className={classes.exceptionHeader}>
        <Typography className={textClasses.xsRegular}>
          {filter.type} Exception
        </Typography>
      </div>
      {!!filter.exceptions.length &&
        filter.exceptions.map((exception, index) => (
          <div key={index} className={classes.exceptionContainer}>
            <Typography
              className={textClasses.xsRegular}
              style={{ width: 16, color: '#616161' }}
            >
              {index + 1}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <Typography
                className={textClasses.xsBold}
                style={{ marginLeft: 8, minWidth: 140 }}
              >
                {exception}
              </Typography>
            </div>
            <div className='remove' onClick={() => handleRemove(index)}>
              <RemoveIcon />
            </div>
          </div>
        ))}
      <div className={classes.exceptionContainer}>
        <MultiSelectList
          selections={selections}
          selected={filter.exceptions}
          title='podcasts'
          handleSave={handleSelect}
        />
      </div>
    </>
  );
};

interface PodcastsFiltersProps {
  filters: PodcastsFilterType[];
  setFilters: (filters: PodcastsFilterType[]) => void;
}

export const PodcastsFilters = ({
  filters,
  setFilters,
}: PodcastsFiltersProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const { garmSources } = useDashboardFilters();

  const handleUpdateFilter = (index: number, newFilter: PodcastsFilterType) => {
    const tempFilters = [...filters];
    tempFilters[index] = newFilter;
    setFilters(tempFilters);
  };

  return (
    <div className={classes.container}>
      <div>
        <Typography className={textClasses.smBold}>Podcasts</Typography>
        <div className={classes.mainContent}>
          {filters.map((filter, index) => (
            <div key={filter.type}>
              <ExceptionTypeSelection
                filter={filter}
                selections={garmSources}
                updateFilter={(newFilter: PodcastsFilterType) =>
                  handleUpdateFilter(index, newFilter)
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
