import { useEffect } from 'react';

interface useClickAwayProps {
  ref: any;
  handleClickAway: () => void;
}

export const useClickAway = ({ ref, handleClickAway }: useClickAwayProps) => {
  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickAway();
      }
    }

    function handleKey(event: any) {
      if (ref.current && event.key === 'Escape') {
        handleClickAway();
      }
    }

    document.addEventListener('mouseup', handleOutsideClick);
    document.addEventListener('keyup', handleKey);

    return function () {
      document.removeEventListener('mouseup', handleOutsideClick);
      document.removeEventListener('keyup', handleKey);
    };
  }, [ref, handleClickAway]);
};
