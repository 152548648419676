import { useState, useCallback, useEffect } from 'react';
import { Modal, Backdrop, Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'timeago.js';
import { useHistory } from 'react-router-dom';

import { getSearchHistory } from 'src/apis/search';
import { SearchHistoryProps } from 'src/types/search';
import { useTextStyles } from 'src/hooks/useTextStyles';
import { ROUTES } from 'src/utils';
import { ReactComponent as SearchIcon } from 'src/assets/icons/searchFilled.svg';

interface SearchModalProps {
  open: boolean;
  closeModal: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      borderBottom: '1px solid #EAECF0',
      background: '#FFF',
      padding: 16,
      '& > svg': {
        width: 16,
        height: 16,
      },

      '& > input': {
        height: 20,
        border: 'none',
        padding: 0,
        margin: 0,
        flex: 1,
        outline: 'none',
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    recentContent: {
      padding: '16px 18px',
      height: 260,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    historyItem: {
      padding: '8px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    loading: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100,
    },
  })
);

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 640,
  bgcolor: 'background.paper',
  background: '#fff',
  borderRadius: 8,
  boxShadow:
    '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
  padding: 0,
  overflow: 'hidden',
};

export const SearchModal = ({ open, closeModal }: SearchModalProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const history = useHistory();

  const [keyword, setKeyword] = useState<string>('');
  const [recentLoading, setRecentLoading] = useState<boolean>(false);
  const [histories, setHistories] = useState<SearchHistoryProps[]>([]);

  const fetchHistories = useCallback(async () => {
    try {
      setRecentLoading(true);
      const { data } = await getSearchHistory();
      setRecentLoading(false);
      setHistories(data);
    } catch {
      setRecentLoading(false);
      setHistories([]);
    }
  }, []);

  useEffect(() => {
    if (open) {
      setKeyword('');
      setHistories([]);
      fetchHistories();
    } else {
      setHistories([]);
    }
  }, [open, fetchHistories]);

  const redirectSearchPage = () => {
    closeModal();
    history.push(ROUTES.SEARCH + '?keyword=' + keyword);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby='self-scoring-modal-title'
      aria-describedby='self-scoring-modal-description'
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 800,
      }}
    >
      <Box sx={{ ...modalStyle }}>
        <Box className={classes.header}>
          <SearchIcon fill='none' stroke='#667085' />
          <input
            placeholder='Search by Episode name, GARM term, IAB category, or Host name'
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && keyword) {
                redirectSearchPage();
              }
            }}
          />
        </Box>
        <Box className={classes.recentContent}>
          {recentLoading && (
            <Box className={classes.loading}>
              <CircularProgress />
            </Box>
          )}
          <Typography
            className={textClasses.smRegular}
            style={{ color: '#98A2B3' }}
          >
            Recent
          </Typography>
          {histories.map((item, index) => (
            <Box
              key={index}
              className={classes.historyItem}
              onClick={() => {
                closeModal();
                history.push(ROUTES.SEARCH + '?keyword=' + item.query);
              }}
            >
              <Typography
                className={textClasses.smMedium}
                style={{ color: '#101828' }}
              >
                {item.query}
              </Typography>
              <Typography
                className={textClasses.smRegular}
                style={{ color: '#98A2B3' }}
              >
                {format(new Date(item.date || ''))}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
