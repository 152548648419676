import { CreateReport } from 'src/types/report';
import { baseAPICreator } from './apiCreator';

const reportApi = baseAPICreator('/report');

export const getAllReports = async () => {
  const { data } = await reportApi.get('/all');
  return data;
};

export const createReport = async (reportData: CreateReport) => {
  const { data } = await reportApi.post('', reportData);
  return data;
};

export const deleteReport = async (id: string) => {
  const { data } = await reportApi.delete(`/${id}`);
  return data;
};
