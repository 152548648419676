import { FC, useState, MouseEventHandler, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

import { useScheduleDemoForm } from 'src/hooks/useScheduleDemoForm';
import Button from 'src/components/Button';
import { useCommonStyles } from '../onboarding-redesign/utils/useCommonStyles';
import { ROUTES } from 'src/utils';

import { ReactComponent as LogoIcon } from 'src/assets/landing/logo.svg';
import backgroundImg from 'src/assets/auth/background.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      minHeight: '100%',
      background: '#EFEDEB',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,

      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
      },
    },
    leftImg: {
      display: 'none',
      margin: 16,
      width: 422,
      height: 'calc(100vh - 32px)',
      minWidth: 300,
      background: '#E8E5E2',
      borderRadius: 2,
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        display: 'flex',
        alignItems: 'flex-end',

        '& > img': {
          width: '100%',
        },
      },
    },
    mainContent: {
      padding: 16,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto',
      position: 'relative',

      '& > div': {
        width: '100%',
        maxWidth: 574,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    backBtn: {
      position: 'absolute',
      width: 'auto',
      height: 'auto',
      fontWeight: 400,
      top: 45,
      left: 16,
      padding: 0,
      justifyContent: 'flex-start',
      color: '#545454',

      '@media screen and (min-width: 756px)': {
        top: 38,
      },

      '@media screen and (min-width: 1040px)': {
        left: 'calc(50% - 287px)',
      },

      '& .MuiInput-underline:before': {
        top: 0,
      },
    },
    loginBtn: {
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      borderRadius: 2,
      padding: '6px 16px',
      height: 40,
      marginTop: 52,

      '&:hover': {
        background:
          'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      },

      '& > .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 45,
        fontSize: 16,
        lineHeight: '24px',
        color: '#EFEDEB',
        fontWeight: 500,
      },
    },
    logoIcon: {
      position: 'absolute',
      left: 32,
      top: 40,
    },
    mobileMenu: {
      width: '100%',
      background:
        'linear-gradient(180deg, rgba(219, 213, 195, 0.54) 0%, rgba(219, 213, 195, 0) 100%)',

      '@media screen and (min-width: 768px)': {
        display: 'none',
      },

      '& > div': {
        width: '100%',
        maxWidth: 1600,
        height: 80,
        margin: 'auto',
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 8,
        zIndex: 100,
        position: 'relative',
      },
    },
  })
);

interface AuthLayoutProps {
  secondaryBack?: boolean;
}

const AuthLayout: FC<PropsWithChildren<AuthLayoutProps>> = ({
  children,
  secondaryBack = false,
}) => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { launchScheduleDemo } = useScheduleDemoForm({
    automaticLaunch: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleBack = () => {
    if (secondaryBack) {
      history.goBack();
    } else {
      history.push(ROUTES.LANDING);
    }
  };

  const handleSpotlightRedirectToDemo = () => {
    if (window.location.host === 'www.thebarometer.co') {
      window.location.assign('https://app.thebarometer.co/spotlight');
    } else {
      handleMenuClose();
      history.push(ROUTES.SPOTLIGHT);
    }
  };

  const handleRedirectToLogin = () => {
    if (window.location.host === 'www.thebarometer.co') {
      window.location.assign('https://app.thebarometer.co/login');
    } else {
      handleMenuClose();
      history.push(ROUTES.LOGIN);
    }
  };

  const handleRedirectToTeams = () => {
    handleMenuClose();
    history.push(ROUTES.TEAM);
  };

  const handleClickScheduleDemo: MouseEventHandler = (event) => {
    event.preventDefault();
    handleMenuClose();
    launchScheduleDemo();
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftImg}>
        <LogoIcon className={classes.logoIcon} />
        <img src={backgroundImg} alt='auth background' />
      </div>
      <div className={classes.mobileMenu}>
        <div>
          <LogoIcon />
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            variant='text'
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {/** temporarily hide the Spotlight page */}
            {/* <MenuItem onClick={handleSpotlightRedirectToDemo}>
              Spotlight
            </MenuItem> */}
            <MenuItem onClick={handleRedirectToTeams}>Our Mission</MenuItem>
            <MenuItem onClick={handleRedirectToLogin}>Login</MenuItem>
            <MenuItem onClick={handleClickScheduleDemo}>
              Let’s connect →
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className={classes.mainContent}>
        <Button
          variant='text'
          className={classNames(classes.backBtn, commonStyles.callout2)}
          onClick={handleBack}
        >
          {secondaryBack ? '← Back' : '← Back to content'}
        </Button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
