import clsx from 'clsx';

import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

import { Theme } from 'src/theme/types/createPalette';
import { InputChangeEvent } from 'src/types';

interface CheckboxProps {
  name?: string;
  label: string;
  checked: boolean;
  noPadding?: boolean;
  onChange?: (checked: boolean, name?: string) => void;
  className?: string;
  [key: string]: any;
  disabled?: boolean;
}

export default function Checkbox({
  name,
  label,
  checked,
  className,
  noPadding,
  onChange,
  disabled = false,
}: CheckboxProps) {
  const classes = useStyles();

  const handleChange = (event: InputChangeEvent) => {
    onChange?.(event.target.checked, name);
  };

  return (
    <FormControlLabel
      className={clsx(classes.root, noPadding && classes.noPadding, className)}
      control={
        <MuiCheckbox
          name={name}
          checked={checked}
          onChange={handleChange}
          color='primary'
          disabled={disabled}
        />
      }
      label={label}
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.grayText.main,
      marginRight: 0,
    },
    noPadding: {
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
  })
);
