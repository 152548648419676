import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import keyBy from 'lodash/keyBy';
import SearchIcon from '@material-ui/icons/Search';

import MenuRow from 'src/components/dashboard/MenuRow';
import useAuth from 'src/hooks/useAuth';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import { DashboardBase, DashboardType } from 'src/types';
import useEnvironment from 'src/hooks/useEnvironment';

import GarmDashboard from './garm/GarmDashboard';
import { useCreateDashboard, useUpdateDashboard } from './hooks';
import { ColumnsSetting } from './ColumnSetting';
import { useDashboardData } from './garm/hooks';
import { FilterSection } from './TopBar/FilterSection';
import MenuSelection from 'src/components/dashboard/MenuRow/menu-selection/MenuSelection';
import { ReactComponent as RefreshIcon } from 'src/assets/icons/refresh.svg';
import InfoTooltip from 'src/components/InfoTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '12px',
      paddingTop: '16px',
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexGrow: 1,
      height: '100%',
      flexWrap: 'nowrap',
    },
    filtersRow: {
      marginBottom: 16,
      marginTop: 12,
      gap: 20,

      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    searchBar: {
      width: 320,
      border: '1px solid #C3C3C7',
      padding: '2px 8px',
      borderRadius: 36,

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
  })
);

export default function Dashboard() {
  const { env } = useParams<{ env?: string }>();
  const classes = useStyles();
  const { organization, user } = useAuth();
  const {
    dashboardId,
    dashboardName,
    riskFilters,
    garmSourceFilters,
    selectedPublishers,
    selectedIABCategories,
    selectedLanguages,
    selectedGenres,
    excludedGenres,
    garmDashboardColumns,
    selectedViewId,
    selectDashboard,
    selectedCustomFilter,
    selectedSelfScoring,
    electionRange,
  } = useDashboardFilters();
  const isViewer = user?.role === 'view';

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { onCreateDashboard } = useCreateDashboard();
  const { setDashboardEnv } = useEnvironment();
  const { onUpdateDashboard } = useUpdateDashboard();
  const {
    currentShow,
    showEpisodes,
    loading,
    page,
    pageGarmShows,
    rowsPerPage,
    onChangeShow,
    onChangePage,
    onChangeRowsPerPage,
    onRequestSort,
    totalShowsCount,
    order,
    orderBy,
    searchText,
    onChangeSearchText,
  } = useDashboardData();

  useEffect(() => {
    setDashboardEnv(env || 'GARM');
  }, [env]);

  if (!organization) {
    return null;
  }

  const getGarmFiltersData = (): DashboardBase => {
    const garmFilters = keyBy(
      Object.values(riskFilters).map((riskFilter) => ({
        dashboardFilterKey: riskFilter.dashboardFilterKey,
        enabled: riskFilter.enabled,
        value: riskFilter.value,
      })),
      'dashboardFilterKey'
    );

    return {
      name: dashboardName,
      type: DashboardType.GARM,
      keywordsList: garmSourceFilters,
      garmFilters: {
        ...garmFilters,
        publishersList: selectedPublishers,
        iabCategories: selectedIABCategories,
        languages: selectedLanguages,
        genresList: selectedGenres,
        excludedGenres,
        selectedViewId,
        electionRange,
      },
      exceptionFilterId: selectedCustomFilter?.id || null,
    };
  };

  const getNewGarmFiltersData = (): DashboardBase => {
    const garmFilters = keyBy(
      Object.values(riskFilters).map((riskFilter) => ({
        dashboardFilterKey: riskFilter.dashboardFilterKey,
        enabled: riskFilter.dashboardFilterKey === 'audience' ? false : true,
        value: riskFilter.dashboardFilterKey === 'audience' ? [0, 5] : [0, 3],
      })),
      'dashboardFilterKey'
    );

    return {
      name: dashboardName,
      type: DashboardType.GARM,
      keywordsList: [],
      garmFilters: {
        ...garmFilters,
        publishersList: [],
        iabCategories: [],
        genresList: [],
      },
      exceptionFilterId: selectedCustomFilter?.id || null,
    };
  };

  const handleCreate = async () => {
    const garmFiltersData = getGarmFiltersData();
    await onCreateDashboard(garmFiltersData);
  };

  const handleUpdate = async () => {
    if (!dashboardId) {
      return;
    }

    const garmFiltersData = getGarmFiltersData();
    await onUpdateDashboard(dashboardId, garmFiltersData);
  };

  const handleNew = async () => {
    const garmFiltersData = getNewGarmFiltersData();

    const dashboard = await onCreateDashboard({
      ...garmFiltersData,
      name: 'Untitled',
    });

    if (dashboard) {
      selectDashboard(dashboard);
    }
  };

  const handleOpenColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColumns = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item container>
        <MenuRow
          totalShowsCount={totalShowsCount}
          orderBy={orderBy}
          searchText={searchText}
          order={order}
          dashboardId={dashboardId}
          dashboardName={dashboardName}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
          onNew={handleNew}
        />
      </Grid>
      <Grid
        item
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.filtersRow}
      >
        <Grid item>
          <MenuSelection />
          {selectedSelfScoring?.isRunning && (
            <InfoTooltip
              title={`Processing Scoring Request: ${selectedSelfScoring.progress}%`}
            >
              <RefreshIcon width={24} height={24} style={{ marginLeft: 20 }} />
            </InfoTooltip>
          )}
        </Grid>
        <Grid item>
          <TextField
            InputLabelProps={{
              shrink: true,
              style: { fontWeight: 'normal' },
            }}
            InputProps={{
              placeholder: 'Search list...',
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon htmlColor='#858591' style={{ fontSize: 20 }} />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={onChangeSearchText}
            className={classes.searchBar}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        className={classes.filtersRow}
      >
        <Grid item style={{ flex: 1 }}>
          <FilterSection isViewer={isViewer} />
        </Grid>
        <ColumnsSetting
          anchorEl={anchorEl}
          onOpen={handleOpenColumns}
          onClose={handleCloseColumns}
        />
      </Grid>
      <GarmDashboard
        columns={['Name'].concat(garmDashboardColumns)}
        currentShow={currentShow}
        totalShowsCount={totalShowsCount}
        showEpisodes={showEpisodes}
        loading={loading}
        order={order}
        orderBy={orderBy}
        page={page}
        pageGarmShows={pageGarmShows}
        rowsPerPage={rowsPerPage}
        onChangeShow={onChangeShow}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRequestSort={onRequestSort}
      />
    </Grid>
  );
}
