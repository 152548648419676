export type Maybe<T> = T | undefined | null;

export enum QueryStatus {
  ERROR = 'error',
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
}

export type queryStatus = 'error' | 'idle' | 'loading' | 'success';

export type TTimeRange = {
  from: Date;
  to: Date;
};

export type TWeighedWord = {
  name: string;
  weight: number;
};

export type TPopularWord = {
  world_name: string;
  popular_count: number;
};

export type TWordCloud = Record<string, TWeighedWord>;

export type TBarometerTeamDetail = {
  name: string;
  pic: string;
  statement: string;
  role: string;
  bio: string;
};

export enum ViewMode {
  NORMAL = 'NORMAL',
  OVER_TIME = 'OVER_TIME',
}

export type GridLayoutSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
