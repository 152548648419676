import { FC, useState, useEffect } from 'react';
import _get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { TAcceptInvitationBody, Member } from 'src/types';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import { Theme } from 'src/theme/types/createPalette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    passwordForm: {
      padding: theme.spacing(4),
      border: `1px solid ${theme.palette.gray.main}`,
      borderRadius: theme.spacing(3),
    },
  }),
);

const inputFields = [
  { name: 'firstName', label: 'What is your first name?', required: true },
  { name: 'lastName', label: 'What is your last name?', required: true },
  {
    name: 'password',
    label: 'What is your password?',
    type: 'password',
    required: true,
  },
  {
    name: 'currentJob',
    label: 'Briefly describe your job:',
  },
  {
    name: 'joinReason',
    label: 'Why do you want to use Barometer?',
  },
];

interface InvitationAcceptFormProps {
  current: Member;
  onSubmit: (form: TAcceptInvitationBody) => void;
}

const InvitationAcceptForm: FC<InvitationAcceptFormProps> = ({
  current,
  onSubmit,
}) => {
  const classes = useStyles();
  const [form, setForm] = useState<TAcceptInvitationBody>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    invitationCode: '',
    currentJob: '',
    joinReason: '',
  });

  useEffect(() => {
    setForm((form) => ({
      ...form,
      email: current.email,
      firstName: current.firstName,
      lastName: current.lastName,
      invitationCode: current.invitationCode || '',
    }));
  }, [current]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(form);
  };

  const handleChange = (value: string, name?: string) => {
    if (!name) return;
    setForm({ ...form, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit} className={classes.passwordForm}>
      <Grid container spacing={1}>
        {inputFields.map((inputField) => (
          <Grid key={inputField.name} item xs={12}>
            <TextInput
              name={inputField.name}
              label={inputField.label}
              required={inputField.required}
              type={inputField.type}
              value={_get(form, inputField.name)}
              fullWidth={true}
              onChange={handleChange}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            fullWidth
            color='primary'
            title='Start using Barometer'
            type='Submit'
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default InvitationAcceptForm;
