import { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';

import heroBackground from 'src/assets/spotlights/waves.png';
import heroBackgroundMobile from 'src/assets/spotlights/wavesMobile.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      position: 'relative',
      background: '#EFEDEB',
      marginBottom: 85,
    },
    mainContainer: {
      height: '100%',
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      columnGap: 120,
      zIndex: 1,
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        padding: '0 100px',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    heroBG: {
      position: 'absolute',
      left: '50%',
      top: -110,
      width: '100%',
      transform: 'translate(-50%, 0)',

      '@media screen and (min-width: 768px)': {
        top: -180,
      },
    },
    mWeb: {
      display: 'block',

      '@media screen and (min-width: 768px)': {
        display: 'none',
      },
    },
    dWeb: {
      display: 'none',

      '@media screen and (min-width: 768px)': {
        display: 'block',
      },
    },
    title: {
      fontWeight: 300,
      fontSize: 38,
      lineHeight: '48px',
      color: '#202226',
      textTransform: 'uppercase',
      marginBottom: 50,
      marginTop: 40,

      '@media screen and (min-width: 768px)': {
        fontSize: 76,
        lineHeight: '96px',
      },
    },
    description: {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px',
      color: '#545454',
      marginBottom: 40,

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '40px',
        marginBottom: 55,
      },
    },
    mainImg: {
      maxWidth: 450,
      width: '100%',
    },
  })
);

const MissionIntroduction: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img
        src={heroBackground}
        alt='heroBG desktop'
        className={classNames(classes.heroBG, classes.dWeb)}
      />
      <img
        src={heroBackgroundMobile}
        alt='heroBG desktop'
        className={classNames(classes.heroBG, classes.mWeb)}
      />
      <div className={classes.mainContainer}>
        <Typography className={classes.title}>
          Our mission-
          <br />
          driven team
        </Typography>
      </div>
      <div className={classes.mainContainer}>
        <Box className={classes.mainImg}></Box>
        <Box flex={1}>
          <Typography className={classes.description}>
            Our mission is to operationalize and expand upon the components and
            risk levels defined in the <b>GARM↗ Brand Safety & Suitability</b>{' '}
            framework and apply them consistently using AI to provide
            transparent content ratings for premium digital content.
            <br />
            <br />
            We know that the status quo of using keyword based solutions
            designed for user generated content environments creates worse
            outcomes for both advertisers and publishers. Our patent pending
            AI-Powered Brand Integrity Cloud is designed to bring the context
            back into the picture.
            <br />
            <br />
            We are an independent third party charged with providing data to
            support advertisers and networks in being informed about the content
            with which they engage, and to simplify the process of aligning
            brands&apos; ad spend to their values.
            <br />
            <br />
            Barometer gives advertisers and brands a path to avoid future
            controversies by association through intelligent digital content
            pre-screens. Instead, brands can use the Barometer to easily align
            their ad buys to their values.
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default MissionIntroduction;
