import { useState, useRef, useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import useDashboardFilters from 'src/hooks/useDashboardFilters';
import Checkbox from 'src/components/Checkbox';
import Button from 'src/components/Button';
import { useClickAway } from 'src/hooks/useClickAway';
import { useStyles } from './AppBar.styles';

interface IABFilterProps {
  isOpen: boolean;
  isSmallSidebar?: boolean;
  handleClose: () => void;
}

export const IABFilter = ({
  isOpen,
  isSmallSidebar = false,
  handleClose,
}: IABFilterProps) => {
  const classes = useStyles();
  const ref = useRef<any>();
  const [data, setData] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const { iabCategories, selectedIABCategories, selectIABCategories } =
    useDashboardFilters();

  useClickAway({ ref, handleClickAway: handleClose });

  const formatTreeData = useCallback(
    (tree: any, isClear?: boolean): any[] => {
      const temp: any[] = [];
      tree
        .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
        .forEach((item: any) => {
          const children =
            item.children && item.children.length
              ? formatTreeData(item.children, isClear)
              : [];
          temp.push({
            ...item,
            isChecked: isClear
              ? false
              : !!selectedIABCategories.find(
                  (category) => category.id === item.id
                ),
            isExpanded: false,
            isShow: true,
            children,
          });
        });
      return temp;
    },
    [selectedIABCategories]
  );

  const filterTreeData = useCallback(
    (tree: any, key: string, isParentShow = false): any[] => {
      const temp: any[] = [];
      tree.forEach((item: any) => {
        const isShow = item.name.toLowerCase().includes(key.toLowerCase());
        const children =
          item.children && item.children.length
            ? filterTreeData(item.children, key, isShow || isParentShow)
            : [];

        temp.push({
          ...item,
          isShow:
            key === '' ||
            isShow ||
            isParentShow ||
            children.find((child) => child.isShow)
              ? true
              : false,
          children,
        });
      });
      return temp;
    },
    [selectedIABCategories]
  );

  const countShowData = useCallback(
    (tree: any): number => {
      let counter = 0;
      tree.forEach((item: any) => {
        const childCounter =
          item.children && item.children.length
            ? countShowData(item.children)
            : 0;
        const currentCounter = childCounter
          ? childCounter
          : item.isShow
          ? 1
          : 0;
        counter = counter + currentCounter;
      });
      return counter;
    },
    [selectedIABCategories]
  );

  useEffect(() => {
    setData(filterTreeData(data, searchKey || ''));
  }, [searchKey]);

  const saveSelection = useCallback((tree: any[]) => {
    let temp: any[] = [];
    tree.forEach((item: any) => {
      if (item.isChecked) {
        temp.push(item);
      }
      if (item.children && item.children.length) {
        const children = saveSelection(item.children);
        temp = [...temp, ...children];
      }
    });
    return temp;
  }, []);

  useEffect(() => {
    if (isOpen) {
      setSearchKey('');
      setData(formatTreeData(iabCategories));
    }
  }, [isOpen, iabCategories]);

  const updateTreeData = useCallback(
    (
      tree: any[],
      id: string,
      property: string,
      value: string | boolean
    ): any[] => {
      const temp: any[] = [...tree];
      tree.forEach((node, index) => {
        if (node.id === id) {
          temp[index][property] = value;
          if (
            property === 'isChecked' &&
            node.children &&
            node.children.length
          ) {
            const children = node.children.map((child: any) => {
              updateTreeData(node.children, child.id, property, value);
              return {
                ...child,
                isChecked: value,
              };
            });
            temp[index].children = children;
          }
        } else {
          if (node.children && node.children.length) {
            const children = updateTreeData(node.children, id, property, value);
            let isChecked = node.isChecked;
            if (property === 'isChecked') {
              isChecked = children.every((child) => child.isChecked);
            }
            temp[index] = {
              ...node,
              children,
              isChecked,
            };
          }
        }
      });
      return temp;
    },
    []
  );

  const showCategories = (categories: any[]) => {
    return categories.map((item: any) => {
      const hasChildren = !!item.children?.length;
      if (!item.isShow) {
        return null;
      }

      return (
        <div key={item.id}>
          <div className={classes.selectOption}>
            {hasChildren ? (
              item.isExpanded ? (
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setData(updateTreeData(data, item.id, 'isExpanded', false));
                  }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  onClick={() => {
                    setData(updateTreeData(data, item.id, 'isExpanded', true));
                  }}
                />
              )
            ) : (
              <div style={{ width: 16 }} />
            )}
            <Checkbox
              name={item.id}
              label=''
              checked={item.isChecked}
              noPadding={true}
              onChange={() => {
                if (item.isChecked) {
                  setData(updateTreeData(data, item.id, 'isChecked', false));
                } else {
                  setData(updateTreeData(data, item.id, 'isChecked', true));
                }
              }}
            />
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 'normal',
              }}
            >
              {item.name} {hasChildren ? <>({item.children?.length})</> : null}
            </Typography>
          </div>
          <div className={classes.selectChildren}>
            {hasChildren && item.isExpanded && showCategories(item.children)}
          </div>
        </div>
      );
    });
  };

  const searchResultCounter = countShowData(data);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={classes.selectionContainer}
      style={{ left: isSmallSidebar ? 372 : 552 }}
      ref={ref}
    >
      <div className={classes.selectionMain}>
        <div className={classes.selectionHeader}>
          <Typography
            style={{
              fontSize: 16,
              color: 'gray',
              fontWeight: 'normal',
              display: 'flex',
              alignItems: 'center',
              gap: 6,
            }}
          >
            <span>Find podcasts by their</span>
            <img
              src={'/images/iab/iabLogo.png'}
              height='26px'
              alt='iab_memberseal'
            />
            <span>categorizations</span>
          </Typography>
          <div className={classes.searchContainer}>
            <SearchIcon />
            <input
              placeholder='Search IAB categories...'
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'normal',
            }}
          >
            {!searchKey
              ? `Showing ${searchResultCounter} results`
              : `Showing ${searchResultCounter} results for ${searchKey}`}
          </Typography>
        </div>
        <div className={classes.selectionOptions}>{showCategories(data)}</div>
      </div>
      <div className={classes.selctionActions}>
        <Button
          title='Clear Selection'
          color='primary'
          variant='text'
          fontSize='sm'
          height={30}
          onClick={() => {
            setData(formatTreeData(iabCategories, true));
            setSearchKey('');
          }}
        />
        <Button
          title='Select Category'
          color='primary'
          fontSize='sm'
          height={30}
          onClick={() => {
            selectIABCategories(saveSelection(data));
            handleClose();
            setSearchKey('');
            setData([]);
          }}
        />
      </div>
    </div>
  );
};
