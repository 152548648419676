import { useQuery } from 'react-query';

import queryKeys from './queryKeys';
import { baseAPICreator } from './apiCreator';
import {
  DocumentBase,
  DocumentDto,
  DocumentPageDto,
  QueryStatus,
} from 'src/types';

const documentApi = baseAPICreator('/document');

export const getPDFDocuments = async (apiKey: string) => {
  const { data } = await documentApi.get(`all?secretKey=${apiKey}`);
  return data;
};

export const getDocumentPages = async (documentId: string, apiKey: string) => {
  const { data } = await documentApi.get(
    `${documentId}/pages?secretKey=${apiKey}`
  );
  return data;
};

export const saveDocument = async (document: DocumentBase, apiKey: string) => {
  const { data } = await documentApi.post(`?secretKey=${apiKey}`, document);
  return data;
};

export const useGetPDFDocuments = (apiKey?: string) => {
  const { status, data, error, refetch } = useQuery(
    [queryKeys.GET_PDF_DOCUMENTS, apiKey],
    async () => {
      if (!apiKey) return [];
      const response = await getPDFDocuments(apiKey);
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    documents: (data || []) as DocumentDto[],
    error,
    refetch,
  };
};

export const useGetDocumentPages = (documentId: string, apiKey?: string) => {
  const { status, data, error, refetch } = useQuery(
    [queryKeys.GET_DOCUMENT_PAGES, apiKey, documentId],
    async () => {
      if (!apiKey) return [];
      const response = await getDocumentPages(documentId, apiKey);
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    documentPages: (data || []) as DocumentPageDto[],
    error,
    refetch,
  };
};
