import { useState, MouseEventHandler } from 'react';
import { Box, Typography, Menu, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useTextStyles } from 'src/hooks/useTextStyles';
import Button from 'src/components/NewButton';

import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrowUp.svg';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      '& .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
      },
    },
  })
);

interface OptionProps {
  label: string;
  value: string;
}

interface ButtonDropdownProps {
  id: string;
  selectedOption: OptionProps;
  options: OptionProps[];
  handleSelect: (option: OptionProps) => void;
}

const ButtonDropdown = ({
  id,
  selectedOption,
  options,
  handleSelect,
}: ButtonDropdownProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const open = Boolean(anchorEl);

  const handleOpen: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        color='secondary'
        disableElevation
        variant='outlined'
        size='small'
        height={34}
        onClick={handleOpen}
        className={classes.button}
      >
        <Typography
          className={textClasses.xsBold}
          style={{
            color: '#344054',
          }}
        >
          {selectedOption.label}
        </Typography>
        <ArrowUpIcon
          stroke='#344054'
          cursor='pointer'
          style={open ? {} : { transform: 'rotate(180deg)' }}
        />
      </Button>
      <Menu
        id={id}
        aria-labelledby={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => {
              handleClose();
              handleSelect(option);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
export default ButtonDropdown;
