import { FC, useMemo, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Spinner from 'src/components/Spinner';
import { Member } from 'src/types';
import Table from 'src/components/Table';
import useAuth from 'src/hooks/useAuth';
import { updateMemeber } from 'src/apis';

interface TeamMembersProps {
  members: Member[];
  onRefetch: VoidFunction;
}

const Permissions: any = {
  view: 'View Only',
  staff: 'Edit',
  admin: 'Admin',
  super: 'Super Admin',
};

const TeamMembers: FC<TeamMembersProps> = ({ members, onRefetch }) => {
  const [loading, setLoading] = useState<string>('');
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin' || user?.role === 'super';

  const headers = [
    { field: 'name', label: 'Name' },
    { field: 'email', label: 'Email Address' },
    { field: 'role', label: 'Permissions' },
  ];

  const membersData = useMemo(() => {
    return members.map((member) => ({
      ...member,
      name: [member.firstName, member.lastName].join(' '),
      role:
        isAdmin && member.role !== 'super' ? (
          <Select
            id='permission-select'
            value={member.role}
            onChange={async (event) => {
              setLoading(member.id);
              await updateMemeber(member.id, {
                email: member.email,
                firstName: member.firstName,
                lastName: member.lastName,
                password: member.password,
                organizationId: member.organizationId,
                enabled: true,
                role: event.target.value,
              });
              await onRefetch();
              setLoading('');
            }}
            style={{
              minWidth: 150,
            }}
            disabled={member.id === loading}
          >
            <MenuItem value='view'>View Only</MenuItem>
            <MenuItem value='staff'>Edit</MenuItem>
            <MenuItem value='admin'>Admin</MenuItem>
          </Select>
        ) : (
          member.role && <Typography>{Permissions[member.role]}</Typography>
        ),
    }));
  }, [members]);

  return <Table headers={headers} data={membersData} />;
};

export default TeamMembers;
