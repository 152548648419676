const OverTimeIcon = ({
  fill = '#000000',
  height = 24,
  width = 24,
}: {
  width?: number;
  height?: number;
  fill?: string;
}): React.ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.33325 20.1556L8.99992 13.4889L13.3333 17.8222L20.6666 6.66667M3.33325 8.00001L6.33325 10.3333L9.51103 6.66667L20.6666 17.8222'
      stroke={fill}
      strokeOpacity='0.54'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default OverTimeIcon;
