import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useLayoutStyles } from 'src/components/useCommonStyles';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(6),
    },
    h5: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 20,
    },
  })
);

const BarometerCookiePolicy = () => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={10} md={8}>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant='h5' className={classes.h5}>
              Cookie Policy for Barometer
            </Typography>
          </Grid>
          <Grid item xs={12} className={layoutClasses.mb2}>
            <Typography variant='body1'>
              This is the Cookie Policy for Barometer, accessible from
              thebarometer.co
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.h6}>
              What Are Cookies
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              As is common practice with almost all professional websites this
              site uses cookies, which are tiny files that are downloaded to
              your computer, to improve your experience. This page describes
              what information they gather, how we use it and why we sometimes
              need to store these cookies. We will also share how you can
              prevent these cookies from being stored however this may downgrade
              or &apos;break&apos; certain elements of the site&apos;s
              functionality.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.h6}>
              How We Use Cookies
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              We use cookies for a variety of reasons detailed below.
              Unfortunately in most cases there are no industry standard options
              for disabling cookies without completely disabling the
              functionality and features they add to this site. It is
              recommended that you leave on all cookies if you are not sure
              whether you need them or not in case they are used to provide a
              service that you use.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.h6}>
              Disabling Cookies
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              You can prevent the setting of cookies by adjusting the settings
              on your browser (see your browser Help for how to do this). Be
              aware that disabling cookies will affect the functionality of this
              and many other websites that you visit. Disabling cookies will
              usually result in also disabling certain functionality and
              features of this site. Therefore it is recommended that you do not
              disable cookies.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.h6}>
              The Cookies We Set
            </Typography>
          </Grid>
          <ul>
            <li>
              <Typography variant='subtitle2'>
                Account related cookies
              </Typography>
              <Typography>
                If you create an account with us then we will use cookies for
                the management of the signup process and general administration.
                These cookies will usually be deleted when you log out however
                in some cases they may remain afterwards to remember your site
                preferences when logged out.
              </Typography>
            </li>
            <li>
              <Typography variant='subtitle2'>Login related cookies</Typography>
              <Typography>
                We use cookies when you are logged in so that we can remember
                this fact. This prevents you from having to log in every single
                time you visit a new page. These cookies are typically removed
                or cleared when you log out to ensure that you can only access
                restricted features and areas when logged in.
              </Typography>
            </li>
            <li>
              <Typography variant='subtitle2'>
                Email newsletters related cookies
              </Typography>
              <Typography>
                This site offers newsletter or email subscription services and
                cookies may be used to remember if you are already registered
                and whether to show certain notifications which might only be
                valid to subscribed/unsubscribed users.
              </Typography>
            </li>
            <li>
              <Typography variant='subtitle2'>
                Orders processing related cookies
              </Typography>
              <Typography>
                This site offers e-commerce or payment facilities and some
                cookies are essential to ensure that your order is remembered
                between pages so that we can process it properly.
              </Typography>
            </li>
            <li>
              <Typography variant='subtitle2'>
                Surveys related cookies
              </Typography>
              <Typography>
                From time to time we offer user surveys and questionnaires to
                provide you with interesting insights, helpful tools, or to
                understand our user base more accurately. These surveys may use
                cookies to remember who has already taken part in a survey or to
                provide you with accurate results after you change pages.
              </Typography>
            </li>
            <li>
              <Typography variant='subtitle2'>Forms related cookies</Typography>
              <Typography>
                When you submit data through a form such as those found on
                contact pages or comment forms cookies may be set to remember
                your user details for future correspondence.
              </Typography>
            </li>
            <li>
              <Typography variant='subtitle2'>
                Site preferences cookies
              </Typography>
              <Typography>
                In order to provide you with a great experience on this site we
                provide the functionality to set your preferences for how this
                site runs when you use it. In order to remember your preferences
                we need to set cookies so that this information can be called
                whenever you interact with a page that is affected by your
                preferences.
              </Typography>
            </li>
          </ul>
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.h6}>
              Third Party Cookies
            </Typography>
            <Typography>
              In some special cases we also use cookies provided by trusted
              third parties. The following section details which third party
              cookies you might encounter through this site.
            </Typography>
          </Grid>
          <ul>
            <li>
              <Typography>
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solutions on the web for
                helping us to understand how you use the site and ways that we
                can improve your experience. These cookies may track things such
                as how long you spend on the site and the pages that you visit
                so we can continue to produce engaging content. For more
                information on Google Analytics cookies, see the official Google
                Analytics page.
              </Typography>
            </li>
            <li>
              <Typography>
                Third party analytics are used to track and measure usage of
                this site so that we can continue to produce engaging content.
                These cookies may track things such as how long you spend on the
                site or pages you visit which helps us to understand how we can
                improve the site for you.
              </Typography>
            </li>
            <li>
              <Typography>
                From time to time we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimisations our users appreciate
                the most.
              </Typography>
            </li>
            <li>
              <Typography>
                As we sell products it&apos;s important for us to understand
                statistics about how many of the visitors to our site actually
                make a purchase and as such this is the kind of data that these
                cookies will track. This is important to you as it means that we
                can accurately make business predictions that allow us to
                monitor our advertising and product costs to ensure the best
                possible price.
              </Typography>
            </li>
          </ul>
          <Grid item xs={12}>
            <Typography variant='h6' className={classes.h6}>
              More Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Hopefully that has clarified things for you and as was previously
              mentioned if there is something that you aren&apos;t sure whether
              you need or not it&apos;s usually safer to leave cookies enabled
              in case it does interact with one of the features you use on our
              site. However if you are still looking for more information then
              you can contact us through one of our preferred contact methods:
            </Typography>
          </Grid>
          <ul>
            <li>
              <Typography>Email: support@thebarometer.co</Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BarometerCookiePolicy;
