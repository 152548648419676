import { MouseEventHandler } from 'react';

import { useScheduleDemoForm } from 'src/hooks/useScheduleDemoForm';
import BarometerIntroduction from './BarometerIntroduction';
import BarometerPartners from './BarometerPartners';
import UnauthorizedContainWrapper from '../UnauthorizedContainWrapper';
import BarometerAdvertisers from './BarometerAdvertisers';
import LeverageAI from './LeverageAI';
import Contact from './Contact';
import BarometerQuotes from './BarometerQuotes';

export default function LandingScreen() {
  const { launchScheduleDemo } = useScheduleDemoForm({ automaticLaunch: true });

  const handleClickScheduleDemo: MouseEventHandler = (event) => {
    event.preventDefault();
    launchScheduleDemo();
  };

  return (
    <UnauthorizedContainWrapper>
      <BarometerIntroduction onScheduleDemo={handleClickScheduleDemo} />
      <BarometerPartners />
      <BarometerAdvertisers />
      <LeverageAI />
      <Contact />
      <BarometerQuotes />
    </UnauthorizedContainWrapper>
  );
}
