import { baseAPICreator } from './apiCreator';
import {
  BrandCustomFilter,
  BrandCustomFilterCreateBody,
} from 'src/types/filter';
import { convertCategoryName } from 'src/utils/category';

const exceptionApi = baseAPICreator('/exception-filter');

export const getAllExceptionFilters = async (): Promise<
  BrandCustomFilter[]
> => {
  const { data } = await exceptionApi.get('all');
  return data.map((item: BrandCustomFilter) => {
    const updatedScoreFilters: any = {};
    for (const key in item.data.scoreFilters) {
      const newKey = convertCategoryName(key); // Use mapped key or original key if no mapping
      updatedScoreFilters[newKey] = item.data.scoreFilters[key];
    }
    return {
      ...item,
      data: {
        ...item.data,
        scoreFilters: updatedScoreFilters,
      },
    };
  });
};

export const getExceptionFilter = async (
  id: string
): Promise<BrandCustomFilter> => {
  const { data } = await exceptionApi.get(id);
  const updatedScoreFilters: any = {};
  for (const key in data.data.scoreFilters) {
    const newKey = convertCategoryName(key); // Use mapped key or original key if no mapping
    updatedScoreFilters[newKey] = data.data.scoreFilters[key];
  }
  return {
    ...data,
    data: {
      ...data.data,
      scoreFilters: updatedScoreFilters,
    },
  };
};

export const createExceptionFilter = async (
  body: BrandCustomFilterCreateBody
): Promise<BrandCustomFilter[]> => {
  const { data } = await exceptionApi.post('', body);
  return data;
};

export const updateExceptionFilter = async (
  id: string,
  body: BrandCustomFilterCreateBody
): Promise<BrandCustomFilter[]> => {
  const { data } = await exceptionApi.put(id, body);
  return data;
};

export const deleteExceptionFilter = async (id: string): Promise<void> => {
  await exceptionApi.delete(id);
};
