import React, { createContext, ReactNode, useContext, useState } from 'react';

import { TTimeRange } from 'src/types';

interface ContextType {
  dashboardEnv: string;
  setDashboardEnv: any; // (env: string) => void;
  timeRange: TTimeRange;
  setTimeRange: any;
}

const EnvironmentContext = createContext<ContextType>({
  dashboardEnv: 'GARM',
  setDashboardEnv: () => {},
  timeRange: {
    from: new Date(),
    to: new Date(),
  },
  setTimeRange: () => {},
});

interface Props {
  children: ReactNode;
}

export const EnvironmentProvider: React.FC<Props> = ({ children }) => {
  const [dashboardEnv, setDashboardEnv] = useState<string>('GARM');
  const [timeRange, setTimeRange] = useState<TTimeRange>({
    from: new Date('2000/1/1'),
    to: new Date(),
  });

  return (
    <EnvironmentContext.Provider
      value={{ dashboardEnv, setDashboardEnv, timeRange, setTimeRange }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => useContext(EnvironmentContext);
export default useEnvironment;
