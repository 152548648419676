import { useCallback, useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const DELAY = 3000;

interface IScheduleDemoFormProps {
  automaticLaunch: boolean;
}

export const useScheduleDemoForm = ({
  automaticLaunch,
}: IScheduleDemoFormProps) => {
  const [hasLaunchedDemo, setHasLaunchedDemo] = useLocalStorage(
    'hasLaunchedDemo',
    false,
  );

  const launchScheduleDemo = useCallback(() => {
    const registerFormLaunchButton = document.getElementById(
      'register-form-launch-button',
    );

    if (!registerFormLaunchButton) return;

    registerFormLaunchButton.click();
    setHasLaunchedDemo(true);
  }, [setHasLaunchedDemo]);

  useEffect(() => {
    if (!automaticLaunch || hasLaunchedDemo) {
      return;
    }

    setTimeout(() => {
      launchScheduleDemo();
    }, DELAY);
  }, [automaticLaunch, hasLaunchedDemo, launchScheduleDemo]);

  return {
    launchScheduleDemo,
  };
};
