import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme/types/createPalette';

export const RISK_COLOR_ASSOCIATION: { [x: string]: string } = {
  nodssi: '#E1E1E3',
  norisk: '#FFFFFF',
  low: '#FAF5FF',
  medium: '#A855F7',
  high: '#581C87',
};

export const RISK_TEXT_ASSOCIATION: { [x: string]: string } = {
  nodssi: 'In progress',
  norisk: 'No Risk',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

export const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    nodssi: {
      backgroundColor: RISK_COLOR_ASSOCIATION['nodssi'],
      color: '#A4A4AC',
    },
    noRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['norisk'],
      color: '#4A4A56',
    },
    lowRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['low'],
      color: '#9333EA',
    },
    medRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['medium'],
      color: '#FFFFFF',
    },
    highRisk: {
      backgroundColor: RISK_COLOR_ASSOCIATION['high'],
      color: '#FFFFFF',
    },

    tableFont: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      letterSpacing: '0.15px',
    },

    genreChip: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.blue.main}`,
      fontSize: 10,
      color: theme.palette.blue.main,
    },

    scoreCell: {
      minHeight: 48,
      width: 108,
      minWidth: 108,
      padding: 4,
      border: '1px solid #F0F0F1',

      '& > div': {
        width: '100%',
        height: '100%',
        borderRadius: 2,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: 'Roboto',
        fontWeight: 600,
        fontSize: 10,
        lineHeight: '12px',
        position: 'relative',
        textTransform: 'uppercase',
      },
    },

    tableCell: {
      minHeight: 48,
      padding: '4px 10px',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: '#2D2D38',
      border: '1px solid #F0F0F1',
    },

    cellChip: {
      background: '#DFECFC',
      borderRadius: 8,
      padding: 4,
      color: '#5EA1F2',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
    },
  })
);

export const getUSElectionColor = (percentage: number) => {
  if (percentage <= 33) {
    return {
      backgroundColor: '#FAF5FF',
      color: '#9333EA',
    };
  } else if (percentage <= 66) {
    return {
      backgroundColor: '#A855F7',
      color: '#FFFFFF',
    };
  }
  return {
    backgroundColor: '#581C87',
    color: '#FFFFFF',
  };
};
