import { useCallback } from 'react';

import { updateDashboard } from 'src/apis';
import useAuth from 'src/hooks/useAuth';
import useNotification from 'src/hooks/useNotification';
import { DashboardUpdate } from 'src/types';
import { METRICS_TYPE, submitMetrics } from 'src/utils';

export const useUpdateDashboard = (refetch = true) => {
  const { organization, fetchOrganization } = useAuth();

  const { handleSuccess, handleError } = useNotification();

  const handleUpdateDashboard = useCallback(
    async (dashboardId: string, data: DashboardUpdate) => {
      if (!dashboardId || !organization?.apiKey) return;

      try {
        await updateDashboard(organization.apiKey, dashboardId, data);
        handleSuccess('Successfully updated the dashboard');

        if (refetch) {
          await fetchOrganization();
        }

        submitMetrics(METRICS_TYPE.UPDATE_DASHBOARD);
      } catch (error) {
        handleError(error, 'There was an error while updating the dashboard');
      }
    },
    [organization, refetch, fetchOrganization, handleError, handleSuccess]
  );

  const handleUpdateMultiDashboards = useCallback(
    async (data: { dashboardId: string; data: DashboardUpdate }[]) => {
      if (!organization?.apiKey) return;
      const fetchArray: Promise<void>[] = [];

      data.forEach((item) => {
        fetchArray.push(
          updateDashboard(organization.apiKey, item.dashboardId, item.data)
        );
      });
      Promise.all(fetchArray)
        .then(() => {
          fetchOrganization();
        })
        .catch((error) => {
          handleError(error, 'There was an error while updating the dashboard');
        });
    },
    [organization, refetch, fetchOrganization, handleError, handleSuccess]
  );

  return {
    onUpdateDashboard: handleUpdateDashboard,
    onUpdateMultiDashboards: handleUpdateMultiDashboards,
  };
};
