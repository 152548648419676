import { UserPayload, User } from 'src/types/user';
import { Organization } from 'src/types/organization';

export const formatUser = (
  user: UserPayload,
  organization: Organization
): User => {
  return {
    ...user,
    apiKey: organization.apiKey,
    organizationName: organization.name,
    subscriptionTier: organization.subscriptionTier,
    fullName: [user.firstName, user.lastName].join(' '),
  };
};

export function serializeQueryParameters(json: any) {
  return (
    '?' +
    Object.keys(json)
      .filter(function (key) {
        return json[key];
      })
      .map(function (key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
      })
      .join('&')
  );
}
