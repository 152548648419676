import { FC, useMemo } from 'react';

import { Member } from 'src/types';
import Table from 'src/components/Table';
import Button from 'src/components/Button';
import { deleteInvitation } from 'src/apis';
import useNotification from 'src/hooks/useNotification';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';

interface InvitedMembersProps {
  members: Member[];
  onRefetch: VoidFunction;
}

const InvitedMembers: FC<InvitedMembersProps> = ({ members, onRefetch }) => {
  const { handleSuccess, handleError } = useNotification();

  const handleRemoveInvitation = async (memberId: string) => {
    try {
      await deleteInvitation(memberId);
      submitMetrics(METRICS_TYPE.REMOVE_INVITATION);
      handleSuccess('Invitation has been removed successfully.');
    } catch (error) {
      handleError(error, 'Invitation was not removed successfully.');
    }
    onRefetch();
  };

  const membersData = useMemo(() => {
    return members.map((member) => ({
      ...member,
      name: [member.firstName, member.lastName].join(' '),
    }));
  }, [members]);

  const headers = [
    { field: 'name', label: 'Name' },
    { field: 'email', label: 'Email Address' },
    { field: 'role', label: 'Permissions' },
    {
      field: 'action',
      label: 'Remove invitation',
      render: (member: Member) => (
        <Button
          variant='text'
          onClick={() => handleRemoveInvitation(member.id)}
        >
          Remove invitation
        </Button>
      ),
    },
  ];

  return <Table headers={headers} data={membersData} />;
};

export default InvitedMembers;
