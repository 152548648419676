import { FC, useState } from 'react';
import classnames from 'classnames';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  styled,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import SearchIcon from '@material-ui/icons/Search';

import { useLayoutStyles } from 'src/components/useCommonStyles';
import InfoTooltip from 'src/components/InfoTooltip';
import TextInput from 'src/components/TextInput';
import {
  RISK_TEXT_ASSOCIATION,
  RISK_COLOR_ASSOCIATION,
} from 'src/containers/dashboard/garm/common';
import {
  getNumericRiskValue,
  normalizeRiskValue,
} from 'src/hooks/useDashboardFilters';
import { Theme } from 'src/theme/types/createPalette';
import useDashboardCategories from 'src/hooks/useDashboardCategories';

import GarmScoreProgress from './GarmScoreProgress';
import USElectionsAccordion from './USElectionsAccordion';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      rowGap: 25,
      minHeight: 'calc(100vh - 70px)',
    },
    accordionContent: {
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    linkTextContainer: {
      position: 'fixed',
      bottom: theme.spacing(2),
    },
    linkText: {
      color: theme.palette.grayText.main,
      fontStyle: 'italic',
    },
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface GarmAccordionProps {
  episode: any;
  search: string;
  onChangeSearch: (value: string) => void;
}

const GarmAccordion: FC<GarmAccordionProps> = ({
  episode,
  search,
  onChangeSearch,
}) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const { dashboardCategories } = useDashboardCategories();
  const [keyStatus, setKeyStatus] = useState<any>(null);

  const clickKeywork = (key: string) => {
    const elements = Array.from(document.querySelectorAll('mark'));

    const matches: any = elements.filter((el: any) => {
      if (el?.textContent.length > key.length + 2) {
        return el?.textContent.toLowerCase().includes(key.toLowerCase());
      }
      return el?.textContent.toLowerCase() === key.toLowerCase();
    });
    let index: number =
      keyStatus && keyStatus.key === key ? keyStatus.index + 1 : 0;

    if (matches && matches.length) {
      if (index >= matches.length) {
        index = 0;
      }
      setKeyStatus({
        key,
        index,
      });
      matches[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const renderAccordionDetails = (rows: any) => {
    return (
      <AccordionDetails style={{ marginTop: 10, padding: 0, border: 'none' }}>
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table size='small' style={{ border: 'none' }}>
            <TableBody>
              {rows?.result
                ? rows?.result.map((item: any, index: number) => (
                    <StyledTableRow key={index}>
                      <TableCell
                        style={{
                          border: 'none',
                          fontSize: 12,
                          fontWeight: 'normal',
                        }}
                      >
                        {item.utterance}
                      </TableCell>
                    </StyledTableRow>
                  ))
                : Object.keys(rows).map((key: any, index: number) => (
                    <StyledTableRow key={`${key}-${index}`}>
                      <TableCell
                        style={{
                          border: 'none',
                          fontSize: 12,
                          fontWeight: 'normal',
                          cursor: 'pointer',
                        }}
                        onClick={() => clickKeywork(key)}
                      >
                        {key}
                      </TableCell>
                      <TableCell
                        align='left'
                        style={{
                          border: 'none',
                          fontSize: 12,
                          fontWeight: 'normal',
                        }}
                      >
                        {rows[key]}
                      </TableCell>
                      <TableCell
                        align='left'
                        style={{
                          border: 'none',
                          fontSize: 12,
                          fontWeight: 'normal',
                        }}
                      ></TableCell>
                      <TableCell
                        align='left'
                        style={{
                          border: 'none',
                          fontSize: 12,
                          fontWeight: 'normal',
                        }}
                      ></TableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    );
  };

  return (
    <Grid className={classnames(layoutClasses.padding2, classes.container)}>
      <Grid container spacing={1}>
        <Typography
          variant='h6'
          style={{ fontWeight: 'normal', fontSize: 16, marginBottom: 10 }}
        >
          GARM
        </Typography>

        {dashboardCategories.map((cat, index) => {
          if (!cat.enabled) {
            return null;
          }

          return (cat.entries as any[]).map((entry: any) => {
            let riskVal = episode[entry.jsonKeyRisk];

            if (!riskVal) {
              return null;
            }

            riskVal = normalizeRiskValue(riskVal);

            const shouldRenderDropdown =
              !!episode[entry.jsonKeyData] &&
              typeof episode[entry.jsonKeyData] === 'object' &&
              (episode[entry.jsonKeyData].result
                ? episode[entry.jsonKeyData].result.length > 0
                : Object.keys(episode[entry.jsonKeyData]).length > 0);

            return (
              <Grid
                alignItems='center'
                item
                xs={12}
                key={`GARM-${index}-${entry.title}`}
                style={{
                  padding: '4px 0px',
                }}
              >
                <Accordion
                  key={cat.title + entry.title}
                  square={true}
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                    padding: 0,
                  }}
                >
                  <AccordionSummary
                    expandIcon={shouldRenderDropdown ? <ArrowDropDown /> : null}
                    style={{
                      padding: 0,
                      margin: 0,
                    }}
                    className={classes.accordionContent}
                  >
                    <Grid
                      alignItems='center'
                      container
                      xs={12}
                      style={shouldRenderDropdown ? {} : { marginRight: 40 }}
                    >
                      <Grid
                        key={'column.field'}
                        item
                        xs={7}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                      >
                        <Typography
                          style={{
                            fontWeight: 'normal',
                            flex: 1,
                            fontSize: 14,
                            paddingRight: 12,
                          }}
                        >
                          {entry.title}
                          {entry.tooltip && (
                            <InfoTooltip
                              content={
                                <div>
                                  {entry.tooltip}
                                  {entry.tooltipLink ? (
                                    <a
                                      href={entry.tooltipLink.href}
                                      style={{ color: '#FFFF00' }}
                                    >
                                      {entry.tooltipLink.label}
                                    </a>
                                  ) : null}
                                </div>
                              }
                            >
                              <InfoOutlined
                                fontSize='small'
                                style={{ marginLeft: 5 }}
                              />
                            </InfoTooltip>
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={5} style={{ position: 'relative' }}>
                        <GarmScoreProgress
                          color={RISK_COLOR_ASSOCIATION[riskVal]}
                          min={0}
                          max={3}
                          value={getNumericRiskValue(riskVal)}
                        />

                        <Typography
                          variant='h6'
                          style={{
                            fontWeight: 'normal',
                            fontSize: 10,
                            color: '#666666',
                            textAlign: 'center',
                            marginTop: -8,
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            transform: 'translate(-50%, 0)',
                          }}
                        >
                          {RISK_TEXT_ASSOCIATION[riskVal]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>

                  {shouldRenderDropdown
                    ? renderAccordionDetails(episode[entry.jsonKeyData])
                    : null}
                </Accordion>
              </Grid>
            );
          });
        })}
        <USElectionsAccordion
          percentage={Number(episode['ELECTIONS PERCENTAGE']) || 0}
          sources={Object.keys(episode['POLITICIAN SCORES'] || {}).map(
            (key) => ({
              name: key,
              count: (episode['POLITICIAN SCORES'] || {})[key]?.count,
              sentiment: (episode['POLITICIAN SCORES'] || {})[key]?.sentiment,
            })
          )}
          utterances={[
            { title: '+Democrat', value: episode['DEMOCRAT POSITIVE'] || 0 },
            { title: '=Democrat', value: episode['DEMOCRAT NEUTRAL'] || 0 },
            { title: '-Democrat', value: episode['DEMOCRAT NEGATIVE'] || 0 },
            {
              title: '+Republican',
              value: episode['REPUBLICAN POSITIVE'] || 0,
            },
            { title: '=Republican', value: episode['REPUBLICAN NEUTRAL'] || 0 },
            {
              title: '-Republican',
              value: episode['REPUBLICAN NEGATIVE'] || 0,
            },
          ]}
        />
        <Grid item xs={12}>
          <TextInput
            label='Search Records'
            value={search}
            variant='standard'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={onChangeSearch}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6' className={classes.linkText}>
          Transcript powered by&nbsp;
          <a href='https://www.rev.ai/' rel='noreferrer' target='_blank'>
            Rev.ai
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GarmAccordion;
