import { useState, PropsWithChildren } from 'react';
import { Grid, createStyles, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useDashboardFilters from 'src/hooks/useDashboardFilters';
import Button from 'src/components/Button';
import { useDashboardStyles } from '../styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      gap: 8,
      flexWrap: 'wrap',
    },
    fitlerItem: {
      width: 'auto',
      border: '1px solid #C3C3C7',
      gap: 4,
      borderRadius: 8,
      padding: '4px 8px',
    },
  })
);

const FilterItem = ({
  content,
  isViewer,
  removeFilter,
}: {
  content: string;
  isViewer: boolean;
  removeFilter: () => void;
}) => {
  const classes = useStyles();
  const fontClasses = useDashboardStyles();

  return (
    <Grid item container direction='row' className={classes.fitlerItem}>
      <Typography className={fontClasses.textXSRegular}>{content}</Typography>
      {!isViewer && (
        <CloseIcon
          style={{ cursor: 'pointer', fontSize: 16 }}
          onClick={removeFilter}
        />
      )}
    </Grid>
  );
};

export const FilterSection = ({ isViewer }: { isViewer: boolean }) => {
  const classes = useStyles();
  const fontClasses = useDashboardStyles();

  const {
    riskChangeFilter,
    selectedPublishers,
    selectedIABCategories,
    selectIABCategories,
    selectPuplisher,
    setRiskChangeFilter,
    setFilterValue,
    getFilterValue,
  } = useDashboardFilters();

  const podcastRating = getFilterValue('audience') as number[];
  const unFilteredByRating =
    podcastRating && podcastRating[0] === 0 && podcastRating[1] === 5;

  const iabFilters = selectedIABCategories.filter(
    (item) => !(item.children && item.children.length)
  );
  const unFilteredByIAB = !(iabFilters && iabFilters.length);

  const unFilteredByPublisher = !(
    selectedPublishers && selectedPublishers.length
  );

  const unFilteredByRiskLevelChange = riskChangeFilter === 'all';

  const handleFilterChange = (key: string, n: number | number[]) => {
    setFilterValue(key, n);
  };

  const handleClearAllFilters = () => {
    !unFilteredByRating && handleFilterChange('audience', [0, 5]);
    !unFilteredByIAB && selectIABCategories([]);
    !unFilteredByPublisher && selectPuplisher([]);
    !unFilteredByRiskLevelChange && setRiskChangeFilter('all');
  };

  if (
    unFilteredByRating &&
    unFilteredByIAB &&
    unFilteredByPublisher &&
    unFilteredByRiskLevelChange
  ) {
    return <Grid item></Grid>;
  }

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      className={classes.container}
    >
      <Typography className={fontClasses.textSMBold} style={{ marginRight: 8 }}>
        Filters:
      </Typography>
      {unFilteredByRating ? (
        <></>
      ) : (
        !!podcastRating && (
          <FilterItem
            isViewer={isViewer}
            content={`Podcast Rating:  >${podcastRating[0]}, <${podcastRating[1]}`}
            removeFilter={() => handleFilterChange('audience', [0, 5])}
          />
        )
      )}
      {unFilteredByIAB ? (
        <></>
      ) : (
        <FilterItem
          isViewer={isViewer}
          content={`IAB: ${iabFilters[0].name}${
            iabFilters.length > 2
              ? ', ' +
                iabFilters[1].name +
                ', +' +
                (iabFilters.length - 2) +
                ' more...'
              : iabFilters.length === 2
              ? ', ' + iabFilters[1].name
              : ''
          }`}
          removeFilter={() => selectIABCategories([])}
        />
      )}
      {unFilteredByPublisher ? (
        <></>
      ) : (
        <FilterItem
          isViewer={isViewer}
          content={`Publisher: ${selectedPublishers[0]}${
            selectedPublishers.length > 2
              ? ', ' +
                selectedPublishers[1] +
                ', +' +
                (selectedPublishers.length - 2) +
                ' more...'
              : selectedPublishers.length === 2
              ? ', ' + selectedPublishers[1]
              : ''
          }`}
          removeFilter={() => selectPuplisher([])}
        />
      )}
      {unFilteredByRiskLevelChange ? (
        <></>
      ) : (
        <FilterItem
          isViewer={isViewer}
          content={`Risk Level Recently Changed:  ${riskChangeFilter.toUpperCase()}`}
          removeFilter={() => setRiskChangeFilter('all')}
        />
      )}
      {!isViewer && (
        <Button
          variant='text'
          color='primary'
          onClick={handleClearAllFilters}
          className={fontClasses.textXSRegular}
          style={{
            color: '#5EA1F2',
            height: 20,
            marginLeft: 8,
          }}
        >
          Clear All
        </Button>
      )}
    </Grid>
  );
};
