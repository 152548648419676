import ReactGA from 'react-ga';

export enum METRICS_CATEGORY {
  USER = 'User',
  INVITATION = 'Invitation',
  ARTICLE = 'Article',
  PAYMENT = 'Payment',
  SUBSCRIPTION = 'Subscription',
  COOKIES = 'Cookies',
  DASHBOARD = 'Dashboard',
  ORGANIZATION = 'Organization',
}

export enum METRICS_TYPE {
  USER_LOGIN,
  USER_LOGOUT,
  RESET_PASSWORD,
  REGISTER_USER,
  VERIFY_RESET_CODE,
  SEND_RESET_PASSWORD_CODE,
  REGISTER_ORGANIZATION,

  ACCEPT_INVITATION,
  SEND_INVITATION,
  REMOVE_INVITATION,

  UPDATE_KPIS,
  NEW_DASHBOARD,
  SCORE_CONTENT,
  SAVE_ARTICLE,
  DOWNLOAD_AS_JON,
  REFRESH_ARTICLES,

  REGISTER_PAYMENT_METHOD,

  REGISTER_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,

  ACCEPT_ALL,
  ACCEPT_SELECTED,

  VIEW_OVER_TIME,
  RETURN_TO_NORMAL,

  CREATE_DASHBOARD,
  UPDATE_DASHBOARD,
  DELETE_DASHBOARD,

  SHOW_EPISODES,
  SHOW_ARTICLE_CONTENT,
}

export function submitMetrics(type: METRICS_TYPE) {
  switch (type) {
    case METRICS_TYPE.USER_LOGIN:
      ReactGA.event({
        category: METRICS_CATEGORY.USER,
        action: 'User Login',
      });
      break;
    case METRICS_TYPE.USER_LOGOUT:
      ReactGA.event({
        category: METRICS_CATEGORY.USER,
        action: 'Logout Session',
      });
      break;
    case METRICS_TYPE.REGISTER_USER:
      ReactGA.event({
        category: METRICS_CATEGORY.USER,
        action: 'Register User',
      });
      break;
    case METRICS_TYPE.RESET_PASSWORD:
      ReactGA.event({
        category: METRICS_CATEGORY.USER,
        action: 'Reset Password',
      });
      break;
    case METRICS_TYPE.VERIFY_RESET_CODE:
      ReactGA.event({
        category: METRICS_CATEGORY.USER,
        action: 'Verify Reset Code',
      });
      break;
    case METRICS_TYPE.SEND_RESET_PASSWORD_CODE:
      ReactGA.event({
        category: METRICS_CATEGORY.USER,
        action: 'Send Reset Password Code',
      });
      break;

    case METRICS_TYPE.REGISTER_ORGANIZATION:
      ReactGA.event({
        category: METRICS_CATEGORY.ORGANIZATION,
        action: 'Register Organization',
      });
      break;

    case METRICS_TYPE.ACCEPT_INVITATION:
      ReactGA.event({
        category: METRICS_CATEGORY.INVITATION,
        action: 'Accept Invitation',
      });
      break;
    case METRICS_TYPE.REMOVE_INVITATION:
      ReactGA.event({
        category: METRICS_CATEGORY.INVITATION,
        action: 'Remove Invitation',
      });
      break;
    case METRICS_TYPE.SEND_INVITATION:
      ReactGA.event({
        category: METRICS_CATEGORY.INVITATION,
        action: 'Send Invitation',
      });
      break;

    case METRICS_TYPE.UPDATE_KPIS:
      ReactGA.event({
        category: METRICS_CATEGORY.ARTICLE,
        action: 'Update KPIs',
      });
      break;
    case METRICS_TYPE.NEW_DASHBOARD:
      ReactGA.event({
        category: METRICS_CATEGORY.ARTICLE,
        action: 'New Dashboard',
      });
      break;
    case METRICS_TYPE.SCORE_CONTENT:
      ReactGA.event({
        category: METRICS_CATEGORY.ARTICLE,
        action: 'Score Content',
      });
      break;
    case METRICS_TYPE.SAVE_ARTICLE:
      ReactGA.event({
        category: METRICS_CATEGORY.ARTICLE,
        action: 'Save Article',
      });
      break;
    case METRICS_TYPE.DOWNLOAD_AS_JON:
      ReactGA.event({
        category: METRICS_CATEGORY.ARTICLE,
        action: 'Download As JSON',
      });
      break;
    case METRICS_TYPE.REFRESH_ARTICLES:
      ReactGA.event({
        category: METRICS_CATEGORY.ARTICLE,
        action: 'Refresh Articles',
      });
      break;

    case METRICS_TYPE.REGISTER_PAYMENT_METHOD:
      ReactGA.event({
        category: METRICS_CATEGORY.PAYMENT,
        action: 'Register Payment Method',
      });
      break;

    case METRICS_TYPE.REGISTER_SUBSCRIPTION:
      ReactGA.event({
        category: METRICS_CATEGORY.SUBSCRIPTION,
        action: 'Register Subscription',
      });
      break;
    case METRICS_TYPE.UPDATE_SUBSCRIPTION:
      ReactGA.event({
        category: METRICS_CATEGORY.SUBSCRIPTION,
        action: 'Update Subscription',
      });
      break;
    case METRICS_TYPE.CANCEL_SUBSCRIPTION:
      ReactGA.event({
        category: METRICS_CATEGORY.SUBSCRIPTION,
        action: 'Cancel Subscription',
      });
      break;

    case METRICS_TYPE.ACCEPT_ALL:
      ReactGA.event({
        category: METRICS_CATEGORY.COOKIES,
        action: 'Accept All Cookies',
      });
      break;
    case METRICS_TYPE.ACCEPT_SELECTED:
      ReactGA.event({
        category: METRICS_CATEGORY.COOKIES,
        action: 'Accept Selected Cookies',
      });
      break;

    case METRICS_TYPE.VIEW_OVER_TIME:
      ReactGA.event({
        category: METRICS_CATEGORY.DASHBOARD,
        action: 'View Over Time',
      });
      break;
    case METRICS_TYPE.RETURN_TO_NORMAL:
      ReactGA.event({
        category: METRICS_CATEGORY.DASHBOARD,
        action: 'Return To Normal',
      });
      break;

    case METRICS_TYPE.CREATE_DASHBOARD:
      ReactGA.event({
        category: METRICS_CATEGORY.DASHBOARD,
        action: 'Create Dashboard',
      });
      break;
    case METRICS_TYPE.UPDATE_DASHBOARD:
      ReactGA.event({
        category: METRICS_CATEGORY.DASHBOARD,
        action: 'Update Dashboard',
      });
      break;
    case METRICS_TYPE.DELETE_DASHBOARD:
      ReactGA.event({
        category: METRICS_CATEGORY.DASHBOARD,
        action: 'Delete Dashboard',
      });
      break;

    case METRICS_TYPE.SHOW_EPISODES:
      ReactGA.event({
        category: METRICS_CATEGORY.DASHBOARD,
        action: 'Show Episodes',
      });
      break;
    case METRICS_TYPE.SHOW_ARTICLE_CONTENT:
      ReactGA.event({
        category: METRICS_CATEGORY.DASHBOARD,
        action: 'Show Article Content',
      });
      break;

    default:
      break;
  }
}
