import { Organization } from './organization';

export enum PaymentMethodBrand {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

export interface PaymentMethodBase {
  userId?: string;
  stripeId?: string;
  nameOnCard: string;
  brand: string;
  lastFour: string;
  expMonth: number;
  expYear: number;
  zipCode: string;
  address?: string;
}

export interface PaymentMethod extends PaymentMethodBase {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export type PaymentMethodCreateForm = PaymentMethodBase;

export enum SubscriptionPlans {
  FREE = 'Free',
  STARTUP = 'Startup',
  ENTERPRISE = 'Enterprise',
}

export interface SubscriptionMode {
  planType: SubscriptionPlans;
  billingYearly: boolean;
}

export interface InvoiceBase {
  stripeToken: string;
  customerToken: string;
  subscriptionToken: string;
  amount: number;
  paid: boolean;
  refunded?: boolean;
  refundedAt?: Date;
}

export interface InvoiceDto extends InvoiceBase {
  id: string;
  paidAt: Date;
}

export interface ISubscriptionInfo {
  plan: SubscriptionPlans;
  price: number;
  period: string;
  billingPeriod: string;
  billingYearly: boolean;
}

export interface CustomerSubscriptionDto {
  totalAmount: number;
  percentOff: number;
  amountOff: number;
  organization: Organization;
}

export interface PromoCode {
  id: string;
  active: boolean;
  code: string;
  amountOff: number;
  percentOff: number;
  created: number;
  maxRedemptions: number;
  timesRedeemed: number;
}
