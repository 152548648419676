import { styled, TableCell } from '@material-ui/core';

const HeaderCell = styled(TableCell)(() => ({
  position: 'relative',
  '&::after': {
    color: '#E0E0E0',
    content: '&apos;|&apos;',
    position: 'absolute',
    right: 0,
  },
  '&:last-child': {
    '&::after': {
      content: '&apos;&apos;',
    },
  },
}));

export default HeaderCell;
