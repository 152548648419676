import { useState, MouseEvent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(() =>
  createStyles({
    filterAction: {
      width: 34,
      height: 34,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      border: '1px solid #C3C3C7',
      cursor: 'pointer',
      '&:hover': {
        background: '#F2F2F2',
      },
    },
  })
);

interface FilterActionsProps {
  hasContainer?: boolean;
  handleDuplicate: () => void;
  handleRemove: () => void;
}

export const FilterActions = ({
  hasContainer = false,
  handleDuplicate,
  handleRemove,
}: FilterActionsProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {hasContainer ? (
        <div className={classes.filterAction} onClick={handleOpenMenu}>
          <IconButton style={{ padding: 0, marginLeft: 0, margin: 'auto' }}>
            <MoreVertIcon htmlColor='#757575' style={{ fontSize: 16 }} />
          </IconButton>
        </div>
      ) : (
        <IconButton
          onClick={handleOpenMenu}
          style={{ padding: 0, marginLeft: 0, margin: 'auto' }}
        >
          <MoreVertIcon htmlColor='#757575' style={{ fontSize: 16 }} />
        </IconButton>
      )}
      <Menu
        id='folder-menu'
        aria-labelledby='folder-button'
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </Menu>
    </div>
  );
};
