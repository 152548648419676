import { FC, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { UserRole } from 'src/types/user';
import RolePermissions from './RolePermissions';
import ActionDialog from 'src/components/ActionDialog';
import Button from 'src/components/Button';
import TextInput from 'src/components/TextInput';
import { useLayoutStyles } from 'src/components/useCommonStyles';
import { createInvitation } from 'src/apis';
import useNotification from 'src/hooks/useNotification';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContainer: {
      padding: theme.spacing(3),
    },
  })
);

interface InviteNewMemberDialogProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onRefetch: VoidFunction;
}

const InviteNewMemberDialog: FC<InviteNewMemberDialogProps> = ({
  isOpen,
  onClose,
  onRefetch,
}) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const { handleSuccess, handleError } = useNotification();
  const [form, setForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
    role: UserRole.ADMIN,
  });

  const handleChangeInput = (value: string, name?: string) => {
    if (!name) return;
    setForm({ ...form, [name]: value });
  };

  const handleChangePermission = (value: any) => {
    setForm({ ...form, role: value });
  };

  const handleInvite = async () => {
    try {
      await createInvitation(form);
      submitMetrics(METRICS_TYPE.SEND_INVITATION);
      handleSuccess('Invitation has been created successfully.');
      onRefetch();
      onClose();
    } catch (error) {
      handleError(error, 'Invitation was not successful.');
    }
  };

  return (
    <ActionDialog
      width={480}
      title='New User Details'
      isOpen={isOpen}
      onClose={onClose}
    >
      <Grid container spacing={2} className={classes.dialogContainer}>
        <Grid
          container
          item
          xs={12}
          justifyContent='space-between'
          className={layoutClasses.mb2}
        >
          <Typography variant='h5'>New User Details</Typography>
          <PersonAddIcon fontSize='large' />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextInput
                name='firstName'
                label='First Name:'
                fullWidth
                value={form.firstName}
                variant='filled'
                onChange={handleChangeInput}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                name='lastName'
                label='Last Name:'
                fullWidth
                value={form.lastName}
                variant='filled'
                onChange={handleChangeInput}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextInput
            name='email'
            label='Email address:'
            fullWidth
            value={form.email}
            variant='filled'
            onChange={handleChangeInput}
          />
        </Grid>

        <Grid item xs={12}>
          <RolePermissions
            value={form.role}
            onChange={handleChangePermission}
          />
        </Grid>

        <Grid container item xs={12} justifyContent='space-between'>
          <Button color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button color='primary' onClick={handleInvite}>
            Invite Now
          </Button>
        </Grid>
      </Grid>
    </ActionDialog>
  );
};

export default InviteNewMemberDialog;
