export const convertCategoryName = (title: string) => {
  switch (title) {
    case 'Adult & Explicit Sexual Content':
      return 'Adult Sexual Explicit';
    case 'Obscenity and Profanity':
      return 'Obscenity & Profanity';
    case 'Debated Sensitive Social issues':
      return 'Debated Sensitive Social Issues (Political issues)';
    case 'Illegal drugs, Tobacco and Alcohol':
      return 'Illegal Drugs/Tobacco/e-Cigarettes/Vaping/Alcohol';
    case 'Arms & Ammunition':
      return 'Arms & Ammunition';
    case 'Online Piracy or Spam':
      return 'Online Piracy or Spam';
    case 'Crime & Human Rights Violations':
      return 'Crime & Violation of Human Rights';
    case 'Death, Injury or Military Conflict':
      return 'Death, Injury or Military Conflict';
    case 'Hate Speech':
      return 'Hate Speech & Acts of Aggression';
    case 'Hate Speech (BETA)':
      return 'Hate Speech & Acts of Aggression';
    default:
      return title;
  }
};
