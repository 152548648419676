import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import AccountDetails from './AccountDetails';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      padding: 24,
      paddingBottom: 0,
      gap: 16,
      background: '#fff',
      flexWrap: 'nowrap',
    },
  })
);

export const APIKey = () => {
  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid container>
        <AccountDetails />
      </Grid>
    </Grid>
  );
};
