import { useCallback } from 'react';

import { deleteDashboard } from 'src/apis';
import useAuth from 'src/hooks/useAuth';
import useNotification from 'src/hooks/useNotification';
import { METRICS_TYPE, submitMetrics } from 'src/utils';

export const useDeleteDashboard = (refetch = true) => {
  const { organization, fetchOrganization } = useAuth();

  const { handleSuccess, handleError } = useNotification();

  const handleDeleteDashboard = useCallback(
    async (dashboardId: string) => {
      if (!organization?.apiKey) return;

      try {
        await deleteDashboard(organization.apiKey, dashboardId);
        handleSuccess('Successfully deleted the dashboard.');

        if (refetch) {
          await fetchOrganization();
        }

        submitMetrics(METRICS_TYPE.DELETE_DASHBOARD);
      } catch (error) {
        handleError(error, 'There was an error while deleting the dashboard.');
      }
    },
    [organization, refetch, fetchOrganization, handleError, handleSuccess]
  );

  return {
    onDeleteDashboard: handleDeleteDashboard,
  };
};
