import { useState } from 'react';
import { Modal, Backdrop, Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';

import { useTextStyles } from 'src/hooks/useTextStyles';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import Button from 'src/components/NewButton';
import {
  getScoringImportedShows,
  getScoringReviewedShows,
  submitRequestSelfScoring,
} from 'src/apis/selfScoring';
import {
  ReviewDataProps,
  ReviewedDataProps,
  SuggestedSuccessShowProps,
} from 'src/types/selfScoring';
import useNotification from 'src/hooks/useNotification';

import { ReactComponent as UploadIcon } from 'src/assets/icons/upload.svg';
import { ReactComponent as ReviewIcon } from 'src/assets/icons/review.svg';
import { ReactComponent as SubmitIcon } from 'src/assets/icons/submit.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { ReactComponent as ConfirmIcon } from 'src/assets/icons/confirm.svg';

import { ImportStep } from './ImportStep';
import { ReviewStep } from './ReviewStep';
import { ConfirmStep } from './ConfirmStep';

interface SelfScoringModalProps {
  open: boolean;
  closeModal: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    progress: {
      position: 'relative',
      width: 'auto',
      margin: 50,
      marginTop: 42,
      marginBottom: 75,
      borderTop: '1px solid #D0D5DD',
      '& .step': {
        position: 'absolute',
        transform: 'translate(-50%, -21px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
        width: 112,

        '& .icon': {
          borderRadius: 8,
          border: '1px solid #EAECF0',
          width: 42,
          height: 42,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#FFF',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        },
      },
    },
    content: {
      width: 'calc(100% + 48px)',
      height: 500,
      marginLeft: -24,
      marginRight: -24,
      padding: 24,
      borderTop: '1px solid #EAECF0',
      borderBottom: '1px solid #EAECF0',
    },
    buttons: {
      paddingTop: 16,
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      '& button': {
        flex: 1,
      },
    },
    warning: {
      borderRadius: 28,
      border: '8px solid #FFFAEB',
      background: '#FEF0C7',
    },
    confirm: {
      borderRadius: 28,
      border: '8px solid #ECFDF3',
      background: '#DCFAE6',
    },
    confirmModalContent: {
      marginTop: 16,
      marginBottom: 24,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    confirmBtn: {
      flex: 1,
    },
  })
);

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  background: '#fff',
  borderRadius: 8,
  boxShadow:
    '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
  padding: 24,
};

export const SelfScoringModal = ({
  open,
  closeModal,
}: SelfScoringModalProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const { dashboardId } = useDashboardFilters();
  const { handleError, handleRefresh } = useNotification();
  const history = useHistory();

  const [importedData, setImportedData] = useState<Record<string, string>[]>(
    []
  );
  const [scoredData, setScoredData] = useState<ReviewDataProps | null>(null);
  const [reviewedData, setReviewedData] = useState<ReviewedDataProps | null>(
    null
  );
  const [confirmedData, setConfirmedData] = useState<
    SuggestedSuccessShowProps[]
  >([]);
  const [duplicatedCount, setDuplicatedCount] = useState<number>(0);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState<
    'confirm' | 'warning' | 'importTable' | null
  >(null);
  const [hasInvalidErr, setHasInvalidErr] = useState<boolean>(false);
  const [hasUnmatchedErr, setHasUnmatchedErr] = useState<boolean>(false);
  const [hasSuggestedErr, setHasSuggestedErr] = useState<boolean>(false);
  const [isNoRSSFeed, setIsNoRSSFeed] = useState<boolean>(false);

  const handlePrevious = () => {
    if (step === 0) {
      initModal();
    } else {
      setStep(step - 1);
    }
  };

  const handleNext = async () => {
    if (loading) {
      return;
    }
    if (step === 2) {
      if (dashboardId) {
        setLoading(true);
        try {
          await submitRequestSelfScoring({
            dashboardId,
            shows: confirmedData.map((item) => ({
              ...item,
              showName: item.showName.toString(),
              rss: item.rss.toString(),
            })),
          });
          setLoading(false);
          setConfirmModal('confirm');
        } catch (e) {
          setLoading(false);
          handleError(
            e,
            // eslint-disable-next-line quotes
            "Oops! Looks like we're having technical problem. We'll be back up soon."
          );
        }
      }
    } else if (step === 0) {
      if (importedData && importedData.length) {
        if (
          Object.keys(importedData[0]).includes('Show Title') &&
          (isNoRSSFeed || Object.keys(importedData[0]).includes('RSS Feed'))
        ) {
          const body = importedData.map((item) => ({
            showName: (item['Show Title'] || '').toString(),
            rss: isNoRSSFeed ? '' : (item['RSS Feed'] || '').toString(),
          }));
          setLoading(true);
          try {
            const data = await getScoringImportedShows(body);
            const temp = {
              ...data.data,
            };
            const uniqueShows: SuggestedSuccessShowProps[] = [];
            let duplicatedShows = 0;
            temp.success.forEach((show) => {
              if (
                uniqueShows.find(
                  (item) =>
                    item.showName === show.showName || item.rss === show.rss
                )
              ) {
                duplicatedShows++;
              } else {
                uniqueShows.push(show);
              }
            });
            setScoredData({
              ...data.data,
              success: uniqueShows,
            });
            setDuplicatedCount(duplicatedShows);
            setLoading(false);
            setStep(1);
          } catch (e) {
            setLoading(false);
            handleError(
              e,
              // eslint-disable-next-line quotes
              "Oops! Looks like we're having technical problem. We'll be back up soon."
            );
          }
        } else {
          setConfirmModal('importTable');
        }
      }
    } else {
      try {
        if (reviewedData) {
          let error = false;
          if (reviewedData.invalid.filter((item) => !item.rss).length) {
            error = true;
            setHasInvalidErr(true);
          } else {
            setHasInvalidErr(false);
          }
          if (reviewedData.unmatched.filter((item) => !item.rss).length) {
            error = true;
            setHasUnmatchedErr(true);
          } else {
            setHasUnmatchedErr(false);
          }
          if (
            reviewedData.suggested.filter((item) => !item.selectedShow).length
          ) {
            error = true;
            setHasSuggestedErr(true);
          } else {
            setHasSuggestedErr(false);
          }
          if (!error) {
            setLoading(true);
            const { data } = await getScoringReviewedShows(reviewedData);
            setLoading(false);
            const successData = scoredData?.success || [];
            setConfirmedData([...successData, ...data.success]);
            setStep(2);
          }
        } else {
          setStep(2);
        }
      } catch (e) {
        setLoading(false);
        handleError(
          e,
          // eslint-disable-next-line quotes
          "Oops! Looks like we're having technical problem. We'll be back up soon."
        );
      }
    }
  };

  const initModal = () => {
    setImportedData([]);
    setScoredData(null);
    setReviewedData(null);
    setConfirmedData([]);
    setStep(0);
    setConfirmModal(null);
  };

  const exitScoringModal = () => {
    closeConfirmModal();
    initModal();
    closeModal();
  };

  const closeConfirmModal = () => {
    setConfirmModal(null);
    if (confirmModal === 'confirm') {
      initModal();
      closeModal();
    }
  };

  const viewRequestSubmission = () => {
    history.push('/settings/self-scoring');
  };

  return (
    <Modal
      open={open}
      aria-labelledby='self-scoring-modal-title'
      aria-describedby='self-scoring-modal-description'
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 800,
      }}
    >
      <Box sx={{ ...modalStyle }}>
        <Box className={classes.header}>
          <Typography
            className={textClasses.lgBold}
            style={{ color: '#101828' }}
          >
            Scoring Request
          </Typography>
          <CancelIcon
            cursor='pointer'
            width={24}
            height={24}
            onClick={() => setConfirmModal('warning')}
          />
        </Box>
        <Box className={classes.progress}>
          <Box className='step' style={{ left: 0 }}>
            <Box className='icon'>
              <UploadIcon stroke={step === 0 ? '#5EA1F2' : '#17B26A'} />
            </Box>
            <Typography
              className={textClasses.smBold}
              style={{ color: step === 0 ? '#5EA1F2' : '#17B26A' }}
            >
              Import List
            </Typography>
          </Box>
          <Box className='step' style={{ left: '50%' }}>
            <Box className='icon'>
              <ReviewIcon
                stroke={
                  step === 0 ? '#667085' : step === 1 ? '#5EA1F2' : '#17B26A'
                }
              />
            </Box>
            <Typography
              className={textClasses.smBold}
              style={{
                color:
                  step === 0 ? '#667085' : step === 1 ? '#5EA1F2' : '#17B26A',
              }}
            >
              Review
            </Typography>
          </Box>
          <Box className='step' style={{ left: '100%' }}>
            <Box className='icon'>
              <SubmitIcon stroke={step === 2 ? '#5EA1F2' : '#667085'} />
            </Box>
            <Typography
              className={textClasses.smBold}
              style={{
                color: step === 2 ? '#5EA1F2' : '#667085',
              }}
            >
              Confirm & Submit
            </Typography>
          </Box>
        </Box>
        <Box className={classes.content}>
          {step === 0 && (
            <ImportStep
              data={importedData}
              setData={setImportedData}
              isNoRSSFeed={isNoRSSFeed}
              setIsNoRSSFeed={setIsNoRSSFeed}
            />
          )}
          {step === 1 && (
            <ReviewStep
              data={scoredData}
              reviewedData={reviewedData}
              hasInvalidErr={hasInvalidErr}
              hasUnmatchedErr={hasUnmatchedErr}
              hasSuggestedErr={hasSuggestedErr}
              duplicatedCount={duplicatedCount}
              setReviewedData={setReviewedData}
            />
          )}
          {step === 2 && <ConfirmStep data={confirmedData} />}
        </Box>
        <Box className={classes.buttons}>
          <Button
            title={step === 0 ? 'Cancel' : 'Previous'}
            variant='outlined'
            height={46}
            onClick={handlePrevious}
          />
          <Button height={46} onClick={handleNext}>
            {loading ? (
              <CircularProgress style={{ color: '#fff' }} size={24} />
            ) : (
              <span>{step === 2 ? 'Submit' : 'Next'}</span>
            )}
          </Button>
        </Box>
        <Modal
          open={!!confirmModal}
          onClose={closeConfirmModal}
          aria-labelledby='warning-modal-title'
          aria-describedby='warning-modal-description'
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 800,
          }}
        >
          <Box sx={{ ...modalStyle, maxWidth: 400 }}>
            <Box className={classes.header}>
              {confirmModal === 'warning' || confirmModal === 'importTable' ? (
                <Box className={classes.warning}>
                  <WarningIcon />
                </Box>
              ) : (
                <Box className={classes.confirm}>
                  <ConfirmIcon />
                </Box>
              )}
              <CancelIcon
                cursor='pointer'
                width={24}
                height={24}
                onClick={closeConfirmModal}
              />
            </Box>
            <Box className={classes.confirmModalContent}>
              <Typography
                className={textClasses.lgBold}
                style={{
                  color: '101828',
                }}
              >
                {confirmModal === 'warning' || confirmModal === 'importTable'
                  ? 'Warning'
                  : 'Submitted'}
              </Typography>
              <Typography
                className={textClasses.smRegular}
                style={{
                  color: '101828',
                }}
              >
                {confirmModal === 'importTable'
                  ? isNoRSSFeed
                    ? 'Please select "Show Title" header on imported data.'
                    : 'Please select "Show Title" and "RSS Feed" headers on imported data.'
                  : confirmModal === 'warning'
                  ? 'Closing the scoring request will not save any of your progress. Are you sure you want to exit?'
                  : 'Thank you! We will identify and score your list in the order it was received. Once complete, your dashboard will be updated with the list of shows.'}
              </Typography>
              {confirmModal === 'confirm' && (
                <Box>
                  <Button
                    variant='text'
                    fontSize='sm'
                    height={20}
                    onClick={viewRequestSubmission}
                  >
                    <Typography
                      className={textClasses.smRegular}
                      style={{ color: '#5EA1F2' }}
                    >
                      View Request Submission
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
            <Box
              style={{
                display: 'flex',
                gap: 12,
                alignItems: 'center',
                width: '100%',
              }}
            >
              {confirmModal === 'importTable' ? (
                <Button
                  className={classes.confirmBtn}
                  title='Confirm'
                  height={46}
                  onClick={closeConfirmModal}
                />
              ) : confirmModal === 'warning' ? (
                <>
                  <Button
                    className={classes.confirmBtn}
                    title='Cancel'
                    variant='outlined'
                    height={46}
                    onClick={closeConfirmModal}
                  />
                  <Button
                    className={classes.confirmBtn}
                    title='Exit'
                    height={46}
                    onClick={exitScoringModal}
                  />
                </>
              ) : (
                <Button
                  className={classes.confirmBtn}
                  title='Close'
                  height={46}
                  onClick={() => {
                    setTimeout(() => {
                      handleRefresh(
                        'Please wait ~ 2 minutes for new shows to score. Once complete they will appear in the dashboard.'
                      );
                    }, 500);
                    exitScoringModal();
                  }}
                />
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};
