import { baseAPICreator } from './apiCreator';
import {
  TAcceptInvitationBody,
  InvitationBase,
  SignedUser,
  User,
  UserBase,
  TVerifyResetPasswordCode,
  TVerifyResetCodeAndUpdatePassword,
} from 'src/types';
import { formatUser, serializeQueryParameters } from './helpers';
import { fetchOrganization } from './organization';

const authApi = baseAPICreator('/user');

export const getToken = () => {
  return sessionStorage.getItem('token');
};

export const removeToken = () => {
  sessionStorage.removeItem('token');
};

export const setToken = (token: string) => {
  sessionStorage.setItem('token', token);
};

export const fetchUser = async (): Promise<User | undefined> => {
  if (!getToken()) return undefined;
  const { data } = await authApi.get('/current');
  const organization = await fetchOrganization(data.organizationId);
  return formatUser(data, organization);
};

export const signIn = async (
  email: string,
  password: string
): Promise<SignedUser> => {
  const { data } = await authApi.post('/login', { email, password });
  setToken(data.token);
  return data;
};

export const resetPassword = async (password: string) => {
  const { data } = await authApi.patch('/password/reset', { password });
  return data;
};

export const sendResetPasswordCodeLink = async (email: string) => {
  const { data } = await authApi.post('/password/reset-code-link', { email });
  return data;
};

export const verifyResetPasswordCode = async (
  body: TVerifyResetPasswordCode
) => {
  const { data } = await authApi.get(
    `/password/verify-reset-code${serializeQueryParameters({
      code: body.code,
      email: body.email,
    })}`
  );
  return data;
};

export const verifyResetCodeAndUpdatePassword = async (
  body: TVerifyResetCodeAndUpdatePassword
) => {
  const { data } = await authApi.put('/password/verify-code-and-update', body);
  return data;
};

export const registerUser = async (user: UserBase) => {
  const { data } = await authApi.post('/', user);
  setToken(data.token);
  return data;
};

export const signOut = async () => {
  removeToken();
};

export const createInvitation = async (invitation: InvitationBase) => {
  const { data } = await authApi.post('/invitation', invitation);
  return data;
};

export const deleteInvitation = async (userId: string) => {
  const { data } = await authApi.delete(`/${userId}`);
  return data;
};

export const acceptInvitation = async (body: TAcceptInvitationBody) => {
  const { data } = await authApi.post('/accept-invitation', body);
  setToken(data.token);
  return data;
};

export const fetchInvitationDetails = async (invitationCode: string) => {
  const { data } = await authApi.get(
    `/invitation-details?invitationCode=${invitationCode}`
  );
  return data;
};

export const updateMemeber = async (id: string, body: any) => {
  const { data } = await authApi.put(`/${id}`, body);
  return data;
};
