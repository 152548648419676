import clsx from 'clsx';
import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import useSidebar, {
  DRAWER_WIDTH,
  DRAWER_WIDTH_SMALL,
  SECONDARY_DRAWER_WIDTH,
} from 'src/hooks/useSidebar';

const useStyles = makeStyles(() =>
  createStyles({
    mainRoot: {
      display: 'flex',
      height: '100%',
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,

      '& > *': {
        boxSizing: 'border-box',
      },
    },
    secDrawerOpen: {
      marginLeft: SECONDARY_DRAWER_WIDTH + DRAWER_WIDTH,
      width: `calc(100% - ${SECONDARY_DRAWER_WIDTH + DRAWER_WIDTH}px)`,
    },
    smallSidebar: {
      width: `calc(100% - ${SECONDARY_DRAWER_WIDTH + DRAWER_WIDTH_SMALL}px)`,
      marginLeft: SECONDARY_DRAWER_WIDTH + DRAWER_WIDTH_SMALL,
    },
    mainContent: {
      width: '100%',
      flexGrow: 1,
      margin: 0,
    },
  })
);

interface props {
  children: React.ReactNode;
}

const AuthenticatedLayout: FC<props> = ({ children }) => {
  const classes = useStyles();
  const { secondSidebarOpen, isSmallSidebar } = useSidebar();

  return (
    <div
      className={clsx(
        classes.mainRoot,
        { [classes.secDrawerOpen]: secondSidebarOpen && !isSmallSidebar },
        { [classes.smallSidebar]: isSmallSidebar }
      )}
    >
      <main className={classes.mainContent}>{children}</main>
    </div>
  );
};

export default AuthenticatedLayout;
