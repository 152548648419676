import { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InfiniteScroll from 'react-infinite-scroller';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import { useTextStyles } from 'src/hooks/useTextStyles';
import Checkbox from 'src/components/Checkbox';
import { ScoreExceptionTypes } from 'src/types/filter';

export const useStyles = makeStyles(() =>
  createStyles({
    selectionContainer: {
      width: 280,
      padding: '4px 8px',
    },
    selectionOptions: {
      maxHeight: 250,
      overflow: 'auto',

      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
      },
      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track ': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
    selectOption: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,

      '& > svg': {
        width: 16,
        height: 16,
        cursor: 'pointer',
      },
    },
    searchContainer: {
      maxWidth: 280,
      display: 'flex',
      alignItems: 'center',
      padding: 4,
      border: '1px solid #C3C3C7',
      borderRadius: 20,
      gap: 4,
      transitionProperty: 'all',
      transitionTimingFunction: 'linear',
      transitionDuration: '250ms',
      marginBottom: 8,
      marginRight: 12,

      '& > svg': {
        width: 16,
        height: 16,
      },

      '& > input': {
        height: 16,
        border: 'none',
        padding: 0,
        margin: 0,
        flex: 1,
        outline: 'none',
        fontSize: 12,
      },
    },
    category: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fontSize: 20,
        cursor: 'pointer',
      },
    },
    categoryContent: {
      marginLeft: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      position: 'relative',

      '&::before': {
        content: '""',
        position: 'absolute',
        left: -11,
        width: 1,
        top: 4,
        bottom: 4,
        borderLeft: '1px dashed gray',
      },
    },
  })
);

interface SelectListProps {
  selections: string[];
  type: ScoreExceptionTypes;
  selected: string;
  selectedOthers: string[];
  handleSave: (selected: string) => void;
}

export const SelectList = ({
  selections,
  type,
  selected,
  selectedOthers,
  handleSave,
}: SelectListProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const [searchKey, setSearchKey] = useState<string>('');
  const itemsPerPage = 100;
  const [hasMore, setHasMore] = useState(true);
  const [records, setRecords] = useState(itemsPerPage);

  const loadMore = () => {
    const data = selections.filter(
      (item: string) =>
        !searchKey ||
        (item && item.toLowerCase().includes(searchKey.toLowerCase()))
    );
    if (records >= data.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setRecords(records + itemsPerPage);
      }, 500);
    }
  };

  return (
    <div className={classes.selectionContainer}>
      <Typography
        className={textClasses.xxsRegular}
        style={{ marginBottom: 8 }}
      >
        Select {type}
      </Typography>
      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          placeholder={`Search ${type}...`}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </div>
      <div className={classes.selectionOptions}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={<h4 className='loader'>Loading...</h4>}
          useWindow={false}
        >
          {selections
            .filter(
              (item: string) =>
                !searchKey ||
                (item && item.toLowerCase().includes(searchKey.toLowerCase()))
            )
            .slice(0, records)
            .map((item: any) => (
              <div key={item} className={classes.selectOption}>
                <Checkbox
                  name={item}
                  label=''
                  checked={selected === item || selectedOthers.includes(item)}
                  noPadding={true}
                  disabled={item !== selected && selectedOthers.includes(item)}
                  onChange={() => {
                    handleSave(selected === item ? '' : item);
                  }}
                />
                <Typography className={textClasses.xsRegular}>
                  {item}
                </Typography>
              </div>
            ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

interface MultiSelectListProps {
  title: string;
  selections: string[];
  selected: string[];
  handleSave: (selected: string[]) => void;
}

export const MultiSelectList = ({
  title,
  selections,
  selected,
  handleSave,
}: MultiSelectListProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const [searchKey, setSearchKey] = useState<string>('');
  const itemsPerPage = 100;
  const [hasMore, setHasMore] = useState(true);
  const [records, setRecords] = useState(itemsPerPage);

  const loadMore = () => {
    const data = selections.filter(
      (item: string) =>
        !searchKey ||
        (item && item.toLowerCase().includes(searchKey.toLowerCase()))
    );
    if (records >= data.length) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setRecords(records + itemsPerPage);
      }, 500);
    }
  };

  const handleCheck = (item: string) => {
    const temp = [...selected];
    const index = selected.indexOf(item);
    if (index < 0) {
      handleSave([...temp, item]);
    } else {
      temp.splice(index, 1);
      handleSave(temp);
    }
  };

  return (
    <div className={classes.selectionContainer} style={{ width: '100%' }}>
      <Typography
        className={textClasses.xxsRegular}
        style={{ marginBottom: 8 }}
      >
        Select {title}
      </Typography>
      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          placeholder={`Search ${title}...`}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </div>
      <div className={classes.selectionOptions}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={<h4 className='loader'>Loading...</h4>}
          useWindow={false}
        >
          {selections
            .filter(
              (item: string) =>
                !searchKey ||
                (item && item.toLowerCase().includes(searchKey.toLowerCase()))
            )
            .slice(0, records)
            .map((item: any) => (
              <div key={item} className={classes.selectOption}>
                <Checkbox
                  name={item}
                  label=''
                  checked={selected.includes(item)}
                  noPadding={true}
                  onChange={() => {
                    handleCheck(item);
                  }}
                />
                <Typography className={textClasses.xsRegular}>
                  {item}
                </Typography>
              </div>
            ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

interface CategorySelectListProps {
  selections: Record<string, string[]>;
  type: ScoreExceptionTypes;
  selected: string;
  selectedOthers: string[];
  handleSave: (selected: string) => void;
}

export const CategorySelectList = ({
  selections,
  type,
  selected,
  selectedOthers,
  handleSave,
}: CategorySelectListProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const [searchKey, setSearchKey] = useState<string>('');
  const [extendedCategory, setExtendedCategory] = useState<string>('');

  return (
    <div className={classes.selectionContainer}>
      <Typography
        className={textClasses.xxsRegular}
        style={{ marginBottom: 8 }}
      >
        Select {type}
      </Typography>
      <div className={classes.searchContainer}>
        <SearchIcon />
        <input
          placeholder={`Search ${type}...`}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </div>
      <div className={classes.selectionOptions}>
        {Object.keys(selections).map((category) => (
          <div key={category}>
            <div className={classes.category}>
              {extendedCategory === category ? (
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setExtendedCategory('');
                  }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  onClick={() => {
                    setExtendedCategory(category);
                  }}
                />
              )}
              <Typography className={textClasses.xsRegular}>
                {category}
              </Typography>
            </div>
            {extendedCategory === category && (
              <div className={classes.categoryContent}>
                {selections[category]
                  .filter(
                    (item: string) =>
                      !searchKey ||
                      (item &&
                        item.toLowerCase().includes(searchKey.toLowerCase()))
                  )
                  .map((item: any) => (
                    <div key={item} className={classes.selectOption}>
                      <Checkbox
                        name={item}
                        label=''
                        checked={
                          selected === item || selectedOthers.includes(item)
                        }
                        noPadding={true}
                        disabled={
                          item !== selected && selectedOthers.includes(item)
                        }
                        onChange={() => {
                          handleSave(selected === item ? '' : item);
                        }}
                      />
                      <Typography className={textClasses.xsRegular}>
                        {item}
                      </Typography>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
