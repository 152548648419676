const OverviewIcon = ({
  fill = '#000000',
  height = 24,
  width = 24,
}: {
  width?: number;
  height?: number;
  fill?: string;
}): React.ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.4071 21.4547C13.6323 21.4547 12.1936 20.0615 12.1936 18.3429C12.1936 16.6242 13.6323 15.231 15.4071 15.231C17.1819 15.231 18.6206 16.6242 18.6206 18.3429C18.6206 20.0615 17.1819 21.4547 15.4071 21.4547Z'
      fill={fill}
      fillOpacity='0.54'
    />
    <path
      d='M7.66442 8.67475C5.88966 8.67475 4.45092 7.28154 4.45092 5.56292C4.45092 3.8443 5.88966 2.45108 7.66442 2.45108C9.43919 2.45108 10.8779 3.8443 10.8779 5.56292C10.8779 7.28154 9.43919 8.67475 7.66442 8.67475Z'
      fill={fill}
      fillOpacity='0.54'
    />
    <path
      d='M11.5363 15.0652C9.76148 15.0652 8.32275 13.672 8.32275 11.9534C8.32275 10.2347 9.76148 8.84152 11.5363 8.84152C13.311 8.84152 14.7498 10.2347 14.7498 11.9534C14.7498 13.672 13.311 15.0652 11.5363 15.0652Z'
      fill={fill}
      fillOpacity='0.54'
    />
    <path
      d='M19.1866 15.014C17.4119 15.014 15.9731 13.6208 15.9731 11.9022C15.9731 10.1835 17.4119 8.79031 19.1866 8.79031C20.9614 8.79031 22.4001 10.1835 22.4001 11.9022C22.4001 13.6208 20.9614 15.014 19.1866 15.014Z'
      fill={fill}
      fillOpacity='0.54'
    />
    <path
      d='M3.88391 15.1157C2.10914 15.1157 0.670404 13.7225 0.670404 12.0039C0.670404 10.2853 2.10914 8.89206 3.88391 8.89206C5.65868 8.89206 7.09741 10.2853 7.09741 12.0039C7.09741 13.7225 5.65868 15.1157 3.88391 15.1157Z'
      fill={fill}
      fillOpacity='0.54'
    />
    <path
      d='M15.3151 8.62368C13.5403 8.62368 12.1016 7.23045 12.1016 5.51183C12.1016 3.79321 13.5403 2.39999 15.3151 2.39999C17.0898 2.39999 18.5286 3.79321 18.5286 5.51183C18.5286 7.23045 17.0898 8.62368 15.3151 8.62368Z'
      fill={fill}
      fillOpacity='0.54'
    />
    <path
      d='M7.75647 21.5054C5.9817 21.5054 4.54296 20.1122 4.54296 18.3935C4.54296 16.6749 5.9817 15.2817 7.75647 15.2817C9.53124 15.2817 10.97 16.6749 10.97 18.3935C10.97 20.1122 9.53124 21.5054 7.75647 21.5054Z'
      fill={fill}
      fillOpacity='0.54'
    />
  </svg>
);

export default OverviewIcon;
