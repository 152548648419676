import { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { HeaderCell } from 'src/containers/dashboard/garm/components';
import { useTextStyles } from 'src/hooks/useTextStyles';
import { SuggestedSuccessShowProps } from 'src/types/selfScoring';

import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrowUp.svg';
import { ReactComponent as RefreshIcon } from 'src/assets/icons/refresh.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    showList: {
      borderRadius: 8,
      border: '1px solid #EAECF0',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      '& .header': {
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        position: 'relative',
      },
      '& .content': {
        overflow: 'auto',
        maxHeight: 242,
        borderTop: '1px solid #EAECF0',
      },
    },
    table: {
      background: '#fff',
      height: '100%',
      borderCollapse: 'collapse',

      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          borderBottom: '1px solid #EAECF0',

          '&:last-child': {
            borderBottom: 'none',
          },

          '& .MuiTableCell-root': {
            borderBottom: 'none',
            borderRight: '1px solid #EAECF0',

            '&:last-child': {
              borderRight: 'none',
            },
          },
        },
      },
    },
    matchedStatus: {
      display: 'inline-block',
      borderRadius: 16,
      border: '1px solid #ABEFC6',
      background: '#ECFDF3',
      color: '#067647',
      mixBlendMode: 'multiply',
      padding: '2px 8px',
    },
    newStatus: {
      display: 'inline-block',
      borderRadius: 16,
      border: '1px solid #E9D5FF',
      background: '#FAF5FF',
      color: '#7E22CE',
      mixBlendMode: 'multiply',
      padding: '2px 8px',
    },
    processingStatus: {
      display: 'inline-block',
      borderRadius: 16,
      border: '1px solid #B2DDFF',
      background: '#EFF8FF',
      color: '#175CD3',
      mixBlendMode: 'multiply',
      padding: '2px 8px',
    },
  })
);

const Request_Types = [
  {
    type: 'Matched',
    title: 'Add to Dashboard',
  },
  {
    type: 'New',
    title: 'Processing',
  },
];

interface ConfirmStepProps {
  data: SuggestedSuccessShowProps[];
}

export const ConfirmStep = ({ data }: ConfirmStepProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const [openedIndex, setOpenedIndex] = useState<number>(-1);

  const handleOpenContent = (index: number) => {
    if (openedIndex === index) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(index);
    }
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={textClasses.smBold} style={{ color: '#344054' }}>
          Confirm & Submit Request
        </Typography>
        <Typography
          className={textClasses.xsRegular}
          style={{ color: '#667085' }}
        >
          By clicking “Submit”, you will start processing all shows not already
          in the Barometer database. Progress indicators{' '}
          <RefreshIcon
            width={20}
            height={20}
            style={{ marginBottom: -6, marginLeft: 2, marginRight: 2 }}
          />{' '}
          will be on your dashboard for processing shows. You can review all
          your submitted requests in Settings.
        </Typography>
      </Box>
      {Request_Types.map((item, index) => {
        const shows = data.filter((show) => show.status === item.type);
        return (
          <Box key={item.type} className={classes.showList}>
            <Box className='header' onClick={() => handleOpenContent(index)}>
              <ArrowUpIcon
                cursor='pointer'
                style={
                  index === openedIndex ? {} : { transform: 'rotate(180deg)' }
                }
                onClick={() => handleOpenContent(index)}
              />
              <Typography className={textClasses.smBold}>
                {item.title}
              </Typography>
              <Box
                className={
                  item.type === 'Matched'
                    ? classes.matchedStatus
                    : classes.processingStatus
                }
              >
                <Typography
                  className={textClasses.xsMedium}
                  style={{ color: 'inherit' }}
                >
                  {shows.length} Shows
                </Typography>
              </Box>
            </Box>
            {index === openedIndex && (
              <Box className='content'>
                <TableContainer component={Paper}>
                  <Table className={classes.table}>
                    <TableHead
                      style={{
                        height: 48,
                      }}
                    >
                      <TableRow>
                        {['Show', 'RSS Feed', 'Status'].map(
                          (column: string) => (
                            <HeaderCell
                              key={column}
                              style={{
                                padding: '4px 10px',
                                borderRadius: 0,
                                borderBottom: 'none',
                                background: '#F8F8F8',
                              }}
                            >
                              <Typography
                                className={textClasses.smBold}
                                style={{
                                  color: '#475467',
                                }}
                              >
                                {column}
                              </Typography>
                            </HeaderCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shows.map((show, index) => (
                        <TableRow
                          key={index}
                          style={{
                            height: '48px',
                          }}
                        >
                          <TableCell
                            style={{
                              borderRight: '1px solid var(--gray-200, #EAECF0)',
                              width: '30%',
                            }}
                          >
                            <Typography
                              className={textClasses.xsRegular}
                              style={{
                                color: '#475467',
                              }}
                            >
                              {show.showName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={textClasses.xsRegular}
                              style={{
                                color: '#475467',
                              }}
                            >
                              {show.rss}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ width: '30%' }}>
                            {show.status === 'New' ? (
                              <Box className={classes.newStatus}>
                                <Typography
                                  className={textClasses.xsMedium}
                                  style={{ color: 'inherit' }}
                                >
                                  Net New
                                </Typography>
                              </Box>
                            ) : (
                              <Box className={classes.matchedStatus}>
                                <Typography
                                  className={textClasses.xsMedium}
                                  style={{ color: 'inherit' }}
                                >
                                  Add to Dashboard
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
