import { useQuery } from 'react-query';

import queryKeys from './queryKeys';
import { QueryStatus, Maybe, ApiUsagePeriodDto } from 'src/types';
import { baseAPICreator } from './apiCreator';

const apiUsageApi = baseAPICreator('/api-usage');

export const fetchLastApiUsagePeriod = async (
  organizationId: string
): Promise<ApiUsagePeriodDto> => {
  const { data } = await apiUsageApi.get(`/${organizationId}/latest`);
  return data;
};

export const useGetLastApiUsagePeriod = (organizationId: Maybe<string>) => {
  const { status, data, error, refetch } = useQuery(
    [queryKeys.GET_LAST_API_USAGE_PERIOD, organizationId],
    async () => {
      if (!organizationId) return null;
      const response = await fetchLastApiUsagePeriod(organizationId);
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    apiUsage: data,
    error,
    refetch,
  };
};
