import { FC } from 'react';
import { Typography, TypographyProps, withStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import InfoTooltip from 'src/components/InfoTooltip';
import { useLayoutStyles } from 'src/components/useCommonStyles';

export const ArticleInputLabel = withStyles({
  root: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
})((props: TypographyProps) => <Typography {...props} />);

interface ScoreShowLabelProps {
  label: string;
  tooltip?: string;
}

export const ScoreShowLabel: FC<ScoreShowLabelProps> = ({ label, tooltip }) => {
  const layoutClasses = useLayoutStyles();

  return (
    <Typography variant='body1'>
      {label}
      {tooltip && (
        <InfoTooltip className={layoutClasses.ml1} content={tooltip}>
          <InfoOutlined />
        </InfoTooltip>
      )}
    </Typography>
  );
};
