import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from 'src/theme/types/createPalette';

export const useFontStyles = makeStyles(() =>
  createStyles({
    fontBolder: {
      fontWeight: 700,
    },
    fontBold: {
      fontWeight: 600,
    },
    font500: {
      fontWeight: 500,
    },
    fontNormal: {
      fontWeight: 400,
    },
    noneTextDecoration: {
      textDecoration: 'none',
    },
  })
);

export const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    mb05: {
      marginBottom: theme.spacing(0.5),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb15: {
      marginBottom: theme.spacing(1.5),
    },
    mb2: {
      marginBottom: theme.spacing(2),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    mb4: {
      marginBottom: theme.spacing(4),
    },
    mb5: {
      marginBottom: theme.spacing(5),
    },
    mb6: {
      marginBottom: theme.spacing(6),
    },
    mb7: {
      marginBottom: theme.spacing(7),
    },
    mb8: {
      marginBottom: theme.spacing(8),
    },
    ml1: {
      marginLeft: theme.spacing(1),
    },
    ml15: {
      marginLeft: theme.spacing(1.5),
    },
    ml2: {
      marginLeft: theme.spacing(2),
    },
    ml3: {
      marginLeft: theme.spacing(3),
    },
    ml4: {
      marginLeft: theme.spacing(4),
    },
    ml6: {
      marginLeft: theme.spacing(6),
    },
    mr05: {
      marginRight: theme.spacing(0.5),
    },
    mr1: {
      marginRight: theme.spacing(1),
    },
    mr15: {
      marginRight: theme.spacing(1.5),
    },
    mr2: {
      marginRight: theme.spacing(2),
    },
    mr3: {
      marginRight: theme.spacing(3),
    },
    mr6: {
      marginRight: theme.spacing(6),
    },
    margin1: {
      marginBottom: theme.spacing(1),
    },
    margin2: {
      marginBottom: theme.spacing(2),
    },
    margin3: {
      marginBottom: theme.spacing(3),
    },
    mt05: {
      marginTop: theme.spacing(0.5),
    },
    mt1: {
      marginTop: theme.spacing(1),
    },
    mt2: {
      marginTop: theme.spacing(2),
    },
    mt3: {
      marginTop: theme.spacing(3),
    },
    mt4: {
      marginTop: theme.spacing(4),
    },
    mt5: {
      marginTop: theme.spacing(5),
    },
    mt6: {
      marginTop: theme.spacing(6),
    },
    mt7: {
      marginTop: theme.spacing(7),
    },
    mt8: {
      marginTop: theme.spacing(8),
    },
    pl1: {
      paddingLeft: theme.spacing(1),
    },
    pl2: {
      paddingLeft: theme.spacing(2),
    },
    pl3: {
      paddingLeft: theme.spacing(3),
    },
    pl4: {
      paddingLeft: theme.spacing(4),
    },
    pt1: {
      paddingTop: theme.spacing(1),
    },
    pt2: {
      paddingTop: theme.spacing(2),
    },
    pt3: {
      paddingTop: theme.spacing(3),
    },
    pt8: {
      paddingTop: theme.spacing(8),
    },
    pb0: {
      paddingBottom: theme.spacing(0),
    },
    pb1: {
      paddingBottom: theme.spacing(1),
    },
    pb2: {
      paddingBottom: theme.spacing(2),
    },
    pb3: {
      paddingBottom: theme.spacing(3),
    },
    padding1: {
      padding: theme.spacing(1),
    },
    padding2: {
      padding: theme.spacing(2),
    },
    padding3: {
      padding: theme.spacing(3),
    },
    padding10: {
      padding: theme.spacing(10),
    },
    noPadding: {
      padding: 0,
    },
    noMargin: {
      padding: 0,
    },
    fullHeight: {
      height: '100%',
    },
    borderRadius3: {
      borderRadius: theme.spacing(3),
    },
  })
);

export const useColorStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: '#ffffff',
    },
    black: {
      color: '#000',
    },
    primary: {
      color: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.main,
    },
    blue: {
      color: theme.palette.blue.main,
    },
    transparentBlue: {
      color: theme.palette.transparentBlue.main,
    },
    redText: {
      color: theme.palette.redText.main,
    },
    grayText: {
      color: theme.palette.grayText.main,
    },
    gray: {
      color: theme.palette.gray.main,
    },
    grayBackground: {
      color: theme.palette.grayBackground.main,
    },
    grey400: {
      color: theme.palette.grey400.main,
    },
    blackBackground: {
      color: theme.palette.blackBackground.main,
    },
    purple: {
      color: theme.palette.purple.main,
    },
    yellow: {
      color: theme.palette.yellow.main,
    },
    green: {
      color: theme.palette.green.main,
    },
    lightGray: {
      color: theme.palette.lightGray.main,
    },
  })
);

export const useBackgroundColorStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      backgroundColor: '#ffffff',
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
    },
    secondary: {
      backgroundColor: theme.palette.secondary.main,
    },
    blue: {
      backgroundColor: theme.palette.blue.main,
    },
    transparentBlue: {
      backgroundColor: theme.palette.transparentBlue.main,
    },
    redText: {
      backgroundColor: theme.palette.redText.main,
    },
    grayText: {
      backgroundColor: theme.palette.grayText.main,
    },
    gray: {
      backgroundColor: theme.palette.gray.main,
    },
    grayBackground: {
      backgroundColor: theme.palette.grayBackground.main,
    },
    lightGrayBackground: {
      backgroundColor: theme.palette.lightGrayBackground.main,
    },
    purple: {
      backgroundColor: theme.palette.purple.main,
    },
    yellow: {
      backgroundColor: theme.palette.yellow.main,
    },
    green: {
      backgroundColor: theme.palette.green.main,
    },
    lightGray: {
      backgroundColor: theme.palette.lightGray.main,
    },
  })
);
