import { useCallback, useState } from 'react';

const useToggle = (initialState = false): [boolean, (s?: boolean) => void] => {
  const [state, setState] = useState<boolean>(initialState);
  const toggle = useCallback(
    (s: any) => setState((state: boolean) => s || !state),
    []
  );

  return [state, toggle];
};

export default useToggle;
