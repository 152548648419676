import { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import Button from 'src/components/Button';
import useAuth from 'src/hooks/useAuth';
import { useGetOrganizationMembers } from 'src/apis';

import TeamMembers from './TeamMembers';
import InvitedMembers from './InvitedMembers';
import InviteNewMemberDialog from './InviteNewMemberDialog';

import { Theme } from 'src/theme/types/createPalette';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layout: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(2),
      overflow: 'auto',
    },
    container: {
      borderRadius: theme.spacing(1),
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      width: '100%',
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`team-tabpanel-${index}`}
      aria-labelledby={`team-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `team-tab-${index}`,
    'aria-controls': `team-tabpanel-${index}`,
  };
};

const TeamManagement: FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);
  const [showInviteNewDialog, setShowInviteNewDialog] = useState(false);
  const { teamMembers, invitedMembers, refetch } = useGetOrganizationMembers(
    user?.organizationId
  );

  const handleChange = (_: any, tab: number) => {
    setSelectedTab(tab);
  };

  const handleShowInviteNewDialog = () => {
    setShowInviteNewDialog(true);
  };

  const handleCloseInviteNewDialog = () => {
    setShowInviteNewDialog(false);
  };

  return (
    <Grid container className={classes.layout}>
      <Grid className={classes.container}>
        <Grid>
          <Grid container justifyContent='flex-end'>
            <Button
              color='primary'
              title='Invite new team members'
              onClick={handleShowInviteNewDialog}
            />
          </Grid>
        </Grid>
        <Grid>
          <Tabs
            value={selectedTab}
            textColor='primary'
            indicatorColor='primary'
            onChange={handleChange}
          >
            <Tab label='Team Members' {...a11yProps(0)} />
            <Tab label='New members invited' {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid>
          <TabPanel value={selectedTab} index={0}>
            <TeamMembers members={teamMembers} onRefetch={refetch} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <InvitedMembers members={invitedMembers} onRefetch={refetch} />
          </TabPanel>
        </Grid>

        <InviteNewMemberDialog
          isOpen={showInviteNewDialog}
          onClose={handleCloseInviteNewDialog}
          onRefetch={refetch}
        />
      </Grid>
    </Grid>
  );
};

export default TeamManagement;
