import { Grid, Slider, styled } from '@material-ui/core';
import React from 'react';

interface SelectionLabels {
  [x: number]: string;
}

interface SelectionMark {
  label?: string;
  value: number;
}

interface SelectionComponentProps {
  axisLabels?: SelectionLabels;
  icon?: boolean;
  onChange: (event: React.ChangeEvent<any>, n: number | number[]) => void;
  value?: number | number[];
}

interface RangeSelectionProps {
  onChange: (event: React.ChangeEvent<any>, n: number | number[]) => void;
  value?: number | number[];
}

const SELECTION_MARKS = [
  { value: 0 },
  { value: 1 },
  { value: 2 },
  { value: 3 },
];

const SliderContainer = styled(Slider)(({ theme }) => ({
  padding: `${theme.spacing(2.5)}px 0`,
  width: '100%',
  marginBottom: 0,
  '& .MuiSlider-track, & .MuiSlider-rail': {
    height: 3,
  },
  '& .MuiSlider-thumb': {
    height: 10,
    marginTop: -4,
    width: 10,
  },
  '& .MuiSlider-markLabel': {
    fontSize: 10,
    fontWeight: 500,
    marginTop: theme.spacing(0.7),
    '&[data-index="0"]': {
      left: '5% !important',
    },
    '&[data-index="4"]': {
      left: '93% !important',
    },
  },
}));

const GridContainer = styled(Grid)(() => ({
  height: '100%',
}));

const SelectionComponent = ({
  axisLabels,
  onChange,
  value,
}: SelectionComponentProps) => {
  const addLabelsToMarks = (): SelectionMark[] =>
    SELECTION_MARKS.map((mark: SelectionMark) => ({
      value: mark?.value,
      ...(axisLabels &&
        axisLabels[mark?.value] && { label: axisLabels[mark?.value] }),
    }));
  const marks = axisLabels ? addLabelsToMarks() : SELECTION_MARKS;

  return (
    <GridContainer
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
    >
      <SliderContainer
        marks={marks}
        onChange={onChange}
        valueLabelDisplay='off'
        step={1}
        max={3}
        min={0}
        value={value}
      />
    </GridContainer>
  );
};

const RangeSelection = ({
  onChange = () => {},
  value = [0, 3],
}: RangeSelectionProps): React.ReactElement => (
  <Grid item xs={12}>
    <SelectionComponent
      axisLabels={{ 0: 'No Risk', 1: 'Low', 2: 'Medium', 3: 'High' }}
      icon
      onChange={onChange}
      value={value}
    />
  </Grid>
);

export default RangeSelection;
