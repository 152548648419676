enum queryKeys {
  LIST_PAYMENT_METHODS = 'list-payment-methods',
  LIST_ARTICLES = 'list-articles',
  LIST_ORGANIZATIONS = 'list-organizations',
  LIST_INVOICES = 'list-invoices',
  LIST_HISTORIES = 'list-histories',
  GET_ARTICLES_METADATA = 'get-articles-metadata',
  GET_DOCUMENT_PAGES = 'get-document-pages',
  GET_ORGANIZATION = 'get-organization',
  GET_ORGANIZATION_MEMBERS = 'get-organization-members',
  GET_PDF_DOCUMENTS = 'get-pdf-documents',
  GET_LAST_API_USAGE_PERIOD = 'get-last-api-usage-period',
}

export default queryKeys;
