import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import { Dashboard } from 'src/types';

const useStyles = makeStyles((theme) =>
  createStyles({
    dashboardSelectionContainer: {
      marginBottom: theme.spacing(2),
    },

    nestedItems: {
      paddingLeft: theme.spacing(4),
    },

    itemRightSideIcon: {
      marginRight: '0 !important',
      '& svg': {
        marginLeft: 'auto',
      },
    },

    active: {
      backgroundColor: '#000',
    },
  }),
);

interface OrganizationDashboardSelectionProps {
  dashboardId: Nullable<string>;
  dashboards: Dashboard[];
  onChangeDashboard: (dashboard: Dashboard) => void;
  toggleDashboardSidebar: () => void;
  toggleFilterSidebar: () => void;
}

export default function CollapsibleDashboardList({
  dashboardId,
  dashboards,
  onChangeDashboard,
  toggleDashboardSidebar,
}: OrganizationDashboardSelectionProps) {
  const classes = useStyles();

  return (
    <List component='div' disablePadding>
      {dashboards.map((dashboard) => (
        <ListItem
          button
          className={classes.nestedItems}
          classes={{ selected: classes.active }}
          selected={dashboardId === dashboard.id}
          onClick={() => onChangeDashboard(dashboard)}
          key={dashboard.id}
        >
          <ListItemText
            primary={dashboard.name}
            primaryTypographyProps={{
              variant: 'caption',
            }}
          />
        </ListItem>
      ))}
      <ListItem
        button
        className={classes.nestedItems}
        onClick={toggleDashboardSidebar}
      >
        <ListItemText
          primary='All Dashboards'
          primaryTypographyProps={{ variant: 'caption' }}
        />
        <ListItemIcon className={classes.itemRightSideIcon}>
          <ArrowRightIcon
            style={{
              fontSize: 20,
              color: 'rgba(0, 0, 0, 0.54)',
            }}
          />
        </ListItemIcon>
      </ListItem>
    </List>
  );
}
