import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import config from './utils/config';
import { AuthProvider } from './hooks/useAuth';
import { SidebarProvider } from './hooks/useSidebar';
import { ViewportProvider } from './hooks/useViewport';
import Routes from './Routes';
import { EnvironmentProvider } from './hooks/useEnvironment';

const queryClient = new QueryClient();

const stripePromise = loadStripe(config.stripePublicKey);

ReactGA.initialize(config.gaTrackingId);

function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={3000}
    >
      <ViewportProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <EnvironmentProvider>
              <SidebarProvider>
                <Elements stripe={stripePromise}>
                  <Routes />
                </Elements>
              </SidebarProvider>
            </EnvironmentProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ViewportProvider>
    </SnackbarProvider>
  );
}

export default App;
