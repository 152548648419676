import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import UnauthorizedContainWrapper from './UnauthorizedContainWrapper';

import AuditReport from './AuditReport';
import BrandAuditCaseStudies from './BrandAuditCaseStudies';
import HowAuditCaseWorks from './HowAuditCaseWorks';

const useStyles = makeStyles((theme) =>
  createStyles({
    topMenuBar: {
      position: 'fixed',
      background: 'white',
      zIndex: 10,
    },
    mb15: {
      marginBottom: theme.spacing(15),
    },
  }),
);

export default function AuditScreen() {
  const classes = useStyles();

  return (
    <UnauthorizedContainWrapper>
      <Grid container className={classes.mb15}>
        <BrandAuditCaseStudies />
      </Grid>

      <Grid container className={classes.mb15}>
        <HowAuditCaseWorks />
      </Grid>

      <Grid container className={classes.mb15}>
        <AuditReport />
      </Grid>
    </UnauthorizedContainWrapper>
  );
}
