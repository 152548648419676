import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme } from 'src/theme/types/createPalette';

import APIKeys from './APIKeys';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layout: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
    },
    container: {
      marginBottom: 32,
    },
    header: {
      marginBottom: theme.spacing(2),
    },

    row: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
);

export default function AccountDetails() {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent='center'
      className={classes.layout}
    >
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <APIKeys />
        </Grid>
      </Grid>
    </Grid>
  );
}
