import { MouseEventHandler, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

import { ReactComponent as LogoIcon } from 'src/assets/landing/logo.svg';
import Button from 'src/components/Button';
import LinkButton from 'src/components/LinkButton';
import { ROUTES } from 'src/utils';
import Footer from './Footer';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: '#EFEDEB',
      width: '100%',
      fontFamily: 'Space Grotesk, Sans Serif',

      '& .MuiButton-text, & .MuiTypography-root, MuiInputBase-root, & .MuiInputBase-root ':
        {
          fontFamily: 'Space Grotesk, Sans Serif',
        },
    },
    menuContainer: {
      width: '100%',
      background:
        'linear-gradient(180deg, rgba(219, 213, 195, 0.54) 0%, rgba(219, 213, 195, 0) 100%)',

      '& > div': {
        width: '100%',
        maxWidth: 1600,
        height: 80,
        margin: 'auto',
        padding: '0 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 8,
        zIndex: 100,
        position: 'relative',

        '@media screen and (min-width: 768px)': {
          padding: '0 100px',
          height: 152,
        },
      },
    },
    mainContainer: {
      width: '100%',
      background: '#EFEDEB',
    },
    logo: {
      width: 88,
      marginRight: 'auto',

      '@media screen and (min-width: 768px)': {
        width: 125,
      },
    },
    linkButton: {
      display: 'none',
      color: '#545454',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      marginRight: 16,
      justifyContent: 'flex-start',

      '@media screen and (min-width: 768px)': {
        display: 'flex',
      },
    },
    scheduleDemoButton: {
      display: 'none',
      background: 'rgba(239, 237, 235, 0.32)',
      borderRadius: 2,
      color: '#202226',
      padding: '4px 16px 6px',
      height: 40,
      border: '2px solid',
      borderImageSlice: 1,
      borderImageSource:
        'linear-gradient(to right, #736FD4, #7570DF, #9873CB, #E571A9)',

      '@media screen and (min-width: 768px)': {
        display: 'flex',
      },

      '&::after': {
        borderRadius: 2,
      },

      '&:hover': {
        background: 'rgba(239, 237, 235, 0.32)',
      },

      '& > .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 45,
        color: '#202226',
        fontWeight: 500,
      },
    },
    menu: {
      '@media screen and (min-width: 768px)': {
        display: 'none',
      },
    },
  })
);

interface props {
  children: React.ReactNode;
}

const UnauthorizedContainWrapper: React.FC<props> = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleConnect: MouseEventHandler = async (event) => {
    event.preventDefault();
    handleMenuClose();
    const link = document.createElement('a');
    link.href = 'https://calendly.com/thebarometer/30min?month=2023-06';
    link.rel = 'noreferrer';
    link.target = '_blank';
    document.body.appendChild(link);
    await link.click();
    document.body.removeChild(link);
  };

  // temporarily hide the Spotlight page
  // const handleSpotlightRedirectToDemo = () => {
  //   if (window.location.host === 'www.thebarometer.co') {
  //     window.location.assign('https://app.thebarometer.co/spotlight');
  //   } else {
  //     handleMenuClose();
  //     history.push(ROUTES.SPOTLIGHT);
  //   }
  // };

  const handleRedirectToLogin = () => {
    if (window.location.host === 'www.thebarometer.co') {
      window.location.assign('https://app.thebarometer.co/login');
    } else {
      handleMenuClose();
      history.push(ROUTES.LOGIN);
    }
  };

  const handleRedirectToTeams = () => {
    handleMenuClose();
    history.push(ROUTES.TEAM);
  };

  return (
    <div className={classes.container}>
      <div className={classes.menuContainer}>
        <div>
          <LogoIcon
            className={classes.logo}
            cursor='pointer'
            onClick={() => {
              history.push(ROUTES.LANDING);
            }}
          />
          {/** temporarily hide the Spotlight page */}
          {/* <Button
            color='secondary'
            variant='text'
            className={classes.linkButton}
            onClick={handleSpotlightRedirectToDemo}
          >
            Spotlight
          </Button> */}
          <LinkButton
            color='secondary'
            title='Blog'
            to={ROUTES.BLOG}
            variant='text'
            className={classes.linkButton}
          />
          <LinkButton
            color='secondary'
            title='Our Mission'
            to={ROUTES.TEAM}
            variant='text'
            className={classes.linkButton}
          />
          <Button
            color='secondary'
            variant='text'
            className={classes.linkButton}
            style={{ marginRight: 50 }}
            onClick={handleRedirectToLogin}
          >
            Login
          </Button>
          <Button
            className={classes.scheduleDemoButton}
            color='primary'
            onClick={handleConnect}
          >
            <span>Let’s connect</span>
            <span>→</span>
          </Button>
          <Button
            aria-controls='simple-menu'
            aria-haspopup='true'
            variant='text'
            className={classes.menu}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {/** temporarily hide the Spotlight page */}
            {/* <MenuItem onClick={handleSpotlightRedirectToDemo}>
              Spotlight
            </MenuItem> */}
            <MenuItem onClick={handleRedirectToTeams}>Our Mission</MenuItem>
            <MenuItem onClick={handleRedirectToLogin}>Login</MenuItem>
            <MenuItem onClick={handleConnect}>Let’s connect →</MenuItem>
          </Menu>
        </div>
      </div>

      <div className={classes.mainContainer}>{children}</div>

      <Footer />
    </div>
  );
};

export default UnauthorizedContainWrapper;
