import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Sans Serif',
    fontWeightRegular: 450,
    fontWeightMedium: 600,
    fontWeightBold: 750,
    fontSize: 16,
    h1: {
      fontWeight: 'bold',
      fontSize: 56,
      lineHeight: 1.15,
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 44,
      lineHeight: 1.1,
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 36,
      lineHeight: 1.1,
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 22,
      lineHeight: 1.2,
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
    },
  },
  palette: {
    background: {
      default: '#F5F7FA',
    },
    primary: {
      main: '#3E91DE', // barometer blue
    },
    secondary: {
      main: '#F2F6FF', // barometer bright blue
    },
    blue: {
      main: '#2986D2', // barometer blue
    },
    transparentBlue: {
      main: 'rgba(48, 134, 220, 0.1)',
    },
    redText: {
      main: '#CB1223', // barometer warning text
    },
    grayText: {
      main: '#6B7280',
    },
    gray: {
      main: '#727272',
    },
    grayBackground: {
      main: '#EEEEEE',
    },
    grey400: {
      main: '#9CA3AF',
    },
    blackBackground: {
      main: '#1F2937',
    },
    lightGrayBackground: {
      main: '#F9FAFB',
    },
    purple: {
      main: '#CC00CC',
    },
    yellow: {
      main: '#FFFF00',
    },
    green: {
      main: '#00CC00',
    },
    lightGray: {
      main: '#CCCCCC',
    },
    purpleBackground: {
      main: '#AE89FE',
    },
    orange: {
      main: '#F88600',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
    },
    sliderRed: {
      main: '#DC5E8A',
    },
    sliderBlue: {
      main: '#4A8BF8',
    },
    sliderYellow: {
      main: '#FCD9A7',
    },
  },
  overrides: {
    MuiFormControl: { marginNormal: { marginTop: 0, marginBottom: 0 } },
    MuiCssBaseline: {
      '@global': {
        'html, body': {
          height: '100%',
        },
      },
    },
  },
});

export default theme;
