import { FC, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Typography, TextField } from '@material-ui/core';

import Button from 'src/components/Button';
import { useCommonStyles } from '../onboarding-redesign/utils/useCommonStyles';
import { sendResetPasswordCodeLink } from 'src/apis';
import AuthLayout from './AuthLayout';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '100%',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '32px',
        color: '#545454',

        '@media screen and (min-width: 768px)': {
          fontSize: 25,
          lineHeight: '40px',
        },
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: '#545454',
      },
    },
    backBtn: {
      position: 'absolute',
      width: 'auto',
      fontWeight: 400,
      top: 45,
      left: 16,
      padding: 0,
      justifyContent: 'flex-start',
      color: '#545454',

      '@media screen and (min-width: 768px)': {
        left: 'calc(50% - 287px)',
        top: 38,
      },

      '& .MuiInput-underline:before': {
        top: 0,
      },
    },
    link: {
      color: '#A172D7',
      width: 'auto',
      height: 'auto',
      padding: 0,
    },
    inputsContainer: {
      marginTop: 52,
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    signup: {
      marginTop: 24,
      display: 'flex',
      alignItems: 'center',
    },
    loginBtn: {
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      borderRadius: 2,
      padding: '6px 16px',
      height: 40,
      marginTop: 52,

      '&:hover': {
        background:
          'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      },

      '& > .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 45,
        fontSize: 16,
        lineHeight: '24px',
        color: '#EFEDEB',
        fontWeight: 500,
      },
    },
  })
);

const ForgotPasswordScreen: FC = () => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const [email, setEmail] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();

    try {
      setLoading(true);
      await sendResetPasswordCodeLink(email);
      submitMetrics(METRICS_TYPE.SEND_RESET_PASSWORD_CODE);
      setLinkSent(true);
    } catch {
      setLoading(false);
    }
  };

  return (
    <AuthLayout secondaryBack>
      <form onSubmit={handleSubmit}>
        <Typography className={commonStyles.headline1}>
          Reset my password
        </Typography>
        <Typography className={commonStyles.body1}>
          {linkSent
            ? 'You have requested a password reset for the Barometer account and you should have received a link shortly. Please click on the link to reset your password.'
            : 'Please enter the email address you used to sign up for Barometer. If an account exists, you will receive an email with next steps.'}
        </Typography>
        <div className={classes.inputsContainer}>
          <TextField
            id='email'
            placeholder='Email'
            className={classes.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Button
            className={classes.loginBtn}
            color='primary'
            type='submit'
            disabled={loading}
          >
            <span>Send password reset link</span>
            <span>→</span>
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ForgotPasswordScreen;
