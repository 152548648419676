import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { useTextStyles } from 'src/hooks/useTextStyles';
import { RiskLevel } from 'src/types/filter';

const useStyles = makeStyles(() =>
  createStyles({
    selection: {
      border: '1px solid #C3C3C7',
      borderRadius: 5,
      padding: 4,
      paddingLeft: 8,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      minWidth: 80,

      '& .MuiSelect-root': {
        padding: 0,
        paddingRight: 24,
      },

      '& .MuiSvgIcon-root': {
        marginTop: -2,
      },

      '&:before, &:after': {
        border: 'none !important',
      },
    },
  })
);

interface RiskLevelSelectProps {
  riskLevel: RiskLevel;
  setRiskLevel: (riskLevel: RiskLevel) => void;
  className?: string;
}

export const RiskLevelSelect = ({
  riskLevel,
  setRiskLevel,
  className,
}: RiskLevelSelectProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const handleChange = (event: any) => {
    setRiskLevel(event.target.value);
  };

  return (
    <Select
      labelId='risk-level-select-label'
      id='risk-level-select'
      value={riskLevel || ''}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      className={classNames(className, classes.selection)}
    >
      {Object.keys(RiskLevel).map((item) => (
        <MenuItem
          key={item}
          value={item}
          className={textClasses.xsRegular}
          style={{ padding: '4px 8px' }}
        >
          {/* @ts-ignore */}
          {item === 'No' ? 'None' : item}
        </MenuItem>
      ))}
    </Select>
  );
};
