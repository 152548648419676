import { useState, useEffect, useCallback, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Button,
  Select,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';

import Spinner from 'src/components/Spinner';
import {
  TableWrapper,
  HeaderCell,
} from 'src/containers/dashboard/garm/components';
import { ReactComponent as ColumnsIcon } from 'src/assets/dashboard/tableColumns.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/dashboard/logo.svg';
import { useDashboardStyles } from 'src/containers/dashboard/styles';
import { FilterActions } from './FilterActions';
import { BrandCustomFilter } from 'src/types/filter';
import useAuth from 'src/hooks/useAuth';
import { getGarmDashboards } from 'src/utils';

import { FilterDetailsPage } from './FilterDetailsPage';
import {
  getAllExceptionFilters,
  deleteExceptionFilter,
} from 'src/apis/exception';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      padding: 24,
      gap: 16,
      background: '#fff',
      flexWrap: 'nowrap',
    },
    title: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px',
      color: '#344054',
    },
    createNew: {
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      padding: '8px 16px',
      textTransform: 'capitalize',
    },
    searchBar: {
      width: 320,
      border: '1px solid #C3C3C7',
      padding: '2px 8px',
      borderRadius: 36,

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
    viewSelection: {
      width: 135,
      height: 36,
      border: '1px solid #C3C3C7',
      borderRadius: 8,
      padding: '0 16px',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      marginLeft: 8,

      '& .MuiSvgIcon-root': {
        marginTop: -2,
      },

      '&:before, &:after': {
        border: 'none !important',
      },

      '& .MuiSelect-selectMenu': {
        padding: 0,
        paddingRight: 0,
      },
    },
    usedDashboard: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    },
  })
);

export const BrandStandardFilters = () => {
  const classes = useStyles();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const createEnv = searchParams.get('create');

  const commonClasses = useDashboardStyles();
  const [search, setSearch] = useState<string>('');
  const [sort, setSort] = useState<string>('Most Recent');
  const [fetching, setFetching] = useState<boolean>(false);
  const [filters, setFilters] = useState<BrandCustomFilter[]>([]);
  const [currentFilter, setCurrentFilter] = useState<BrandCustomFilter | null>(
    null
  );

  const { organization } = useAuth();
  const garmDashboards = useMemo(
    () => (organization ? getGarmDashboards(organization) : []),
    [organization]
  );

  const handleBack = () => {
    setCurrentFilter(null);
    fetchFilters();
    if (createEnv === 'true') {
      history.push('/settings');
    }
  };

  const handleCreateNewFilter = () => {
    setCurrentFilter({
      id: '',
      name: 'New Saved Custom Filter',
      description: '',
      data: {},
      dashboardIds: [],
    });
  };

  const fetchFilters = useCallback(async () => {
    setFetching(true);
    try {
      const data = await getAllExceptionFilters();
      setFilters(data);
    } finally {
      setFetching(false);
    }
  }, []);

  const handleRemove = (id: string) => {
    deleteExceptionFilter(id).then(() => {
      const temp = [...filters];
      setFilters(temp.filter((item) => item.id !== id));
    });
  };

  const handleDuplicate = (filter: BrandCustomFilter) => {
    setCurrentFilter({
      ...filter,
      id: '',
      dashboardIds: [],
      name: 'Copy of ' + filter.name,
    });
  };

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    if (createEnv === 'true') {
      handleCreateNewFilter();
    }
  }, [createEnv]);

  if (currentFilter) {
    return (
      <FilterDetailsPage
        filter={currentFilter}
        handleDuplicate={() => handleDuplicate(currentFilter)}
        handleBack={handleBack}
      />
    );
  }

  return (
    <Grid container direction='column' className={classes.container}>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography className={classes.title}>
          Brand Standard Filters
        </Typography>
        <Button
          className={classes.createNew}
          color='primary'
          disableElevation
          variant='contained'
          onClick={handleCreateNewFilter}
        >
          Create New
        </Button>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <TextField
          InputLabelProps={{
            shrink: true,
            style: { fontWeight: 'normal' },
          }}
          InputProps={{
            placeholder: 'Search brand standard filters...',
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon htmlColor='#858591' style={{ fontSize: 20 }} />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className={classes.searchBar}
        />
        <Grid item>
          <Grid container direction='row' alignItems='center'>
            <Typography>Sort:</Typography>
            <Select
              labelId='filter-sort-select-label'
              id='filter-sort-select'
              value={sort || ''}
              onChange={(e) => setSort(e.target.value as string)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              className={classes.viewSelection}
            >
              {['Most Recent'].map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  className={commonClasses.textXSRegular}
                  style={{ padding: '4px 8px' }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ flex: 1 }}>
        <TableContainer
          component={Paper}
          style={{
            overflowX: 'scroll',
            boxShadow: '0px 0px 0px 1px #E0E0E0',
            borderRadius: 4,
            flex: 1,
            flexGrow: 1,
          }}
        >
          <TableWrapper
            stickyHeader
            style={{
              borderRadius: 4,
              height: '100%',
              borderCollapse: 'collapse',
            }}
          >
            <TableHead
              style={{
                position: 'sticky',
                top: -1,
                zIndex: 2,
              }}
            >
              <TableRow>
                {['Filter Name', 'Description', 'Used On'].map(
                  (column: string) => (
                    <HeaderCell
                      key={column}
                      style={{
                        padding: '4px 10px',
                        borderRadius: 0,
                        border: '1px solid #F0F0F1',
                        borderBottom: 'none',
                        background: '#F8F8F8',
                      }}
                    >
                      <span>{column}</span>
                    </HeaderCell>
                  )
                )}
                <HeaderCell
                  align='center'
                  style={{
                    padding: '4px 10px',
                    borderRadius: 0,
                    border: '1px solid #F0F0F1',
                    borderBottom: 'none',
                    background: '#F8F8F8',
                  }}
                >
                  <ColumnsIcon style={{ marginTop: 8 }} />
                </HeaderCell>
              </TableRow>
            </TableHead>
            {fetching ? (
              <TableRow style={{ height: '100%', border: 'none' }}>
                <TableCell colSpan={4}>
                  <Spinner />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {filters
                  .filter((filter) =>
                    filter.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((filter) => (
                    <TableRow
                      key={filter.id}
                      style={{
                        minHeight: '48px',
                        borderColor: '#E1E1E1',
                      }}
                    >
                      <TableCell>
                        <Typography
                          variant='caption'
                          align='left'
                          style={{
                            fontSize: 14,
                            lineHeight: '20px',
                            color: '#5EA1F2',
                            cursor: 'pointer',
                          }}
                          onClick={() => setCurrentFilter(filter)}
                        >
                          {filter.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant='caption'
                          align='left'
                          style={{
                            fontSize: 14,
                            lineHeight: '20px',
                            color: '#2D2D38',
                          }}
                        >
                          {filter.description}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          gap: 8,
                          height: '100%',
                        }}
                      >
                        {filter.dashboardIds.map((dashboardId) => (
                          <div
                            key={dashboardId}
                            className={classes.usedDashboard}
                          >
                            <DashboardIcon />
                            <Typography
                              variant='caption'
                              align='left'
                              style={{
                                fontSize: 14,
                                lineHeight: '20px',
                                color: '#5EA1F2',
                              }}
                            >
                              {
                                garmDashboards.filter(
                                  (item) => item.id === dashboardId
                                )[0]?.name
                              }
                            </Typography>
                          </div>
                        ))}
                      </TableCell>
                      <TableCell>
                        <FilterActions
                          handleDuplicate={() => handleDuplicate(filter)}
                          handleRemove={() => handleRemove(filter.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow style={{ height: '100%', border: 'none' }}>
                  <TableCell />
                </TableRow>
              </TableBody>
            )}
          </TableWrapper>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
