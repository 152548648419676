import clsx from 'clsx';

import MaterialButton from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

type ButtonVariantType = 'text' | 'outlined' | 'contained';

interface StyleProps {
  height?: string | number;
  width?: string | number;
}

interface ButtonProps extends StyleProps {
  title?: string | JSX.Element;
  padding?: number;
  variant?: ButtonVariantType;
  fontSize?: 'sm' | 'md' | 'lg';
  className?: string;
  [key: string]: any;
}

export default function Button({
  title,
  height,
  width,
  variant = 'contained',
  fontSize = 'md',
  className,
  children,
  ...props
}: ButtonProps) {
  const classes = useStyles({ height, width });

  return (
    <MaterialButton
      variant={variant}
      disableElevation
      disableRipple
      className={clsx(
        className,
        classes.button,
        fontSize === 'sm' && classes.fontSizeSmall,
        fontSize === 'md' && classes.fontSizeNormal,
        fontSize === 'lg' && classes.fontSizeLarge,
        variant === 'contained' && classes.contained,
        variant === 'outlined' && classes.outlined,
        variant === 'text' && classes.text
      )}
      {...props}
    >
      {title}
      {children}
    </MaterialButton>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      borderRadius: 8,
      textTransform: 'none',
      height: (style: StyleProps) => style.height || 48,
      width: (style: StyleProps) => style.width,
    },
    fontSizeSmall: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
    },
    fontSizeNormal: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    fontSizeLarge: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '30px',
    },
    contained: {
      border: '1px solid #5EA1F2',
      background: '#5EA1F2',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

      '&:hover': {
        background: '#6EB1FF',
        borderColor: '#6EB1FF',
      },

      '& > span': {
        color: '#FFF',
      },
    },
    outlined: {
      border: '1px solid #D0D5DD',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

      '& > span': {
        color: '#344054',
      },
    },
    text: {
      color: '#5EA1F2',
      padding: 0,
    },
  })
);
