import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useViewport } from 'src/hooks/useViewport';
import { useColorStyles, useFontStyles } from 'src/components/useCommonStyles';

const BrandAuditCaseStudies = () => {
  const { isMobile } = useViewport();
  const fontClasses = useFontStyles();
  const colorClasses = useColorStyles();

  return (
    <Grid container spacing={isMobile ? 3 : 6}>
      <Grid item xs={12}>
        <Typography variant='h2'>Brand Audit Case Studies</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant='h6'
          className={clsx(fontClasses.fontNormal, colorClasses.grayText)}
        >
          We have developed an automated brand-auditing tool which reports on
          biases present in brands’ policy materials, employee feedback and news
          about the brand. Like spell-check for cancel culture, Barometer helps
          brands manage risk and ensure compliance with brand safety standards.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BrandAuditCaseStudies;
