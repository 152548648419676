import { useCallback } from 'react';

import { toggleFavoriteDashboard } from 'src/apis';
import useAuth from 'src/hooks/useAuth';
import useNotification from 'src/hooks/useNotification';

export const useChangeFavoriteDashboard = (refetch = true) => {
  const { organization, fetchOrganization } = useAuth();

  const { handleSuccess, handleError } = useNotification();

  const handleChangeFavoriteDashboard = useCallback(
    async (dashboardId: string, favorite: boolean) => {
      if (!organization?.apiKey) return;

      try {
        await toggleFavoriteDashboard(
          organization.apiKey,
          dashboardId,
          favorite
        );
        handleSuccess('Successfully updated the dashboard favorite status.');

        if (refetch) {
          await fetchOrganization();
        }
      } catch (error) {
        handleError(
          error,
          'There was an error while updating dashboard favorite status.'
        );
      }
    },
    [organization, refetch, fetchOrganization, handleError, handleSuccess]
  );

  return {
    onChangeFavoriteDashboard: handleChangeFavoriteDashboard,
  };
};
