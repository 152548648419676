import { FC, useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  Collapse,
  styled,
  Typography,
  createStyles,
  makeStyles,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from '@material-ui/icons/Search';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import Button from 'src/components/Button';
import { Dashboard } from 'src/types';
import { Theme } from 'src/theme/types/createPalette';
import { useUpdateDashboard } from 'src/containers/dashboard/hooks';

import { DashboardItem } from './DashboardItem';
import { DashboardFolder } from './DashboardFolder';

export const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      height: 'calc(100vh - 50px)',
    },
    mainContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      maxHeight: '100%',
      overflow: 'auto',
    },
    addBtn: {
      background: '#ffffff',
      color: '#000000',
      border: '1px solid #000000',
      fontWeight: 'normal',
      fontSize: 16,
    },
    collapseContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: '20px 12px',
      paddingRight: 0,
    },
    collapseTitle: {
      fontSize: 15,
      fontWeight: 500,
      flex: 1,
    },
    collapseCounter: {
      border: '1px solid #000000',
      borderRadius: 2,
      fontSize: 12,
      lineHeight: '12px',
      padding: 4,
      marginRight: 8,
    },
    searchBar: {
      width: '100%',
      border: '1px solid #C3C3C7',
      padding: '2px 8px',
      borderRadius: 36,

      '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
        border: 'none !important',
      },
    },
  })
);

export const DashboardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 20,
  lineHeight: '24px',
  fontWeight: 500,
}));

export const ListContainer = styled(List)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

export const ListItemContainer = styled(ListItem)(() => ({
  display: 'flex',
  flex: 1,
  gap: 20,

  '& .MuiListItemText-primary': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
  },
  '& .MuiListItemText-secondary': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
  },
}));

interface DashboardListProps {
  selectedDashboardId: string | null;
  dashboards: Dashboard[];
  onChangeFavorite: (dashboardId: string, favorite: boolean) => void;
  onSelectDashboard: (dashboard: Dashboard) => void;
}

const DashboardList: FC<DashboardListProps> = ({
  dashboards,
  selectedDashboardId,
  onSelectDashboard,
}) => {
  const classes = useStyles();
  const [openUncategorized, setOpenUncategorized] = useState<boolean>(true);
  const [openFolders, setOpenFolders] = useState<boolean>(true);
  const [openRecentlyViewed, setOpenRecentlyViewed] = useState<boolean>(true);
  const { onUpdateMultiDashboards } = useUpdateDashboard();
  const [uncategoriedDashbords, setUncategoriedDashboards] = useState<
    Dashboard[]
  >([]);
  const [folders, setFolders] = useState<Record<string, Dashboard[]>>({});
  const [activeDashboard, setActiveDashboard] = useState<Dashboard | null>(
    null
  );
  const [searchText, setSearchText] = useState<string>('');

  // const handleClickFavorite = (event: MouseEvent, dashboard: Dashboard) => {
  //   event.stopPropagation();
  //   onChangeFavorite(dashboard.id, !dashboard.favorite);
  // };

  useEffect(() => {
    const newUncategoriedDashbords: Dashboard[] = [];
    const newFolders: Record<string, Dashboard[]> = {};
    dashboards.forEach((item: Dashboard) => {
      if (item.folder) {
        if (newFolders[item.folder]) {
          newFolders[item.folder] = [...newFolders[item.folder], item];
        } else {
          newFolders[item.folder] = [item];
        }
      } else {
        newUncategoriedDashbords.push(item);
      }
    });
    setFolders(newFolders);
    setUncategoriedDashboards(newUncategoriedDashbords);
  }, [dashboards]);

  useEffect(() => {
    if (selectedDashboardId) {
      const dashboard = dashboards.find(
        (item) => item.id === selectedDashboardId
      );
      setActiveDashboard(dashboard || dashboards[0]);
    } else {
      setActiveDashboard(dashboards[0]);
    }
  }, [dashboards, selectedDashboardId]);

  const handleNewFolder = () => {
    let folderName = 'New Folder';
    for (let index = 0; index < 10000; index++) {
      if (folders[folderName]) {
        folderName = `New Folder ${index + 1}`;
      } else {
        break;
      }
    }
    const newFolders: Record<string, Dashboard[]> = {
      ...folders,
    };
    newFolders[folderName] = [];
    setFolders(newFolders);
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination || !source) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      return;
    }

    let dragItem: Dashboard | null = null;
    const tempUncategoriedDashbords = [...uncategoriedDashbords];
    const tempFolders = { ...folders };

    if (source.droppableId === 'Uncategorized Dashboards') {
      dragItem = tempUncategoriedDashbords[source.index];
      tempUncategoriedDashbords.splice(source.index, 1);
    } else {
      dragItem = folders[source.droppableId][source.index];
      const temp = [...tempFolders[source.droppableId]];
      temp.splice(source.index, 1);
      tempFolders[source.droppableId] = temp;
    }

    if (destination.droppableId === 'Uncategorized Dashboards') {
      tempUncategoriedDashbords.push(dragItem);
    } else {
      tempFolders[destination.droppableId] = [
        ...tempFolders[destination.droppableId],
        dragItem,
      ];
    }

    setUncategoriedDashboards(tempUncategoriedDashbords);
    setFolders(tempFolders);
    onUpdateMultiDashboards([
      {
        dashboardId: dragItem.id,
        data: {
          name: dragItem.name,
          type: dragItem.type,
          keywordsList: dragItem.keywordsList,
          folder:
            destination.droppableId === 'Uncategorized Dashboards'
              ? null
              : destination.droppableId,
          exceptionFilterId: dragItem.exceptionFilterId,
        },
      },
    ]);
  };

  const handleDeleteFolder = (folderName: string) => {
    const folderDashboards = folders[folderName].map((item) => ({
      dashboardId: item.id,
      data: {
        name: item.name,
        type: item.type,
        keywordsList: item.keywordsList,
        folder: null,
        exceptionFilterId: item.exceptionFilterId,
      },
    }));
    const tempFolders = { ...folders };
    setUncategoriedDashboards([
      ...uncategoriedDashbords,
      ...tempFolders[folderName],
    ]);
    delete tempFolders[folderName];
    setFolders(tempFolders);
    onUpdateMultiDashboards(folderDashboards);
  };

  const handleRenameFolder = (folderName: string, newName: string) => {
    const folderDashboards = folders[folderName].map((item) => ({
      dashboardId: item.id,
      data: {
        name: item.name,
        type: item.type,
        keywordsList: item.keywordsList,
        folder: newName,
        exceptionFilterId: item.exceptionFilterId,
      },
    }));
    const tempFolders = { ...folders };
    tempFolders[newName] = tempFolders[folderName];
    delete tempFolders[folderName];
    setFolders(tempFolders);
    if (folderDashboards.length) {
      onUpdateMultiDashboards(folderDashboards);
    }
  };

  const handleChangeSearchText = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event.target.value);
  };

  return (
    <div className={classes.container}>
      <DashboardTitle variant='h5'>Dashboards</DashboardTitle>
      <TextField
        InputLabelProps={{
          shrink: true,
          style: { fontWeight: 'normal' },
        }}
        InputProps={{
          placeholder: 'Search all dashboards...',
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon htmlColor='#858591' style={{ fontSize: 20 }} />
            </InputAdornment>
          ),
        }}
        value={searchText}
        onChange={handleChangeSearchText}
        className={classes.searchBar}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={classes.mainContent}>
          <Grid container>
            <Grid
              container
              item
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              onClick={() => {
                setOpenRecentlyViewed(!openRecentlyViewed);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Typography className={classes.collapseTitle}>
                Recently Viewed
              </Typography>
              {openRecentlyViewed ? (
                <KeyboardArrowUpIcon style={{ fontSize: 18 }} />
              ) : (
                <KeyboardArrowDownIcon style={{ fontSize: 18 }} />
              )}
            </Grid>
            <Collapse
              in={openRecentlyViewed}
              timeout='auto'
              unmountOnExit
              style={{ width: '100%' }}
            >
              <Grid container className={classes.collapseContainer}>
                {activeDashboard && (
                  <DashboardItem
                    key={activeDashboard.id}
                    index={0}
                    dashboard={activeDashboard}
                    noDraggable
                    onSelectDashboard={onSelectDashboard}
                  />
                )}
              </Grid>
            </Collapse>
          </Grid>
          <Grid container>
            <Grid
              container
              item
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              onClick={() => {
                setOpenFolders(!openFolders);
              }}
              style={{ cursor: 'pointer' }}
            >
              <Typography className={classes.collapseTitle}>Folders</Typography>
              <Typography className={classes.collapseCounter}>
                {Object.keys(folders).length}
              </Typography>
              {openFolders ? (
                <KeyboardArrowUpIcon style={{ fontSize: 18 }} />
              ) : (
                <KeyboardArrowDownIcon style={{ fontSize: 18 }} />
              )}
            </Grid>
            <Collapse
              in={openFolders}
              timeout='auto'
              unmountOnExit
              style={{ width: '100%' }}
            >
              <Grid container className={classes.collapseContainer}>
                {Object.keys(folders).map((key) => (
                  <DashboardFolder
                    key={key}
                    name={key}
                    dashboards={folders[key].filter((item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchText.trim().toLowerCase())
                    )}
                    onDeleteFolder={handleDeleteFolder}
                    onRenameFolder={handleRenameFolder}
                    onSelectDashboard={onSelectDashboard}
                  />
                ))}
              </Grid>
            </Collapse>
          </Grid>
          <Droppable droppableId='Uncategorized Dashboards'>
            {(provided) => (
              <Grid
                container
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Grid
                  container
                  item
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  onClick={() => {
                    setOpenUncategorized(!openUncategorized);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography className={classes.collapseTitle}>
                    Uncategorized Dashboards
                  </Typography>
                  <Typography className={classes.collapseCounter}>
                    {
                      uncategoriedDashbords.filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchText.trim().toLowerCase())
                      ).length
                    }
                  </Typography>
                  {openUncategorized ? (
                    <KeyboardArrowUpIcon style={{ fontSize: 18 }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ fontSize: 18 }} />
                  )}
                </Grid>
                <Collapse
                  in={openUncategorized}
                  timeout='auto'
                  unmountOnExit
                  style={{ width: '100%' }}
                >
                  <Grid container className={classes.collapseContainer}>
                    {uncategoriedDashbords
                      .filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchText.trim().toLowerCase())
                      )
                      .map((dashboard, index) => (
                        <DashboardItem
                          key={dashboard.id}
                          index={index}
                          dashboard={dashboard}
                          onSelectDashboard={onSelectDashboard}
                        />
                      ))}
                  </Grid>
                  {provided.placeholder}
                </Collapse>
              </Grid>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <Button
        title='+ Add new folder'
        color='primary'
        fontSize='sm'
        height={36}
        width='100%'
        className={classes.addBtn}
        onClick={handleNewFolder}
      />
    </div>
  );
};

export default DashboardList;
