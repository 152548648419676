import { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  styled,
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Theme } from 'src/theme/types/createPalette';
import { getUSElectionColor } from 'src/containers/dashboard/garm/common';
import GarmScoreProgress from './GarmScoreProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      rowGap: 25,
      minHeight: 'calc(100vh - 70px)',
    },
    accordionContent: {
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    linkTextContainer: {
      position: 'fixed',
      bottom: theme.spacing(2),
    },
    linkText: {
      color: theme.palette.grayText.main,
      fontStyle: 'italic',
    },
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface USElectionsAccordionProps {
  percentage: number;
  sources: any[];
  utterances: any[];
}

const USElectionsAccordion = ({
  percentage,
  sources,
  utterances,
}: USElectionsAccordionProps) => {
  const classes = useStyles();
  const [keyStatus, setKeyStatus] = useState<any>(null);

  const clickKeywork = (key: string) => {
    const elements = Array.from(document.querySelectorAll('mark'));

    const matches: any = elements.filter((el: any) => {
      if (el?.textContent.length > key.length + 2) {
        return el?.textContent.toLowerCase().includes(key.toLowerCase());
      }
      return el?.textContent.toLowerCase() === key.toLowerCase();
    });
    let index: number =
      keyStatus && keyStatus.key === key ? keyStatus.index + 1 : 0;

    if (matches && matches.length) {
      if (index >= matches.length) {
        index = 0;
      }
      setKeyStatus({
        key,
        index,
      });
      matches[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const renderAccordionDetails = (sources: any, utterances: any) => {
    return (
      <AccordionDetails style={{ marginTop: 10, padding: 0, border: 'none' }}>
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table size='small' style={{ border: 'none' }}>
            <TableHead>
              <TableRow>
                {['Name', 'Count', 'Sentiment'].map((header) => (
                  <TableCell key={header}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sources.map((row: any, index: number) => (
                <StyledTableRow key={index}>
                  <TableCell
                    style={{
                      border: 'none',
                      fontSize: 12,
                      fontWeight: 'normal',
                      cursor: 'pointer',
                    }}
                    onClick={() => clickKeywork(row.name)}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{
                      border: 'none',
                      fontSize: 12,
                      fontWeight: 'normal',
                    }}
                  >
                    {row.count}
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{
                      border: 'none',
                      fontSize: 12,
                      fontWeight: 'normal',
                    }}
                  >
                    {row.sentiment}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table size='small' style={{ border: 'none' }}>
            <TableHead>
              <TableRow>
                {['Party Valance', '% of Election-Focused Utterance'].map(
                  (header) => (
                    <TableCell key={header}>{header}</TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {utterances.map((utterance: any, index: number) => (
                <StyledTableRow key={index}>
                  <TableCell
                    style={{
                      border: 'none',
                      fontSize: 12,
                      fontWeight: 'normal',
                      cursor: 'pointer',
                      minWidth: 130,
                    }}
                  >
                    {utterance.title}
                  </TableCell>
                  <TableCell
                    align='center'
                    style={{
                      border: 'none',
                      fontSize: 12,
                      fontWeight: 'normal',
                      minWidth: 240,
                    }}
                  >
                    {utterance.value}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    );
  };

  return (
    <Grid
      alignItems='center'
      item
      xs={12}
      style={{
        padding: '4px 0px',
      }}
    >
      <Accordion
        square={true}
        style={{
          border: 'none',
          boxShadow: 'none',
          padding: 0,
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          style={{
            padding: 0,
            margin: 0,
          }}
          className={classes.accordionContent}
        >
          <Grid alignItems='center' container xs={12}>
            <Grid
              key={'column.field'}
              item
              xs={7}
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
            >
              <Typography
                style={{
                  fontWeight: 'normal',
                  flex: 1,
                  fontSize: 18,
                  paddingRight: 12,
                }}
              >
                US Elections
              </Typography>
            </Grid>

            <Grid item xs={5} style={{ position: 'relative' }}>
              <GarmScoreProgress
                color={getUSElectionColor(percentage).backgroundColor}
                min={0}
                max={100}
                marks={false}
                value={percentage}
              />
            </Grid>
          </Grid>
        </AccordionSummary>

        {renderAccordionDetails(sources, utterances)}
      </Accordion>
    </Grid>
  );
};

export default USElectionsAccordion;
