import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core';
import { Typography, TextField } from '@material-ui/core';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from 'src/components/Button';
import { useCommonStyles } from '../onboarding-redesign/utils/useCommonStyles';
import { ROUTES } from 'src/utils';
import AuthLayout from './AuthLayout';
import { METRICS_TYPE, submitMetrics } from 'src/utils/metrics';
import useAuth from 'src/hooks/useAuth';
import useNotification from 'src/hooks/useNotification';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      width: '100%',
      '& .MuiInputBase-input': {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '32px',
        color: '#545454',

        '@media screen and (min-width: 768px)': {
          fontSize: 25,
          lineHeight: '40px',
        },
      },

      '& .MuiInput-underline:before': {
        borderBottomColor: '#545454',
      },
    },
    backBtn: {
      position: 'absolute',
      width: 'auto',
      fontWeight: 400,
      top: 45,
      left: 16,
      padding: 0,
      justifyContent: 'flex-start',
      color: '#545454',

      '@media screen and (min-width: 768px)': {
        left: 'calc(50% - 287px)',
        top: 38,
      },

      '& .MuiInput-underline:before': {
        top: 0,
      },
    },
    link: {
      color: '#A172D7',
      width: 'auto',
      height: 'auto',
      padding: 0,
    },
    inputsContainer: {
      marginTop: 52,
      marginBottom: 12,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    signup: {
      marginTop: 24,
      display: 'flex',
      alignItems: 'center',
    },
    loginBtn: {
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      borderRadius: 2,
      padding: '6px 16px',
      height: 40,
      marginTop: 52,
      minWidth: 140,

      '&:hover': {
        background:
          'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      },

      '& > .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 45,
        fontSize: 16,
        lineHeight: '24px',
        color: '#EFEDEB',
        fontWeight: 500,
      },
    },
  })
);

const LoginScreen: FC = () => {
  const classes = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const { logIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { handleError } = useNotification();

  const handleResetPassword = () => {
    history.push(ROUTES.FORGOT_PASSWORD);
  };

  const handleSubmit = async (e: React.FormEvent<any>) => {
    e.preventDefault();
    if (email && password) {
      try {
        setLoading(true);
        await logIn(email, password);
        submitMetrics(METRICS_TYPE.USER_LOGIN);
        history.push('/dashboard');
      } catch (e) {
        setLoading(false);
        handleError(e, 'Incorrect email or password.');
      }
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <Typography className={commonStyles.headline1}>Login</Typography>
        <Typography className={commonStyles.body1}>
          Stop guessing what’s in the podcast.
          <br />
          Know with Barometer.
        </Typography>
        <div className={classes.inputsContainer}>
          <TextField
            id='email'
            placeholder='Email'
            className={classes.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id='password'
            type='password'
            placeholder='Password'
            className={classes.input}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <Button
            variant='text'
            className={classNames(commonStyles.callout2, classes.link)}
            onClick={handleResetPassword}
          >
            Reset my password?
          </Button>
        </div>
        <div>
          <Button
            className={classes.loginBtn}
            color='primary'
            type='submit'
            disabled={loading}
          >
            {loading ? (
              <CircularProgress style={{ color: '#fff' }} size={24} />
            ) : (
              <>
                <span>Log in</span>
                <span>→</span>
              </>
            )}
          </Button>
        </div>
        {/* disable signup for now */}
        {/* <div className={classes.signup}>
          <Typography className={commonStyles.callout2}>
            You do not have an account?
          </Typography>
          <Button
            variant='text'
            className={classNames(commonStyles.callout2, classes.link)}
            onClick={() => {}}
          >
            Sign up
          </Button>
        </div> */}
      </form>
    </AuthLayout>
  );
};

export default LoginScreen;
