import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Grid } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { PaperContainer } from '../styles';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';

import useDashboardFilters from 'src/hooks/useDashboardFilters';
import Spinner from 'src/components/Spinner';

function getSuggestionValue(suggestion: any) {
  return suggestion;
}

function getSuggestions(value: any, dataSource: any[]) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : dataSource.filter((suggestion: any) =>
        _.includes(suggestion.toLowerCase(), inputValue)
      );
}

function renderSuggestion(suggestion: any, { query, isHighlighted }: any) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      component='div'
      onMouseDown={(e: any) => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {parts.map((part: any, index: any) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>{part.text}</span>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options: any) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function renderInput(inputProps: any) {
  const { onChange, chips, ref, dataSource, ...other } = inputProps;

  return (
    <ChipInput
      {...other}
      className='TagCloudInput'
      classes={{
        chipContainer: 'TagCloudInput--ChipsContainer',
        input: 'TagCloudInput--input',
      }}
      fullWidth
      allowDuplicates={false}
      dataSource={dataSource}
      clearInputValueOnChange
      onUpdateInput={onChange}
      value={chips}
      inputRef={ref}
    />
  );
}

interface TagCloudInputProps {
  value: string[];
  onChange: (value: string[]) => void;
  classes: any;
  isExpanded?: boolean;
  isLoading?: boolean;
}

const TagCloud = ({
  value,
  onChange,
  classes,
  isExpanded = true,
  isLoading = false,
}: TagCloudInputProps): React.ReactElement => {
  const [textFieldInput, setTextFieldInput] = useState('');
  const [suggestions, setSuggestions]: any[] = useState([]);

  const { garmSources } = useDashboardFilters();

  const handleSuggestionsFetchRequested = ({ value }: any) => {
    setSuggestions(getSuggestions(value, garmSources));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handletextFieldInputChange = (event: any, { newValue }: any) => {
    setTextFieldInput(newValue);
  };

  const handleAddChip = (chip: any) => {
    onChange([...value, chip]);
    setTextFieldInput('');
  };

  const handleDeleteChip = (_chip: any, index: any) => {
    onChange(value.filter((_each, _index) => _index !== index));
  };

  const inputProps = {
    value: textFieldInput,
    onChange: handletextFieldInputChange,
    chips: value,
    onAdd: (chip: any) => handleAddChip(chip),
    onDelete: (chip: any, index: any) => handleDeleteChip(chip, index),
  };

  return (
    <>
      <Grid container>
        <PaperContainer
          className={!isExpanded && classes.isMinimized}
          style={{ minHeight: 52 }}
        >
          {isLoading ? (
            <Spinner size={24} />
          ) : (
            <Autosuggest
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
              }}
              renderInputComponent={renderInput}
              suggestions={suggestions}
              onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={handleSuggestionsClearRequested}
              renderSuggestionsContainer={renderSuggestionsContainer}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onSuggestionSelected={(e: any, { suggestionValue }: any) => {
                handleAddChip(suggestionValue);
                e.preventDefault();
              }}
              focusInputOnSuggestionClick
              inputProps={inputProps}
            />
          )}
        </PaperContainer>
      </Grid>
    </>
  );
};

const TagCloudInput = withStyles((theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  isMinimized: {
    '& .MuiFormControl-root': {
      maxHeight: 120,
      overflowY: 'auto',
      paddingBottom: 2,

      '&::-webkit-scrollbar': {
        width: 6,
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: 3,
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    },
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  textField: {
    width: '100%',
  },
}))(TagCloud);

export default TagCloudInput;
