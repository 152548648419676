import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, styled } from '@material-ui/core/styles';
import { useFontStyles, useLayoutStyles } from 'src/components/useCommonStyles';

const NormalTypography = styled(Typography)({
  fontWeight: 'normal',
});

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      border: '1px solid black',
      padding: theme.spacing(3, 5),
    },
  })
);

interface AuditReportProps {}

const AuditReport: FC<AuditReportProps> = () => {
  const classes = useStyles();
  const fontClasses = useFontStyles();
  const layoutClasses = useLayoutStyles();

  return (
    <Grid container>
      <Grid container className={layoutClasses.mb3}>
        <Typography variant='h2'>Case Study 1: NCR</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={3}>
            <Typography variant='h2'>NCR</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant='h2' className={fontClasses.fontNormal}>
              Rating: B+
            </Typography>
          </Grid>
          <Grid container item xs={12} md={5} alignItems='flex-end'>
            <Typography variant='h2' className={fontClasses.fontNormal}>
              V1 2021
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container className={layoutClasses.mb3}>
            <Grid item xs={12} md={4}>
              <Typography variant='h4'>Negative News Search</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <NormalTypography variant='h4'>
                Content scanned: 2000 items
              </NormalTypography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>
                Average sensationalism:
              </NormalTypography>
              <NormalTypography variant='h4'>
                Average colloquialism:
              </NormalTypography>
              <NormalTypography variant='h4'>
                Average editorial bias:
              </NormalTypography>
            </Grid>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>SES Bias:</NormalTypography>
              <NormalTypography variant='h4'>Racial Bias:</NormalTypography>
            </Grid>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>
                GARM Components flagged:
              </NormalTypography>
              <ul>
                <li>Adult content</li>
                <li>Terrorism</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container className={layoutClasses.mb3}>
            <Typography variant='h4'>Gram Elements Flagged</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <img
              src='images/screens/garm-elements.png'
              width='100%'
              alt='GARM Elements'
            />
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container className={layoutClasses.mb3}>
            <Grid item xs={12} md={4}>
              <Typography variant='h4'>Employee feedback:</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant='h4'>Feedback scanned: 2000 items</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography variant='h4'>Average sensationalism:</Typography>
              <Typography variant='h4'>Emotion:</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>SES Bias:</NormalTypography>
              <NormalTypography variant='h4'>Racial Bias:</NormalTypography>
            </Grid>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>
                Top Feedback Categories
              </NormalTypography>
              <ul>
                <li>Adult content</li>
                <li>Terrorism</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container className={layoutClasses.mb3}>
            <Typography variant='h4'>Top Feedback Categories</Typography>
          </Grid>
          <Grid container>
            <img
              alt='Top Feedback Categories'
              src='images/screens/top-feedback-categories.png'
              width='100%'
            />
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container className={layoutClasses.mb3}>
            <Grid item xs={12} md={4}>
              <Typography variant='h4'>Policies and Materials</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant='h4'>Policies scanned: 10 items</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>
                Average sensationalism:
              </NormalTypography>
              <NormalTypography variant='h4'>
                Average colloquialism:
              </NormalTypography>
              <NormalTypography variant='h4'>
                Average editorial bias:
              </NormalTypography>
            </Grid>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>SES Bias:</NormalTypography>
              <NormalTypography variant='h4'>Racial Bias:</NormalTypography>
            </Grid>
            <Grid item xs={12} md={4}>
              <NormalTypography variant='h4'>Insights</NormalTypography>
              <ul>
                <li>Consistency across stakeholders</li>
                <li>Competitors</li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12}>
            <Typography variant='h4'>Policies and Materials 1</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12}>
            <Typography variant='h4'>Policies and Materials 2</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12}>
            <Typography variant='h4'>Policies and Materials 3</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12}>
            <Typography variant='h4'>Policies and Materials 4</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={12}>
            <Typography variant='h4'>Policies and Materials 5</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.container}>
          <Grid container className={layoutClasses.mb3}>
            <Typography variant='h4'>Policies and Materials 6</Typography>
          </Grid>
          <Grid container>
            <img
              alt='Plicies and Materials'
              src='images/screens/policies-materials.png'
              width='100%'
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuditReport;
