import { TScoreMapping, ISubscriptionInfo, SubscriptionPlans } from 'src/types';

export const DebouncedDelays = {
  TYPING: 50,
};

export const editorialMappings: TScoreMapping[] = [
  { from: [0.8, 1], range: [4.5, 5.5], label: 'Very unbiased' },
  { from: [0.6, 0.8], range: [3.5, 4.5], label: 'Fairly unbiased' },
  { from: [0.4, 0.6], range: [2.5, 3.5], label: 'Somewhat biased' },
  { from: [0.2, 0.4], range: [1.5, 2.5], label: 'Very biased' },
  { from: [0, 0.2], range: [0.5, 1.5], label: 'Extremely biased' },
];

export const colloquialMappings: TScoreMapping[] = [
  { from: [0.8, 1], range: [4.5, 5.5], label: 'Extremely literary' },
  { from: [0.6, 0.8], range: [3.5, 4.5], label: 'Very literary' },
  { from: [0.4, 0.6], range: [2.5, 3.5], label: 'Somewhat literary' },
  { from: [0.2, 0.4], range: [1.5, 2.5], label: 'Very colloquial' },
  { from: [0, 0.2], range: [0.5, 1.5], label: 'Extremely colloquial' },
];

export const sentimentMappings: TScoreMapping[] = [
  { from: [0.3, 1], range: [0.5, 1], label: 'Positive' },
  { from: [-0.3, 0.3], range: [-0.5, 0.5], label: 'Fairly neutral' },
  { from: [-1, -0.3], range: [-1.5, -0.5], label: 'Negative' },
];

export const subscriptionInfos: ISubscriptionInfo[] = [
  {
    plan: SubscriptionPlans.STARTUP,
    price: 5000,
    period: 'month',
    billingYearly: false,
    billingPeriod: 'monthly',
  },
  {
    plan: SubscriptionPlans.STARTUP,
    price: 48000,
    period: 'year',
    billingYearly: true,
    billingPeriod: 'yearly',
  },
  {
    plan: SubscriptionPlans.ENTERPRISE,
    price: 20000,
    period: 'month',
    billingYearly: false,
    billingPeriod: 'monthly',
  },
  {
    plan: SubscriptionPlans.ENTERPRISE,
    price: 196000,
    period: 'year',
    billingYearly: true,
    billingPeriod: 'yearly',
  },
];

export const ERROR_MESSAGES = {
  SCORE_GENERATION: 'Generating score failed.',
  SAVE_ARTICLE: 'Saving article failed.',
  UPLOAD_DOCUMENT: 'Uploading and scording document failed.',
};

export const SUCCESS_MESSAGES = {
  SCORE_GENERATION: 'Successfully genereated the score!',
  SAVE_ARTICLE: 'Successfully saved article.',
  UPLOAD_DOCUMENT: 'Uploaded the document and scored successfully.',
};

export const CORRELATOR_KEYS = {
  CONTENT_EDITORIAL_BIAS: 'contentEditorialBias',
  TITLE_EDITORIAL_BIAS: 'titleEditorialBias',
  CONTENT_COLLOQUIALISM: 'contentColloquialism',
  TITLE_COLLOQUIALISM: 'titleColloquialism',
  CONTENT_SENTIMENT: 'contentSentiment',
  TITLE_SENTIMENT: 'titleSentiment',
};

export const LINKS = {
  CALENDAR: 'https://calendly.com/thebarometer',
  CHROME:
    'https://chrome.google.com/webstore/detail/future-picture-bias-detec/cdhjhodfiglepjloeiekpmiolhpobhdi?hl=en',
};

export const TOOLTIPS = {
  EDITORIAL_BIAS:
    'Editorial bias refers to the amount of editorial spin present in the text. Editorial spin includes argumentative or persuasive language as well as calls to action.',
  COLLOQUIALISM:
    'Colloquialism refers to how conversational or literary the language is to indicate the formality and accessibility of the language.',
  SENSATIONALISM:
    'Sensationalism refers to the degree of emotional or loaded language present in the content.',
  SES_BIAS:
    'Socioeconomic status (SES) bias module detects biases pertaining to immigration, housing, government assistance and economic status.',
};

export const LANGUAGE_LIST = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Albanian', value: 'sq' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Arabic (Algeria)', value: 'ar-dz' },
  { label: 'Arabic (Bahrain)', value: 'ar-bh' },
  { label: 'Arabic (Egypt)', value: 'ar-eg' },
  { label: 'Arabic (Iraq)', value: 'ar-iq' },
  { label: 'Arabic (Jordan)', value: 'ar-jo' },
  { label: 'Arabic (Kuwait)', value: 'ar-kw' },
  { label: 'Arabic (Lebanon)', value: 'ar-lb' },
  { label: 'Arabic (Libya)', value: 'ar-ly' },
  { label: 'Arabic (Morocco)', value: 'ar-ma' },
  { label: 'Arabic (Oman)', value: 'ar-om' },
  { label: 'Arabic (Qatar)', value: 'ar-qa' },
  { label: 'Arabic (Saudi Arabia)', value: 'ar-sa' },
  { label: 'Arabic (Syria)', value: 'ar-sy' },
  { label: 'Arabic (Tunisia)', value: 'ar-tn' },
  { label: 'Arabic (U.A.E.)', value: 'ar-ae' },
  { label: 'Arabic (Yemen)', value: 'ar-ye' },
  { label: 'Basque', value: 'eu' },
  { label: 'Belarusian', value: 'be' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Chinese', value: 'zh' },
  { label: 'Chinese (Hong Kong)', value: 'zh-hk' },
  { label: 'Chinese (PRC)', value: 'zh-cn' },
  { label: 'Chinese (Singapore)', value: 'zh-sg' },
  { label: 'Chinese (Taiwan)', value: 'zh-tw' },
  { label: 'Croatian', value: 'hr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Danish', value: 'da' },
  { label: 'Dutch (Standard)', value: 'nl' },
  { label: 'Dutch (Belgium)', value: 'nl-be' },
  { label: 'English', value: 'en' },
  { label: 'English (Australia)', value: 'en-au' },
  { label: 'English (Belize)', value: 'en-bz' },
  { label: 'English (Canada)', value: 'en-ca' },
  { label: 'English (Ireland)', value: 'en-ie' },
  { label: 'English (Jamaica)', value: 'en-jm' },
  { label: 'English (New Zealand)', value: 'en-nz' },
  { label: 'English (South Africa)', value: 'en-za' },
  { label: 'English (Trinidad)', value: 'en-tt' },
  { label: 'English (United Kingdom)', value: 'en-gb' },
  { label: 'English (United States)', value: 'en-us' },
  { label: 'Estonian', value: 'et' },
  { label: 'Faeroese', value: 'fo' },
  { label: 'Farsi', value: 'fa' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French (Standard)', value: 'fr' },
  { label: 'French (Belgium)', value: 'fr-be' },
  { label: 'French (Canada)', value: 'fr-ca' },
  { label: 'French (Luxembourg)', value: 'fr-lu' },
  { label: 'French (Switzerland)', value: 'fr-ch' },
  { label: 'Gaelic (Scotland)', value: 'gd' },
  { label: 'German (Standard)', value: 'de' },
  { label: 'German (Austria)', value: 'de-at' },
  { label: 'German (Liechtenstein)', value: 'de-li' },
  { label: 'German (Luxembourg)', value: 'de-lu' },
  { label: 'German (Switzerland)', value: 'de-ch' },
  { label: 'Greek', value: 'el' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Icelandic', value: 'is' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Irish', value: 'ga' },
  { label: 'Italian (Standard)', value: 'it' },
  { label: 'Italian (Switzerland)', value: 'it-ch' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Kurdish', value: 'ku' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Macedonian (FYROM)', value: 'mk' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Malaysian', value: 'ms' },
  { label: 'Maltese', value: 'mt' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Norwegian (Bokmål)', value: 'nb' },
  { label: 'Norwegian (Nynorsk)', value: 'nn' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese (Brazil)', value: 'pt-br' },
  { label: 'Portuguese (Portugal)', value: 'pt' },
  { label: 'Punjabi', value: 'pa' },
  { label: 'Rhaeto-Romanic', value: 'rm' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Romanian (Republic of Moldova)', value: 'ro-md' },
  { label: 'Russian', value: 'ru' },
  { label: 'Russian (Republic of Moldova)', value: 'ru-md' },
  { label: 'Serbian', value: 'sr' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Slovenian', value: 'sl' },
  { label: 'Sorbian', value: 'sb' },
  { label: 'Spanish (Argentina)', value: 'es-ar' },
  { label: 'Spanish (Bolivia)', value: 'es-bo' },
  { label: 'Spanish (Chile)', value: 'es-cl' },
  { label: 'Spanish (Colombia)', value: 'es-co' },
  { label: 'Spanish (Costa Rica)', value: 'es-cr' },
  { label: 'Spanish (Dominican Republic)', value: 'es-do' },
  { label: 'Spanish (Ecuador)', value: 'es-ec' },
  { label: 'Spanish (El Salvador)', value: 'es-sv' },
  { label: 'Spanish (Guatemala)', value: 'es-gt' },
  { label: 'Spanish (Honduras)', value: 'es-hn' },
  { label: 'Spanish (Mexico)', value: 'es-mx' },
  { label: 'Spanish (Nicaragua)', value: 'es-ni' },
  { label: 'Spanish (Panama)', value: 'es-pa' },
  { label: 'Spanish (Paraguay)', value: 'es-py' },
  { label: 'Spanish (Peru)', value: 'es-pe' },
  { label: 'Spanish (Puerto Rico)', value: 'es-pr' },
  { label: 'Spanish (Spain)', value: 'es' },
  { label: 'Spanish (Uruguay)', value: 'es-uy' },
  { label: 'Spanish (Venezuela)', value: 'es-ve' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Swedish (Finland)', value: 'sv-fi' },
  { label: 'Thai', value: 'th' },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Tswana', value: 'tn' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukrainian', value: 'ua' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Venda', value: 've' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Welsh', value: 'cy' },
  { label: 'Xhosa', value: 'xh' },
  { label: 'Yiddish', value: 'ji' },
  { label: 'Zulu', value: 'zu' },
];
