import { createStyles, makeStyles } from '@material-ui/core';

export const useCommonStyles = makeStyles(() =>
  createStyles({
    headline1: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '0.04em',
      color: '#202226',
      textTransform: 'uppercase',

      '@media screen and (min-width: 768px)': {
        fontSize: 20,
        lineHeight: '40px',
      },
    },
    title2: {
      fontWeight: 300,
      fontSize: 30,
      lineHeight: '40px',
      color: '#202226',

      '@media screen and (min-width: 768px)': {
        fontSize: 48,
        lineHeight: '56px',
      },
    },
    body1: {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px',
      color: '#545454',

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '40px',
      },
    },
    body2: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      color: '#202226',

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '40px',
      },
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#545454',
      textTransform: 'uppercase',
    },
    callout1: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '16px',
      color: '#E8E5E2',

      '@media screen and (min-width: 768px)': {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    callout2: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: '-0.02em',
      color: '#545454',
    },
    dWebOnly: {
      display: 'none',

      '@media screen and (min-width: 768px)': {
        display: 'block',
      },
    },
    mWebOnly: {
      dWeb: {
        display: 'block',

        '@media screen and (min-width: 768px)': {
          display: 'none',
        },
      },
    },
  })
);
