import { useQuery } from 'react-query';

import queryKeys from './queryKeys';
import { baseAPICreator } from './apiCreator';
import { QueryStatus } from 'src/types';
import {
  PaymentMethodCreateForm,
  PaymentMethod,
  InvoiceDto,
  CustomerSubscriptionDto,
  PromoCode,
} from 'src/types/payment';

const paymentApi = baseAPICreator('/payment');

export const registerPaymentMethod = async (
  paymentMethod: PaymentMethodCreateForm
) => {
  const { data } = await paymentApi.post('/method', paymentMethod);
  return data;
};

export const listPaymentMethods = async () => {
  const { data } = await paymentApi.get('/method');
  return data;
};

export const registerCustomerSubscription = async (
  planName: string,
  token: string,
  promoCode?: string
): Promise<CustomerSubscriptionDto> => {
  const { data } = await paymentApi.post('/customer-subscription', {
    token,
    planName,
    promoCode,
  });
  return data;
};

export const getPromoCodeInfo = async (
  promoCode: string
): Promise<PromoCode | undefined> => {
  try {
    const { data } = await paymentApi.get(`/promo-code/${promoCode}`);
    return data;
  } catch {
    return undefined;
  }
};

export const updateCustomerSubscription = async (
  planName: string,
  promoCode?: string
) => {
  const { data } = await paymentApi.put('/customer-subscription', {
    planName,
    promoCode,
  });
  return data;
};

export const cancelCustomerSubscription = async () => {
  const { data } = await paymentApi.delete('/customer-subscription');
  return data;
};

export const listInvoices = async () => {
  const { data } = await paymentApi.get('/invoice');
  return data;
};

export const useGetPaymentMethods = () => {
  const { status, data, error, refetch } = useQuery(
    [queryKeys.LIST_PAYMENT_METHODS],
    async () => {
      const response = await listPaymentMethods();
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    paymentMethods: (data || []) as PaymentMethod[],
    error,
    refetch,
  };
};

export const useGetInvoices = () => {
  const { status, data, error, refetch } = useQuery(
    [queryKeys.LIST_INVOICES],
    async () => {
      const response = await listInvoices();
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    invoices: (data || []) as InvoiceDto[],
    error,
    refetch,
  };
};
