import { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useViewport } from 'src/hooks/useViewport';
import { DRAWER_WIDTH } from 'src/hooks/useSidebar';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(0, 0, 0, 0.5),
      overflow: 'hidden',
    },
  }),
);

export default function BarometerDocumentation() {
  const classes = useStyles();
  const { width } = useViewport();
  const [numPages, setNumPages] = useState<number[]>([]);

  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(new Array(numPages).fill(0).map((_, index) => index + 1));
  };

  return (
    <div className={classes.container}>
      <Document
        file='./Documentation.pdf'
        externalLinkTarget='_blank'
        onLoadSuccess={handleDocumentLoadSuccess}
      >
        {numPages.map((page) => (
          <div key={page} id={`toc-page-${page}`}>
            <Page
              width={width - DRAWER_WIDTH - 24}
              pageNumber={page}
              onGetAnnotationsSuccess={() =>
                console.log('get annotations success')
              }
              onGetAnnotationsError={() => console.log('get annotations error')}
            />
          </div>
        ))}
      </Document>
    </div>
  );
}
