import { TableCell, Typography } from '@material-ui/core';
import { useStyles } from '../common';
import { getUSElectionColor } from '../common';

interface GarmTableCellProps {
  content: number;
  up: number;
  down: number;
  isSecondary?: boolean;
}

export const USElectionTableCell = ({
  content,
  up,
  down,
  isSecondary = false,
}: GarmTableCellProps) => {
  const classes = useStyles();
  const colors = getUSElectionColor(content);

  return (
    <TableCell className={classes.scoreCell}>
      <div style={{ background: colors.backgroundColor, color: colors.color }}>
        <Typography>{content}%</Typography>
        {!isSecondary && (
          <span>
            <span style={{ marginRight: 8 }}>
              &#x2B07;
              {down}
            </span>
            <span>
              &#x2B06;
              {up}
            </span>
          </span>
        )}
      </div>
    </TableCell>
  );
};
