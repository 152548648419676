import {
  useContext,
  useCallback,
  useState,
  createContext,
  useEffect,
} from 'react';
import { User } from 'src/types/user';
import { Organization } from 'src/types/organization';

import * as apis from 'src/apis';

type AuthContextType = {
  user?: User;
  isAuthenticated: boolean;
  organization?: Organization;
  fullName?: string;
  isLoading: boolean;
  apiKey?: string;
  logIn: (email: string, password: string) => Promise<any>;
  logOut: () => Promise<any>;
  fetchUser: () => Promise<any>;
  fetchOrganization: () => Promise<any>;
  updateOrganizationPrimaryDashboard: (id: string) => Promise<any>;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<AuthContextType>(undefined!);

export function AuthProvider(props: any) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>();
  const [organization, setOrganization] = useState<Organization | undefined>();

  async function fetchUser() {
    try {
      const user = await apis.fetchUser();
      setUser(user);
    } catch (error) {
      setUser(undefined);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchOrganization = useCallback(async () => {
    if (!user) return;
    const organization = await apis.fetchOrganization(user?.organizationId);
    setOrganization(organization);
  }, [user]);

  const updateOrganizationPrimaryDashboard = useCallback(
    async (id: string) => {
      if (!user) return;
      const organization = await apis.updateOrganizationPrimaryDashboard(id);
      setOrganization(organization);
    },
    [user]
  );

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchOrganization();
  }, [user, fetchOrganization]);

  async function logIn(email: string, password: string) {
    await apis.signIn(email, password);
    const user = await apis.fetchUser();
    setUser(user);
  }

  async function logOut() {
    setOrganization(undefined);
    setUser(undefined);
    await apis.signOut();
  }

  const isAuthenticated = !!user;

  const values = {
    isAuthenticated,
    user,
    organization,
    logIn,
    logOut,
    fetchUser,
    fetchOrganization,
    updateOrganizationPrimaryDashboard,
    isLoading,
    fullName: user ? [user.firstName, user.lastName].join(' ') : undefined,
    apiKey: organization?.apiKey,
  };

  return <AuthContext.Provider value={values} {...props} />;
}

export default function useAuth() {
  return useContext(AuthContext);
}
