import { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { GARMCategories } from 'src/hooks/useDashboardCategories';
import { useTextStyles } from 'src/hooks/useTextStyles';
import {
  ScoreFilterType,
  ScoreExceptionTypes,
  RiskLevel,
} from 'src/types/filter';
import RangeSelectionComponent from './RangeSelection';
import useDashboardFilters from 'src/hooks/useDashboardFilters';
import { RiskLevelSelect } from './RiskLevelSelect';
import { SelectList, CategorySelectList } from './SelectList';
import { AddSelectList, AddCategorySelectList } from './AddSelectList';
import { ReactComponent as RemoveIcon } from 'src/assets/icons/trash.svg';
import { GramReasoningData } from './GarmReasoningData';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,

      '& > div': {
        padding: 16,
        background: '#FFF',
        borderRadius: 8,
      },
    },
    addBtn: {
      '& > .MuiButton-label': {
        fontSize: 10,
        fontWeight: 400,
        lineHeight: '16px',
        textTransform: 'none',

        '& > .MuiButton-startIcon': {
          marginRight: 4,
        },
      },
    },
    selectType: {
      marginTop: 4,

      '& > .MuiButton-label': {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '12px',
        textTransform: 'none',

        '& > .MuiButton-startIcon': {
          marginRight: 4,
        },
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      gap: 20,
      marginBottom: 8,
    },
    mainContent: {
      margin: '8px 0 8px 4px',
      padding: '8px 0 8px 16px',
      borderLeft: '1px solid #E1E1E3',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,

      '& > div': {
        borderRadius: 8,
        background: '#F5F5F5',
        padding: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      },
    },
    exceptionHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      marginBottom: 4,
    },
    viewSelection: {
      width: 150,
      border: '1px solid #C3C3C7',
      borderRadius: 5,
      padding: 4,
      paddingLeft: 8,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',

      '& .MuiSelect-root': {
        padding: 0,
        paddingRight: 24,
      },

      '& .MuiSvgIcon-root': {
        marginTop: -2,
      },

      '&:before, &:after': {
        border: 'none !important',
      },
    },
    exceptionContainer: {
      display: 'flex',
      padding: '8px 16px',
      borderRadius: 6,
      border: '2px solid #EEE',
      background: '#FFF',
      position: 'relative',

      '& .add': {
        position: 'absolute',
        width: 22,
        height: 22,
        borderRadius: '100%',
        border: '1px solid #C3C3C7',
        background: '#FFF',
        right: 45,
        top: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      },

      '& .remove': {
        position: 'absolute',
        width: 22,
        height: 22,
        borderRadius: '100%',
        border: '1px solid #C3C3C7',
        background: '#FFF',
        padding: 4,
        right: 16,
        top: 10,
        display: 'flex',
        cursor: 'pointer',
      },
    },
    arrowBtn: {
      fontSize: 16,
      fontWeight: 600,
      cursor: 'pointer',
    },
    fullWidth: {
      width: '100%',
    },
  })
);

interface ExceptionTypeSelectionProps {
  filter: ScoreFilterType;
  selections: string[] | null;
  selectionsWithCategory: Record<string, string[]> | null;
  disableGarmReasoning?: boolean;
  updateFilter: (filter: ScoreFilterType) => void;
}

const ExceptionTypeSelection = ({
  filter,
  selections,
  selectionsWithCategory,
  disableGarmReasoning = false,
  updateFilter,
}: ExceptionTypeSelectionProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const [type, setType] = useState<ScoreExceptionTypes>(filter.type);
  const [openException, setOpenException] = useState<number>(-1);
  const [isAddNew, setIsAddNew] = useState<boolean>(false);
  const [newAddExceptionNames, setAddNewExceptionNames] = useState<string[]>(
    []
  );
  const [newAddRisk, setNewAddRisk] = useState<RiskLevel>(RiskLevel.No);

  const handleChangeView = (event: any) => {
    if (filter.exceptions.length) {
      return;
    }
    const tempFilter = { ...filter };

    const newType = event.target.value;
    tempFilter.type = newType;
    setType(event.target.value);
    updateFilter(tempFilter);
  };

  const handleAddNew = () => {
    if (!isAddNew) {
      handleFilterEmpty();
      setAddNewExceptionNames([]);
      setNewAddRisk(RiskLevel.No);
      setIsAddNew(true);
      setOpenException(-1);
    }
  };

  const handleRemove = (index: number) => {
    const tempFilter = { ...filter };
    tempFilter.exceptions.splice(index, 1);
    updateFilter(tempFilter);
    setOpenException(-1);
  };

  const handleUpdateSelect = (name: string, risk: RiskLevel, index: number) => {
    const tempFilter = { ...filter };
    tempFilter.exceptions[index] = {
      name,
      risk,
    };
    updateFilter(tempFilter);
  };

  const handleAddSelect = () => {
    const tempFilter = { ...filter };
    const newExceptions = newAddExceptionNames.map((name) => ({
      name,
      risk: newAddRisk,
    }));
    const filteredExceptions = [...tempFilter.exceptions].filter(
      (exception) => !newAddExceptionNames.includes(exception.name)
    );
    tempFilter.exceptions = [...filteredExceptions, ...newExceptions];
    updateFilter(tempFilter);
    setIsAddNew(false);
  };

  const handleFilterEmpty = () => {
    const tempFilter = { ...filter };
    tempFilter.exceptions = tempFilter.exceptions.filter((item) => item.name);
    updateFilter(tempFilter);
  };

  return (
    <>
      <div className={classes.exceptionHeader}>
        <Typography className={textClasses.xsRegular}>
          Exception Type
        </Typography>
        <Select
          labelId='exception-type-select-label'
          id='exception-type-select'
          value={type || ''}
          onChange={handleChangeView}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          className={classes.viewSelection}
          readOnly={!!filter.exceptions.length}
        >
          {Object.keys(ScoreExceptionTypes).map((item) => (
            <MenuItem
              key={item}
              value={
                /* @ts-ignore */
                ScoreExceptionTypes[item as unknown as ScoreExceptionTypes]
              }
              className={textClasses.xsRegular}
              style={{ padding: '4px 8px' }}
              disabled={disableGarmReasoning && item === 'GarmReasoning'}
            >
              {/* @ts-ignore */}
              {ScoreExceptionTypes[item as unknown as ScoreExceptionTypes]}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div style={{ overflow: 'auto', maxHeight: 470 }}>
        {!!filter.exceptions.length &&
          filter.exceptions.map((exception, index) => (
            <div key={index} className={classes.exceptionContainer}>
              <div style={{ display: 'flex', marginTop: 4 }}>
                {index === openException ? (
                  <KeyboardArrowUpIcon
                    className={classes.arrowBtn}
                    onClick={() => {
                      handleFilterEmpty();
                      setIsAddNew(false);
                      setOpenException(-1);
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    className={classes.arrowBtn}
                    onClick={() => {
                      handleFilterEmpty();
                      setIsAddNew(false);
                      setOpenException(index);
                    }}
                  />
                )}
                <Typography
                  className={textClasses.xsRegular}
                  style={{ width: 16, color: '#616161' }}
                >
                  {index + 1}
                </Typography>
              </div>
              {index === openException ? (
                <div style={{ display: 'flex', gap: 24, width: '100%' }}>
                  {selections && (
                    <SelectList
                      selections={selections}
                      selected={exception.name}
                      selectedOthers={filter.exceptions.map(
                        (item) => item.name
                      )}
                      type={filter.type}
                      handleSave={(name) =>
                        handleUpdateSelect(name, exception.risk, index)
                      }
                    />
                  )}
                  {selectionsWithCategory && (
                    <CategorySelectList
                      selections={selectionsWithCategory}
                      selected={exception.name}
                      type={filter.type}
                      selectedOthers={filter.exceptions.map(
                        (item) => item.name
                      )}
                      handleSave={(name) =>
                        handleUpdateSelect(name, exception.risk, index)
                      }
                    />
                  )}
                  <div
                    style={{
                      flex: 1,
                      maxWidth: 450,
                      paddingTop: 4,
                      paddingRight: 32,
                    }}
                  >
                    <Typography
                      className={textClasses.xxsRegular}
                      style={{ marginBottom: 8 }}
                    >
                      Max Risk Level
                    </Typography>
                    <RiskLevelSelect
                      riskLevel={exception.risk}
                      setRiskLevel={(risk) =>
                        handleUpdateSelect(exception.name, risk, index)
                      }
                      className={classes.fullWidth}
                    />
                  </div>
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <Typography
                    className={textClasses.xsBold}
                    style={{ marginLeft: 8, minWidth: 140 }}
                  >
                    {exception.name ||
                      `Please select ${filter.type.toLowerCase()}`}
                  </Typography>
                  <RiskLevelSelect
                    riskLevel={exception.risk}
                    setRiskLevel={(risk) =>
                      handleUpdateSelect(exception.name, risk, index)
                    }
                  />
                </div>
              )}
              <div className='remove' onClick={() => handleRemove(index)}>
                <RemoveIcon />
              </div>
            </div>
          ))}
      </div>
      {isAddNew && (
        <div className={classes.exceptionContainer}>
          <div style={{ display: 'flex', marginTop: 4 }}>
            <KeyboardArrowUpIcon
              className={classes.arrowBtn}
              onClick={() => {
                setIsAddNew(false);
              }}
            />
            <Typography
              className={textClasses.xsRegular}
              style={{ width: 16, color: '#616161' }}
            >
              {filter.exceptions.length + 1}
            </Typography>
          </div>

          <div style={{ display: 'flex', gap: 24, width: '100%' }}>
            {selections && (
              <AddSelectList
                selections={selections}
                selected={newAddExceptionNames}
                type={filter.type}
                handleSave={setAddNewExceptionNames}
              />
            )}
            {selectionsWithCategory && (
              <AddCategorySelectList
                selections={selectionsWithCategory}
                selected={newAddExceptionNames}
                type={filter.type}
                handleSave={setAddNewExceptionNames}
              />
            )}
            <div
              style={{
                flex: 1,
                maxWidth: 450,
                paddingTop: 4,
                paddingRight: 32,
              }}
            >
              <Typography
                className={textClasses.xxsRegular}
                style={{ marginBottom: 8 }}
              >
                Max Risk Level
              </Typography>
              <RiskLevelSelect
                riskLevel={newAddRisk}
                setRiskLevel={(risk) => setNewAddRisk(risk)}
                className={classes.fullWidth}
              />
            </div>
          </div>
          <div
            className='add'
            onClick={() => {
              handleAddSelect();
            }}
          >
            <AddIcon style={{ fontSize: 15 }} />
          </div>
          <div className='remove' onClick={() => setIsAddNew(false)}>
            <RemoveIcon />
          </div>
        </div>
      )}
      <div>
        <Button
          color='primary'
          disableElevation
          startIcon={<AddIcon style={{ fontSize: 10 }} />}
          variant='text'
          size='small'
          onClick={handleAddNew}
          className={classes.selectType}
        >
          Select {filter.exceptions.length ? 'another' : 'new'}{' '}
          {filter.type.toLowerCase()}
        </Button>
      </div>
    </>
  );
};

interface GarmScoreFiltersProps {
  filters: Record<string, { score: number[]; content: ScoreFilterType[] }>;
  setFilters: (
    filters: Record<string, { score: number[]; content: ScoreFilterType[] }>
  ) => void;
}

export const GarmScoreFilters = ({
  filters,
  setFilters,
}: GarmScoreFiltersProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();
  const { genres } = useDashboardFilters();

  const handleAddException = (title: string) => {
    const tempFilters = { ...filters };
    const scoreFilter = tempFilters[title];
    if (scoreFilter) {
      tempFilters[title] = {
        score: scoreFilter.score,
        content: [
          ...scoreFilter.content,
          {
            type: ScoreExceptionTypes.Genre,
            exceptions: [],
          },
        ],
      };
    } else {
      tempFilters[title] = {
        score: [0, 3],
        content: [
          {
            type: ScoreExceptionTypes.Genre,
            exceptions: [],
          },
        ],
      };
    }
    setFilters(tempFilters);
  };

  const handleUpdateFilter = (
    title: string,
    index: number,
    newFilter: ScoreFilterType
  ) => {
    const tempFilters = { ...filters };
    tempFilters[title].content[index] = newFilter;
    setFilters(tempFilters);
  };

  const handleUpdateFilterScore = (title: string, value: number | number[]) => {
    const tempFilters = { ...filters };
    tempFilters[title].score = value as number[];
    setFilters(tempFilters);
  };

  return (
    <div className={classes.container}>
      {GARMCategories[0].entries.map(({ title, jsonKeyRisk }) => (
        <div key={title}>
          <div className={classes.header}>
            <Typography className={textClasses.smBold}>{title}</Typography>
            <div style={{ minWidth: 420, width: 420, marginRight: 20 }}>
              <RangeSelectionComponent
                onChange={(_e, v) => handleUpdateFilterScore(title, v)}
                value={filters[title].score}
              />
            </div>
          </div>
          {filters[title].content && !!filters[title].content.length && (
            <div className={classes.mainContent}>
              {filters[title].content.map((filter, index) => (
                <div key={index}>
                  <ExceptionTypeSelection
                    filter={filter}
                    selections={
                      filter.type === ScoreExceptionTypes.GarmReasoning &&
                      jsonKeyRisk === 'GARM 8 Risk'
                        ? null
                        : filter.type === ScoreExceptionTypes.GarmReasoning &&
                          jsonKeyRisk === 'GARM 11 Risk'
                        ? GramReasoningData.garm11
                        : genres
                    }
                    selectionsWithCategory={
                      filter.type === ScoreExceptionTypes.GarmReasoning &&
                      jsonKeyRisk === 'GARM 8 Risk'
                        ? GramReasoningData.garm8
                        : null
                    }
                    disableGarmReasoning={
                      jsonKeyRisk !== 'GARM 8 Risk' &&
                      jsonKeyRisk !== 'GARM 11 Risk'
                    }
                    updateFilter={(newFilter: ScoreFilterType) =>
                      handleUpdateFilter(title, index, newFilter)
                    }
                  />
                </div>
              ))}
            </div>
          )}
          <Button
            color='primary'
            disableElevation
            startIcon={<AddIcon style={{ fontSize: 12 }} />}
            variant='text'
            size='small'
            onClick={() => handleAddException(title)}
            className={classes.addBtn}
            disabled={
              jsonKeyRisk !== 'GARM 8 Risk' && jsonKeyRisk !== 'GARM 11 Risk'
                ? filters[title].content.length === 1
                : filters[title].content.length === 2
            }
          >
            Add Exception
          </Button>
        </div>
      ))}
    </div>
  );
};
