import { FC } from 'react';
import { Box, Chip, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      margin: theme.spacing(-1),
    },

    chip: {
      margin: theme.spacing(1),
    },
  }),
);

interface KeywordsListProps {
  keywords: string[];
}

const KeywordsList: FC<KeywordsListProps> = ({ keywords }) => {
  const classes = useStyles();
  return (
    <Box display='flex' flexWrap='wrap' className={classes.container}>
      {keywords.map((keyword) => (
        <Chip
          className={classes.chip}
          key={keyword}
          label={keyword}
          color='primary'
          variant='outlined'
          style={{
            textTransform: 'capitalize',
            fontWeight: 'normal',
            fontSize: 13,
            lineHeight: 13,
            height: 26,
          }}
        />
      ))}
    </Box>
  );
};

export default KeywordsList;
