const TagsIcon = ({
  fill = '#000000',
  height = 24,
  width = 24,
}: {
  width?: number;
  height?: number;
  fill?: string;
}): React.ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1.33325'
      y='4.66667'
      width='13.3333'
      height='4'
      rx='2'
      fill={fill}
      fillOpacity='0.54'
    />
    <rect
      x='16'
      y='4.66667'
      width='6.66667'
      height='4'
      rx='2'
      fill={fill}
      fillOpacity='0.54'
    />
    <rect
      x='1.33325'
      y='15.3333'
      width='11.3333'
      height='4'
      rx='2'
      fill={fill}
      fillOpacity='0.54'
    />
    <rect
      x='14'
      y='15.3333'
      width='8.66667'
      height='4'
      rx='2'
      fill={fill}
      fillOpacity='0.54'
    />
    <rect
      x='1.33325'
      y='10'
      width='6.66667'
      height='4'
      rx='2'
      fill={fill}
      fillOpacity='0.54'
    />
    <rect
      x='9.33325'
      y='10'
      width='8.66667'
      height='4'
      rx='2'
      fill={fill}
      fillOpacity='0.54'
    />
    <rect
      x='18.6665'
      y='10'
      width='4'
      height='4'
      rx='2'
      fill={fill}
      fillOpacity='0.54'
    />
  </svg>
);

export default TagsIcon;
