import { createStyles, makeStyles } from '@material-ui/core';
import ReactLoading from 'react-loading';
import clsx from 'clsx';

const useStyles: any = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& > div': {
        margin: 0,
      },
    },
  })
);

type LoadingType =
  | 'blank'
  | 'balls'
  | 'bars'
  | 'bubbles'
  | 'cubes'
  | 'cylon'
  | 'spin'
  | 'spinningBubbles'
  | 'spokes';

interface SpinnerProps {
  color?: string;
  className?: string;
  type?: LoadingType;
  size?: any;
}

export default function Spinner({
  color = '#3E91DE',
  className,
  type = 'spinningBubbles',
  size = 64,
}: SpinnerProps) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.container)}>
      <ReactLoading
        className=''
        width={size}
        height={size}
        type={type}
        color={color}
      />
    </div>
  );
}
