import { format } from 'date-fns';
import { Maybe } from 'src/types';

export const formatDate = (day: Maybe<Date | string>) => {
  return format(new Date(day || new Date()), 'MMMM dd, yyyy');
};

export const formatDateDMY = (day: Maybe<Date | string>) => {
  return format(new Date(day || new Date()), 'dd/MM/yy');
};
