import { useEffect } from 'react';

const useDialog = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const onClose = () => {
    document.body.style.overflow = 'auto';
  };

  return {
    onClose,
  };
};

export default useDialog;
