import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  useBackgroundColorStyles,
  useLayoutStyles,
} from 'src/components/useCommonStyles';
import ConnectAPIGuiIcon from 'src/icons/connect-api-gui.svg';
import BarometerAuditTextIcon from 'src/icons/barometer-audit-text.svg';
import VisualizeInsightsIcon from 'src/icons/visualize-insight.svg';
import { useViewport } from 'src/hooks/useViewport';

const HOW_IT_WORKS = [
  {
    icon: ConnectAPIGuiIcon,
    title: 'Collect content and news about brand',
    description:
      'We buy access 3 years of news about a brand for our analysis. We also access policies and handbooks.',
  },
  {
    icon: BarometerAuditTextIcon,
    title: 'Integrate Employee Feedback',
    description:
      'We partner with copmanies employees trust to integrate their anonymous feedback into our analysis.',
  },
  {
    icon: VisualizeInsightsIcon,
    title: 'Visualize insights',
    description:
      'You can visualize the insights about all this content and present actionalbe next steps for risk mitigation across the brand’s content ecosystem.',
  },
];

const HowAuditCaseWorks = () => {
  const { isMobile } = useViewport();
  const layoutClasses = useLayoutStyles();
  const backgroundClasses = useBackgroundColorStyles();

  return (
    <Grid
      container
      className={clsx(
        isMobile ? layoutClasses.padding1 : layoutClasses.padding10,
        backgroundClasses.lightGrayBackground
      )}
    >
      <Grid
        container
        justifyContent='center'
        className={isMobile ? layoutClasses.mb1 : layoutClasses.mb7}
      >
        <Typography align='center' variant='h2'>
          How It Works
        </Typography>
      </Grid>

      <Grid container item xs={12} spacing={3}>
        {HOW_IT_WORKS.map((work) => (
          <Grid key={work.title} item xs={12} md={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <img src={work.icon} alt={work.title} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{work.title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body1'>{work.description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default HowAuditCaseWorks;
