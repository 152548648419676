import clsx from 'clsx';
import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { UserRole } from 'src/types';
import { Theme } from 'src/theme/types/createPalette';

const permissions = [
  {
    role: UserRole.ADMIN,
    label: 'Admin',
    description: 'Full access to web app. View & edit payment details.',
  },
  {
    role: UserRole.STAFF,
    label: 'Edit',
    description: 'Full access to web app. Cannot view & edit payment details.',
  },
  {
    role: UserRole.VIEW,
    label: 'View Only',
    description: 'Access Dashboard. Cannot score content or view/edit payment.',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.gray.main,
    },
    box: {
      cursor: 'pointer',
      background: 'white',
      border: '1px solid black',
      padding: theme.spacing(1),
      margin: theme.spacing(1, 0),
    },
    selectedBox: {
      background: theme.palette.lightGray.main,
    },
    description: {
      lineHeight: 1.1,
    },
  })
);

interface RolePermissionsProps {
  value: string;
  onChange: (value: string) => void;
}

const RolePermissions: FC<RolePermissionsProps> = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='body1' className={classes.label}>
          Permissions:
        </Typography>
      </Grid>

      {permissions.map((permission, index) => (
        <Grid
          key={permission.role}
          item
          xs={12}
          role='button'
          tabIndex={index}
          className={clsx(
            classes.box,
            value === permission.role && classes.selectedBox
          )}
          onClick={() => onChange(permission.role)}
        >
          <Grid container alignItems='center'>
            <Grid item xs={4}>
              <Typography variant='subtitle1' align='center'>
                {permission.label}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='body1' className={classes.description}>
                {permission.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default RolePermissions;
