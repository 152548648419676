import clsx from 'clsx';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import {
  useLayoutStyles,
  useBackgroundColorStyles,
} from 'src/components/useCommonStyles';
import { useViewport } from 'src/hooks/useViewport';
import { SubscriptionDto } from 'src/types';
import { ROUTES } from 'src/utils';

import PricingList from './PricingList';
import ChangePricingTierDialog from './ChangePricingTierDialog';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      background: 'white',
      zIndex: 1100,
      overflowY: 'auto',
    },
  })
);

const Subscription = () => {
  const history = useHistory();
  const { isMobile } = useViewport();
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const backgroundClasses = useBackgroundColorStyles();

  const [plan, setPlan] = useState<Nullable<SubscriptionDto>>(null);

  const handleClose = () => {
    setPlan(null);
  };

  const handleSelectPricing = (plan: SubscriptionDto) => {
    setPlan(plan);
  };

  const handleFinishSubscription = () => {
    history.push(ROUTES.DASHBOARD);
  };

  return (
    <Grid
      container
      justifyContent='center'
      className={clsx(
        classes.container,
        isMobile ? layoutClasses.padding1 : layoutClasses.padding10,
        backgroundClasses.lightGrayBackground
      )}
    >
      {plan !== null && (
        <ChangePricingTierDialog
          plan={plan}
          onClose={handleClose}
          onOK={handleFinishSubscription}
        />
      )}

      <Grid item xs={8}>
        <Grid container className={layoutClasses.mt1}>
          <Grid item xs={12} md={10}>
            <Typography variant='h3' align='left'>
              Please pick a monthly or annual plan:
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          className={isMobile ? layoutClasses.mb1 : layoutClasses.mt6}
        >
          <PricingList onClick={handleSelectPricing} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Subscription;
