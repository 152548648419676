export enum DashboardType {
  MAIN = 'MAIN',
  NORMAL = 'NORMAL',
  DOCUMENT = 'DOCUMENT',
  GARM = 'GARM',
}

export interface DashboardGarmFilter {
  dashboardFilterKey: string;
  enabled: boolean;
  value: number | number[];
}

export interface SelfScoringProcessTypes {
  isRunning: boolean;
  progress: number;
}

export interface DashboardBase {
  name: string;
  type: DashboardType;
  folder?: string | null;
  keywordsList: string[];
  exceptionFilterId: string | null;
  garmFilters?: Record<
    string,
    DashboardGarmFilter | string[] | Nullable<string> | number[]
  >;
  iabCategories?: any[];
  selfScoring?: SelfScoringProcessTypes;
}

export type DashboardCreate = DashboardBase;

export type DashboardUpdate = DashboardBase;

export interface Dashboard extends DashboardBase {
  id: string;
  organizationId: string;
  favorite: boolean;
  documentId?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGarmCategoryEntry {
  title: string;
  jsonKeyRisk: string;
  jsonKeyData: string | null;
  jsonKeyDataHighlight: string | null;
  filterType: string;
  dashboardFilterKey: string;
  tooltip: string;
  tooltipLink?: {
    label: string;
    href: string;
  };
}

export interface IIncompletedGarmCategoryEntry {
  title: string;
  jsonKeyRisk: string;
}

export interface IGarmCategory {
  title: string;
  enabled: boolean;
  tooltip?: string;
  entries: (IGarmCategoryEntry | IIncompletedGarmCategoryEntry)[];
}

export interface DashboardView {
  data: any;
  id: string;
  dashboardId: string;
  title: string;
}
