import { FC, useState, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import ADWEEKImg from 'src/assets/landing/quotes/ADWEEK.png';
import CynopsisImg from 'src/assets/landing/quotes/Cynopsis.png';
import IABLogoImg from 'src/assets/landing/quotes/IABLogo.png';
import MarketingBrewImg from 'src/assets/landing/quotes/MarketingBrew.png';
import OxfordImg from 'src/assets/landing/quotes/Oxford.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      background: '#EFEDEB',
      borderRadius: 2,
      padding: '185px 0 85px',
    },
    mainContainer: {
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      gap: 32,

      '@media screen and (min-width: 768px)': {
        flexDirection: 'row',
        padding: '0 100px',
      },
    },
    logo: {
      height: 50,

      '@media screen and (min-width: 768px)': {
        height: 80,
      },
    },
    source: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '24px',
      color: '#202226',

      '@media screen and (min-width: 768px)': {
        fontSize: 20,
        lineHeight: '40px',
      },
    },
    author: {
      fontWeight: 300,
      fontSize: 18,
      lineHeight: '24px',
      color: '#545454',

      '@media screen and (min-width: 768px)': {
        fontSize: 25,
        lineHeight: '40px',
      },
    },
    quote: {
      fontWeight: 300,
      fontSize: 38,
      lineHeight: '48px',
      color: '#202226',

      '@media screen and (min-width: 768px)': {
        fontSize: 58,
        lineHeight: '64px',
      },
    },
    info: {
      padding: 0,

      '@media screen and (min-width: 768px)': {
        padding: '0 60px',
      },

      '& img': {
        transition: 'transform .25s linear',
        '&:hover': {
          transform: 'scale(1.1);',
        },
      },
    },
    desktopButtons: {
      display: 'none',

      '@media screen and (min-width: 768px)': {
        display: 'flex',
      },
    },
    mobileButtons: {
      display: 'flex',

      '@media screen and (min-width: 768px)': {
        display: 'none',
      },
    },
    button: {
      fontWeight: 300,
      fontSize: 38,
      lineHeight: '48px',
      color: '#545454',

      '@media screen and (min-width: 768px)': {
        fontSize: 60,
        lineHeight: '64px',
      },
    },
    disabled: {
      opacity: 0.32,
    },
  })
);

const BarometerQuotes: FC = () => {
  const classes = useStyles();
  const [step, setStep] = useState(0);

  const quotesContent = [
    {
      source: 'ADWEEK',
      link: 'https://www.google.com/url?q=https://www.adweek.com/partner-articles/its-time-to-meet-the-programmatic-podcast-opportunity/&sa=D&source=editors&ust=1679307682453452&usg=AOvVaw330KhDwQNoHgkj125MVzvQ',
      author: 'Jeremy Randol, VP, Programmatic Sales Strategy, SXM Media',
      date: 'February 2023',
      quote:
        'This reporting feeds brand suitability measurement—as companies like Barometer use AI to comb through transcripts of episodes to assign them a grade. Those grades are matched to the DSP reporting output and advertisers are provided with a summary on where their ads ran and whether the content was brand suitable. This is the third-party validation that the industry needs to get comfortable. And it’s all in a clean and tidy user interface that buyers are already comfortable using.',
      img: ADWEEKImg,
    },
    {
      source: 'Marketing Brew',
      link: 'https://www.google.com/url?q=https://www.marketingbrew.com/stories/2022/12/13/new-ai-tool-can-help-advertisers-vet-podcast-hosts-based-on-news-they-might-make-outside-of-their-shows&sa=D&source=editors&ust=1679307682455745&usg=AOvVaw2jHUxXNBoxJKSmo_p0CAW1',
      author: 'Alyssa Meyers, Marketing Brew Reporter',
      date: 'December 13, 2022',
      quote:
        'New AI tool can help advertisers vet podcast hosts based on news they make outside of their shows',
      img: MarketingBrewImg,
    },
    {
      source: 'IAB',
      link: 'https://www.marketingbrew.com/stories/2022/12/13/new-ai-tool-can-help-advertisers-vet-podcast-hosts-based-on-news-they-might-make-outside-of-their-shows',
      author: 'Alyssa Meyers, Marketing Brew Reporter',
      date: '',
      quote:
        'Eric John, VP of the IAB’s Media Center, said in an email that audio-focused IAB members have indicated “that brands need to have greater trust in their ability to identify brand safe and suitable content” before they fully take advantage of podcasts at scale.',
      img: IABLogoImg,
    },
    {
      source: 'Marketing Brew',
      link: 'https://www.google.com/url?q=https://www.marketingbrew.com/stories/2022/12/13/new-ai-tool-can-help-advertisers-vet-podcast-hosts-based-on-news-they-might-make-outside-of-their-shows&sa=D&source=editors&ust=1679307682458358&usg=AOvVaw0QQYGrriwOHjaEYc7jPl2Z',
      author: 'Jennifer Laine, Oxford Road',
      date: 'December 13, 2022',
      quote:
        'As an agency, it’s virtually impossible” to conduct these audits across the many podcasts its clients advertise on, Laine [Oxford Road] said. Still, “so much of the host controversy actually happens outside the episode.',
      img: OxfordImg,
    },
    {
      source: 'Cynopsis Model D Awards',
      link: 'https://www.cynopsis.com/events/model-d-it-list-awards-2022/',
      author: '',
      date: 'December 7, 2022',
      quote:
        'Oxford Road x Barometer Bring Preeminent AI-Powered Brand Safety and Suitability Solution to Podcasts',
      img: CynopsisImg,
    },
  ];

  const quote = useMemo(() => quotesContent[step], [step]);

  const handleBackStep = () => {
    if (step !== 0) {
      setStep((preview) => preview - 1);
    }
  };

  const handleNextStep = () => {
    if (step !== quotesContent.length - 1) {
      setStep((preview) => preview + 1);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <Box
          display='flex'
          flexDirection='column'
          gridGap={16}
          className={classes.info}
        >
          <a href={quote.link} rel='noreferrer' target='_blank'>
            <img className={classes.logo} src={quote.img} alt='quote logo' />
          </a>

          <Box>
            <Typography className={classes.source}>{quote.source}</Typography>
            <Typography className={classes.author}>{quote.author}</Typography>
          </Box>
          <Box
            width={200}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            className={classes.desktopButtons}
          >
            <Button
              className={classNames(
                classes.button,
                step === 0 && classes.disabled
              )}
              onClick={handleBackStep}
              disabled={step === 0}
            >
              ←
            </Button>
            <Button
              className={classNames(
                classes.button,
                step === quotesContent.length && classes.disabled
              )}
              onClick={handleNextStep}
              disabled={step === quotesContent.length}
            >
              →
            </Button>
          </Box>
        </Box>
        <Typography className={classes.quote}>“{quote.quote}“</Typography>
        <Box
          width={200}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          className={classes.mobileButtons}
        >
          <Button
            className={classNames(
              classes.button,
              step === 0 && classes.disabled
            )}
            onClick={handleBackStep}
            disabled={step === 0}
          >
            ←
          </Button>
          <Button
            className={classNames(
              classes.button,
              step === quotesContent.length - 1 && classes.disabled
            )}
            onClick={handleNextStep}
            disabled={step === quotesContent.length - 1}
          >
            →
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default BarometerQuotes;
