import { useState, useEffect } from 'react';
import {
  Box,
  MenuItem,
  Typography,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { HeaderCell } from 'src/containers/dashboard/garm/components';
import { useTextStyles } from 'src/hooks/useTextStyles';
import { ReviewDataProps, ReviewedDataProps } from 'src/types/selfScoring';
import Button from 'src/components/NewButton';
import { sendManualReviews } from 'src/apis/selfScoring';
import useNotification from 'src/hooks/useNotification';

import { ReactComponent as ArrowUpIcon } from 'src/assets/icons/arrowUp.svg';
import { ReactComponent as RemoveIcon } from 'src/assets/icons/trash.svg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 14,
    },
    showList: {
      borderRadius: 8,
      border: '1px solid #EAECF0',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      '& .header': {
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        position: 'relative',

        '& .count': {
          padding: '2px 6px',
          borderRadius: 6,
          border: '1px solid #D0D5DD',
        },

        '& .attention': {
          borderRadius: 16,
          border: '1px solid #FECDCA',
          background: '#FEF3F2',
          mixBlendMode: 'multiply',
          padding: '2px 8px',
          position: 'absolute',
          right: 16,
          top: '50%',
          transform: 'translate(0, -50%)',
        },
      },
      '& .content': {
        padding: 16,
        overflow: 'auto',
        maxHeight: 230,
        borderTop: '1px solid #EAECF0',

        '& .description': {
          marginBottom: 16,
        },

        '& .missing-actions': {
          marginTop: 4,
          display: 'flex',
          alignItems: 'center',
          gap: 16,
        },
      },
    },
    table: {
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      border: '1px solid #EAECF0',
      borderRadius: 8,
      background: '#fff',
      height: '100%',
      borderCollapse: 'collapse',

      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          borderBottom: '1px solid #EAECF0',

          '&:last-child': {
            borderBottom: 'none',
          },

          '& .MuiTableCell-root': {
            borderBottom: 'none',
            borderRight: '1px solid #EAECF0',

            '&:last-child': {
              borderRight: 'none',
            },
          },
        },
      },
    },
    matchedStatus: {
      display: 'inline-block',
      borderRadius: 16,
      border: '1px solid #ABEFC6',
      background: '#ECFDF3',
      mixBlendMode: 'multiply',
      padding: '2px 8px',
    },
    newStatus: {
      display: 'inline-block',
      borderRadius: 16,
      border: '1px solid #EAECF0',
      background: '#F9FAFB',
      mixBlendMode: 'multiply',
      padding: '2px 8px',
    },
    selectOption: {
      borderRadius: 8,
      border: '1px solid #D0D5DD',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      padding: '2px 14px',
      width: '100%',
      maxWidth: 220,

      '&::after, &::before': {
        display: 'none',
      },
    },
    removeIcon: {
      borderRadius: 8,
      border: '1px solid #D0D5DD',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      padding: 8,
      height: 34,
      width: 34,
      cursor: 'pointer',
      margin: 'auto',
    },
    input: {
      borderRadius: 8,
      border: '1px solid #D0D5DD',
      background: '#FFF',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      width: '100%',
      padding: '8px 16px',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    sentBtn: {
      border: '1px solid #5EA1F2',
      background: '#5EA1F2',
      cursor: 'not-allowed',

      '& > span': {
        color: '#fff',
      },
    },
  })
);

const Review_Types = [
  {
    type: 'success',
    title: 'Successful Submissions',
    description:
      'The following shows have been matched with existing shows in the Barometer database or have been added to the queue for scoring.',
    fields: ['Show', 'RSS Feed', 'Status'],
  },
  {
    type: 'suggested',
    title: 'Review Suggested Shows',
    description:
      'We couldn’t find an exact match for the shows below. Please review suggestions for similar titles and pick the one you meant. If you don’t see the right show, select “None” and add it will be added to “Missing Shows” beneath.',
    fields: ['Show', 'RSS Feed', 'Suggested Similar Show(s)'],
  },
  {
    type: 'invalid',
    title: 'Invalid RSS Shows',
    description:
      'If you are unable to locate the RSS feed or need help, all unmatched shows will be sent to the Barometer team for manual processing.',
    fields: ['Show', 'Add RSS Feed', ''],
  },
  {
    type: 'unmatched',
    title: 'Unmatched Shows',
    description:
      'If you are unable to locate the RSS feed or need help, all unmatched shows will be sent to the Barometer team for manual processing.',
    fields: ['Show', 'Add RSS Feed', ''],
  },
];

type Types = 'suggested' | 'invalid' | 'unmatched';

interface ReviewStepProps {
  data: ReviewDataProps | null;
  reviewedData: ReviewedDataProps | null;
  hasSuggestedErr?: boolean;
  hasInvalidErr?: boolean;
  hasUnmatchedErr?: boolean;
  duplicatedCount?: number;
  setReviewedData: (reviewedData: ReviewedDataProps | null) => void;
}

export const ReviewStep = ({
  data,
  reviewedData,
  hasSuggestedErr = false,
  hasInvalidErr = false,
  hasUnmatchedErr = false,
  duplicatedCount = 0,
  setReviewedData,
}: ReviewStepProps) => {
  const classes = useStyles();
  const textClasses = useTextStyles();

  const [openedIndx, setOpenedIndex] = useState<number>(-1);
  const [sentManualInvalidReview, setSentManualInvalidReview] =
    useState<boolean>(false);
  const [sentManualUnmatchedReview, setSentManualUnmatchedReview] =
    useState<boolean>(false);

  const { handleError, handleSuccess } = useNotification();

  const handleOpenContent = (index: number) => {
    if (openedIndx === index) {
      setOpenedIndex(-1);
    } else {
      setOpenedIndex(index);
    }
  };

  const handleRemoveMissing = (type: Types) => {
    if (reviewedData) {
      const temp = { ...reviewedData };
      temp[type] = [];
      setReviewedData(temp);
    }
  };

  const handleSendManualReview = async (type: Types) => {
    try {
      if (reviewedData && reviewedData[type] && reviewedData[type].length) {
        await sendManualReviews(reviewedData[type]);
        if (type === 'invalid') {
          setSentManualInvalidReview(true);
          handleSuccess('Invalid RSS Shows sent for manual review');
        } else {
          setSentManualUnmatchedReview(true);
          handleSuccess('Unmatched Shows sent for manual review');
        }

        handleRemoveMissing(type);
      }
    } catch (e) {
      console.log(e);
      handleError(e, 'Please try again later');
    }
  };

  useEffect(() => {
    const newData = { ...data };
    setReviewedData({
      suggested:
        newData['suggested']?.map((item) => ({
          showName: item.showName,
          rss: item.rss,
          suggestedShows: item.suggestedShows || [],
          selectedShow: '',
        })) || [],
      invalid:
        newData['invalid']?.map((item) => ({
          showName: item.showName,
          rss: item.rss,
        })) || [],
      unmatched:
        newData['unmatched']?.map((item) => ({
          showName: item.showName,
          rss: '',
        })) || [],
    });
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <Box className={classes.container}>
      {Review_Types.map((item, index) => (
        <Box key={item.type} className={classes.showList}>
          <Box className='header' onClick={() => handleOpenContent(index)}>
            <ArrowUpIcon
              cursor='pointer'
              style={
                index === openedIndx ? {} : { transform: 'rotate(180deg)' }
              }
              onClick={() => handleOpenContent(index)}
            />
            <Typography className={textClasses.smBold}>{item.title}</Typography>
            <Box className='count'>
              <Typography className={textClasses.xsMedium}>
                {
                  data[
                    item.type as
                      | 'success'
                      | 'suggested'
                      | 'invalid'
                      | 'unmatched'
                  ].length
                }
              </Typography>
            </Box>
            {index > 0 ? (
              <Box className='attention'>
                <Typography
                  className={textClasses.xsMedium}
                  style={{ color: '#B42318' }}
                >
                  Needs Attention
                </Typography>
              </Box>
            ) : (
              !!duplicatedCount && (
                <Box className='attention'>
                  <Typography
                    className={textClasses.xsMedium}
                    style={{ color: '#B42318' }}
                  >
                    {duplicatedCount} duplicated rows found.
                  </Typography>
                </Box>
              )
            )}
          </Box>
          {index === openedIndx && (
            <Box className='content'>
              <Box className='description'>
                <Typography className={textClasses.smRegular}>
                  {item.description}
                </Typography>
                {index > 1 && (
                  <Box className='missing-actions'>
                    <Button
                      title={
                        (index === 2 && sentManualInvalidReview) ||
                        (index === 3 && sentManualUnmatchedReview)
                          ? 'Shows sent for manual review'
                          : 'Send Show Names for Manual Review'
                      }
                      fontSize='sm'
                      variant='outlined'
                      height={28}
                      disabled={
                        (index === 2 && sentManualInvalidReview) ||
                        (index === 3 && sentManualUnmatchedReview)
                      }
                      onClick={() => handleSendManualReview(item.type as Types)}
                      className={
                        (index === 2 && sentManualInvalidReview) ||
                        (index === 3 && sentManualUnmatchedReview)
                          ? classes.sentBtn
                          : ''
                      }
                    />
                    <Button
                      title='Remove All'
                      fontSize='sm'
                      variant='outlined'
                      height={28}
                      onClick={() => handleRemoveMissing(item.type as Types)}
                    />
                  </Box>
                )}
              </Box>

              <TableContainer component={Paper}>
                <Table className={classes.table}>
                  <TableHead
                    style={{
                      height: 48,
                    }}
                  >
                    <TableRow>
                      {item.fields.map((column: string) => (
                        <HeaderCell
                          key={column}
                          style={{
                            padding: '4px 10px',
                            borderRadius: 0,
                            borderBottom: 'none',
                            background: '#F8F8F8',
                          }}
                        >
                          <Typography
                            className={textClasses.smBold}
                            style={{
                              color: '#475467',
                            }}
                          >
                            {column}
                          </Typography>
                        </HeaderCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.type === 'success' &&
                      data['success'].map((successData, index) => (
                        <TableRow
                          key={index}
                          style={{
                            height: '48px',
                          }}
                        >
                          <TableCell
                            style={{
                              borderRight: '1px solid var(--gray-200, #EAECF0)',
                              width: '30%',
                            }}
                          >
                            <Typography
                              className={textClasses.xsRegular}
                              style={{
                                color: '#475467',
                              }}
                            >
                              {successData.showName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={textClasses.xsRegular}
                              style={{
                                color: '#475467',
                              }}
                            >
                              {successData.rss}
                            </Typography>
                          </TableCell>
                          <TableCell style={{ width: '30%' }}>
                            {successData.status === 'New' ? (
                              <Box className={classes.newStatus}>
                                <Typography
                                  className={textClasses.xsMedium}
                                  style={{
                                    color: '#344054',
                                  }}
                                >
                                  Net New RSS Feed
                                </Typography>
                              </Box>
                            ) : (
                              <Box className={classes.matchedStatus}>
                                <Typography
                                  className={textClasses.xsMedium}
                                  style={{
                                    color: '#067647',
                                  }}
                                >
                                  Matched with Existing
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    {item.type === 'suggested' &&
                      reviewedData?.suggested.map((suggestedData, index) => (
                        <TableRow
                          key={index}
                          style={{
                            height: '48px',
                          }}
                        >
                          <TableCell
                            style={{
                              borderRight: '1px solid var(--gray-200, #EAECF0)',
                              width: '30%',
                            }}
                          >
                            <Typography
                              className={textClasses.xsRegular}
                              style={{
                                color: '#475467',
                              }}
                            >
                              {suggestedData.showName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              className={textClasses.xsRegular}
                              style={{
                                color: '#475467',
                              }}
                            >
                              {suggestedData.rss}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              width: '30%',
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Select
                              id='suggestion-select'
                              className={classes.selectOption}
                              value={suggestedData.selectedShow}
                              onChange={(event) => {
                                const temp = { ...reviewedData };
                                temp.suggested[index].selectedShow = event
                                  .target.value as string;
                                setReviewedData(temp);
                              }}
                            >
                              {['None', ...suggestedData.suggestedShows].map(
                                (suggestedShow) => (
                                  <MenuItem
                                    key={suggestedShow}
                                    value={suggestedShow}
                                  >
                                    {suggestedShow}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </TableCell>
                        </TableRow>
                      ))}
                    {reviewedData &&
                      (item.type === 'invalid' || item.type === 'unmatched') &&
                      reviewedData[item.type].map((missingData, index) => (
                        <TableRow
                          key={index}
                          style={{
                            height: '48px',
                          }}
                        >
                          <TableCell
                            style={{
                              borderRight: '1px solid var(--gray-200, #EAECF0)',
                              width: '30%',
                            }}
                          >
                            <Typography
                              className={textClasses.xsRegular}
                              style={{
                                color: '#475467',
                              }}
                            >
                              {missingData.showName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              padding: 10,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <input
                              className={classes.input}
                              placeholder='e.g. https://feeds.simplecast.com/'
                              value={missingData.rss}
                              onChange={(event) => {
                                const temp = { ...reviewedData };
                                temp[item.type as Types][index].rss = event
                                  .target.value as string;
                                setReviewedData(temp);
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align='center'
                            style={{
                              minWidth: 64,
                              maxWidth: 64,
                              width: 64,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            <Box
                              className={classes.removeIcon}
                              onClick={() => {
                                const temp = { ...reviewedData };
                                temp[item.type as Types].splice(index, 1);
                                setReviewedData(temp);
                              }}
                            >
                              <RemoveIcon width={16} height={16} />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      ))}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          marginTop: 'auto',
        }}
      >
        {(hasSuggestedErr ||
          (!sentManualInvalidReview && hasInvalidErr) ||
          (!sentManualUnmatchedReview && hasUnmatchedErr)) && (
          <Typography
            className={textClasses.xsMedium}
            style={{ color: '#B42318' }}
          >
            Please review all suggested, invalid, unmatched shows before submit.
          </Typography>
        )}
      </Box>
    </Box>
  );
};
