export const localConfig = {
  projectId: 'vericrypt-web-app',
  apiUrl: 'http://localhost:8080/api',
  googleRecaptchaKey: '6LecR10aAAAAAAjuw96vSBCRqTkC8Fcb-sqdqx9j',
  stripePublicKey: 'pk_test_HKKCd7BHxLb8zjIyIg4BZcTg00LaTf8RLI',
  gaTrackingId: '',
  showLogoDirectory: 'https://cdn.thebarometer.com/images/showlogos/',
};

export const developmentConfig = {
  projectId: 'vericrypt-web-app',
  apiUrl: 'https://api-dev.thebarometer.co/api',
  googleRecaptchaKey: '6LecR10aAAAAAAjuw96vSBCRqTkC8Fcb-sqdqx9j',
  stripePublicKey: 'pk_test_HKKCd7BHxLb8zjIyIg4BZcTg00LaTf8RLI',
  gaTrackingId: '',
  showLogoDirectory: 'https://cdn.thebarometer.com/images/showlogos/',
};

export const testConfig = {
  projectId: 'vericrypt-web-app',
  apiUrl: 'https://api-test.thebarometer.co/api',
  googleRecaptchaKey: '6LecR10aAAAAAAjuw96vSBCRqTkC8Fcb-sqdqx9j',
  stripePublicKey: 'pk_test_HKKCd7BHxLb8zjIyIg4BZcTg00LaTf8RLI',
  gaTrackingId: '',
  showLogoDirectory: 'https://cdn.thebarometer.com/images/showlogos/',
};

export const prodConfig = {
  projectId: 'vericrypt-web-app',
  apiUrl: 'https://api.thebarometer.co/api',
  googleRecaptchaKey: '6LecR10aAAAAAAjuw96vSBCRqTkC8Fcb-sqdqx9j',
  stripePublicKey: 'pk_live_bnmFh5yUj9BqrHlbn1n2JVq900oHVSRqHM',
  gaTrackingId: 'UA-193154198-2',
  showLogoDirectory: 'https://cdn.thebarometer.com/images/showlogos/',
};

let config = localConfig;

if (process.env.REACT_APP_ENV === 'development') {
  config = developmentConfig;
} else if (process.env.REACT_APP_ENV === 'test') {
  config = testConfig;
} else if (process.env.REACT_APP_ENV === 'prod') {
  config = prodConfig;
}

export default config;
