import { useState } from 'react';
import {
  Button,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableSortLabel,
  TablePagination,
  Paper,
  styled,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { format } from 'date-fns';
import { Chart } from 'chart.js';

import { ReportGraph as ReportGraphType } from 'src/types';
import { options } from './ReportGraph';

export const LinkButton = styled(Button)(() => ({
  fontSize: 14,
  fontWeight: 500,
}));

const StyledTableCell = styled(TableCell)(() => ({
  padding: '4px 16px',
}));

const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      display: 'flex',
      alignItems: 'center',
      gap: 30,
    },
    pagination: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
    },
  })
);

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface ReportTableProps {
  data: any;
  deleteReport: (id: string) => void;
  viewReport: (data: ReportGraphType) => void;
}

export default function ReportTable({
  data,
  deleteReport,
  viewReport,
}: ReportTableProps) {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = async (data: ReportGraphType) => {
    const canvas = document.getElementById(
      'download-graph'
    ) as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    const plugin = {
      id: 'download-graph',
      beforeDraw: (chart: any) => {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    };
    if (ctx) {
      const myChart = new Chart(ctx, {
        type: 'bar',
        data,
        options: {
          ...options,
          animation: false,
        },
        plugins: [plugin],
      });
      const chartData = myChart.toBase64Image();
      const link = document.createElement('a');
      link.href = chartData;
      link.download = 'report.png';
      link.click();
      link.remove();
      myChart.destroy();
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>No</TableCell>
            <TableCell
              align='left'
              sortDirection={orderBy === 'createdAt' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'createdAt'}
                direction={orderBy === 'createdAt' ? order : 'asc'}
                onClick={() => handleRequestSort('createdAt')}
              >
                Date Created
              </TableSortLabel>
            </TableCell>
            <TableCell
              align='left'
              sortDirection={orderBy === 'dashboardName' ? order : false}
            >
              <TableSortLabel
                active={orderBy === 'dashboardName'}
                direction={orderBy === 'dashboardName' ? order : 'asc'}
                onClick={() => handleRequestSort('dashboardName')}
              >
                Title of Dashboard Reported
              </TableSortLabel>
            </TableCell>
            <TableCell align='center'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item: any, index: number) => (
              <TableRow key={item.key}>
                <StyledTableCell align='center'>{index + 1}</StyledTableCell>
                <StyledTableCell align='left'>
                  {format(
                    new Date(item.createdAt || new Date()),
                    'MMMM dd, yyyy'
                  )}
                </StyledTableCell>
                <StyledTableCell align='left'>
                  {item.dashboardName}
                </StyledTableCell>
                <StyledTableCell align='center'>
                  <div className={classes.actions}>
                    <LinkButton
                      color='primary'
                      disableElevation
                      variant='text'
                      size='small'
                      onClick={() => {
                        viewReport(item.data);
                      }}
                    >
                      View Report
                    </LinkButton>
                    <LinkButton
                      color='primary'
                      disableElevation
                      startIcon={<CloudDownloadIcon />}
                      variant='text'
                      size='small'
                      onClick={() => {
                        handleDownload(item.data);
                      }}
                    >
                      .PNG
                    </LinkButton>
                    <LinkButton
                      color='primary'
                      disableElevation
                      startIcon={<DeleteIcon />}
                      variant='text'
                      size='small'
                      onClick={() => {
                        deleteReport(item.id);
                      }}
                    >
                      Delete
                    </LinkButton>
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div className={classes.pagination}>
        <TablePagination
          rowsPerPageOptions={[5]}
          component='div'
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <canvas
        id='download-graph'
        width='800'
        height='400'
        style={{ display: 'none', backgroundColor: '#ffffff' }}
      ></canvas>
    </TableContainer>
  );
}
