import { useMemo } from 'react';
import { useQuery } from 'react-query';

import queryKeys from './queryKeys';
import { SubscriptionTier, User, Member } from 'src/types';
import { QueryStatus } from 'src/types/general';
import { baseAPICreator } from './apiCreator';
import {
  BillingInterval,
  OrganizationBase,
  Organization,
  OrganizationRegisterPayload,
  TUpdateOrganizationKPIs,
} from 'src/types';

const organizationApi = baseAPICreator('/organization');

export const listOrganizations = async (): Promise<Organization[]> => {
  const { data } = await organizationApi.get('');
  return data;
};

export const fetchOrganization = async (
  organizationId: string
): Promise<Organization> => {
  const { data } = await organizationApi.get(`/${organizationId}`);
  return data;
};

export const fetchOrganizationMembers = async (
  organizationId: string
): Promise<User[]> => {
  const { data } = await organizationApi.get(`/${organizationId}/members`);
  return data;
};

export const registerOrganization = async (
  organization: OrganizationBase
): Promise<OrganizationRegisterPayload> => {
  const { data } = await organizationApi.post('/', organization);
  return data;
};

export const updateSubscriptionTier = async (
  subscriptionTier: SubscriptionTier,
  billingInterval?: BillingInterval
) => {
  const { data } = await organizationApi.put('/subscription-tier', {
    subscriptionTier,
    billingInterval,
  });
  return data;
};

export const updateOrganizationKPIs = async (body: TUpdateOrganizationKPIs) => {
  const { data } = await organizationApi.put('/KPIs', body);
  return data;
};

export const addOrganizationDashboard = async (dashboard: string) => {
  const { data } = await organizationApi.post('/dashboard', { dashboard });
  return data;
};

export const useGetOrganization = (organizationId: string) => {
  const { status, data, error, refetch } = useQuery(
    [queryKeys.GET_ORGANIZATION, organizationId],
    async () => {
      const response = await fetchOrganization(organizationId);
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    organization: data as Organization,
    error,
    refetch,
  };
};

export const useGetOrganizations = () => {
  const { status, data, error, refetch } = useQuery(
    [queryKeys.LIST_ORGANIZATIONS],
    async () => {
      const response = await listOrganizations();
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    organizations: (data || []) as Organization[],
    error,
    refetch,
  };
};

export const useGetOrganizationMembers = (organizationId?: string) => {
  const {
    status,
    data: members,
    error,
    refetch,
  } = useQuery(
    [queryKeys.GET_ORGANIZATION_MEMBERS, organizationId],
    async () => {
      if (!organizationId) return [];
      const response = await fetchOrganizationMembers(organizationId);
      return response as Member[];
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const teamMembers = useMemo(
    () => (members || []).filter((user) => !user.invited),
    [members]
  );

  const invitedMembers = useMemo(
    () => (members || []).filter((user) => user.invited),
    [members]
  );

  return {
    status,
    loading: status === QueryStatus.LOADING,
    members,
    teamMembers,
    invitedMembers,
    error,
    refetch,
  };
};

export const updateOrganizationPrimaryDashboard = async (
  primaryDashboardId: string
): Promise<Organization> => {
  const { data } = await organizationApi.put('/primary-dashboard', {
    primaryDashboardId,
  });
  return data;
};
