import MissionIntroduction from './MissionIntroduction';
import Founders from './Founders';
import SpotlightSubscribe from '../Spotlight/SpotlightSubscribe';
import UnauthorizedContainWrapper from '../UnauthorizedContainWrapper';

export default function OurMission() {
  return (
    <UnauthorizedContainWrapper>
      <MissionIntroduction />
      <Founders />
      <SpotlightSubscribe />
    </UnauthorizedContainWrapper>
  );
}
