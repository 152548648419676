import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import AppBar from 'src/containers/sidebar/AppBar';
import AcceptInvitation from 'src/containers/auth/AcceptInvitation';
import ConfirmResetCodeScreen from 'src/containers/auth/ConfirmResetCodeScreen';
import ForgotPasswordScreen from 'src/containers/auth/ForgotPasswordScreen';
import LogInScreen from 'src/containers/auth/LoginScreen';
import ResetPasswordScreen from 'src/containers/auth/ResetPasswordScreen';
import CookiePolicyBox from 'src/containers/CookiePolicyBox';
import Dashboard from 'src/containers/dashboard/Dashboard';
import BarometerCookiePolicy from 'src/containers/documentation/BarometerCookiePolicy';
import BarometerDocumentation from 'src/containers/documentation/BarometerDocumentation';
import BarometerTOC from 'src/containers/documentation/BarometerTOC';
import AuditScreen from 'src/containers/onboarding/AuditScreen';
import LandingScreen from 'src/containers/onboarding-redesign/Landing';
import Blog from './containers/onboarding-redesign/Blog';

{
  /** temporarily hide the Spotlight page */
}
// import SpotlightScreen from './containers/onboarding-redesign/Spotlight';
import BarometerTeam from 'src/containers/onboarding-redesign/Mission';
import Subscription from 'src/containers/subscription/Subscription';
// import AccountDetails from 'src/containers/settings/AccountDetails';
import { BrandStandardFilters } from 'src/containers/settings/BrandStandardFilters';
import { APIKey } from 'src/containers/settings/APIKey';
import { SelfScoringHistory } from './containers/settings/SelfScoringHistory';
import Reports from 'src/containers/reports';
import TeamManagement from 'src/containers/team/TeamManagement';
import useAuth from 'src/hooks/useAuth';
import { DashboardFilterProvider } from 'src/hooks/useDashboardFilters';
import { AuthenticatedLayout } from 'src/layouts';
import RouteChangeTracker from 'src/RouteChangeTracker';
import Search from './containers/search';
import { ROUTES } from 'src/utils';

export default function Routes() {
  const { isAuthenticated, isLoading, organization } = useAuth();

  if (isLoading || (isAuthenticated && !organization)) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const AuthenticatedRoutes = (
    <DashboardFilterProvider>
      <AuthenticatedLayout>
        <AppBar />

        <Switch>
          <Route exact key='reset-password' path={ROUTES.RESET_PASSWORD}>
            <ResetPasswordScreen />
          </Route>
          <Route exact key='dashboard' path={ROUTES.DASHBOARD}>
            <Dashboard />
          </Route>
          <Route exact key='dashboard' path={ROUTES.DASHBOARD + '/:env'}>
            <Dashboard />
          </Route>
          <Route exact key='reports' path={ROUTES.REPORTS}>
            <Reports />
          </Route>
          <Route exact key='search' path={ROUTES.SEARCH}>
            <Search />
          </Route>
          <Route key='settings' path={ROUTES.SETTINGS}>
            <Route
              exact
              key='settings/standard-filters'
              path={ROUTES.STANDARD_FILTERS}
            >
              <BrandStandardFilters />
            </Route>
            <Route exact key='settings/api-key' path={ROUTES.API_KEY}>
              <APIKey />
            </Route>
            <Route exact key='settings/self-scoring' path={ROUTES.SELF_SCORING}>
              <SelfScoringHistory />
            </Route>
          </Route>
          <Route exact key='team' path={ROUTES.TEAM}>
            <TeamManagement />
          </Route>
          <Route exact key='documentation' path={ROUTES.DOCUMENTATION}>
            <BarometerDocumentation />
          </Route>
          <Route path={ROUTES.COOKIE_POLICY}>
            <BarometerCookiePolicy />
          </Route>
          <Route path={ROUTES.SUBSCRIPTION}>
            <Subscription />
          </Route>
          <Redirect to={ROUTES.DASHBOARD} />
        </Switch>
      </AuthenticatedLayout>
    </DashboardFilterProvider>
  );

  const NonAuthenticatedRoutes = (
    <Switch>
      {/** temporarily hide the Spotlight page */}
      {/* <Route exact key='spotlight' path={ROUTES.SPOTLIGHT}>
        <SpotlightScreen />
      </Route> */}
      <Route exact key='landing' path={ROUTES.LANDING}>
        <LandingScreen />
      </Route>
      <Route exact key='audit' path={ROUTES.AUDIT}>
        <AuditScreen />
      </Route>
      {/* <Route exact key='register' path={ROUTES.REGISTER}>
        <RegisterScreen />
      </Route> */}
      <Route exact key='accpet-invitation' path={ROUTES.ACCEPT_INVITATION}>
        <AcceptInvitation />
      </Route>
      <Route exact key='login' path='/login'>
        <LogInScreen />
      </Route>
      <Route exact key='forgot-password' path={ROUTES.FORGOT_PASSWORD}>
        <ForgotPasswordScreen />
      </Route>
      <Route exact key='confirm-reset-code' path={ROUTES.CONFIRM_RESET_CODE}>
        <ConfirmResetCodeScreen />
      </Route>
      <Route key='toc' path={ROUTES.TOC}>
        <BarometerTOC />
      </Route>
      <Route key='team' path={ROUTES.TEAM}>
        <BarometerTeam />
      </Route>
      <Route key='blog' path={ROUTES.BLOG}>
        <Blog />
      </Route>
      <Route path={ROUTES.COOKIE_POLICY}>
        <BarometerCookiePolicy />
      </Route>
      <Route path={ROUTES.SUBSCRIPTION}>
        <Subscription />
      </Route>
      <Redirect to={ROUTES.LANDING} />
    </Switch>
  );

  return (
    <BrowserRouter>
      {isAuthenticated ? AuthenticatedRoutes : NonAuthenticatedRoutes}

      {!isAuthenticated && <CookiePolicyBox />}

      <RouteChangeTracker />
    </BrowserRouter>
  );
}
