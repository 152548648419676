import { FC } from 'react';
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import clsx from 'clsx';

const useInfoTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    textInfo: {
      borderBottomStyle: 'dashed',
      borderBottomWidth: 2,
      borderBottomColor: 'inherit',
      cursor: 'pointer',
    },
    title: {
      color: theme.palette.common.white,
      marginBottom: theme.spacing(0.25),
    },
    tooltip: {
      padding: theme.spacing(1, 1.5),
      color: theme.palette.grey[200],
    },
    icon: {
      padding: 0,
    },
  })
);

export interface InfoTooltipProps
  extends Omit<TooltipProps, 'children' | 'title' | 'content'> {
  title?: React.ReactNode;
  content?: React.ReactNode | React.ReactNodeArray;
  children?: React.ReactNode | React.ReactNodeArray;
  variant?: 'icon' | 'text';
}

const InfoTooltip: FC<InfoTooltipProps> = ({
  title,
  content,
  children,
  variant = 'icon',
  classes: propClasses,
  ...props
}) => {
  const classes = useInfoTooltipStyles();
  const { tooltip: propClassesTooltip, ...restPropClasses } = propClasses || {};
  return (
    <Tooltip
      arrow
      interactive
      title={
        <>
          {Boolean(title) && (
            <Typography className={classes.title}>{title}</Typography>
          )}
          {content}
        </>
      }
      classes={{
        tooltip: clsx(classes.tooltip, propClassesTooltip),
        ...restPropClasses,
      }}
      {...props}
    >
      {variant === 'icon' ? (
        <IconButton aria-label='More info' className={classes.icon}>
          {children}
        </IconButton>
      ) : (
        <span aria-label='More info' className={classes.textInfo}>
          {children}
        </span>
      )}
    </Tooltip>
  );
};

export default InfoTooltip;
