import { FC, MouseEventHandler } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Button from 'src/components/Button';
import heroBackground from 'src/assets/landing/heroBG.png';
import heroMobileBackground from 'src/assets/landing/heroMobileBG.png';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      position: 'relative',
      height: 500,
      background: '#EFEDEB',

      '@media screen and (min-width: 768px)': {
        height: 720,
      },
    },
    mainContainer: {
      height: '100%',
      width: '100%',
      maxWidth: 1600,
      margin: 'auto',
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      paddingBottom: 70,
      position: 'relative',

      '@media screen and (min-width: 768px)': {
        padding: '0 100px',
      },
    },
    heroDesktopBG: {
      display: 'none',
      position: 'absolute',
      top: 0,
      width: '100%',
      height: 720,

      '@media screen and (min-width: 768px)': {
        display: 'block',
      },
    },
    heroMobileBG: {
      display: 'block',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: 360,

      '@media screen and (min-width: 768px)': {
        display: 'none',
      },
    },
    title: {
      maxWidth: 1300,
      fontWeight: 300,
      fontSize: 38,
      lineHeight: '48px',
      textTransform: 'uppercase',
      color: '#202226',

      '@media screen and (min-width: 768px)': {
        fontSize: 76,
        lineHeight: '96px',
      },
    },
    brands: {
      maxWidth: 1200,
      display: 'flex',
      marginTop: 4,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      gap: 12,

      '@media screen and (min-width: 768px)': {
        gap: 40,
      },
      '& > span': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: '#545454',
      },
    },
    scheduleDemoButton: {
      background:
        'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      borderRadius: 2,
      padding: '6px 16px',
      height: 40,
      marginTop: 36,

      '&:hover': {
        background:
          'linear-gradient(259.81deg, #E671A9 -4.16%, #9873CB 49.59%, #7570DF 84.28%, #736FD5 126.47%)',
      },

      '& > .MuiButton-label': {
        display: 'flex',
        alignItems: 'center',
        gap: 45,
        fontSize: 16,
        lineHeight: '24px',
        color: '#EFEDEB',
        fontWeight: 500,
      },
    },
  })
);

interface BarometerIntroductionProps {
  onScheduleDemo: MouseEventHandler;
}

const BarometerIntroduction: FC<BarometerIntroductionProps> = ({
  onScheduleDemo,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <img
        src={heroBackground}
        alt='heroBG desktop'
        className={classes.heroDesktopBG}
      />
      <img
        src={heroMobileBackground}
        alt='heroBG mobile'
        className={classes.heroMobileBG}
      />
      <div className={classes.mainContainer}>
        <div className={classes.title}>
          AI-POWERED, OMNICHANNEL, CONTEXTUAL ENGINE FOR INCLUSIVE BRAND
          SUITABILITY
        </div>
        <div className={classes.brands}>
          <span>
            Brand
            <br />
            Safety
          </span>

          <span>
            Creator
            <br />
            Intelligence
          </span>
          <span>
            Contextual
            <br />
            Targeting
          </span>
          <span>
            Brand
            <br />
            Suitability
          </span>
        </div>
        <div>
          <Button
            className={classes.scheduleDemoButton}
            color='primary'
            onClick={onScheduleDemo}
          >
            <span>Request a Demo</span>
            <span>→</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BarometerIntroduction;
