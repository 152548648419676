import {
  Dashboard,
  DashboardCreate,
  DashboardUpdate,
} from 'src/types/dashboard';
import { baseAPICreator } from './apiCreator';

const dashboardApi = baseAPICreator('/dashboard');

export const createDashboard = async (
  apiKey: string,
  dashboard: DashboardCreate
): Promise<Dashboard> => {
  const { data } = await dashboardApi.post(`?secretKey=${apiKey}`, dashboard);
  return data;
};

export const updateDashboard = async (
  apiKey: string,
  dashboardId: string,
  dashboard: DashboardUpdate
) => {
  const { data } = await dashboardApi.put(
    `/${dashboardId}?secretKey=${apiKey}`,
    dashboard
  );
  return data;
};

export const deleteDashboard = async (apiKey: string, dashboardId: string) => {
  const { data } = await dashboardApi.delete(
    `/${dashboardId}?secretKey=${apiKey}`
  );
  return data;
};

export const toggleFavoriteDashboard = async (
  apiKey: string,
  dashboardId: string,
  favorite: boolean
) => {
  const { data } = await dashboardApi.put(
    `/${dashboardId}/favorite?secretKey=${apiKey}`,
    {
      favorite,
    }
  );
  return data;
};
