import clsx from 'clsx';
import React, { useState } from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  styled,
  Typography,
} from '@material-ui/core';
import { FixedSizeArray } from 'src/types';

import { PaperContainer } from '../styles';

interface TriSelectorOption {
  name: string;
  count: number;
}

interface TriSelectorProps {
  onChange?: (x: TriSelectorOption) => void;
  value?: FixedSizeArray<3, TriSelectorOption>;
}

const SelectorContainer = styled(Grid)(({ theme }) => ({
  background: '#BDBDBD',
  borderRadius: '50%',
  color: theme.palette.common.white,
  cursor: 'pointer',
  fontSize: 14,
  '& .SmallText': {
    fontSize: 11,
  },
}));

const TriSelectorRow = styled(Grid)(({ theme }) => ({
  height: '100%',
  '& .SelectorParent': {
    height: '100%',
    placeContent: 'space-around',
    '& .SelectorLabel': {
      marginTop: 'auto',
      marginBottom: theme.spacing(0.2),
    },
  },
}));

const Selector = ({
  item,
  onClick,
  className,
  selectorClass,
}: {
  className?: string;
  item: TriSelectorOption;
  isActive: boolean;
  onClick: (x: TriSelectorOption) => void;
  selectorClass: string;
}) => (
  <Grid
    item
    container
    className={clsx('SelectorParent', className)}
    alignContent='center'
    justifyContent='center'
  >
    <SelectorContainer
      className={selectorClass}
      container
      item
      justifyContent='center'
      alignItems='center'
      onClick={() => onClick(item)}
    >
      <Typography component='span'>{item.count}</Typography>
    </SelectorContainer>
    <Typography variant='caption' className='SelectorLabel'>
      {item.name}
    </Typography>
  </Grid>
);

const TriSelector = ({
  onChange = () => {},
  value = [
    { name: 'Negative', count: 1 },
    { name: 'Neutral', count: 10 },
    { name: 'Positive', count: 50 },
  ],
}: TriSelectorProps) => {
  const [isActiveSelector, setActiveSelector] = useState<number>();
  const classes = useStyles();

  return (
    <PaperContainer>
      <TriSelectorRow
        container
        justifyContent='space-around'
        alignItems='center'
        wrap='nowrap'
      >
        {value.map((item: TriSelectorOption, index) => (
          <Selector
            item={item}
            key={index}
            isActive={isActiveSelector === index}
            onClick={(option: TriSelectorOption) => {
              onChange(option);
              setActiveSelector(index);
            }}
            selectorClass={classes.medium}
          />
        ))}
      </TriSelectorRow>
    </PaperContainer>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    small: {
      height: 34,
      width: 34,
    },
    medium: {
      height: 52,
      width: 52,
    },
    large: {
      height: 64,
      width: 64,
    },
  })
);

export default TriSelector;
