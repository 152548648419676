import { Bar } from 'react-chartjs-2';

import { ReportGraph as ReportGraphType } from 'src/types/report';

export const options = {
  plugins: {
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (context: any) => context[0].label.replaceAll(',', ' '),
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 10,
        },
      },
    },
    y: {
      stacked: true,
    },
  },
};

interface ReportGraphProps {
  data: ReportGraphType;
}

export default function ReportGraph({ data }: ReportGraphProps) {
  return <Bar options={options} data={data} />;
}
