import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActions, { CardActionsProps } from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { Theme } from 'src/theme/types/createPalette';
import { BillingInterval, SubscriptionDto } from 'src/types';
import {
  useColorStyles,
  useLayoutStyles,
} from 'src/components/useCommonStyles';
import { useViewport } from 'src/hooks/useViewport';
import { numberWithCommas } from 'src/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: 'relative',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      borderRadius: theme.spacing(1),
    },
    cardHeader: {
      fontSize: '2.0rem',
      color: 'white',
      backgroundColor: theme.palette.blackBackground.main,
      padding: theme.spacing(5, 3),
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'baseline',
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2),
    },
    cardContent: {
      flexGrow: 1,
    },
    features: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    chipContainer: {
      position: 'absolute',
      top: -15,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    chip: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

export interface PricingTierProps {
  plan: SubscriptionDto;
  width?: number | string;
  cardActions?: CardActionsProps['children'];
}

const PricingTier = ({ plan, width, cardActions }: PricingTierProps) => {
  const classes = useStyles();
  const { isMobile } = useViewport();
  const colorClasses = useColorStyles();
  const layoutClasses = useLayoutStyles();

  const {
    name,
    amount,
    interval,
    metadata: { benefits },
  } = plan;

  return (
    <Card
      elevation={5}
      style={{ minHeight: 400, width, marginBottom: isMobile ? 24 : 0 }}
      className={classes.card}
    >
      <CardHeader
        title={
          <>
            <Typography variant='h4'>{name}</Typography>
            <div className={clsx(classes.cardPricing, layoutClasses.mt2)}>
              <Typography variant='h2' className={layoutClasses.mr05}>
                $
              </Typography>
              <Typography variant='h1'>{numberWithCommas(amount)}</Typography>
              <Typography variant='h6' className={colorClasses.grayText}>
                {interval === BillingInterval.MONTH ? '/month' : '/year'}
              </Typography>
            </div>
          </>
        }
        titleTypographyProps={{ align: 'left', variant: 'h4' }}
        className={classes.cardHeader}
      />

      <CardContent className={classes.cardContent}>
        <ul className={classes.features}>
          <li>
            <Typography variant='h5'>Benefits:</Typography>
          </li>
          {benefits?.map((benefit: string) => (
            <li key={benefit}>
              <Box
                my={1}
                display='flex'
                flexDirection='row'
                alignItems='flex-start'
              >
                <CheckIcon
                  className={clsx(colorClasses.primary, layoutClasses.mr1)}
                />
                <Typography variant='subtitle1'>{benefit}</Typography>
              </Box>
            </li>
          ))}
        </ul>
      </CardContent>

      <CardActions className={classes.cardActions}>{cardActions}</CardActions>
    </Card>
  );
};

export default PricingTier;
